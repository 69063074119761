"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NotificationText = exports.NotificationContainer = exports.MenuContainer = exports.ImageContainer = exports.Image = exports.DurationWrapper = exports.Container = void 0;

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _polished = require("polished");

var _excluded = ["theme"];

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7;

var imageDiameter = '4.5rem';

var MenuContainer = _styledComponents.default.div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\tmin-width: ", ";\n\theight: ", ";\n\n\tlabel {\n\t\tmargin: 0;\n\t}\n"])), imageDiameter, "calc(".concat(imageDiameter, " + 2rem)"));

exports.MenuContainer = MenuContainer;

var Container = _styledComponents.default.div(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n\tdisplay: flex;\n\talign-items: flex-start;\n\tcursor: pointer;\n\n\t&:hover {\n\t\tbackground-color: ", ";\n\t}\n"])), _ref => {
  var {
    theme
  } = _ref;
  return (0, _polished.rgba)(theme['midnight-lighten-10'], 0.5);
});

exports.Container = Container;

var ImageContainer = _styledComponents.default.div(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["\n\ta {\n\t\tdisplay: block;\n\t\tpadding: 1rem 0 1rem 2.5rem;\n\t\tmin-width: ", ";\n\t}\n"])), imageDiameter);

exports.ImageContainer = ImageContainer;

var Image = _styledComponents.default.div(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)(["\n\tborder-radius: 50%;\n\twidth: ", ";\n\theight: ", ";\n\tbackground-color: ", ";\n\tbackground-image: url(", ");\n\tbackground-position: center center;\n\tbackground-repeat: no-repeat;\n\tbackground-size: cover;\n"])), imageDiameter, imageDiameter, _ref2 => {
  var {
    theme
  } = _ref2;
  return (0, _polished.rgba)(theme['white'], 0.1);
}, props => props.url);

exports.Image = Image;

var NotificationContainer = _styledComponents.default.div(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2.default)(["\n\ta {\n\t\tdisplay: flex;\n\t\talign-items: center;\n\t\tpadding: 1rem 0;\n\t\tmin-height: ", ";\n\t}\n"])), imageDiameter);

exports.NotificationContainer = NotificationContainer;

var NotificationText = _styledComponents.default.p(_templateObject6 || (_templateObject6 = (0, _taggedTemplateLiteral2.default)(["\n\tflex: 1 1 0;\n\tmargin: 0 1rem;\n\tcolor: ", ";\n\tfont-weight: ", ";\n\tfont-size: 0.9rem;\n\toverflow-wrap: anywhere;\n"])), _ref3 => {
  var {
    theme
  } = _ref3,
      props = (0, _objectWithoutProperties2.default)(_ref3, _excluded);
  return props.read ? (0, _polished.rgba)(theme['white'], 0.4) : theme['white'];
}, props => props.read ? '400' : '600');

exports.NotificationText = NotificationText;

var DurationWrapper = _styledComponents.default.span(_templateObject7 || (_templateObject7 = (0, _taggedTemplateLiteral2.default)(["\n\tfont-weight: 400;\n\twhite-space: nowrap;\n\n\tsvg {\n\t\tposition: relative;\n\t\ttop: -1px;\n\t\tmargin-right: 0.25rem;\n\t\twidth: 0.8rem;\n\t\theight: 0.8rem;\n\t}\n"])));

exports.DurationWrapper = DurationWrapper;