"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.attachTimestamp = attachTimestamp;
exports.createSelector = createSelector;
exports.extendQueue = extendQueue;
exports.filterQueue = filterQueue;
exports.getTranslationId = exports.getTranscriptsForOrder = exports.getTranscriptionId = exports.getOrderMode = void 0;
exports.handleError = handleError;
exports.identity = identity;
exports.isSubtitledGfxOrder = exports.isPrintOrder = exports.isPrintDateOrder = exports.isNarrationOrder = exports.isGfxOrder = exports.isGfxOnlyOrder = exports.isDialogueOrder = exports.isDedicatedGfxOrder = exports.isAutoGfxOrder = void 0;
exports.metaIdentity = metaIdentity;
exports.takeWithPayload = takeWithPayload;
exports.updateDispatcher = updateDispatcher;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _lodash = _interopRequireDefault(require("lodash"));

var _lodash2 = _interopRequireDefault(require("lodash.matches"));

var _effects = require("redux-saga/effects");

var _lodash3 = _interopRequireDefault(require("lodash.get"));

var _exceptionHandler = require("../modules/pixwel/exception-handler.factory");

var _toastService = _interopRequireDefault(require("../modules/services/toast-service"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function identity(payload) {
  return payload;
}

function metaIdentity(payload, meta) {
  return meta;
}

function attachTimestamp(payload, metadata) {
  return _objectSpread(_objectSpread({}, metadata), {}, {
    requestedAt: new Date()
  });
}

function extendQueue(queue, toAdd) {
  if (_lodash.default.isArray(toAdd)) {
    return _lodash.default.union(queue, toAdd);
  }

  if (queue.indexOf(toAdd) === -1) {
    queue.push(toAdd);
  }

  return queue;
}

function filterQueue(queue, toRemove) {
  if (_lodash.default.isArray(toRemove)) {
    return queue.filter(id => !toRemove.includes(id));
  }

  return queue.filter(id => toRemove !== id);
}
/**
 * Helper method that grabs our specific part of the global store, then passes it into the given selector.
 * This makes it so that each selector doesn't have to grab the sub-state itself, reducing code duplication.
 */


function createSelector(storeName, selector) {
  return function selectorWrapper(state) {
    var subStore = state[storeName];

    for (var _len = arguments.length, rest = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      rest[_key - 1] = arguments[_key];
    }

    return selector(subStore, ...rest);
  };
}
/**
 * Custom take method that checks action type and payload (partial not exact)
 */


function takeWithPayload(actions, payload) {
  return (0, _effects.take)(function takeMatcher(action) {
    if (!actions.include(action.type)) {
      return false;
    }

    var matcher = (0, _lodash2.default)(payload);
    return matcher(action.payload);
  });
}

function* updateDispatcher(channel, uploadRequest, actionFn) {
  var {
    id,
    file: {
      name
    }
  } = uploadRequest; // Capture all update events before handling the final result
  // Once the `take` receives an `END` event, this entire function
  // will just end, so we don't need to worry about the infinite loop.

  while (true) {
    var {
      progress = 0,
      error
    } = yield (0, _effects.take)(channel); // Convert 0-100 to 0.0-1.0

    progress /= 100;
    var update = {
      id,
      name,
      progress,
      error
    };

    if (actionFn) {
      yield (0, _effects.put)(actionFn(update));
    }
  }
}
/**
 * Handles exceptions in Redux saga catch block:
 * 1) if exception is not an error object, converts it to one
 * 2) captures error in LogRocket
 * 3) logs error to browser console
 * 4) returns error
 *
 * @param {*} e - exception thrown from try block
 * @returns {Object} - error object
 */


function handleError(e) {
  var _e$data, _e$data$error, _e$data2, _e$data2$error, _e$data3, _e$data3$error, _e$response2;

  var prependMsg = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'Error';

  if (!e) {
    return;
  }

  if ((e === null || e === void 0 ? void 0 : e.status) === 403 || (e === null || e === void 0 ? void 0 : (_e$data = e.data) === null || _e$data === void 0 ? void 0 : (_e$data$error = _e$data.error) === null || _e$data$error === void 0 ? void 0 : _e$data$error.code) === 403) {
    return e;
  }

  if ((e === null || e === void 0 ? void 0 : e.status) === 404 || (e === null || e === void 0 ? void 0 : (_e$data2 = e.data) === null || _e$data2 === void 0 ? void 0 : (_e$data2$error = _e$data2.error) === null || _e$data2$error === void 0 ? void 0 : _e$data2$error.code) === 404 || e !== null && e !== void 0 && e.cause) {
    return e;
  }

  if ((e === null || e === void 0 ? void 0 : e.status) === 405 || (e === null || e === void 0 ? void 0 : (_e$data3 = e.data) === null || _e$data3 === void 0 ? void 0 : (_e$data3$error = _e$data3.error) === null || _e$data3$error === void 0 ? void 0 : _e$data3$error.code) === 405) {
    var _e$data4, _e$data4$error;

    // 405 is a known error, no need to capture it if message is provided
    if (!(e !== null && e !== void 0 && (_e$data4 = e.data) !== null && _e$data4 !== void 0 && (_e$data4$error = _e$data4.error) !== null && _e$data4$error !== void 0 && _e$data4$error.message)) {
      (0, _exceptionHandler.captureError)(e, 'Unknown Error');
    }

    return;
  }

  if ((e === null || e === void 0 ? void 0 : e.status) === 422) {
    var _e$response;

    var errors = (_e$response = e.response) === null || _e$response === void 0 ? void 0 : _e$response.data;
    var errorMessages = Object.keys(errors).map(key => {
      return errors[key];
    });

    _toastService.default.create('error', errorMessages.join(', '));

    return;
  }

  var err;

  if (e.error) {
    err = new Error(e.data.error.message);
  } else if (e.data) {
    err = e.data.error ? new Error(e.data.error.message) : new Error(e.data.message);
  } else if ((_e$response2 = e.response) !== null && _e$response2 !== void 0 && _e$response2.data) {
    var _e$response$data;

    err = (_e$response$data = e.response.data) !== null && _e$response$data !== void 0 && _e$response$data.error ? new Error(e.response.data.error) : new Error(e.response.data.message);
  } else if (e.message) {
    var _e$message;

    var message = ((_e$message = e.message) === null || _e$message === void 0 ? void 0 : _e$message.message) || e.message || '';
    err = new Error(message);

    if (message.includes('404')) {
      return e;
    }
  } else {
    err = new Error(e);
  }

  (0, _exceptionHandler.captureError)(err, "".concat(prependMsg, ": ").concat(err.message));
  return err;
}
/* this functions tests if an order is requesting the translation of graphics
*  either Subbed or Dedicated/Localized
*  it is mainly used to load appropriate data for orders
* */


var isSubtitledGfxOrder = workRequest => {
  return (0, _lodash3.default)(workRequest, 'dng.graphics[0]', false) === 'Subtitled';
};

exports.isSubtitledGfxOrder = isSubtitledGfxOrder;

var isDedicatedGfxOrder = workRequest => {
  return (0, _lodash3.default)(workRequest, 'dng.graphics[0]', false) === 'Dedicated / Localized';
};

exports.isDedicatedGfxOrder = isDedicatedGfxOrder;

var isGfxOrder = workRequest => {
  return isSubtitledGfxOrder(workRequest) || isDedicatedGfxOrder(workRequest);
};

exports.isGfxOrder = isGfxOrder;

var isGfxOnlyOrder = workRequest => {
  return (isDedicatedGfxOrder(workRequest) || isSubtitledGfxOrder(workRequest)) && !isDialogueOrder(workRequest) && !isNarrationOrder(workRequest);
};
/* this function tests if an order is requesting the translation of the dialogue
*  Subbed option for dialogue
*  it is mainly used to load appropriate data for orders
* */


exports.isGfxOnlyOrder = isGfxOnlyOrder;

var isDialogueOrder = workRequest => {
  return (0, _lodash3.default)(workRequest, 'dng.dialogue[0]', false) === 'Subtitled' || workRequest.dng === 'Subtitled';
};
/* this function tests if an order is requesting the translation of the narration
*  Subbed option for narration
*  it is mainly used to load appropriate data for orders
* */


exports.isDialogueOrder = isDialogueOrder;

var isNarrationOrder = workRequest => {
  return (0, _lodash3.default)(workRequest, 'dng.narration[0]', false) === 'Subtitled';
};

exports.isNarrationOrder = isNarrationOrder;

var isPrintOrder = workRequest => {
  return workRequest.assetCategory === 'print';
};

exports.isPrintOrder = isPrintOrder;

var isPrintDateOrder = workRequest => {
  return workRequest.assetCategory === 'printdate';
};

exports.isPrintDateOrder = isPrintDateOrder;

var isAutoGfxOrder = workRequest => workRequest.auto && !!(0, _lodash3.default)(workRequest, 'asset.transcription.autogfx', false);

exports.isAutoGfxOrder = isAutoGfxOrder;

var getOrderMode = workRequest => {
  if (isPrintOrder(workRequest)) {
    return 'print';
  }

  if (isPrintDateOrder(workRequest)) {
    return 'printdate';
  }

  if (isGfxOrder(workRequest)) {
    if (isSubtitledGfxOrder(workRequest) && (isDialogueOrder(workRequest) || isNarrationOrder(workRequest))) {
      return 'script';
    }

    if (isDialogueOrder(workRequest) || isNarrationOrder(workRequest)) {
      return 'script+gfx';
    }

    if (isAutoGfxOrder(workRequest)) {
      return 'autogfx';
    }

    return 'gfx';
  }

  if (isDialogueOrder(workRequest) || isNarrationOrder(workRequest)) {
    return 'script';
  }
};

exports.getOrderMode = getOrderMode;

var getTranscriptionId = workRequest => {
  switch (getOrderMode(workRequest)) {
    case 'script':
    case 'print':
      return workRequest.asset.transcription._id;

    case 'autogfx':
      return workRequest.asset.transcription.autogfx;

    case 'gfx':
      return workRequest.asset.transcription.graphics;

    case 'script+gfx':
      return [workRequest.asset.transcription.dialogue, workRequest.auto ? workRequest.asset.transcription.autogfx : workRequest.asset.transcription.graphics];

    default:
      return workRequest.asset.transcription._id;
  }
};

exports.getTranscriptionId = getTranscriptionId;

var getTranslationId = workRequest => {
  var _workRequest$translat, _workRequest$graphics, _workRequest$translat2, _workRequest$graphics2, _workRequest$translat3;

  switch (getOrderMode(workRequest)) {
    case 'print':
    case 'script':
      return (_workRequest$translat = workRequest.translation) === null || _workRequest$translat === void 0 ? void 0 : _workRequest$translat._id;

    case 'autogfx':
    case 'gfx':
      return (_workRequest$graphics = workRequest.graphicsTranslation) === null || _workRequest$graphics === void 0 ? void 0 : _workRequest$graphics._id;

    case 'script+gfx':
      return [(_workRequest$translat2 = workRequest.translation) === null || _workRequest$translat2 === void 0 ? void 0 : _workRequest$translat2._id, (_workRequest$graphics2 = workRequest.graphicsTranslation) === null || _workRequest$graphics2 === void 0 ? void 0 : _workRequest$graphics2._id];

    default:
      return (_workRequest$translat3 = workRequest.translation) === null || _workRequest$translat3 === void 0 ? void 0 : _workRequest$translat3._id;
  }
};

exports.getTranslationId = getTranslationId;

var getTranscriptsForOrder = workRequest => {
  var transcripts = {
    _id: false,
    graphics: false,
    autogfx: false,
    dialogue: false
  };
  var isPrintMode = isPrintOrder(workRequest);
  var isGfxMode = isGfxOrder(workRequest);
  var isSubtitledGfx = isSubtitledGfxOrder(workRequest);
  var isDedicatedGfx = isDedicatedGfxOrder(workRequest);
  var isDialogueMode = isDialogueOrder(workRequest);
  var isNarrationMode = isNarrationOrder(workRequest);
  var isAuto = workRequest.auto;
  var isAutosubs = workRequest.autosubs;

  if (isPrintMode) {
    transcripts._id = true;
  } else if (isAutosubs && !isAuto) {
    transcripts._id = true;
  } // automated mode options
  else if (isAuto) {
    if (isGfxMode) {
      transcripts.autogfx = true;

      if (isDialogueMode) {
        transcripts.dialogue = true;
      }
    } else if (isDialogueMode) {
      transcripts._id = true;
    }
  } else {
    if (isGfxMode && !(isDialogueMode || isNarrationMode)) {
      transcripts.graphics = true;
    }

    if ((isDialogueMode || isNarrationMode) && !isGfxMode) {
      transcripts._id = true;
    }

    if ((isDialogueMode || isNarrationMode) && isGfxMode) {
      if (isSubtitledGfx) {
        transcripts._id = true;
      } else if (isDedicatedGfx) {
        transcripts.dialogue = true;
        transcripts.graphics = true;
      }
    }
  }

  return transcripts;
};

exports.getTranscriptsForOrder = getTranscriptsForOrder;