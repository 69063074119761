"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _apiService = _interopRequireDefault(require("./api-service"));

var CommentService = {
  createComment(data) {
    return _apiService.default.client.post('/comments', data).then(res => res.data);
  },

  getCommentsForWorkRequest(workRequestId) {
    return _apiService.default.client.get("/comments?workRequest=".concat(workRequestId)).then(res => res.data);
  },

  getCommentsForOffline(offlineId) {
    return _apiService.default.client.get("/comments?offline=".concat(offlineId)).then(res => res.data);
  },

  deleteComment(id) {
    return _apiService.default.client.delete("/comments/".concat(id)).then(res => res.data);
  },

  convertToImageAnnotation(comment) {
    var result = {
      _id: comment._id,
      created: comment.created,
      user: comment.user,
      body: comment.body
    };

    if (comment.replies) {
      // this is a comment
      result.replies = comment.replies.map(reply => {
        return {
          _id: reply._id,
          replyTo: reply.replyTo,
          created: reply.created,
          user: reply.user,
          body: reply.body
        };
      });
    }

    if (comment.replyTo) {
      // this is a reply
      result.replyTo = comment.replyTo;
    }

    if (comment.annotation) {
      // this is an annotation
      result.annotation = comment.annotation;
    }

    return result;
  }

};
var _default = CommentService;
exports.default = _default;