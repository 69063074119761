"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actions = exports.STORE_NAME = exports.INITIAL_STATE = exports.FETCH_STUDIOS_SUCCESS = exports.FETCH_STUDIOS_REQUEST = exports.FETCH_STUDIOS_FAILURE = exports.FETCH_STUDIOS = void 0;
exports.fetchStudios = fetchStudios;
exports.reducer = reducer;
exports.selectors = void 0;
exports.watchStudios = watchStudios;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _lodash = _interopRequireDefault(require("lodash"));

var _reduxActions = require("redux-actions");

var _effects = require("redux-saga/effects");

var _common = require("./common");

var _session = require("./session");

var _studioService = _interopRequireDefault(require("~/app/3x/modules/services/studio-service"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var STORE_NAME = 'studiosStore';
exports.STORE_NAME = STORE_NAME;

function* fetchStudios(_ref) {
  var {
    payload
  } = _ref;

  try {
    yield (0, _effects.put)(internalActions.getStudiosRequest(payload));
    var studios = yield _studioService.default.getAllStudios();
    yield (0, _effects.put)(internalActions.getStudiosSuccess(studios));
    return studios;
  } catch (e) {
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.getStudiosFailure(err));
    return err;
  }
}

var FETCH_STUDIOS = 'studios.fetch';
exports.FETCH_STUDIOS = FETCH_STUDIOS;
var FETCH_STUDIOS_REQUEST = 'studios.fetch.request';
exports.FETCH_STUDIOS_REQUEST = FETCH_STUDIOS_REQUEST;
var FETCH_STUDIOS_SUCCESS = 'studios.fetch.success';
exports.FETCH_STUDIOS_SUCCESS = FETCH_STUDIOS_SUCCESS;
var FETCH_STUDIOS_FAILURE = 'studios.fetch.failure';
exports.FETCH_STUDIOS_FAILURE = FETCH_STUDIOS_FAILURE;
var INITIAL_STATE = {
  isLoading: false,
  lastLoadedAt: null,
  studios: {}
};
exports.INITIAL_STATE = INITIAL_STATE;

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _session.RESET_STORES:
      return INITIAL_STATE;

    case FETCH_STUDIOS_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });

    case FETCH_STUDIOS_SUCCESS:
      {
        var now = new Date();

        var studios = _lodash.default.reduce(action.payload, (result, studio) => {
          result[studio._id] = {
            isLoading: false,
            lastLoadedAt: now,
            data: studio
          };
          return result;
        }, {});

        return _objectSpread(_objectSpread({}, state), {}, {
          isLoading: false,
          lastLoadedAt: now,
          studios: _objectSpread(_objectSpread({}, state.studios), studios)
        });
      }

    case FETCH_STUDIOS_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });

    default:
      return state;
  }
}

var actions = {
  getStudios: (0, _reduxActions.createAction)(FETCH_STUDIOS)
};
/**
 * Actions that should only be invoked internally
 */

exports.actions = actions;
var internalActions = {
  getStudiosRequest: (0, _reduxActions.createAction)(FETCH_STUDIOS_REQUEST),
  getStudiosSuccess: (0, _reduxActions.createAction)(FETCH_STUDIOS_SUCCESS, _common.identity, _common.attachTimestamp),
  getStudiosFailure: (0, _reduxActions.createAction)(FETCH_STUDIOS_FAILURE)
};
var selectors = {
  isLoading: createSelector(isLoading),
  isLoaded: createSelector(isLoaded),
  getStudios: createSelector(getStudios),
  getStudioById: createSelector(getStudioById),
  getLastLoadedAt: createSelector(getLastLoadedAt)
};
exports.selectors = selectors;

function isLoading(state) {
  var studioId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

  if (!studioId) {
    return state.isLoading;
  }

  var studio = getStudioContainer(state, studioId);

  if (!studio) {
    return false;
  }

  return studio.isLoading;
}

function isLoaded(state) {
  var studioId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

  if (!studioId) {
    return state.lastLoadedAt !== null;
  }

  var studio = getStudioContainer(state, studioId);

  if (!studio) {
    return false;
  }

  return studio.lastLoadedAt !== null;
}

function getStudios(state) {
  return _lodash.default.values(state.studios).map(wrapper => wrapper.data);
}

function getStudioById(state, id) {
  var studio = state[id];
  return studio ? studio.data : studio;
}

function getLastLoadedAt(state, id) {
  if (!id) {
    return state.lastLoadedAt;
  }

  var studioContainer = getStudioContainer(state, id);
  return studioContainer ? studioContainer.lastLoadedAt : null;
}

function getStudioContainer(state, id) {
  return state.studios[id];
}

function createSelector(selector) {
  return function wrappedSelector(state) {
    var studiosStore = state[STORE_NAME];

    for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      args[_key - 1] = arguments[_key];
    }

    return selector(studiosStore, ...args);
  };
}

function* watchStudios() {
  yield (0, _effects.takeLatest)(FETCH_STUDIOS, fetchStudios);
}