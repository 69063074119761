"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.cdn = cdn;

function cdn(url) {
  if (!url) {
    return;
  }

  if (url.includes('apple-touch-icon')) {
    return url;
  }

  var uri = new URL("https:".concat(url));

  switch (window.env) {
    case 'development':
      return "http://".concat(url);

    case 'staging':
      return "https://icdn-staging.pixwel.com/".concat(uri.pathname);

    case 'production':
      return "https://icdn.pixwel.com/".concat(uri.pathname);
  }
}