"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _authorizedUploadHandler = _interopRequireDefault(require("./authorized-upload-handler"));

var UploadService = {
  uploadTranslation(file, options, updateCb) {
    var {
      translationId
    } = options;
    var transformedOptions = {
      file,
      updateCb,
      request: {
        usage: 'customTranslation',
        name: 'attachments',
        translation: translationId
      }
    };
    return (0, _authorizedUploadHandler.default)(transformedOptions);
  },

  uploadFile(file, options, updateCb) {
    var {
      workRequestId
    } = options;
    var transformedOptions = {
      file,
      updateCb,
      request: {
        usage: 'workRequestAttachment',
        name: 'attachments',
        workRequest: workRequestId
      }
    };
    return (0, _authorizedUploadHandler.default)(transformedOptions);
  },

  uploadTagFile(file, options, updateCb) {
    var {
      tagIndex,
      fileIndex,
      tag,
      workRequestId
    } = options;
    var transformedOptions = {
      tagIndex,
      fileIndex,
      file,
      updateCb,
      request: {
        usage: 'workRequestAttachment',
        name: tag.tag,
        workRequest: workRequestId
      }
    };
    return (0, _authorizedUploadHandler.default)(transformedOptions);
  },

  uploadOffline(file, options, updateCb) {
    var {
      workRequestId,
      tagName
    } = options;

    if (!file) {
      throw new Error('The provided file is not valid');
    }

    var transformedOptions = {
      file,
      updateCb,
      request: {
        usage: 'workRequestOffline',
        workRequest: workRequestId,
        tag: tagName
      }
    };
    return (0, _authorizedUploadHandler.default)(transformedOptions);
  },

  uploadFileIngest(file, options, updateCb) {
    var {
      ingestId
    } = options;
    var transformedOptions = {
      file,
      updateCb,
      request: {
        usage: 'slurpee2',
        ingest: ingestId
      }
    };
    return (0, _authorizedUploadHandler.default)(transformedOptions);
  },

  uploadFileGraphicsProject(file, options, updateCb) {
    var {
      ingestId,
      assetId
    } = options;
    var transformedOptions = {
      file,
      updateCb,
      request: {
        usage: 'graphicsProject',
        name: 'graphicsProject',
        ingest: ingestId,
        asset: assetId,
        'Content-Type': 'application/zip'
      }
    };
    return (0, _authorizedUploadHandler.default)(transformedOptions);
  },

  uploadFileAssetAudio(file, options, updateCb) {
    var {
      assetId,
      language
    } = options;
    var transformedOptions = {
      file,
      updateCb,
      request: {
        usage: 'assetAudio',
        name: 'assetAudio',
        asset: assetId,
        language
      }
    };
    return (0, _authorizedUploadHandler.default)(transformedOptions);
  },

  uploadFileWorkRequestAudio(file, options, updateCb) {
    var transformedOptions = {
      file,
      updateCb,
      request: {
        usage: 'workRequestAudio',
        name: 'workRequestAudio'
      }
    };
    return (0, _authorizedUploadHandler.default)(transformedOptions, options.signal);
  }

};
var _default = UploadService;
exports.default = _default;