"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actions = exports.UPLOAD_GRAPHICS_PROJECT_UPDATE = exports.UPLOAD_GRAPHICS_PROJECT_SUCCESS = exports.UPLOAD_GRAPHICS_PROJECT_REQUEST = exports.UPLOAD_GRAPHICS_PROJECT_FAILURE = exports.UPLOAD_GRAPHICS_PROJECT = exports.UPLOAD_FILE_UPDATE = exports.UPLOAD_FILE_SUCCESS = exports.UPLOAD_FILE_RETRY = exports.UPLOAD_FILE_REQUEST = exports.UPLOAD_FILE_FAILURE = exports.UPLOAD_FILE_CANCEL = exports.UPLOAD_FILES = exports.UPLOAD_FILE = exports.STORE_NAME = exports.INITIAL_STATE = exports.CLEAR_COMPLETED_UPLOADS = void 0;
exports.reducer = reducer;
exports.selectors = void 0;
exports.watchIngestUpload = watchIngestUpload;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _lodash = _interopRequireDefault(require("lodash"));

var _lodash2 = _interopRequireDefault(require("lodash.iserror"));

var _reduxSaga = require("redux-saga");

var _reduxActions = require("redux-actions");

var _effects = require("redux-saga/effects");

var _v = _interopRequireDefault(require("uuid/v4"));

var _common = require("./common");

var _ingestService = _interopRequireDefault(require("../modules/services/ingest-service"));

var _uploadService = _interopRequireDefault(require("../modules/services/upload-service"));

var _fileService = _interopRequireDefault(require("../modules/services/file-service"));

var _routeService = _interopRequireDefault(require("../modules/services/route-service"));

var _assetService = _interopRequireDefault(require("../modules/services/asset-service"));

var _toastService = _interopRequireDefault(require("../modules/services/toast-service"));

var _ingests = require("./ingests");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var STORE_NAME = 'ingestUploadStore';
exports.STORE_NAME = STORE_NAME;

function* uploadGraphicsProject(_ref) {
  var {
    payload
  } = _ref;
  var {
    file,
    ingest
  } = payload;
  var uploadId = (0, _v.default)();
  var assetId = ingest.asset.id;
  var ingestId = ingest.id;

  var name = _fileService.default.getFileName(file.name);

  try {
    var _ingest$asset;

    yield (0, _effects.put)(internalActions.uploadGraphicsProjectRequest({
      uploadId,
      name
    }));
    var uploadResult = yield (0, _effects.call)(uploadFileGraphicsProject, file, {
      uploadId,
      ingestId,
      assetId
    });

    if ((0, _lodash2.default)(uploadResult.result)) {
      throw uploadResult.result;
    }

    var files = ((_ingest$asset = ingest.asset) === null || _ingest$asset === void 0 ? void 0 : _ingest$asset.file) || {};
    var asset = yield (0, _effects.call)(_assetService.default.updateAsset, assetId, {
      file: _objectSpread(_objectSpread({}, files), {}, {
        graphicsProject: uploadResult.result
      })
    });

    if ((0, _lodash2.default)(asset)) {
      _toastService.default.create('error', 'Asset could not be updated successfully, please try again');

      throw asset;
    }

    _toastService.default.create('success', 'File uploaded successfully');

    var analysis = yield (0, _effects.call)(_assetService.default.triggerGraphicsAnalysis, assetId);

    if ((0, _lodash2.default)(analysis)) {
      _toastService.default.create('error', 'Graphics Analysis Could Not Be Launched Successfully, Please Try Again');

      throw analysis;
    }

    _toastService.default.create('success', 'Graphics Analysis Launched Successfully');

    return;
  } catch (e) {
    yield (0, _common.handleError)(e);
  }
}

function* uploadFileGraphicsProject(file, params) {
  var {
    uploadId,
    ingestId,
    assetId
  } = params;
  var uploadRequest = {
    ingestId,
    assetId,
    file
  }; // Create an event channel so that our async updates can dispatch update events.
  // The inner upload method always resolves, that way parallel tasks are not

  var resultPromise;
  var channel = (0, _reduxSaga.eventChannel)(emit => {
    var emitUpdate = update => {
      emit(update);
    };

    resultPromise = _uploadService.default.uploadFileGraphicsProject(uploadRequest.file, uploadRequest, emitUpdate).then(result => {
      return {
        uploadRequest,
        result
      };
    }).catch(e => {
      var err = (0, _lodash2.default)(e) ? e : new Error(e); // Do not emit Error directly as it will crash the channel

      emit({
        error: err
      });
      return {
        uploadRequest,
        result: err
      };
    }).finally(() => {
      // End this channel
      emit(_reduxSaga.END);
    });
    return _lodash.default.noop;
  }); // We have to fork the update dispatcher because the when a channel ends, the generator
  // function is ended at the `take` and no additional code is run. We don't want this
  // generator function to end because we need to be able to return the promise to the
  // calling function. Can't do that when a generator function just ends.

  yield (0, _effects.fork)(_common.updateDispatcher, channel, uploadRequest, update => {
    return internalActions.uploadGraphicsProjectUpdate({
      uploadId,
      progress: update.progress
    });
  });
  var uploadResult = yield resultPromise;
  return uploadResult;
}

function* uploadFiles(_ref2) {
  var {
    payload
  } = _ref2;
  var {
    files,
    context
  } = payload;
  var tasks = files.map(file => (0, _effects.call)(uploadFile, actions.uploadFile({
    file,
    context
  })));
  yield (0, _effects.all)(tasks);
}

function* uploadFile(_ref3) {
  var {
    payload
  } = _ref3;
  var {
    file,
    context
  } = payload;
  var uploadId = (0, _v.default)();

  var name = _fileService.default.getFileName(file.name);

  try {
    yield (0, _effects.put)(internalActions.uploadFileRequest({
      uploadId,
      name
    }));
    var {
      size
    } = file;

    var url = _routeService.default.stateHref('ingest.view', {
      id: ':ingest'
    }, {
      absolute: true
    });

    var urls = {
      view: decodeURIComponent(url)
    };
    var ingest = yield _ingestService.default.create({
      context,
      size,
      urls
    });
    var ingestId = ingest._id;
    var uploadResult = yield (0, _effects.call)(uploadFileIngest, file, {
      uploadId,
      ingestId
    });

    if ((0, _lodash2.default)(uploadResult.result)) {
      throw uploadResult.result;
    }

    ingest = yield _ingestService.default.update(ingest._id, {
      status: 'uploaded',
      source: {
        url: uploadResult.result
      }
    });
    yield (0, _effects.put)(_ingests.actions.addIngestManually({
      ingest
    }));
    yield (0, _effects.put)(internalActions.uploadFileSuccess());
    return ingest;
  } catch (e) {
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.uploadFileFailure({
      uploadId,
      error: err
    }));
    return err;
  }
}

function* uploadFileIngest(file, params) {
  var {
    uploadId,
    ingestId
  } = params;
  var uploadRequest = {
    ingestId,
    file
  }; // Create an event channel so that our async updates can dispatch update events.
  // The inner upload method always resolves, that way parallel tasks are not

  var resultPromise;
  var channel = (0, _reduxSaga.eventChannel)(emit => {
    var emitUpdate = update => {
      emit(update);
    };

    resultPromise = _uploadService.default.uploadFileIngest(uploadRequest.file, uploadRequest, emitUpdate).then(result => {
      return {
        uploadRequest,
        result
      };
    }).catch(e => {
      var err = (0, _lodash2.default)(e) ? e : new Error(e); // Do not emit Error directly as it will crash the channel

      emit({
        error: err
      });
      return {
        uploadRequest,
        result: err
      };
    }).finally(() => {
      // End this channel
      emit(_reduxSaga.END);
    });
    return _lodash.default.noop;
  }); // We have to fork the update dispatcher because the when a channel ends, the generator
  // function is ended at the `take` and no additional code is run. We don't want this
  // generator function to end because we need to be able to return the promise to the
  // calling function. Can't do that when a generator function just ends.

  yield (0, _effects.fork)(_common.updateDispatcher, channel, uploadRequest, update => {
    return internalActions.uploadFileUpdate({
      uploadId,
      progress: update.progress
    });
  });
  var uploadResult = yield resultPromise;
  return uploadResult;
}

var UPLOAD_FILES = 'ingest-upload.upload-files';
exports.UPLOAD_FILES = UPLOAD_FILES;
var UPLOAD_FILE = 'ingest-upload.upload-file';
exports.UPLOAD_FILE = UPLOAD_FILE;
var UPLOAD_FILE_REQUEST = 'ingest-upload.upload-file.request';
exports.UPLOAD_FILE_REQUEST = UPLOAD_FILE_REQUEST;
var UPLOAD_FILE_SUCCESS = 'ingest-upload.upload-file.success';
exports.UPLOAD_FILE_SUCCESS = UPLOAD_FILE_SUCCESS;
var UPLOAD_FILE_FAILURE = 'ingest-upload.upload-file.failure';
exports.UPLOAD_FILE_FAILURE = UPLOAD_FILE_FAILURE;
var UPLOAD_FILE_UPDATE = 'ingest-upload.upload-file.update';
exports.UPLOAD_FILE_UPDATE = UPLOAD_FILE_UPDATE;
var UPLOAD_FILE_RETRY = 'ingest-upload.upload-file.retry';
exports.UPLOAD_FILE_RETRY = UPLOAD_FILE_RETRY;
var UPLOAD_FILE_CANCEL = 'ingest-upload.upload-file.cancel';
exports.UPLOAD_FILE_CANCEL = UPLOAD_FILE_CANCEL;
var CLEAR_COMPLETED_UPLOADS = 'ingest-upload.clear.completed';
exports.CLEAR_COMPLETED_UPLOADS = CLEAR_COMPLETED_UPLOADS;
var UPLOAD_GRAPHICS_PROJECT = 'ingest-upload.upload-graphics-project';
exports.UPLOAD_GRAPHICS_PROJECT = UPLOAD_GRAPHICS_PROJECT;
var UPLOAD_GRAPHICS_PROJECT_REQUEST = 'ingest-upload.upload-graphics-project.request';
exports.UPLOAD_GRAPHICS_PROJECT_REQUEST = UPLOAD_GRAPHICS_PROJECT_REQUEST;
var UPLOAD_GRAPHICS_PROJECT_SUCCESS = 'ingest-upload.upload-graphics-project.success';
exports.UPLOAD_GRAPHICS_PROJECT_SUCCESS = UPLOAD_GRAPHICS_PROJECT_SUCCESS;
var UPLOAD_GRAPHICS_PROJECT_FAILURE = 'ingest-upload.upload-graphics-project.failure';
exports.UPLOAD_GRAPHICS_PROJECT_FAILURE = UPLOAD_GRAPHICS_PROJECT_FAILURE;
var UPLOAD_GRAPHICS_PROJECT_UPDATE = 'ingest-upload.upload-graphics-project.update';
exports.UPLOAD_GRAPHICS_PROJECT_UPDATE = UPLOAD_GRAPHICS_PROJECT_UPDATE;
var INITIAL_STATE = {
  graphicsProjects: {}
};
exports.INITIAL_STATE = INITIAL_STATE;

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case UPLOAD_FILE_REQUEST:
      {
        var {
          uploadId,
          name
        } = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          [uploadId]: {
            name,
            progress: 0
          }
        });
      }

    case UPLOAD_FILE_SUCCESS:
      {
        return _objectSpread({}, state);
      }

    case UPLOAD_FILE_UPDATE:
      {
        var {
          uploadId: _uploadId,
          progress
        } = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          [_uploadId]: _objectSpread(_objectSpread({}, state[_uploadId]), {}, {
            progress
          })
        });
      }

    case UPLOAD_FILE_FAILURE:
      {
        var {
          uploadId: _uploadId2,
          error
        } = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          [_uploadId2]: _objectSpread(_objectSpread({}, state[_uploadId2]), {}, {
            progress: 0,
            error
          })
        });
      }

    case UPLOAD_GRAPHICS_PROJECT_REQUEST:
      {
        var {
          uploadId: _uploadId3,
          name: _name
        } = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          graphicsProjects: _objectSpread(_objectSpread({}, state.graphicsProjects), {}, {
            [_uploadId3]: {
              name: _name,
              progress: 0
            }
          })
        });
      }

    case UPLOAD_GRAPHICS_PROJECT_SUCCESS:
      {
        return _objectSpread({}, state);
      }

    case UPLOAD_GRAPHICS_PROJECT_UPDATE:
      {
        var {
          uploadId: _uploadId4,
          progress: _progress
        } = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          graphicsProjects: _objectSpread(_objectSpread({}, state.graphicsProjects), {}, {
            [_uploadId4]: _objectSpread(_objectSpread({}, state.graphicsProjects[_uploadId4]), {}, {
              progress: _progress
            })
          })
        });
      }

    case UPLOAD_GRAPHICS_PROJECT_FAILURE:
      {
        var {
          uploadId: _uploadId5,
          error: _error
        } = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          graphicsProjects: _objectSpread(_objectSpread({}, state.graphicsProjects), {}, {
            [_uploadId5]: _objectSpread(_objectSpread({}, state[_uploadId5]), {}, {
              progress: 0,
              error: _error
            })
          })
        });
      }

    case CLEAR_COMPLETED_UPLOADS:
      {
        var newState = _objectSpread({}, state);

        for (var id in newState) {
          if (newState.hasOwnProperty(id)) {
            if (newState[id].progress === 1) {
              delete newState[id];
            }
          }
        }

        return newState;
      }

    default:
      return state;
  }
}

var actions = {
  uploadFiles: (0, _reduxActions.createAction)(UPLOAD_FILES),
  uploadFile: (0, _reduxActions.createAction)(UPLOAD_FILE),
  retryUploadFile: (0, _reduxActions.createAction)(UPLOAD_FILE_RETRY),
  cancelUploadFile: (0, _reduxActions.createAction)(UPLOAD_FILE_CANCEL),
  clearCompleted: (0, _reduxActions.createAction)(CLEAR_COMPLETED_UPLOADS),
  uploadGraphicsProject: (0, _reduxActions.createAction)(UPLOAD_GRAPHICS_PROJECT)
};
/**
 * Actions that should only be invoked internally
 */

exports.actions = actions;
var internalActions = {
  uploadFileRequest: (0, _reduxActions.createAction)(UPLOAD_FILE_REQUEST),
  uploadFileSuccess: (0, _reduxActions.createAction)(UPLOAD_FILE_SUCCESS),
  uploadFileFailure: (0, _reduxActions.createAction)(UPLOAD_FILE_FAILURE),
  uploadFileUpdate: (0, _reduxActions.createAction)(UPLOAD_FILE_UPDATE),
  uploadGraphicsProjectRequest: (0, _reduxActions.createAction)(UPLOAD_GRAPHICS_PROJECT_REQUEST),
  uploadGraphicsProjectSuccess: (0, _reduxActions.createAction)(UPLOAD_GRAPHICS_PROJECT_SUCCESS),
  uploadGraphicsProjectFailure: (0, _reduxActions.createAction)(UPLOAD_GRAPHICS_PROJECT_FAILURE),
  uploadGraphicsProjectUpdate: (0, _reduxActions.createAction)(UPLOAD_GRAPHICS_PROJECT_UPDATE)
};
var selectors = {
  getIngestUploads: (0, _common.createSelector)(STORE_NAME, getIngestUploads),
  getGraphicsProjectUploads: (0, _common.createSelector)(STORE_NAME, getGraphicsProjectUploads),
  isUploadingGraphicsProject: (0, _common.createSelector)(STORE_NAME, isUploadingGraphicsProject)
};
exports.selectors = selectors;

function getIngestUploads(state) {
  return _lodash.default.difference(Object.keys(state), Object.keys(INITIAL_STATE)).map(id => _objectSpread({
    id
  }, state[id]));
}

function getGraphicsProjectUploads(state) {
  var graphicsProjectUploads = Object.keys(state.graphicsProjects) || [];
  return _lodash.default.map(graphicsProjectUploads, id => _objectSpread({
    id
  }, state.graphicsProjects[id]));
}

function isUploadingGraphicsProject(state) {
  var progresses = _lodash.default.map(state.graphicsProjects, 'progress');

  return _lodash.default.some(progresses, progress => progress > 0 && progress < 1);
}

function* watchIngestUpload() {
  yield (0, _effects.takeEvery)(UPLOAD_FILES, uploadFiles);
  yield (0, _effects.takeEvery)(UPLOAD_FILE, uploadFile);
  yield (0, _effects.takeEvery)(UPLOAD_GRAPHICS_PROJECT, uploadGraphicsProject);
}