"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _parseSrt = _interopRequireDefault(require("parse-srt"));

var _stripTagsService = _interopRequireDefault(require("./strip-tags-service"));

var _subtitleService = _interopRequireDefault(require("./subtitle-service"));

var _timeFormatService = _interopRequireDefault(require("./time-format-service"));

var _lodash = _interopRequireDefault(require("lodash"));

var SRTService = {
  parse: data => {
    // check for malformed times
    var lines = data.split('\n');
    lines.forEach(line => {
      if (line.includes(' --> ')) {
        var range = line.split(' --> ');

        if (!_timeFormatService.default.isValidTime(range[0]) || !_timeFormatService.default.isValidTime(range[1])) {
          throw new Error('Malformed SRT file');
        }
      }
    });
    return (0, _parseSrt.default)(data).map(_ref => {
      var {
        start,
        end,
        text
      } = _ref;
      return {
        startTime: _subtitleService.default.offsetToTimecode(start),
        stopTime: _subtitleService.default.offsetToTimecode(end),
        text: text.replace(/<br \/>/, '\n')
      };
    });
  },
  export: lines => {
    var result = '';
    var i = 1;
    lines.forEach(line => {
      if (line.text === undefined) {
        return;
      }

      line.text = line.text.replace(/&nbsp;/g, ' '); // Replace <div> and <br> tags with newlines. These tags may accidentally be stored in the translation

      var text = _lodash.default.unescape(line.text.replace(/<br>|<\/div>/g, '\n')); // Remove any tags that may have made it into the translation (div tags seem to be the main problem).
      // <b> <i> <u> are the only two supported tags currently


      text = (0, _stripTagsService.default)(text, '<b><i><u>');
      result += i++ + '\n';
      result += _timeFormatService.default.startTime(line) + ' --> ' + _timeFormatService.default.stopTime(line) + '\n';
      result += text + '\n\n';
    });
    return result;
  }
};
var _default = SRTService;
exports.default = _default;