"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _lodash = _interopRequireDefault(require("lodash"));

var _config = require("~/config/config.3x");

var _apiService = _interopRequireDefault(require("./api-service"));

var LocaleService = {
  catalogs: [],
  lang: 'en',

  initialize() {
    return (0, _asyncToGenerator2.default)(function* () {
      LocaleService.catalogs = _config.CATALOG_MANIFEST;
      var browserLanguage = window.navigator.language || window.navigator.userLanguage;
      var language = browserLanguage.toLowerCase();

      if (_lodash.default.find(LocaleService.catalogs, {
        name: language
      })) {
        return LocaleService.loadLocale(language);
      }

      language = language.split(/(_|-)/)[0];

      if (_lodash.default.find(LocaleService.catalogs, {
        name: language
      })) {
        return LocaleService.loadLocale(language);
      }

      return LocaleService.loadLocale('en');
    })();
  },

  loadLocale(lang) {
    return (0, _asyncToGenerator2.default)(function* () {
      var i = LocaleService._findUrl(lang, true) || LocaleService._findUrl(lang, false);

      lang = LocaleService.catalogs[i]['lang'];

      if (LocaleService.catalogs[i]['loaded']) {
        LocaleService.lang = lang;
        return lang;
      }

      var {
        data
      } = yield _apiService.default.client.get(LocaleService.catalogs[i]['url'], {
        headers: {
          'Authorization': null
        }
      });
      LocaleService.catalogs[i]['loaded'] = true;
      LocaleService.catalogs[lang] = data;
      LocaleService.lang = lang;
      return lang;
    })();
  },

  _findUrl(lang, strict) {
    for (var i in LocaleService.catalogs) {
      if (strict && LocaleService.catalogs[i]['lang'] === lang) {
        return i;
      }

      if (!strict && LocaleService.catalogs[i]['lang'] === lang.split(/(_|-)/)[0]) {
        return i;
      }
    }

    return null;
  },

  getLocaleValue(key, params) {
    if (!(LocaleService.lang in LocaleService.catalogs)) {
      LocaleService.catalogs[LocaleService.lang] = {};
    }

    var message = LocaleService.catalogs[LocaleService.lang][key];

    if (_lodash.default.isObject(message)) {
      var nb = params.count > 1 ? 'n' : params.count;

      if (!message[nb]) {
        for (nb in message) {
          break;
        }
      }

      message = message[nb];
    }

    return LocaleService.interpolate(message, params);
  },

  interpolate(message, params) {
    if (!params) {
      return message;
    }

    var match = message.match(/{{(.*)}}/);

    if (match) {
      for (var i = 0; i < match.length; i += 2) {
        var toReplace = match[i];
        var replace = params[match[i + 1].trim()];
        message = message.replace(toReplace, replace);
      }
    }

    return message;
  }

};
var _default = LocaleService;
exports.default = _default;