"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.ERROR_TERRITORIES_UNKNOWN = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _apiService = _interopRequireDefault(require("./api-service"));

var _lodash2 = _interopRequireDefault(require("lodash.get"));

var TERRITORIES_QUERY = "query getTerritories($offset: Int, $limit: Int, $search: String) {\n  territories (offset: $offset, limit: $limit, search: $search) {\n  \ttotalCount\n    nodes {\n      id\n      name\n      code\n      standard\n      countries\n      languages\n    }\n  }\n}";
var TERRITORIES_QUERY_SHORT = "query getTerritories ($limit: Int, $search: String) {\n\tterritories (limit: $limit, search: $search) {\n\t\ttotalCount\n\t\tnodes {\n\t\t\tcode\n\t\t\tname\n\t\t}\n\t}\n}";
var TERRITORIES_QUERY_SHORT_LABEL_VALUE = "query getTerritories ($limit: Int, $search: String) {\n\tterritories (limit: $limit, search: $search) {\n\t\ttotalCount\n\t\tnodes {\n\t\t\tvalue: id\n\t\t\tlabel: name\n\t\t}\n\t}\n}";
var ERROR_TERRITORIES_UNKNOWN = 'error.territory.unknown';
exports.ERROR_TERRITORIES_UNKNOWN = ERROR_TERRITORIES_UNKNOWN;
var TerritoryService = {
  getSelectableTerritories(territories) {
    return _lodash.default.filter(territories, territory => {
      return ['WW', 'AC'].indexOf(territory.code) === -1;
    });
  },

  getTerritoriesWithCodes(territories) {
    return _lodash.default.filter(territories, territory => territory.code && territory.code !== '--');
  },

  getAllTerritories(params) {
    return _apiService.default.graphql.request(TERRITORIES_QUERY, params).then(res => {
      var _res$territories, _res$territories$node, _res$territories2;

      return params ? (_res$territories = res === null || res === void 0 ? void 0 : res.territories) !== null && _res$territories !== void 0 ? _res$territories : {} : (_res$territories$node = res === null || res === void 0 ? void 0 : (_res$territories2 = res.territories) === null || _res$territories2 === void 0 ? void 0 : _res$territories2.nodes) !== null && _res$territories$node !== void 0 ? _res$territories$node : [];
    });
  },

  getAllTerritoriesShort(params) {
    return _apiService.default.graphql.request(TERRITORIES_QUERY_SHORT, params).then(data => data.territories.nodes);
  },

  getAllTerritoriesShortLabelValue(params) {
    return _apiService.default.graphql.request(TERRITORIES_QUERY_SHORT_LABEL_VALUE, params).then(data => data.territories);
  },

  postTerritory(data) {
    return new Promise((resolve, reject) => {
      _apiService.default.client.post("/territories", data).then(resolve).catch(err => {
        var _err$response;

        reject({
          code: ERROR_TERRITORIES_UNKNOWN,
          message: Object.keys(err.response.data).map(key => (0, _lodash2.default)(err, "response.data.".concat(key, "[0]"), 'Failed to create a new territory'))[0],
          innerError: (err === null || err === void 0 ? void 0 : (_err$response = err.response) === null || _err$response === void 0 ? void 0 : _err$response.data) || err
        });
      });
    });
  },

  patchTerritory(data) {
    return new Promise((resolve, reject) => {
      _apiService.default.client.patch("/territories/".concat(data.id), data).then(resolve).catch(err => {
        var _err$response2;

        reject({
          code: ERROR_TERRITORIES_UNKNOWN,
          message: (0, _lodash2.default)(err, 'response.data.message', Object.keys(err.response.data).map(key => (0, _lodash2.default)(err, "response.data.".concat(key, "[0]"), 'Failed to create a new territory'))[0]),
          innerError: (err === null || err === void 0 ? void 0 : (_err$response2 = err.response) === null || _err$response2 === void 0 ? void 0 : _err$response2.data) || err
        });
      });
    });
  },

  deleteTerritory(_ref) {
    var {
      id
    } = _ref;
    return new Promise((resolve, reject) => {
      _apiService.default.client.delete("/territories/".concat(id)).then(resolve).catch(err => {
        var _err$response3;

        reject({
          code: ERROR_TERRITORIES_UNKNOWN,
          message: (0, _lodash2.default)(err, 'response.data.message', 'An error has occurred while trying to delete this territory, Please try again later'),
          innerError: (err === null || err === void 0 ? void 0 : (_err$response3 = err.response) === null || _err$response3 === void 0 ? void 0 : _err$response3.data) || err
        });
      });
    });
  }

};
var _default = TerritoryService;
exports.default = _default;