"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = setupVideoJs;

var _video = _interopRequireDefault(require("video.js"));

var _videojsAnnotationComments = _interopRequireDefault(require("@pixwel/videojs-annotation-comments"));

function setupVideoJs() {
  try {
    _video.default.registerPlugin('annotationComments', (0, _videojsAnnotationComments.default)(_video.default));
  } catch (e) {
    throw new Error(e.message);
  }
}