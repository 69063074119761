"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actions = exports.LOAD_SUCCESS = exports.LOAD_REQUEST = exports.LOAD_FAILURE = exports.LOAD = exports.INITIAL_STATE = void 0;
exports.reducer = reducer;
exports.selectors = void 0;
exports.watchProjectSidebar = watchProjectSidebar;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _lodash = _interopRequireDefault(require("lodash.iserror"));

var _reduxActions = require("redux-actions");

var _effects = require("redux-saga/effects");

var _common = require("./common");

var _projects = require("./projects");

var _studios = require("./studios");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function* loadSidebarData(_ref) {
  var {
    payload: projectSlug
  } = _ref;

  try {
    yield (0, _effects.put)(internalActions.loadPageRequest(projectSlug));
    var projectTask = yield (0, _effects.fork)(_projects.fetchProjectBySlug, _projects.actions.getProjectBySlug(projectSlug));
    var studiosTask = yield (0, _effects.fork)(_studios.fetchStudios, _studios.actions.getStudios()); // Join forked task back in

    var project = yield (0, _effects.join)(projectTask);
    var studios = yield (0, _effects.join)(studiosTask);

    if ((0, _lodash.default)(project) || (0, _lodash.default)(studios)) {
      throw new Error('Failed to load sidebar resources');
    }

    yield (0, _effects.put)(internalActions.loadPageSuccess(projectSlug));
  } catch (e) {
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.loadPageFailure({
      projectSlug,
      err
    }));
    return err;
  }
}

var LOAD = 'project-sidebar.load';
exports.LOAD = LOAD;
var LOAD_REQUEST = 'project-sidebar.load.request';
exports.LOAD_REQUEST = LOAD_REQUEST;
var LOAD_SUCCESS = 'project-sidebar.load.success';
exports.LOAD_SUCCESS = LOAD_SUCCESS;
var LOAD_FAILURE = 'project-sidebar.load.failure';
exports.LOAD_FAILURE = LOAD_FAILURE;
var INITIAL_STATE = {
  isLoading: false,
  loadFailed: false
};
exports.INITIAL_STATE = INITIAL_STATE;

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case LOAD_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });

    case LOAD_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false,
        loadFailed: false
      });

    case LOAD_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false,
        loadFailed: true
      });

    default:
      return state;
  }
}

var actions = {
  loadSidebarData: (0, _reduxActions.createAction)(LOAD)
};
/**
 * Actions that should only be invoked internally
 */

exports.actions = actions;
var internalActions = {
  loadPageRequest: (0, _reduxActions.createAction)(LOAD_REQUEST),
  loadPageSuccess: (0, _reduxActions.createAction)(LOAD_SUCCESS),
  loadPageFailure: (0, _reduxActions.createAction)(LOAD_FAILURE)
};
var selectors = {
  isLoading,
  isReady,
  hasError,
  needsRefresh
};
exports.selectors = selectors;

function isLoading(state) {
  var {
    projectSidebarStore
  } = state;
  return projectSidebarStore.isLoading;
}

function hasError(state) {
  var {
    projectSidebarStore
  } = state;
  return !!projectSidebarStore.loadFailed;
}

function isReady(state) {
  var projectSlug = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

  var isProjectLoaded = _projects.selectors.isLoadedBySlug(state, projectSlug);

  var areStudiosLoaded = _studios.selectors.isLoaded(state);

  if (!isProjectLoaded || !areStudiosLoaded) {
    return false;
  }

  var projectDataLastLoadedAt = _projects.selectors.getLastLoadedAtBySlug(state, projectSlug);

  var studiosDataLastLoadedAt = _studios.selectors.getLastLoadedAt(state);

  var now = new Date();
  var isProjectDataStale = now - projectDataLastLoadedAt > 7200000;
  var isStudiosDataStale = now - studiosDataLastLoadedAt > 7200000; // If the data has been loaded within the last past two hours, its ready

  return !isProjectDataStale && !isStudiosDataStale;
}
/**
 * The project's sidebar data needs to be refreshed whenever data it depends on does not exist,
 * or the data it depends on is older then 30 minutes.
 * @param state
 * @param projectSlug
 * @returns {boolean}
 */


function needsRefresh(state) {
  var projectSlug = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

  var isProjectLoaded = _projects.selectors.isLoadedBySlug(state, projectSlug);

  var areStudiosLoaded = _studios.selectors.isLoaded(state);

  if (!isProjectLoaded || !areStudiosLoaded) {
    return true;
  }

  var projectDataLastLoadedAt = _projects.selectors.getLastLoadedAtBySlug(state, projectSlug);

  var studiosDataLastLoadedAt = _studios.selectors.getLastLoadedAt(state);

  var now = new Date();
  var isProjectDataStale = now - projectDataLastLoadedAt > 1800000;
  var isStudiosDataStale = now - studiosDataLastLoadedAt > 1800000; // If the data has been loaded within the last past two hours, its ready

  return isProjectDataStale || isStudiosDataStale;
}

function* watchProjectSidebar() {
  yield (0, _effects.takeLatest)(LOAD, loadSidebarData);
}