"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.UPDATE_USER_MUTATION = exports.UPDATE_PREFERENCES_MUTATION = exports.SEARCH_USERS_QUERY = exports.SEARCH_GROUPS_QUERY = exports.GET_USERS_BY_ID_QUERY = exports.GET_PREFERENCES_QUERY = exports.GET_GROUPS_BY_ID_QUERY = exports.GET_ALL_USERS_QUERY = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _lodash = _interopRequireDefault(require("lodash"));

var _apiService = _interopRequireDefault(require("./api-service"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var GET_PREFERENCES_QUERY = "\nquery getPreferences {\n\tsession {\n\t\tid\n\t\temailNormal\n\t\tpreferences\n\t}\n}\n";
exports.GET_PREFERENCES_QUERY = GET_PREFERENCES_QUERY;
var UPDATE_PREFERENCES_MUTATION = "\nmutation updatePreferences($input: UserInput) {\n\tupdateSession(input: $input) {\n\t\tsession {\n\t\t\tid\n\t\t\tpreferences\n\t\t}\n\t}\n}\n";
exports.UPDATE_PREFERENCES_MUTATION = UPDATE_PREFERENCES_MUTATION;
var UPDATE_USER_MUTATION = "\nmutation updateUser($input: UserInput) {\n  updateUser(input: $input) {\n    user {\n      id\n      name\n    }\n  }\n}\n";
exports.UPDATE_USER_MUTATION = UPDATE_USER_MUTATION;
var SEARCH_USERS_QUERY = "\nquery searchUsers($q: String) {\n  users(q: $q, limit: 5) {\n    nodes {\n      id\n      name\n      emailNormal\n    }\n  }\n}\n";
exports.SEARCH_USERS_QUERY = SEARCH_USERS_QUERY;
var GET_ALL_USERS_QUERY = "\nquery getAllUsers($offset: Int, $limit: Int, $search: String, $approved: Boolean, $registered: Boolean) {\n  users(offset: $offset, limit: $limit, q: $search, approved: $approved, registered: $registered) {\n  \ttotalCount\n    nodes {      \n      id\n      name\n      emailNormal\n      territory {\n        id\n        code\n        name\n\t  }\n      status\n      username\n      groups\n\t  created\n    }\n  }\n}\n";
exports.GET_ALL_USERS_QUERY = GET_ALL_USERS_QUERY;
var SEARCH_GROUPS_QUERY = "\nquery searchGroups($q: String) {\n  groups(q: $q, limit: 5) {\n    nodes {\n      id\n      name\n    }\n  }\n}\n";
exports.SEARCH_GROUPS_QUERY = SEARCH_GROUPS_QUERY;
var GET_USERS_BY_ID_QUERY = "\nquery getUsersById($id: [ID]) {\n  users(id: $id) {\n    nodes {\n\t\t\tid\n\t\t\tname\n      emailNormal\n    }\n  }\n}\n";
exports.GET_USERS_BY_ID_QUERY = GET_USERS_BY_ID_QUERY;
var GET_GROUPS_BY_ID_QUERY = "\nquery getGroupsById($id: [ID]) {\n  groups(id: $id) {\n    nodes {\n\t\t\tid\n\t\t\tname\n    }\n  }\n}\n";
exports.GET_GROUPS_BY_ID_QUERY = GET_GROUPS_BY_ID_QUERY;
var UserService = {
  isAdmin(user) {
    return _lodash.default.chain(user.groups).pluck('type').contains('a').value() || this.isAdminManager(user);
  },

  isAdminManager(user) {
    return _lodash.default.any(user.permissions, permission => {
      return permission.access && permission.access.adminWorkRequests === true;
    });
  },

  isVendor(user) {
    return _lodash.default.any(user.permissions, permission => {
      return permission.access && permission.access.manageWorkRequests === true;
    });
  },

  hasReportAccess(user, report) {
    if (UserService.isAdmin(user)) {
      return true;
    } // checking for access to an individual report


    if (report) {
      return user.permissions.some(permission => {
        var _permission$access;

        return ((_permission$access = permission.access) === null || _permission$access === void 0 ? void 0 : _permission$access[report]) === true;
      });
    } // checking for any report access (to show menu button
    // and allow access to /reports)


    var accessKeys = ['workRequestReport', 'groupSpendingReport', 'exceptionReport', 'downloadReport', 'feedbackReport', 'encodeReport'];
    return accessKeys.some(key => {
      return user.permissions.some(permission => {
        var _permission$access2;

        return ((_permission$access2 = permission.access) === null || _permission$access2 === void 0 ? void 0 : _permission$access2[key]) === true;
      });
    });
  },

  getPreferences() {
    return _apiService.default.graphql.request(GET_PREFERENCES_QUERY);
  },

  updatePreferences(id, preferences) {
    return _apiService.default.graphql.request(UPDATE_PREFERENCES_MUTATION, {
      input: {
        id,
        preferences
      }
    });
  },

  updateUser(id, data) {
    return _apiService.default.graphql.request(UPDATE_USER_MUTATION, {
      input: _objectSpread(_objectSpread({}, data), {}, {
        id
      })
    });
  },

  patchUser(data) {
    return _apiService.default.graphql.request(UPDATE_USER_MUTATION, {
      input: data
    });
  },

  createUser(data) {
    return _apiService.default.client.post('/users', data);
  },

  getUsers(query) {
    return _apiService.default.graphql.request(SEARCH_USERS_QUERY, {
      q: query
    });
  },

  getAllUsers(params) {
    if (!params.search) {
      delete params.search;
    }

    return _apiService.default.graphql.request(GET_ALL_USERS_QUERY, params).then(res => res.users);
  },

  getGroups(query) {
    return _apiService.default.graphql.request(SEARCH_GROUPS_QUERY, {
      q: query
    });
  },

  getUsersByIds(userIds) {
    return _apiService.default.graphql.request(GET_USERS_BY_ID_QUERY, {
      id: userIds
    });
  },

  getGroupsByIds(groupIds) {
    return _apiService.default.graphql.request(GET_GROUPS_BY_ID_QUERY, {
      id: groupIds
    });
  },

  getUserByEmail(email) {
    return _apiService.default.client.get("/users?q=".concat(encodeURIComponent(email))).then(res => {
      var _res$data;

      return (res === null || res === void 0 ? void 0 : (_res$data = res.data) === null || _res$data === void 0 ? void 0 : _res$data[0]) || (res === null || res === void 0 ? void 0 : res.data);
    });
  },

  deleteUser(id) {
    return _apiService.default.client.delete("/users/".concat(typeof id === 'object' ? id.id : id));
  }

};
var _default = UserService;
exports.default = _default;