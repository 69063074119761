"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _aspera = _interopRequireDefault(require("./aspera"));

class Transfer {
  constructor(data) {
    var _data$transfer_spec;

    (0, _defineProperty2.default)(this, "pause", () => {
      this.isPausing = true;
      return new Promise((resolve, reject) => _aspera.default.Connect.stopTransfer(this.id, {
        success: result => {
          this.status = _aspera.default.TRANSFER_STATUS.CANCELLED;
          this.isPausing = false;
          resolve(result);
        },
        error: e => {
          this.isPausing = false;
          reject(e);
        }
      }));
    });
    (0, _defineProperty2.default)(this, "resume", () => {
      this.isResuming = true;
      return new Promise((resolve, reject) => _aspera.default.Connect.resumeTransfer(this.id, {}, {
        success: result => {
          this.status = _aspera.default.TRANSFER_STATUS.RUNNING;
          this.isResuming = false;
          resolve(result);
        },
        error: e => {
          this.isResuming = false;
          reject(e);
        }
      }));
    });
    (0, _defineProperty2.default)(this, "cancel", () => {
      this.isCancelling = true;
      return new Promise((resolve, reject) => _aspera.default.Connect.removeTransfer(this.id, {
        success: result => {
          this.status = _aspera.default.TRANSFER_STATUS.REMOVED;
          this.isCancelling = false;
          resolve(result);
        },
        error: e => {
          this.isCancelling = false;
          reject(e);
        }
      }));
    });
    this.id = data.uuid;
    this.fileName = data.title;
    this.percentage = (data.percentage * 100).toFixed(2);
    this.rate = data.calculated_rate_kbps;
    this.status = data.status;
    this.addTime = data.add_time;
    this.direction = (_data$transfer_spec = data.transfer_spec) === null || _data$transfer_spec === void 0 ? void 0 : _data$transfer_spec.direction;
    this.bytes = {
      total: data.bytes_expected,
      received: data.bytes_written
    };
    this.error = !data.error_code ? null : {
      code: data.error_code,
      desc: data.error_desc
    }; // These are currently untrustworthy because TransferManager creates a new Transfer record on each update,
    // rather then updating existing fields.

    this.isPausing = false;
    this.isResuming = false;
    this.isCancelling = false;
  }

  get isPaused() {
    return this.status === _aspera.default.TRANSFER_STATUS.CANCELLED;
  }

  get isDownloading() {
    return this.status === _aspera.default.TRANSFER_STATUS.INITIATING || this.status === _aspera.default.TRANSFER_STATUS.RUNNING || this.status === _aspera.default.TRANSFER_STATUS.WILLRETRY || this.status === _aspera.default.TRANSFER_STATUS.QUEUED;
  } // Cancel means removed, not paused. Aspera treats paused as cancelled.


  get isCancelled() {
    return this.status === _aspera.default.TRANSFER_STATUS.REMOVED;
  }

  get isFailed() {
    return this.status === _aspera.default.TRANSFER_STATUS.FAILED;
  }

  get isCompleted() {
    return this.status === _aspera.default.TRANSFER_STATUS.COMPLETED;
  }

}

var _default = Transfer;
exports.default = _default;