"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _eventService = _interopRequireDefault(require("./event-service"));

var _lodash = _interopRequireDefault(require("lodash"));

var _transferManager = _interopRequireDefault(require("./transfer-manager"));

var CONNECT_URL = '//d3gcli72yxqn2z.cloudfront.net/connect/v4';
var Aspera = {
  check: () => {
    if (!Aspera.Connect) {
      _eventService.default.broadcast('Connect:NotInstalled');
    }

    return !!Aspera.Connect;
  },
  Connect: null,
  installer: window.AW4 ? new window.AW4.ConnectInstaller({
    sdkLocation: CONNECT_URL
  }) : null,
  options: {
    id: 'pixwel',
    sdkLocation: CONNECT_URL,
    minVersion: '3.6.0',
    dragDropEnabled: true
  },
  init: () => {
    var AW4 = window.AW4;

    try {
      Aspera.Connect = new AW4.Connect(Aspera.options);
      Aspera.Connect.addEventListener(AW4.Connect.EVENT.STATUS, (eventType, data) => {
        _eventService.default.broadcast('Connect:Plugin:Status', eventType, data);

        var status = AW4.Connect.STATUS;

        if (eventType !== AW4.Connect.EVENT.STATUS) {
          return;
        }

        if (data === status.INITIALIZING) {// Aspera.installer.showLaunching();
        }

        if (data === status.FAILED) {// Aspera.installer.showDownload();
        }

        if (data === status.OUTDATED) {
          Aspera.installer.showUpdate();
        }

        if (data === status.RUNNING) {
          Aspera.Connect.addEventListener(Aspera.EVENT.TRANSFER, _transferManager.default.eventHandler);
          return Aspera.installer.connected(0);
        }
      });
      Aspera.Connect.addEventListener(AW4.Connect.EVENT.TRANSFER, (e, data) => {
        _eventService.default.broadcast('Connect:Plugin:Transfer', e, data);
      });

      if (!window.Cypress) {
        Aspera.Connect.initSession();
      }

      if (!AW4.Connect) {
        throw new Error('AW4.Connect not found');
      }

      Aspera.TRANSFER_STATUS = AW4.Connect.TRANSFER_STATUS;
      Aspera.EVENT = AW4.Connect.EVENT;
      Aspera.STATUS = AW4.Connect.STATUS;
    } catch (error) {
      _eventService.default.broadcast('Connect:NotInstalled');
    }

    var selectFilesFolders = (type, e, options) => {
      return _eventService.default.emit('Connect:Select' + type, {
        success: files => {
          var specs = {};

          if (!files.dataTransfer.files.length) {
            return;
          }

          specs = {};
          specs[options.destination.nodeId] = {
            info: options.node,
            rootId: options.destination.fileId,
            files: files.dataTransfer.files.map(file => {
              return {
                source: file.name
              };
            }),
            success: options.success
          };
          return _eventService.default.emit('Connect:Upload', specs);
        }
      }, {
        title: 'Select ' + type
      });
    };

    var addEvent = (event, cb) => {
      return _eventService.default.on('Connect:' + event, args => {
        return Aspera.check() ? cb.apply(void 0, args) : null;
      });
    };

    var transferWithSpecs = (direction, specs) => {
      if (!Aspera.check()) {
        return;
      }

      var Spec = {
        // See https://developer.asperasoft.com/docroot/adn/apidocs/connect_3_6/web4/objects/TransferSpec/index.html
        paths: []
      };

      var _loop = function _loop(nodeId) {
        var spec = specs[nodeId];

        if (direction === 'receive' && spec.name) {
          Spec.paths.forEach(path => {
            path.destination = spec.name;
          });
        }

        if (!(Spec.direction && Spec.paths && Spec.remote_host)) {
          return {
            v: void 0
          };
        }

        _eventService.default.emit('Connect:TransferStarted', Aspera.Connect.startTransfer(Spec, {
          allow_dialogs: false
        }, {
          success: data => {
            if (typeof Spec.success === 'function') {
              return Spec.success(data);
            }
          }
        }));
      };

      for (var nodeId in specs) {
        var _ret = _loop(nodeId);

        if (typeof _ret === "object") return _ret.v;
      }
    };

    _lodash.default.forEach({
      'Plugin:AddEventListener': (e, event, cb) => {
        Aspera.Connect.addEventListener(event, cb);
      },
      'SelectFilesAndUpload': (e, options) => {
        selectFilesFolders('Files', e, options);
      },
      'SelectFoldersAndUpload': (e, options) => {
        selectFilesFolders('Folders', e, options);
      },
      'SelectFiles': (e, cb, options) => {
        Aspera.Connect.showSelectFileDialog(cb, options);
      },
      'SelectFolders': (e, cb, options) => {
        Aspera.Connect.showSelectFolderDialog(cb, options);
      },
      'Download': (e, specs) => {
        transferWithSpecs('receive', specs);
      },
      'Upload': (e, specs) => {
        transferWithSpecs('send', specs);
      },
      'Plugin:ResumeTransfer': (e, uuid) => {
        Aspera.Connect.resumeTransfer(uuid);
      },
      'Plugin:RemoveTransfer': (e, uuid) => {
        Aspera.Connect.removeTransfer(uuid);
      },
      'Plugin:ShowDirectory': (e, uuid) => {
        Aspera.Connect.showDirectory(uuid);
      },
      'Plugin:StopTransfer': (e, uuid) => {
        Aspera.Connect.stopTransfer(uuid);
      },
      'Plugin:ShowTransferWindow': () => {
        Aspera.Connect.showTransferManager();
      }
    }, (fn, name) => {
      addEvent(name, fn);
    }); // Allow users to turn off aspera support and use fallback http downloads


    Aspera.isDisabled = false;
  }
};
var _default = Aspera;
exports.default = _default;