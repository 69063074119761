"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _apiService = _interopRequireDefault(require("./api-service"));

var _lodash = _interopRequireDefault(require("lodash.get"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var ASSET_TYPES_QUERY = "query getAssetTypes($offset: Int, $limit: Int, $search: String) {\n  assetTypes (offset: $offset, limit: $limit, search: $search) {\n  \ttotalCount\n    nodes {\n      id : name\n      name\n      group\n      category\n      file\n    }\n  }\n}";
var AssetTypeService = {
  createInstance(data) {
    return _objectSpread(_objectSpread({}, data), {}, {
      filterByLength: () => {
        return ['tv-spot.us', 'tv-spot.intl', 'tv-spot.home-video', 'film-clip.us', 'film-clip.intl', 'film-clip.home-video'].indexOf(data.name) > -1;
      }
    });
  },

  getAllAssetTypes(params) {
    return _apiService.default.graphql.request(ASSET_TYPES_QUERY, params).then(res => {
      var _res$assetTypes, _res$assetTypes$nodes, _res$assetTypes2;

      return params ? (_res$assetTypes = res === null || res === void 0 ? void 0 : res.assetTypes) !== null && _res$assetTypes !== void 0 ? _res$assetTypes : {} : (_res$assetTypes$nodes = res === null || res === void 0 ? void 0 : (_res$assetTypes2 = res.assetTypes) === null || _res$assetTypes2 === void 0 ? void 0 : _res$assetTypes2.nodes) !== null && _res$assetTypes$nodes !== void 0 ? _res$assetTypes$nodes : [];
    });
  },

  postAssetType(data) {
    return new Promise((resolve, reject) => {
      _apiService.default.client.post("/assettypes", data).then(resolve).catch(err => {
        reject({
          code: 'ERROR',
          message: Object.keys(err.response.data).map(key => (0, _lodash.default)(err, "response.data.".concat(key, "[0]"), 'Failed to create a new asset type'))[0]
        });
      });
    });
  },

  patchAssetType(data) {
    return new Promise((resolve, reject) => {
      _apiService.default.client.patch("/assettypes/".concat(data.id), data).then(resolve).catch(err => {
        reject({
          code: 'ERROR',
          message: Object.keys(err.response.data).map(key => (0, _lodash.default)(err, "response.data.".concat(key, "[0]"), 'Failed to update the asset type'))[0]
        });
      });
    });
  },

  deleteAssetType(_ref) {
    var {
      name
    } = _ref;
    return _apiService.default.client.delete("/assettypes/".concat(name));
  }

};
var _default = AssetTypeService;
exports.default = _default;