"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _apiService = _interopRequireDefault(require("./api-service"));

var _lodash = _interopRequireDefault(require("lodash"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var TranslationReplicator = {
  loadTranscription: asset => {
    if (!asset.transcription || !asset.transcription._id || !asset.$links.transcription) {
      return new Error('Asset does not contain translations');
    }

    return _apiService.default.client.get("/Translations/".concat(asset.transcription._id));
  },
  loadGfxTranscription: asset => {
    if (!asset.transcription || !asset.transcription.graphics) {
      return new Error('Asset does not contain graphics translations');
    }

    return _apiService.default.client.get("/Translations/".concat(asset.transcription.graphics));
  },
  prepareTranslation: (transcription, translation, category) => {
    var LINE_SOURCES = {
      av: 'lines',
      print: 'printLines'
    };
    var lineSource = LINE_SOURCES[category];
    var ovLines = TranslationReplicator.cleanLines(transcription, lineSource);

    if (!translation) {
      return TranslationReplicator.createNewTranslation(ovLines, lineSource, transcription);
    }

    return TranslationReplicator.extendExistingTranslation(translation, ovLines, lineSource);
  },
  cleanLines: (transcription, source) => {
    return _lodash.default.map(transcription[source], line => {
      line.text = line.text.replace(/\r/g, '');
      return line;
    });
  },
  createNewTranslation: (ovLines, source, transcription) => {
    var translation = {
      $$ovId: transcription._id
    };

    if (ovLines.length) {
      translation[source] = _lodash.default.map(ovLines, ovLine => {
        return _objectSpread(_objectSpread({}, ovLine), {}, {
          $$ovText: ovLine.text
        });
      });
    }

    return translation;
  },
  extendExistingTranslation: (translation, ovLines, source) => {
    translation[source] = _lodash.default.map(translation[source], (line, index) => {
      var ovLine = ovLines[index];

      if (!ovLine) {
        return line;
      }

      return _objectSpread(_objectSpread({}, line), {}, {
        $$ovText: ovLine.text
      });
    });
    return translation;
  },
  translate: function () {
    var _translate = (0, _asyncToGenerator2.default)(function* (asset, translation) {
      var type = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'srt';
      var category = asset.mediaType === 'video' ? 'av' : 'print';
      var transcription = type === 'srt' ? yield TranslationReplicator.loadTranscription(asset) : yield TranslationReplicator.loadGfxTranscription(asset);
      return TranslationReplicator.prepareTranslation(transcription.data, translation, category);
    });

    function translate(_x, _x2) {
      return _translate.apply(this, arguments);
    }

    return translate;
  }()
};
var _default = TranslationReplicator;
exports.default = _default;