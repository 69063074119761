"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "pixwelApp", {
  enumerable: true,
  get: function get() {
    return _pixwel.default;
  }
});

require("react-dates/initialize");

var _pixwel = _interopRequireDefault(require("./modules/pixwel"));