"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var stripTags = (input, allowed) => {
  var _input$replace;

  allowed = (((allowed || '') + '').toLowerCase().match(/<[a-z][a-z0-9]*>/g) || []).join('');
  var tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi;
  var commentsAndPhpTags = /<!--[\s\S]*?-->|<\?(?:php)?[\s\S]*?\?>/gi;
  return input === null || input === void 0 ? void 0 : (_input$replace = input.replace(commentsAndPhpTags, '')) === null || _input$replace === void 0 ? void 0 : _input$replace.replace(tags, ($0, $1) => {
    return allowed.indexOf('<' + $1.toLowerCase() + '>') > -1 ? $0 : '';
  });
};

var _default = stripTags;
exports.default = _default;