"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _axios = _interopRequireDefault(require("axios"));

var Upload = {
  addFieldToFormData: (config, formData, val, key) => {
    if (val !== undefined) {
      if (_lodash.default.isDate(val)) {
        val = val.toISOString();
      }

      if (_lodash.default.isString(val)) {
        formData.append(key, val);
      } else if (config.sendFieldsAs === 'form') {
        if (_lodash.default.isObject(val)) {
          for (var k in val) {
            if (val.hasOwnProperty(k)) {
              Upload.addFieldToFormData(config, formData, val[k], key + '[' + k + ']');
            }
          }
        } else {
          formData.append(key, val);
        }
      } else {
        val = _lodash.default.isString(val) ? val : JSON.stringify(val);

        if (config.sendFieldsAs === 'json-blob') {
          formData.append(key, new Blob([val], {
            type: 'application/json'
          }));
        } else {
          formData.append(key, val);
        }
      }
    }
  },
  upload: config => {
    config.headers = config.headers || {};
    config.headers['Content-Type'] = undefined;
    config.transformRequest = config.transformRequest ? _lodash.default.isArray(config.transformRequest) ? config.transformRequest : [config.transformRequest] : [];
    config.transformRequest.push(data => {
      var formData = new FormData();
      var allFields = {};
      var key;

      for (key in config.fields) {
        if (config.fields.hasOwnProperty(key)) {
          allFields[key] = config.fields[key];
        }
      }

      if (data) {
        allFields.data = data;
      }

      for (key in allFields) {
        if (allFields.hasOwnProperty(key)) {
          var val = allFields[key];

          if (config.formDataAppender) {
            config.formDataAppender(formData, key, val);
          } else {
            Upload.addFieldToFormData(config, formData, val, key);
          }
        }
      }

      if (config.file !== null) {
        var fileFormName = config.fileFormDataName || 'file';

        if (_lodash.default.isArray(config.file)) {
          var isFileFormNameString = _lodash.default.isString(fileFormName);

          for (var i = 0; i < config.file.length; i++) {
            formData.append(isFileFormNameString ? fileFormName : fileFormName[i], config.file[i], config.fileName && config.fileName[i] || config.file[i].name);
          }
        } else {
          formData.append(fileFormName, config.file, config.fileName || config.file.name);
        }
      }

      return formData;
    });
    config.method = config.method || 'POST';
    config.headers = config.headers || {};
    delete config.headers['Authorization'];
    return _axios.default.request(config);
  }
};
var _default = Upload;
exports.default = _default;