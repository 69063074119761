"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actions = exports.UPDATE_SAVED_FILTERS = exports.STORE_NAME = exports.LOAD_SUCCESS = exports.LOAD_REQUEST = exports.LOAD_FAILURE = exports.LOAD = exports.INITIAL_STATE = void 0;
exports.reducer = reducer;
exports.selectors = void 0;
exports.watchProjectsIndex = watchProjectsIndex;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _lodash = _interopRequireDefault(require("lodash"));

var _lodash2 = _interopRequireDefault(require("lodash.iserror"));

var _reduxActions = require("redux-actions");

var _effects = require("redux-saga/effects");

var _common = require("./common");

var _studios = require("./studios");

var _projects = require("./projects");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var STORE_NAME = 'projectsIndexStore';
exports.STORE_NAME = STORE_NAME;

function* loadPageData() {
  try {
    yield (0, _effects.put)(internalActions.loadPageRequest());
    var studiosTask = yield (0, _effects.fork)(_studios.fetchStudios, _studios.actions.getStudios());
    var projectsTask = yield (0, _effects.fork)(_projects.fetchProjects, _projects.actions.getProjects());
    var studios = yield (0, _effects.join)(studiosTask);
    var projects = yield (0, _effects.join)(projectsTask);

    if ((0, _lodash2.default)(studios) || (0, _lodash2.default)(projects) || !projects) {
      throw new Error('Failed to load page resources');
    }

    yield (0, _effects.put)(internalActions.loadPageSuccess());
  } catch (e) {
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.loadPageFailure(err));
    return err;
  }
}

var LOAD = 'projects-index.load';
exports.LOAD = LOAD;
var LOAD_REQUEST = 'projects-index.load.request';
exports.LOAD_REQUEST = LOAD_REQUEST;
var LOAD_SUCCESS = 'projects-index.load.success';
exports.LOAD_SUCCESS = LOAD_SUCCESS;
var LOAD_FAILURE = 'projects-index.load.failure';
exports.LOAD_FAILURE = LOAD_FAILURE;
var UPDATE_SAVED_FILTERS = 'projects-index.saved-filters.update';
exports.UPDATE_SAVED_FILTERS = UPDATE_SAVED_FILTERS;
var INITIAL_STATE = {
  isLoading: false,
  loadFailed: false,
  savedFilters: null
};
exports.INITIAL_STATE = INITIAL_STATE;

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case LOAD_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });

    case LOAD_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false,
        loadFailed: false
      });

    case LOAD_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false,
        loadFailed: true
      });

    case UPDATE_SAVED_FILTERS:
      {
        // Clone the object so that we don't get reference side effects
        var savedFilters = _lodash.default.cloneDeep(action.payload);

        return _objectSpread(_objectSpread({}, state), {}, {
          savedFilters
        });
      }

    default:
      return state;
  }
}

var actions = {
  loadPageData: (0, _reduxActions.createAction)(LOAD),
  updateSavedFilters: (0, _reduxActions.createAction)(UPDATE_SAVED_FILTERS)
};
/**
 * Actions that should only be invoked internally
 */

exports.actions = actions;
var internalActions = {
  loadPageRequest: (0, _reduxActions.createAction)(LOAD_REQUEST),
  loadPageSuccess: (0, _reduxActions.createAction)(LOAD_SUCCESS),
  loadPageFailure: (0, _reduxActions.createAction)(LOAD_FAILURE)
};
var selectors = {
  isReady,
  needsRefresh,
  isLoading: (0, _common.createSelector)(STORE_NAME, isLoading),
  isFailedLoad: (0, _common.createSelector)(STORE_NAME, isFailedLoad),
  getSavedFilters: (0, _common.createSelector)(STORE_NAME, getSavedFilters)
};
exports.selectors = selectors;

function isLoading(state) {
  return state.isLoading;
}

function isReady(state) {
  var areProjectsLoaded = _projects.selectors.isLoaded(state);

  var areStudiosLoaded = _studios.selectors.isLoaded(state);

  if (!areProjectsLoaded || !areStudiosLoaded) {
    return false;
  }

  var projectsDataLastLoadedAt = _projects.selectors.getLastLoadedAt(state);

  var studiosDataLastLoadedAt = _studios.selectors.getLastLoadedAt(state);

  var now = new Date();
  var isProjectsDataStale = now - projectsDataLastLoadedAt > 7200000;
  var isStudiosDataStale = now - studiosDataLastLoadedAt > 7200000; // If the data has been loaded within the last past two hours, its ready

  return !isProjectsDataStale && !isStudiosDataStale;
}

function needsRefresh(state) {
  var areProjectsLoaded = _projects.selectors.isLoaded(state);

  var areStudiosLoaded = _studios.selectors.isLoaded(state);

  if (!areProjectsLoaded || !areStudiosLoaded) {
    return true;
  }

  var projectsDataLastLoadedAt = _projects.selectors.getLastLoadedAt(state);

  var studiosDataLastLoadedAt = _studios.selectors.getLastLoadedAt(state);

  var now = new Date();
  var isProjectsDataStale = now - projectsDataLastLoadedAt > 1800000;
  var isStudiosDataStale = now - studiosDataLastLoadedAt > 1800000; // If the data has been loaded within the last past two hours, its ready

  return isProjectsDataStale || isStudiosDataStale;
}

function getSavedFilters(state) {
  // Return a copy of the filters to avoid state side effects
  return _lodash.default.cloneDeep(state.savedFilters);
}

function isFailedLoad(state) {
  return state.loadFailed;
}

function* watchProjectsIndex() {
  yield (0, _effects.takeLatest)(LOAD, loadPageData);
}