"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ContactListsService = void 0;

var _apiService = _interopRequireDefault(require("~/app/3x/modules/services/api-service"));

var GET_GROUPS_AND_USERS_QUERY = "\nquery getGroupsAndUsers($groups: [ID], $users: [ID], $skipGroups: Boolean!, $skipUsers: Boolean!) {\n  groups(id: $groups) @skip(if: $skipGroups) {\n    nodes {\n      id\n      name\n    }\n  },\n\tusers(id: $users) @skip(if: $skipUsers){\n\t\tnodes {\n\t\t\tid\n\t\t\tname\n\t\t\temailNormal\n\t\t}\n  }\n}\n";
var ContactListsService = {
  getGroupsAndUsers(groups, users) {
    var skipGroups = false;
    var skipUsers = false;

    if (!(groups !== null && groups !== void 0 && groups.length)) {
      skipGroups = true;
    }

    if (!(users !== null && users !== void 0 && users.length)) {
      skipUsers = true;
    }

    return _apiService.default.graphql.request(GET_GROUPS_AND_USERS_QUERY, {
      groups,
      users,
      skipGroups,
      skipUsers
    });
  }

};
exports.ContactListsService = ContactListsService;