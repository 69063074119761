"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actions = exports.STORE_NAME = exports.INITIAL_STATE = exports.FETCH_GROUPS_SUCCESS = exports.FETCH_GROUPS_REQUEST = exports.FETCH_GROUPS_FAILURE = exports.FETCH_GROUPS = void 0;
exports.fetchGroups = fetchGroups;
exports.reducer = reducer;
exports.selectors = void 0;
exports.watchGroups = watchGroups;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _lodash = _interopRequireDefault(require("lodash"));

var _lodash2 = _interopRequireDefault(require("lodash.keyby"));

var _reduxActions = require("redux-actions");

var _effects = require("redux-saga/effects");

var _common = require("./common");

var _groupService = _interopRequireDefault(require("~/app/3x/modules/services/group-service"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var STORE_NAME = 'groupsStore';
exports.STORE_NAME = STORE_NAME;

function* fetchGroups(_ref) {
  var {
    payload
  } = _ref;

  try {
    yield (0, _effects.put)(internalActions.getGroupsRequest(payload));
    var groups = yield _groupService.default.getGroups(payload);
    yield (0, _effects.put)(internalActions.getGroupsSuccess(groups));
    return groups;
  } catch (e) {
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.getGroupsFailure(err));
    return err;
  }
}

var FETCH_GROUPS = 'groups.fetch';
exports.FETCH_GROUPS = FETCH_GROUPS;
var FETCH_GROUPS_REQUEST = 'groups.fetch.request';
exports.FETCH_GROUPS_REQUEST = FETCH_GROUPS_REQUEST;
var FETCH_GROUPS_SUCCESS = 'groups.fetch.success';
exports.FETCH_GROUPS_SUCCESS = FETCH_GROUPS_SUCCESS;
var FETCH_GROUPS_FAILURE = 'groups.fetch.failure';
exports.FETCH_GROUPS_FAILURE = FETCH_GROUPS_FAILURE;
var INITIAL_STATE = {
  isLoading: false,
  lastLoadedAt: null,
  allGroups: [],
  structuredGroups: {}
};
exports.INITIAL_STATE = INITIAL_STATE;

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case FETCH_GROUPS_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });

    case FETCH_GROUPS_SUCCESS:
      {
        var now = new Date();
        var allGroups = action.payload;
        var structuredGroups = (0, _lodash2.default)(allGroups, 'name');
        return _objectSpread(_objectSpread({}, state), {}, {
          allGroups,
          structuredGroups,
          isLoading: false,
          lastLoadedAt: now
        });
      }

    case FETCH_GROUPS_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });

    default:
      return state;
  }
}

var actions = {
  getGroups: (0, _reduxActions.createAction)(FETCH_GROUPS)
};
/**
 * Actions that should only be invoked internally
 */

exports.actions = actions;
var internalActions = {
  getGroupsRequest: (0, _reduxActions.createAction)(FETCH_GROUPS_REQUEST),
  getGroupsSuccess: (0, _reduxActions.createAction)(FETCH_GROUPS_SUCCESS),
  getGroupsFailure: (0, _reduxActions.createAction)(FETCH_GROUPS_FAILURE)
};
var selectors = {
  getGroups,
  getGroup,
  isLoading,
  isLoaded,
  getLastLoadedAt
};
exports.selectors = selectors;

function getGroups(state) {
  var groupsStore = state[STORE_NAME];
  return groupsStore.allGroups;
}

function getGroup(state, type) {
  var groupsStore = state[STORE_NAME];
  return _lodash.default.find(groupsStore.allGroups, {
    name: type
  });
}

function isLoading(state) {
  var groupsStore = state[STORE_NAME];
  return groupsStore.isLoading;
}

function isLoaded(state) {
  var groupsStore = state[STORE_NAME];
  return !!groupsStore.lastLoadedAt;
}

function getLastLoadedAt(state) {
  var groupsStore = state[STORE_NAME];
  return groupsStore.lastLoadedAt;
}

function* watchGroups() {
  yield (0, _effects.takeLatest)(FETCH_GROUPS, fetchGroups);
}