"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actions = exports.UPDATE_WORK_REQUEST_SUCCESS = exports.UPDATE_WORK_REQUEST_REQUEST = exports.UPDATE_WORK_REQUEST_FAILURE = exports.UPDATE_WORK_REQUEST = exports.STORE_NAME = exports.REMOVE_TAG_FILE_SUCCESS = exports.REMOVE_TAG_FILE_REQUEST = exports.REMOVE_TAG_FILE_FAILURE = exports.REMOVE_TAG_FILE = exports.REMOVE_FILE_SUCCESS = exports.REMOVE_FILE_REQUEST = exports.REMOVE_FILE_FAILURE = exports.REMOVE_FILE = exports.MANUAL_UPDATE_WORK_REQUEST = exports.INITIAL_STATE = exports.FETCH_WORK_REQUEST_BY_ID_SUCCESS = exports.FETCH_WORK_REQUEST_BY_ID_REQUEST = exports.FETCH_WORK_REQUEST_BY_ID_FAILURE = exports.FETCH_WORK_REQUEST_BY_ID = exports.BATCH_UPDATE_COMPLETE = exports.BATCH_UPDATE = exports.ADD_TAG_FILE_UPDATE = exports.ADD_TAG_FILE_SUCCESS = exports.ADD_TAG_FILE_REQUEST = exports.ADD_TAG_FILE_FAILURE = exports.ADD_TAG_FILE = exports.ADD_FILE_UPDATE = exports.ADD_FILE_SUCCESS = exports.ADD_FILE_REQUEST = exports.ADD_FILE_FAILURE = exports.ADD_FILE = void 0;
exports.fetchWorkRequestById = fetchWorkRequestById;
exports.internalActions = void 0;
exports.reducer = reducer;
exports.selectors = void 0;
exports.updateWorkRequest = updateWorkRequest;
exports.watchWorkRequests = watchWorkRequests;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _lodash = _interopRequireDefault(require("lodash"));

var _lodash2 = _interopRequireDefault(require("lodash.get"));

var _lodash3 = _interopRequireDefault(require("lodash.iserror"));

var _v = _interopRequireDefault(require("uuid/v4"));

var _reduxActions = require("redux-actions");

var _reduxSaga = require("redux-saga");

var _effects = require("redux-saga/effects");

var _common = require("./common");

var _uploadService = _interopRequireDefault(require("../modules/services/upload-service"));

var _workRequestService = _interopRequireDefault(require("../modules/services/work-request-service"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var STORE_NAME = 'workRequestsStore';
exports.STORE_NAME = STORE_NAME;

function* fetchWorkRequestById(_ref) {
  var {
    payload: id
  } = _ref;

  try {
    yield (0, _effects.put)(internalActions.getWorkRequestByIdRequest(id));
    var workRequest = yield _workRequestService.default.getById(id);

    if (workRequest === null) {
      throw new Error('Failed to load work request');
    }

    yield (0, _effects.put)(internalActions.getWorkRequestByIdSuccess(workRequest));
    return workRequest;
  } catch (e) {
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.getWorkRequestByIdFailure({
      id,
      error: err
    }));
    return err;
  }
}

function* updateWorkRequest(_ref2) {
  var {
    payload
  } = _ref2;
  var {
    id,
    data
  } = payload;

  try {
    yield (0, _effects.put)(internalActions.updateWorkRequestRequest({
      id
    }));
    var workRequest = yield _workRequestService.default.update(id, data);
    yield (0, _effects.put)(internalActions.updateWorkRequestSuccess({
      workRequest
    }));
    return workRequest;
  } catch (e) {
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.updateWorkRequestFailure({
      id,
      err
    }));
    return err;
  }
}

function* addFile(_ref3) {
  var {
    type,
    payload
  } = _ref3;
  var {
    workRequestId,
    file
  } = payload;

  if (!file.id) {
    file.id = (0, _v.default)();
  }

  try {
    yield (0, _effects.put)(internalActions.addFileRequest({
      workRequestId,
      file
    }));
    var uploadResult = yield performFileUpload(workRequestId, file);

    if ((0, _lodash3.default)(uploadResult.result)) {
      throw uploadResult.result;
    }

    if (_lodash.default.isEmpty(uploadResult.result)) {
      throw new Error('An Error Has Occurred While Uploading the File, Please try again');
    }

    var batchId = (0, _v.default)();
    var path = uploadResult.result;
    yield (0, _effects.put)(internalActions.batchUpdate({
      batchId,
      type,
      workRequestId,
      file: path
    }));
    var batchComplete = yield (0, _effects.take)(_ref4 => {
      var {
        type,
        payload = {}
      } = _ref4;
      var {
        batchIds = []
      } = payload;
      return type === BATCH_UPDATE_COMPLETE && payload.workRequestId === workRequestId && batchIds.includes(batchId);
    });

    if (!batchComplete.payload.success) {
      throw new Error('Failed to add file to work request');
    }

    yield (0, _effects.put)(internalActions.addFileSuccess({
      workRequestId,
      file
    }));
  } catch (e) {
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.addFileFailure({
      workRequestId,
      file,
      error: err
    }));
    return err;
  }
}

function* addTagFile(_ref5) {
  var {
    type,
    payload
  } = _ref5;
  var {
    workRequestId,
    tag,
    file
  } = payload;

  if (!file.id) {
    file.id = (0, _v.default)();
  }

  try {
    yield (0, _effects.put)(internalActions.addTagFileRequest({
      workRequestId,
      tag,
      file
    }));
    var uploadResult = yield performTagFileUpload(workRequestId, tag, file);

    if ((0, _lodash3.default)(uploadResult.result)) {
      throw uploadResult.result;
    }

    var batchId = (0, _v.default)();
    var path = uploadResult.result;
    yield (0, _effects.put)(internalActions.batchUpdate({
      batchId,
      type,
      workRequestId,
      tag,
      file: path
    }));
    var batchComplete = yield (0, _effects.take)(_ref6 => {
      var {
        type,
        payload = {}
      } = _ref6;
      var {
        batchIds = []
      } = payload;
      return type === BATCH_UPDATE_COMPLETE && payload.workRequestId === workRequestId && batchIds.includes(batchId);
    });

    if (!batchComplete.payload.success) {
      throw new Error('Failed to add tag file to work request');
    }

    yield (0, _effects.put)(internalActions.addTagFileSuccess({
      workRequestId,
      tag,
      file
    }));
  } catch (e) {
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.addTagFileFailure({
      workRequestId,
      tag,
      file,
      error: err
    }));
    return err;
  }
} // Unlike other async sagas, this one relies on a queue and therefore will only put the request
// and let the queue handle success/failure events when it gets processed.


function* removeFile(_ref7) {
  var {
    type,
    payload
  } = _ref7;
  var {
    workRequestId,
    file
  } = payload;

  try {
    yield (0, _effects.put)(internalActions.removeFileRequest({
      workRequestId,
      file
    }));
    var batchId = (0, _v.default)();
    yield (0, _effects.put)(internalActions.batchUpdate({
      batchId,
      type,
      workRequestId,
      file
    }));
    var batchComplete = yield (0, _effects.take)(_ref8 => {
      var {
        type,
        payload = {}
      } = _ref8;
      var {
        batchIds = []
      } = payload;
      return type === BATCH_UPDATE_COMPLETE && payload.workRequestId === workRequestId && batchIds.includes(batchId);
    });

    if (!batchComplete.payload.success) {
      throw new Error('Failed to remove file from work request');
    }

    yield (0, _effects.put)(internalActions.removeFileSuccess({
      workRequestId,
      file
    }));
  } catch (e) {
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.removeFileFailure({
      workRequestId,
      file,
      error: err
    }));
    return err;
  }
} // Unlike other async sagas, this one relies on a queue and therefore will only put the request
// and let the queue handle success/failure events when it gets processed.


function* removeTagFile(_ref9) {
  var {
    type,
    payload
  } = _ref9;
  var {
    workRequestId,
    tag,
    file
  } = payload;

  try {
    yield (0, _effects.put)(internalActions.removeTagFileRequest({
      workRequestId,
      tag,
      file
    }));
    var batchId = (0, _v.default)();
    yield (0, _effects.put)(internalActions.batchUpdate({
      batchId,
      type,
      workRequestId,
      tag,
      file
    }));
    var batchComplete = yield (0, _effects.take)(_ref10 => {
      var {
        type,
        payload = {}
      } = _ref10;
      var {
        batchIds = []
      } = payload;
      return type === BATCH_UPDATE_COMPLETE && payload.workRequestId === workRequestId && batchIds.includes(batchId);
    });

    if (!batchComplete.payload.success) {
      throw new Error('Failed to remove file from work request');
    }

    yield (0, _effects.put)(internalActions.removeTagFileSuccess({
      workRequestId,
      tag,
      file
    }));
  } catch (e) {
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.removeTagFileFailure({
      workRequestId,
      tag,
      file,
      error: err
    }));
    return err;
  }
}

function* performFileUpload(workRequestId, file) {
  var uploadRequest = {
    id: file.id,
    workRequestId,
    file
  }; // Create an event channel so that our async updates can dispatch update events.
  // The inner upload method always resolves, that way parallel tasks are not

  var resultPromise;
  var channel = (0, _reduxSaga.eventChannel)(emit => {
    var emitUpdate = update => {
      emit(update);
    };

    resultPromise = _uploadService.default.uploadFile(file, uploadRequest, emitUpdate).then(result => {
      return {
        uploadRequest,
        result
      };
    }).catch(e => {
      var err = (0, _lodash3.default)(e) ? e : new Error(e); // Do not emit Error directly as it will crash the channel

      emit({
        error: err
      });
      return {
        uploadRequest,
        result: err
      };
    }).finally(() => {
      // End this channel
      emit(_reduxSaga.END);
    }); // Noop unsubscribe

    return _lodash.default.noop;
  }); // We have to fork the update dispatcher because the when a channel ends, the generator
  // function is ended at the `take` and no additional code is run. We don't want this
  // generator function to end because we need to be able to return the promise to the
  // calling function. Can't do that when a generator function just ends.

  yield (0, _effects.fork)(_common.updateDispatcher, channel, uploadRequest, update => {
    return internalActions.addFileUpdate({
      workRequestId,
      update
    });
  });
  var result = yield resultPromise;
  return result;
} // TODO: Find a clean way to reuse file uploader with tag file uploads


function* performTagFileUpload(workRequestId, tag, file) {
  var uploadRequest = {
    id: file.id,
    workRequestId,
    tag,
    file
  }; // Create an event channel so that our async updates can dispatch update events.
  // The inner upload method always resolves, that way parallel tasks are not

  var resultPromise;
  var channel = (0, _reduxSaga.eventChannel)(emit => {
    var emitUpdate = update => {
      emit(update);
    };

    resultPromise = _uploadService.default.uploadFile(file, uploadRequest, emitUpdate).then(result => {
      return {
        uploadRequest,
        result
      };
    }).catch(e => {
      var err = (0, _lodash3.default)(e) ? e : new Error(e); // Do not emit Error directly as it will crash the channel

      emit({
        error: err
      });
      return {
        uploadRequest,
        result: err
      };
    }).finally(() => {
      // End this channel
      emit(_reduxSaga.END);
    }); // Noop unsubscribe

    return _lodash.default.noop;
  }); // We have to fork the update dispatcher because the when a channel ends, the generator
  // function is ended at the `take` and no additional code is run. We don't want this
  // generator function to end because we need to be able to return the promise to the
  // calling function. Can't do that when a generator function just ends.

  yield (0, _effects.fork)(_common.updateDispatcher, channel, uploadRequest, update => {
    return internalActions.addTagFileUpdate({
      workRequestId,
      tag,
      update
    });
  });
  var result = yield resultPromise;
  return result;
} // A special update method used by files and tags to perform
// queued and batched updates. This prevents race conditions
// from multiple file additions or removals occurring quickly.


function* queueAndBatchUpdateFiles() {
  var queue = yield (0, _effects.actionChannel)(BATCH_UPDATE, _reduxSaga.buffers.expanding(10)); // Keep a reference to our actions due to naming collisions below

  var WorkRequestActions = actions;

  while (true) {
    var _actions = [];
    var groupedPayloads = {};
    var workRequestSuccess = new Set();

    try {
      // Implement a takeAll (take blocks but only returns one, flush gets all but doesn't block)
      var action = yield (0, _effects.take)(queue);
      _actions = yield (0, _effects.flush)(queue);

      _actions.unshift(action); // There is no guarantee that all these actions are for the same work request


      groupedPayloads = _lodash.default.groupBy(_actions, _ref11 => {
        var {
          payload
        } = _ref11;
        return payload.workRequestId;
      });
      var tasks = [];

      for (var [workRequestId, _actions2] of Object.entries(groupedPayloads)) {
        var workRequest = yield (0, _effects.select)(selectors.getWorkRequestById, workRequestId);
        var update = {};

        for (var _action2 of _actions2) {
          var {
            payload
          } = _action2;
          var {
            type,
            file,
            tag
          } = payload;

          switch (type) {
            case ADD_FILE:
              {
                // Make a deep clone so that our changes don't mess with the data source
                if (!update.files) {
                  var _update$files;

                  update.files = _lodash.default.cloneDeep(workRequest.files); // If the attachments object has not been added, put it on

                  if (!((_update$files = update.files) !== null && _update$files !== void 0 && _update$files.length)) {
                    update.files.push({
                      name: 'attachments',
                      files: []
                    });
                  }
                }

                if (!_lodash.default.isEmpty(file)) {
                  update.files[0].files.push(file);
                }

                break;
              }

            case ADD_TAG_FILE:
              {
                // Make a deep clone so that our changes don't mess with the data source
                if (!update.tags) {
                  update.tags = _lodash.default.cloneDeep(workRequest.tags);
                }

                var tagIndex = _lodash.default.findIndex(update.tags, {
                  name: tag
                }); // If there is no files array yet, create one


                if (!update.tags[tagIndex].files) {
                  update.tags[tagIndex].files = [];
                }

                if (!_lodash.default.isEmpty(file)) {
                  update.tags[tagIndex].files.push(file);
                }

                break;
              }

            case REMOVE_FILE:
              {
                // Make a deep clone so that our changes don't mess with the data source
                if (!update.files) {
                  update.files = _lodash.default.cloneDeep(workRequest.files);
                }

                var fileIndex = update.files[0].files.indexOf(file);
                update.files[0].files.splice(fileIndex, 1);
                break;
              }

            case REMOVE_TAG_FILE:
              {
                // Make a deep clone so that our changes don't mess with the data source
                if (!update.tags) {
                  update.tags = _lodash.default.cloneDeep(workRequest.tags);
                }

                var _tagIndex = _lodash.default.findIndex(update.tags, {
                  name: tag
                });

                var _fileIndex = update.tags[_tagIndex].files.indexOf(file);

                update.tags[_tagIndex].files.splice(_fileIndex, 1);

                break;
              }
          }
        }

        var _action = WorkRequestActions.updateWorkRequest({
          id: workRequestId,
          data: update
        });

        var task = yield (0, _effects.fork)(updateWorkRequest, _action);
        tasks.push(task);
      }

      var workRequests = yield (0, _effects.join)(tasks);

      for (var _workRequest of workRequests) {
        if (!(0, _lodash3.default)(_workRequest)) {
          workRequestSuccess.add(_workRequest._id);
        }
      }
    } catch (e) {
      (0, _common.handleError)(e);
    } finally {
      for (var [_workRequestId, _actions3] of Object.entries(groupedPayloads)) {
        var batchIds = _lodash.default.map(_actions3, a => a.payload.batchId);

        var success = workRequestSuccess.has(_workRequestId);
        yield (0, _effects.put)(internalActions.batchUpdateComplete({
          workRequestId: _workRequestId,
          batchIds,
          success
        }));
      }
    }
  }
}

var FETCH_WORK_REQUEST_BY_ID = 'work-requests.fetch.by-id';
exports.FETCH_WORK_REQUEST_BY_ID = FETCH_WORK_REQUEST_BY_ID;
var FETCH_WORK_REQUEST_BY_ID_REQUEST = 'work-requests.fetch.by-id.request';
exports.FETCH_WORK_REQUEST_BY_ID_REQUEST = FETCH_WORK_REQUEST_BY_ID_REQUEST;
var FETCH_WORK_REQUEST_BY_ID_SUCCESS = 'work-requests.fetch.by-id.success';
exports.FETCH_WORK_REQUEST_BY_ID_SUCCESS = FETCH_WORK_REQUEST_BY_ID_SUCCESS;
var FETCH_WORK_REQUEST_BY_ID_FAILURE = 'work-requests.fetch.by-id.failure';
exports.FETCH_WORK_REQUEST_BY_ID_FAILURE = FETCH_WORK_REQUEST_BY_ID_FAILURE;
var UPDATE_WORK_REQUEST = 'work-requests.update';
exports.UPDATE_WORK_REQUEST = UPDATE_WORK_REQUEST;
var UPDATE_WORK_REQUEST_REQUEST = 'work-requests.update.request';
exports.UPDATE_WORK_REQUEST_REQUEST = UPDATE_WORK_REQUEST_REQUEST;
var UPDATE_WORK_REQUEST_SUCCESS = 'work-requests.update.success';
exports.UPDATE_WORK_REQUEST_SUCCESS = UPDATE_WORK_REQUEST_SUCCESS;
var UPDATE_WORK_REQUEST_FAILURE = 'work-requests.update.failure';
exports.UPDATE_WORK_REQUEST_FAILURE = UPDATE_WORK_REQUEST_FAILURE;
var ADD_FILE = 'work-request.add-file';
exports.ADD_FILE = ADD_FILE;
var ADD_FILE_REQUEST = 'work-request.add-file.request';
exports.ADD_FILE_REQUEST = ADD_FILE_REQUEST;
var ADD_FILE_UPDATE = 'work-request.add-file.update';
exports.ADD_FILE_UPDATE = ADD_FILE_UPDATE;
var ADD_FILE_SUCCESS = 'work-request.add-file.success';
exports.ADD_FILE_SUCCESS = ADD_FILE_SUCCESS;
var ADD_FILE_FAILURE = 'work-request.add-file.failure';
exports.ADD_FILE_FAILURE = ADD_FILE_FAILURE;
var ADD_TAG_FILE = 'work-request.add-tag-file';
exports.ADD_TAG_FILE = ADD_TAG_FILE;
var ADD_TAG_FILE_REQUEST = 'work-request.add-tag-file.request';
exports.ADD_TAG_FILE_REQUEST = ADD_TAG_FILE_REQUEST;
var ADD_TAG_FILE_UPDATE = 'work-request.add-tag-file.update';
exports.ADD_TAG_FILE_UPDATE = ADD_TAG_FILE_UPDATE;
var ADD_TAG_FILE_SUCCESS = 'work-request.add-tag-file.success';
exports.ADD_TAG_FILE_SUCCESS = ADD_TAG_FILE_SUCCESS;
var ADD_TAG_FILE_FAILURE = 'work-request.add-tag-file.failure';
exports.ADD_TAG_FILE_FAILURE = ADD_TAG_FILE_FAILURE;
var REMOVE_FILE = 'work-request.remove-file';
exports.REMOVE_FILE = REMOVE_FILE;
var REMOVE_FILE_REQUEST = 'work-request.remove-file.request';
exports.REMOVE_FILE_REQUEST = REMOVE_FILE_REQUEST;
var REMOVE_FILE_SUCCESS = 'work-request.remove-file.success';
exports.REMOVE_FILE_SUCCESS = REMOVE_FILE_SUCCESS;
var REMOVE_FILE_FAILURE = 'work-request.remove-file.failure';
exports.REMOVE_FILE_FAILURE = REMOVE_FILE_FAILURE;
var REMOVE_TAG_FILE = 'work-request.remove-tag-file';
exports.REMOVE_TAG_FILE = REMOVE_TAG_FILE;
var REMOVE_TAG_FILE_REQUEST = 'work-request.remove-tag-file.request';
exports.REMOVE_TAG_FILE_REQUEST = REMOVE_TAG_FILE_REQUEST;
var REMOVE_TAG_FILE_SUCCESS = 'work-request.remove-tag-file.success';
exports.REMOVE_TAG_FILE_SUCCESS = REMOVE_TAG_FILE_SUCCESS;
var REMOVE_TAG_FILE_FAILURE = 'work-request.remove-tag-file.failure';
exports.REMOVE_TAG_FILE_FAILURE = REMOVE_TAG_FILE_FAILURE;
var BATCH_UPDATE = 'work-request.batch-update';
exports.BATCH_UPDATE = BATCH_UPDATE;
var BATCH_UPDATE_COMPLETE = 'work-request.batch-update.complete';
exports.BATCH_UPDATE_COMPLETE = BATCH_UPDATE_COMPLETE;
var MANUAL_UPDATE_WORK_REQUEST = 'work-requests.manual-update';
exports.MANUAL_UPDATE_WORK_REQUEST = MANUAL_UPDATE_WORK_REQUEST;
var INITIAL_STATE = {
  workRequests: {}
};
exports.INITIAL_STATE = INITIAL_STATE;

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case FETCH_WORK_REQUEST_BY_ID:
      {
        var id = action.payload;
        var existingContainer = (0, _lodash2.default)(state.workRequests, id, {
          data: null,
          lastLoadedAt: null,
          isUpdating: false,
          updateError: null
        });
        return _objectSpread(_objectSpread({}, state), {}, {
          workRequests: _objectSpread(_objectSpread({}, state.workRequests), {}, {
            [id]: _objectSpread(_objectSpread({}, existingContainer), {}, {
              isLoading: true,
              loadError: null
            })
          })
        });
      }

    case FETCH_WORK_REQUEST_BY_ID_SUCCESS:
      {
        var workRequest = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          workRequests: _objectSpread(_objectSpread({}, state.workRequests), {}, {
            [workRequest._id]: {
              data: workRequest,
              lastLoadedAt: new Date(),
              isLoading: false,
              loadError: null
            }
          })
        });
      }

    case FETCH_WORK_REQUEST_BY_ID_FAILURE:
      {
        var {
          id: _id,
          error
        } = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          workRequests: _objectSpread(_objectSpread({}, state.workRequests), {}, {
            [_id]: _objectSpread(_objectSpread({}, state.workRequests[_id]), {}, {
              isLoading: false,
              loadError: error
            })
          })
        });
      }

    case UPDATE_WORK_REQUEST_REQUEST:
      {
        var {
          id: _id2
        } = action.payload;

        var _existingContainer = (0, _lodash2.default)(state.workRequests, _id2, {
          data: null,
          lastLoadedAt: null,
          isLoading: false,
          loadError: null
        });

        return _objectSpread(_objectSpread({}, state), {}, {
          workRequests: _objectSpread(_objectSpread({}, state.workRequests), {}, {
            [_id2]: _objectSpread(_objectSpread({}, _existingContainer), {}, {
              isUpdating: true,
              updateError: null
            })
          })
        });
      }

    case UPDATE_WORK_REQUEST_SUCCESS:
      {
        var {
          workRequest: _workRequest2
        } = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          workRequests: _objectSpread(_objectSpread({}, state.workRequests), {}, {
            [_workRequest2._id]: _objectSpread(_objectSpread({}, state.workRequests[_workRequest2._id]), {}, {
              data: _workRequest2,
              lastLoadedAt: new Date(),
              isUpdating: false,
              updateError: null
            })
          })
        });
      }

    case UPDATE_WORK_REQUEST_FAILURE:
      {
        var {
          id: _id3,
          err
        } = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          workRequests: _objectSpread(_objectSpread({}, state.workRequests), {}, {
            [_id3]: _objectSpread(_objectSpread({}, state.workRequests[_id3]), {}, {
              isUpdating: false,
              updateError: err
            })
          })
        });
      }

    case MANUAL_UPDATE_WORK_REQUEST:
      {
        var _workRequest3 = action.payload;
        var workRequestContainer = state.workRequests[_workRequest3._id];

        if (!workRequestContainer) {
          workRequestContainer = {
            isLoading: false,
            lastLoadedAt: null
          };
        }

        return _objectSpread(_objectSpread({}, state), {}, {
          workRequests: _objectSpread(_objectSpread({}, state.workRequests), {}, {
            [_workRequest3._id]: _objectSpread(_objectSpread({}, state.workRequests[_workRequest3._id]), {}, {
              data: _workRequest3,
              lastLoadedAt: new Date()
            })
          })
        });
      }

    case ADD_FILE_REQUEST:
      {
        var {
          workRequestId,
          file
        } = action.payload;
        var container = (0, _lodash2.default)(state.workRequests, workRequestId, {});

        var _isAddingFiles = (0, _lodash2.default)(container, 'isAddingFiles', {});

        _isAddingFiles = _objectSpread(_objectSpread({}, _isAddingFiles), {}, {
          [file.id]: {
            id: file.id,
            name: file.name,
            progress: 0
          }
        });
        return _objectSpread(_objectSpread({}, state), {}, {
          workRequests: _objectSpread(_objectSpread({}, state.workRequests), {}, {
            [workRequestId]: _objectSpread(_objectSpread({}, container), {}, {
              isAddingFiles: _isAddingFiles
            })
          })
        });
      }

    case ADD_FILE_UPDATE:
      {
        var {
          workRequestId: _workRequestId2,
          update
        } = action.payload;

        var _container = (0, _lodash2.default)(state.workRequests, _workRequestId2, {});

        var _isAddingFiles2 = (0, _lodash2.default)(_container, 'isAddingFiles', {});

        _isAddingFiles2 = _objectSpread(_objectSpread({}, _isAddingFiles2), {}, {
          [update.id]: update
        });
        return _objectSpread(_objectSpread({}, state), {}, {
          workRequests: _objectSpread(_objectSpread({}, state.workRequests), {}, {
            [_workRequestId2]: _objectSpread(_objectSpread({}, _container), {}, {
              isAddingFiles: _isAddingFiles2
            })
          })
        });
      }

    case ADD_FILE_SUCCESS:
    case ADD_FILE_FAILURE:
      {
        var {
          workRequestId: _workRequestId3,
          file: _file
        } = action.payload;

        var _container2 = (0, _lodash2.default)(state.workRequests, _workRequestId3, {});

        var _isAddingFiles3 = (0, _lodash2.default)(_container2, 'isAddingFiles', {});

        _isAddingFiles3 = _lodash.default.omit(_isAddingFiles3, _file.id);
        return _objectSpread(_objectSpread({}, state), {}, {
          workRequests: _objectSpread(_objectSpread({}, state.workRequests), {}, {
            [_workRequestId3]: _objectSpread(_objectSpread({}, _container2), {}, {
              isAddingFiles: _isAddingFiles3
            })
          })
        });
      }

    case ADD_TAG_FILE_REQUEST:
      {
        var {
          workRequestId: _workRequestId4,
          tag,
          file: _file2
        } = action.payload;

        var _container3 = (0, _lodash2.default)(state.workRequests, _workRequestId4, {});

        var _isAddingTagFiles = (0, _lodash2.default)(_container3, 'isAddingTagFiles', {});

        var _isAddingFiles4 = (0, _lodash2.default)(_isAddingTagFiles, tag, {});

        _isAddingTagFiles = _objectSpread(_objectSpread({}, _isAddingTagFiles), {}, {
          [tag]: _objectSpread(_objectSpread({}, _isAddingFiles4), {}, {
            [_file2.id]: {
              id: _file2.id,
              name: _file2.name,
              progress: 0
            }
          })
        });
        return _objectSpread(_objectSpread({}, state), {}, {
          workRequests: _objectSpread(_objectSpread({}, state.workRequests), {}, {
            [_workRequestId4]: _objectSpread(_objectSpread({}, _container3), {}, {
              isAddingTagFiles: _isAddingTagFiles
            })
          })
        });
      }

    case ADD_TAG_FILE_UPDATE:
      {
        var {
          workRequestId: _workRequestId5,
          tag: _tag,
          update: _update
        } = action.payload;

        var _container4 = (0, _lodash2.default)(state.workRequests, _workRequestId5, {});

        var _isAddingTagFiles2 = (0, _lodash2.default)(_container4, 'isAddingTagFiles', {});

        var _isAddingFiles5 = (0, _lodash2.default)(_isAddingTagFiles2, _tag, {});

        _isAddingFiles5 = _objectSpread(_objectSpread({}, _isAddingFiles5), {}, {
          [_update.id]: _update
        });
        _isAddingTagFiles2 = _objectSpread(_objectSpread({}, _isAddingTagFiles2), {}, {
          [_tag]: _isAddingFiles5
        });
        return _objectSpread(_objectSpread({}, state), {}, {
          workRequests: _objectSpread(_objectSpread({}, state.workRequests), {}, {
            [_workRequestId5]: _objectSpread(_objectSpread({}, _container4), {}, {
              isAddingTagFiles: _isAddingTagFiles2
            })
          })
        });
      }

    case ADD_TAG_FILE_SUCCESS:
    case ADD_TAG_FILE_FAILURE:
      {
        var {
          workRequestId: _workRequestId6,
          tag: _tag2,
          file: _file3
        } = action.payload;

        var _container5 = (0, _lodash2.default)(state.workRequests, _workRequestId6, {});

        var _isAddingTagFiles3 = (0, _lodash2.default)(_container5, 'isAddingTagFiles', {});

        var _isAddingFiles6 = (0, _lodash2.default)(_isAddingTagFiles3, _tag2, {});

        _isAddingFiles6 = _lodash.default.omit(_isAddingFiles6, _file3.id);
        _isAddingTagFiles3 = _objectSpread(_objectSpread({}, _isAddingTagFiles3), {}, {
          [_tag2]: _isAddingFiles6
        });

        if (_lodash.default.isEmpty(_isAddingFiles6)) {
          _isAddingTagFiles3 = _lodash.default.omit(_isAddingTagFiles3, _tag2);
        }

        return _objectSpread(_objectSpread({}, state), {}, {
          workRequests: _objectSpread(_objectSpread({}, state.workRequests), {}, {
            [_workRequestId6]: _objectSpread(_objectSpread({}, _container5), {}, {
              isAddingTagFiles: _isAddingTagFiles3
            })
          })
        });
      }

    case REMOVE_FILE_REQUEST:
      {
        var {
          workRequestId: _workRequestId7,
          file: _file4
        } = action.payload;

        var _container6 = (0, _lodash2.default)(state.workRequests, _workRequestId7, {});

        var _isRemovingFiles = (0, _lodash2.default)(_container6, 'isRemovingFiles', {});

        _isRemovingFiles = _objectSpread(_objectSpread({}, _isRemovingFiles), {}, {
          [_file4]: true
        });
        return _objectSpread(_objectSpread({}, state), {}, {
          workRequests: _objectSpread(_objectSpread({}, state.workRequests), {}, {
            [_workRequestId7]: _objectSpread(_objectSpread({}, _container6), {}, {
              isRemovingFiles: _isRemovingFiles
            })
          })
        });
      }

    case REMOVE_FILE_SUCCESS:
    case REMOVE_FILE_FAILURE:
      {
        var {
          workRequestId: _workRequestId8,
          file: _file5
        } = action.payload;

        var _container7 = (0, _lodash2.default)(state.workRequests, [_workRequestId8], {});

        var _isRemovingFiles2 = (0, _lodash2.default)(_container7, 'isRemovingFiles', {});

        _isRemovingFiles2 = _lodash.default.omit(_isRemovingFiles2, _file5);
        return _objectSpread(_objectSpread({}, state), {}, {
          workRequests: _objectSpread(_objectSpread({}, state.workRequests), {}, {
            [_workRequestId8]: _objectSpread(_objectSpread({}, _container7), {}, {
              isRemovingFiles: _isRemovingFiles2
            })
          })
        });
      }

    case REMOVE_TAG_FILE_REQUEST:
      {
        var {
          workRequestId: _workRequestId9,
          tag: _tag3,
          file: _file6
        } = action.payload;

        var _container8 = (0, _lodash2.default)(state.workRequests, [_workRequestId9], {});

        var _isRemovingTagFiles = (0, _lodash2.default)(_container8, 'isRemovingTagFiles', {});

        var _isRemovingFiles3 = (0, _lodash2.default)(_isRemovingTagFiles, _tag3, {});

        _isRemovingFiles3 = _objectSpread(_objectSpread({}, _isRemovingFiles3), {}, {
          [_file6]: true
        });
        _isRemovingTagFiles = _objectSpread(_objectSpread({}, _isRemovingTagFiles), {}, {
          [_tag3]: _isRemovingFiles3
        });
        return _objectSpread(_objectSpread({}, state), {}, {
          workRequests: _objectSpread(_objectSpread({}, state.workRequests), {}, {
            [_workRequestId9]: _objectSpread(_objectSpread({}, _container8), {}, {
              isRemovingTagFiles: _isRemovingTagFiles
            })
          })
        });
      }

    case REMOVE_TAG_FILE_SUCCESS:
    case REMOVE_TAG_FILE_FAILURE:
      {
        var {
          workRequestId: _workRequestId10,
          tag: _tag4,
          file: _file7
        } = action.payload;

        var _container9 = (0, _lodash2.default)(state.workRequests, [_workRequestId10], {});

        var _isRemovingTagFiles2 = (0, _lodash2.default)(_container9, 'isRemovingTagFiles', {});

        var _isRemovingFiles4 = (0, _lodash2.default)(_isRemovingTagFiles2, _tag4, {});

        _isRemovingFiles4 = _lodash.default.omit(_isRemovingFiles4, _file7);
        _isRemovingTagFiles2 = _objectSpread(_objectSpread({}, _isRemovingTagFiles2), {}, {
          [_tag4]: _isRemovingFiles4
        });

        if (_lodash.default.isEmpty(_isRemovingFiles4)) {
          _isRemovingTagFiles2 = _lodash.default.omit(_isRemovingTagFiles2, _tag4);
        }

        return _objectSpread(_objectSpread({}, state), {}, {
          workRequests: _objectSpread(_objectSpread({}, state.workRequests), {}, {
            [_workRequestId10]: _objectSpread(_objectSpread({}, _container9), {}, {
              isRemovingTagFiles: _isRemovingTagFiles2
            })
          })
        });
      }

    default:
      return state;
  }
}

var actions = {
  getWorkRequestById: (0, _reduxActions.createAction)(FETCH_WORK_REQUEST_BY_ID),
  updateWorkRequest: (0, _reduxActions.createAction)(UPDATE_WORK_REQUEST),
  manualUpdateWorkRequest: (0, _reduxActions.createAction)(MANUAL_UPDATE_WORK_REQUEST),
  addFile: (0, _reduxActions.createAction)(ADD_FILE),
  addTagFile: (0, _reduxActions.createAction)(ADD_TAG_FILE),
  removeFile: (0, _reduxActions.createAction)(REMOVE_FILE),
  removeTagFile: (0, _reduxActions.createAction)(REMOVE_TAG_FILE)
};
/**
 * Actions that should only be invoked internally
 */

exports.actions = actions;
var internalActions = {
  getWorkRequestByIdRequest: (0, _reduxActions.createAction)(FETCH_WORK_REQUEST_BY_ID_REQUEST),
  getWorkRequestByIdSuccess: (0, _reduxActions.createAction)(FETCH_WORK_REQUEST_BY_ID_SUCCESS),
  getWorkRequestByIdFailure: (0, _reduxActions.createAction)(FETCH_WORK_REQUEST_BY_ID_FAILURE),
  updateWorkRequestRequest: (0, _reduxActions.createAction)(UPDATE_WORK_REQUEST_REQUEST),
  updateWorkRequestSuccess: (0, _reduxActions.createAction)(UPDATE_WORK_REQUEST_SUCCESS),
  updateWorkRequestFailure: (0, _reduxActions.createAction)(UPDATE_WORK_REQUEST_FAILURE),
  addFileRequest: (0, _reduxActions.createAction)(ADD_FILE_REQUEST),
  addFileUpdate: (0, _reduxActions.createAction)(ADD_FILE_UPDATE),
  addFileSuccess: (0, _reduxActions.createAction)(ADD_FILE_SUCCESS),
  addFileFailure: (0, _reduxActions.createAction)(ADD_FILE_FAILURE),
  addTagFileRequest: (0, _reduxActions.createAction)(ADD_TAG_FILE_REQUEST),
  addTagFileUpdate: (0, _reduxActions.createAction)(ADD_TAG_FILE_UPDATE),
  addTagFileSuccess: (0, _reduxActions.createAction)(ADD_TAG_FILE_SUCCESS),
  addTagFileFailure: (0, _reduxActions.createAction)(ADD_TAG_FILE_FAILURE),
  removeFileRequest: (0, _reduxActions.createAction)(REMOVE_FILE_REQUEST),
  removeFileSuccess: (0, _reduxActions.createAction)(REMOVE_FILE_SUCCESS),
  removeFileFailure: (0, _reduxActions.createAction)(REMOVE_FILE_FAILURE),
  removeTagFileRequest: (0, _reduxActions.createAction)(REMOVE_TAG_FILE_REQUEST),
  removeTagFileSuccess: (0, _reduxActions.createAction)(REMOVE_TAG_FILE_SUCCESS),
  removeTagFileFailure: (0, _reduxActions.createAction)(REMOVE_TAG_FILE_FAILURE),
  batchUpdate: (0, _reduxActions.createAction)(BATCH_UPDATE),
  batchUpdateComplete: (0, _reduxActions.createAction)(BATCH_UPDATE_COMPLETE)
};
exports.internalActions = internalActions;
var selectors = {
  isLoading: (0, _common.createSelector)(STORE_NAME, isLoading),
  isLoaded: (0, _common.createSelector)(STORE_NAME, isLoaded),
  didLoadFail: (0, _common.createSelector)(STORE_NAME, didLoadFail),
  getWorkRequestById: (0, _common.createSelector)(STORE_NAME, getWorkRequestById),
  isAddingFiles: (0, _common.createSelector)(STORE_NAME, isAddingFiles),
  isAddingTagFiles: (0, _common.createSelector)(STORE_NAME, isAddingTagFiles),
  isRemovingFiles: (0, _common.createSelector)(STORE_NAME, isRemovingFiles),
  isRemovingTagFiles: (0, _common.createSelector)(STORE_NAME, isRemovingTagFiles)
};
exports.selectors = selectors;

function getWorkRequestById(state, id) {
  var workRequestContainer = state.workRequests[id];

  if (!workRequestContainer) {
    return null;
  }

  return workRequestContainer.data;
}

function isLoading(state, id) {
  var workRequestContainer = state.workRequests[id];

  if (!workRequestContainer) {
    return false;
  }

  return workRequestContainer.isLoading;
}

function isLoaded(state, id) {
  var workRequestContainer = state.workRequests[id];

  if (!workRequestContainer) {
    return false;
  }

  return !!workRequestContainer.lastLoadedAt;
}

function didLoadFail(state, id) {
  var workRequestContainer = state.workRequests[id];

  if (!workRequestContainer) {
    return false;
  }

  return !!workRequestContainer.loadError;
}

function isAddingFiles(state, id) {
  return (0, _lodash2.default)(state.workRequests, [id, 'isAddingFiles'], false);
}

function isAddingTagFiles(state, id) {
  return (0, _lodash2.default)(state.workRequests, [id, 'isAddingTagFiles'], false);
}

function isRemovingFiles(state, id) {
  return (0, _lodash2.default)(state.workRequests, [id, 'isRemovingFiles'], false);
}

function isRemovingTagFiles(state, id) {
  return (0, _lodash2.default)(state.workRequests, [id, 'isRemovingTagFiles'], false);
}

function* watchWorkRequests() {
  yield (0, _effects.takeEvery)(FETCH_WORK_REQUEST_BY_ID, fetchWorkRequestById);
  yield (0, _effects.takeEvery)(UPDATE_WORK_REQUEST, updateWorkRequest);
  yield (0, _effects.takeEvery)(ADD_FILE, addFile);
  yield (0, _effects.takeEvery)(ADD_TAG_FILE, addTagFile);
  yield (0, _effects.takeEvery)(REMOVE_FILE, removeFile);
  yield (0, _effects.takeEvery)(REMOVE_TAG_FILE, removeTagFile);
  yield queueAndBatchUpdateFiles();
}