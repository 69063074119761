"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actions = exports.UPDATE_SAVED_PAGE_PREFERENCES = exports.UPDATE_SAVED_FILTERS = exports.STORE_NAME = exports.LOAD_SUCCESS = exports.LOAD_REQUEST = exports.LOAD_FAILURE = exports.LOAD = exports.INITIAL_STATE = void 0;
exports.reducer = reducer;
exports.selectors = void 0;
exports.watchProjectPage = watchProjectPage;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _lodash = _interopRequireDefault(require("lodash"));

var _lodash2 = _interopRequireDefault(require("lodash.iserror"));

var _reduxActions = require("redux-actions");

var _effects = require("redux-saga/effects");

var _common = require("./common");

var _projects = require("./projects");

var _assets = require("./assets");

var _assetTypes = require("./asset-types");

var _session = require("./session");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var STORE_NAME = 'projectPageStore';
exports.STORE_NAME = STORE_NAME;

function* loadPageData(_ref) {
  var {
    payload: projectSlug
  } = _ref;

  try {
    yield (0, _effects.put)(internalActions.loadPageRequest()); // Fork to run separately (non-blocking)

    var assetTypesTask = yield (0, _effects.fork)(_assetTypes.fetchAssetTypes, _assetTypes.actions.getAssetTypes());
    var sessionTask = yield (0, _effects.fork)(_session.fetchSession, _session.actions.getSession()); // Run sequentially

    var project = yield (0, _effects.call)(_projects.fetchProjectBySlug, _projects.actions.getProjectBySlug(projectSlug));

    if ((0, _lodash2.default)(project) || project === null) {
      throw new Error('Failed to load project');
    }

    var assets = yield (0, _effects.call)(_assets.fetchProjectAssets, _assets.actions.getProjectAssets({
      projectId: project._id
    }));

    if ((0, _lodash2.default)(assets)) {
      throw new Error('Failed to load project\'s assets');
    } // Join forked tasks back in


    var assetTypes = yield (0, _effects.join)(assetTypesTask);

    if ((0, _lodash2.default)(assetTypes)) {
      throw new Error('Failed to load page resources');
    }

    var session = yield (0, _effects.join)(sessionTask);

    if ((0, _lodash2.default)(session)) {
      throw new Error('Failed to load page resources');
    }

    yield (0, _effects.put)(internalActions.loadPageSuccess());
  } catch (e) {
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.loadPageFailure(err));
    return err;
  }
}

var LOAD = 'project-page.load';
exports.LOAD = LOAD;
var LOAD_REQUEST = 'project-page.load.request';
exports.LOAD_REQUEST = LOAD_REQUEST;
var LOAD_SUCCESS = 'project-page.load.success';
exports.LOAD_SUCCESS = LOAD_SUCCESS;
var LOAD_FAILURE = 'project-page.load.failure';
exports.LOAD_FAILURE = LOAD_FAILURE;
var UPDATE_SAVED_FILTERS = 'project-page.saved-filters.update';
exports.UPDATE_SAVED_FILTERS = UPDATE_SAVED_FILTERS;
var UPDATE_SAVED_PAGE_PREFERENCES = 'project-page.saved-page-preferences.update';
exports.UPDATE_SAVED_PAGE_PREFERENCES = UPDATE_SAVED_PAGE_PREFERENCES;
var INITIAL_STATE = {
  isLoading: false,
  loadFailed: false,
  savedFiltersByProject: {},
  savedPagePreferences: null
};
exports.INITIAL_STATE = INITIAL_STATE;

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case LOAD_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });

    case LOAD_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false,
        loadFailed: false
      });

    case LOAD_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false,
        loadFailed: true
      });

    case UPDATE_SAVED_FILTERS:
      {
        var {
          projectId,
          filters
        } = action.payload; // Clone the object so that we don't get reference side effects

        var savedFilters = _lodash.default.cloneDeep(filters);

        return _objectSpread(_objectSpread({}, state), {}, {
          savedFiltersByProject: _objectSpread(_objectSpread({}, state.savedFiltersByProject), {}, {
            [projectId]: savedFilters
          })
        });
      }

    case UPDATE_SAVED_PAGE_PREFERENCES:
      {
        // Clone the object so that we don't get reference side effects
        var savedPagePreferences = _lodash.default.cloneDeep(action.payload);

        return _objectSpread(_objectSpread({}, state), {}, {
          savedPagePreferences
        });
      }

    default:
      return state;
  }
}

var actions = {
  loadPageData: (0, _reduxActions.createAction)(LOAD),
  updateSavedFilters: (0, _reduxActions.createAction)(UPDATE_SAVED_FILTERS),
  updateSavedPagePreferences: (0, _reduxActions.createAction)(UPDATE_SAVED_PAGE_PREFERENCES)
};
/**
 * Actions that should only be invoked internally
 */

exports.actions = actions;
var internalActions = {
  loadPageRequest: (0, _reduxActions.createAction)(LOAD_REQUEST),
  loadPageSuccess: (0, _reduxActions.createAction)(LOAD_SUCCESS),
  loadPageFailure: (0, _reduxActions.createAction)(LOAD_FAILURE)
};
var selectors = {
  isLoading,
  isReady,
  needsRefresh,
  isFailedLoad,
  getSavedFilters: (0, _common.createSelector)(STORE_NAME, getSavedFilters),
  getSavedPagePreferences: (0, _common.createSelector)(STORE_NAME, getSavedPagePreferences)
};
exports.selectors = selectors;

function isLoading(state) {
  var {
    projectPageStore
  } = state;
  return projectPageStore.isLoading;
}

function isFailedLoad(state) {
  var {
    projectPageStore
  } = state;
  return projectPageStore.loadFailed;
}

function isReady(state, projectId) {
  var isProjectLoaded = _projects.selectors.isLoaded(state, projectId);

  var areAssetsLoaded = _assets.selectors.areProjectAssetsLoaded(state, projectId);

  var areAssetTypesLoaded = _assetTypes.selectors.isLoaded(state);

  var isSessionLoaded = _session.selectors.isLoaded(state);

  if (!isProjectLoaded || !areAssetsLoaded || !areAssetTypesLoaded || !isSessionLoaded) {
    return false;
  }

  var projectDataLastLoadedAt = _projects.selectors.getLastLoadedAt(state, projectId);

  var assetsDataLastLoadedAt = _assets.selectors.getProjectAssetsLastLoadedAt(state, projectId);

  var assetTypesDataLastLoadedAt = _assetTypes.selectors.getLastLoadedAt(state);

  var sessionDataLastLoadedAt = _session.selectors.getLastLoadedAt(state);

  var now = new Date();
  var isProjectDataStale = now - projectDataLastLoadedAt > 7200000;
  var isAssetsDataStale = now - assetsDataLastLoadedAt > 7200000;
  var isAssetTypesDataStale = now - assetTypesDataLastLoadedAt > 7200000;
  var isSessionDataStale = now - sessionDataLastLoadedAt > 7200000; // If the data has been loaded within the last past two hours, its ready

  return !isProjectDataStale && !isAssetsDataStale && !isAssetTypesDataStale && !isSessionDataStale;
}

function needsRefresh(state, projectId) {
  var isProjectLoaded = _projects.selectors.isLoaded(state, projectId);

  var areAssetsLoaded = _assets.selectors.areProjectAssetsLoaded(state, projectId);

  var areAssetTypesLoaded = _assetTypes.selectors.isLoaded(state);

  var isSessionLoaded = _session.selectors.isLoaded(state);

  if (!isProjectLoaded || !areAssetsLoaded || !areAssetTypesLoaded || isSessionLoaded) {
    return true;
  }

  var projectDataLastLoadedAt = _projects.selectors.getLastLoadedAt(state, projectId);

  var assetsDataLastLoadedAt = _assets.selectors.getProjectAssetsLastLoadedAt(state, projectId);

  var assetTypesDataLastLoadedAt = _assetTypes.selectors.getLastLoadedAt(state);

  var sessionDataLastLoadedAt = _session.selectors.getLastLoadedAt(state);

  var now = new Date();
  var isProjectDataStale = now - projectDataLastLoadedAt > 1800000;
  var isAssetsDataStale = now - assetsDataLastLoadedAt > 1800000;
  var isAssetTypesDataStale = now - assetTypesDataLastLoadedAt > 1800000;
  var isSessionDataStale = now - sessionDataLastLoadedAt > 1800000; // If the data has been loaded within the last past two hours, its ready

  return isProjectDataStale || isAssetsDataStale || isAssetTypesDataStale || isSessionDataStale;
}

function getSavedFilters(state, projectId) {
  var savedFilters = state.savedFiltersByProject[projectId];

  if (savedFilters) {
    // Return a copy of the filters to avoid state side effects
    savedFilters = _lodash.default.cloneDeep(savedFilters);
  }

  return savedFilters;
}

function getSavedPagePreferences(state) {
  // Return a copy of the filters to avoid state side effects
  return _lodash.default.cloneDeep(state.savedPagePreferences);
}

function* watchProjectPage() {
  yield (0, _effects.takeLatest)(LOAD, loadPageData);
}