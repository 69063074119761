"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.StorageService = void 0;

var _jsCookie = _interopRequireDefault(require("js-cookie"));

var StorageService = {
  get(key) {
    return _jsCookie.default.get(key);
  },

  set(key, val) {
    return _jsCookie.default.set(key, val);
  }

};
exports.StorageService = StorageService;
var _default = StorageService;
exports.default = _default;