"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledIcon = exports.Container = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _polished = require("polished");

var _icon = _interopRequireDefault(require("../icon"));

var _templateObject, _templateObject2;

var StyledIcon = (0, _styledComponents.default)(_icon.default)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n\tmargin: 1rem 1.375rem;\n\n\tpath {\n\t\ttransition: fill 100ms;\n\t\tfill: ", ";\n\t}\n"])), _ref => {
  var {
    theme,
    isActive
  } = _ref;
  return isActive ? theme['white'] : (0, _polished.rgba)(theme['white'], 0.3);
});
exports.StyledIcon = StyledIcon;

var Container = _styledComponents.default.div(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n\tposition: relative;\n\tdisplay: flex;\n\talign-items: center;\n\tflex-wrap: nowrap;\n\ttransition: color 100ms, background-color 100ms;\n\tcolor: ", ";\n\tcursor: pointer;\n\n\tspan {\n\t\tfont-size: 0.9rem;\n\t\tfont-weight: 700;\n\t}\n\n\t&::after {\n\t\tcontent: '';\n\t\tdisplay: ", ";\n\t\tposition: absolute;\n\t\ttop: 0.6875rem;\n\t\tleft: 2.3125rem;\n\t\twidth: 0.625rem;\n\t\theight: 0.625rem;\n\t\tborder-radius: 50%;\n\t\tbackground-color: ", ";\n\t}\n\n\t&:hover {\n\t\tbackground-color: ", ";\n\t\tcolor: ", ";\n\n\t\t", " {\n\t\t\tpath {\n\t\t\t\tfill: ", ";\n\t\t\t}\n\t\t}\n\t}\n"])), _ref2 => {
  var {
    theme,
    isActive
  } = _ref2;
  return isActive ? theme['white'] : (0, _polished.rgba)(theme['white'], 0.3);
}, _ref3 => {
  var {
    hasUnread
  } = _ref3;
  return hasUnread ? 'block' : 'none';
}, _ref4 => {
  var {
    theme
  } = _ref4;
  return theme['blue-base'];
}, _ref5 => {
  var {
    theme
  } = _ref5;
  return theme['midnight-lighten-10'];
}, _ref6 => {
  var {
    theme
  } = _ref6;
  return theme['white'];
}, StyledIcon, _ref7 => {
  var {
    theme
  } = _ref7;
  return theme['white'];
});

exports.Container = Container;