"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.ProjectService = exports.PROJECT_CONSTRAINTS = exports.ERROR_SLUG = exports.ERROR_PROJECT_UNKNOWN = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _validationService = _interopRequireDefault(require("./validation-service"));

var _apiService = _interopRequireWildcard(require("./api-service"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var ERROR_SLUG = 'error.slug';
exports.ERROR_SLUG = ERROR_SLUG;
var ERROR_PROJECT_UNKNOWN = 'error.project.unknown';
exports.ERROR_PROJECT_UNKNOWN = ERROR_PROJECT_UNKNOWN;
var PROJECT_CONSTRAINTS = {
  name: {
    presence: {
      allowEmpty: false
    }
  },
  slug: {
    presence: {
      allowEmpty: false
    },
    format: {
      pattern: /^[a-zA-Z0-9-]+$/
    }
  },
  studio: {
    presence: {
      allowEmpty: false
    }
  }
};
exports.PROJECT_CONSTRAINTS = PROJECT_CONSTRAINTS;
var PROJECTS_QUERY = "\nquery getProjects($archived: Boolean, $offset: Int, $limit: Int, $search: String, $studio: String) {\n\tprojects(offset: $offset, limit: $limit, archived: $archived, search: $search, studio: $studio) {\n\t\ttotalCount\n\t  nodes {\n\t\tid\n\t\tname\n\t\tslug\n\t\tstudio {\n\t\t\tid\n\t\t\tname\n\t\t\tlogo\n\t\t}\n\t\tposter\n\t\tarchived\n\t\tfilePrefix\n\t\tverified\n\t  }\n\t}\n\t}\n";
var PROJECTS_QUERY_SHORT = "query getProjects($archived: Boolean, $limit: Int, $search: String) {\n\tprojects(limit: $limit, archived: $archived, search: $search) {\n\ttotalCount\n\t  nodes {\n\t\tvalue : id\n\t\tlabel : name\n\t  }\n\t}\n}";
var ProjectService = {
  createProject(project) {
    return new Promise((resolve, reject) => {
      _apiService.default.client.post("/projects", project).then(resolve).catch(err => {
        if (err.message === 'Projects can\'t share the same slug') {
          reject({
            code: ERROR_SLUG,
            message: 'Slug already in use',
            innerError: err
          });
          return;
        }

        reject({
          code: ERROR_PROJECT_UNKNOWN,
          message: 'Failed to create a new project',
          innerError: err
        });
      });
    });
  },

  patchProject(data) {
    return new Promise((resolve, reject) => {
      _apiService.default.client.patch("/projects/".concat(data.slug), data).then(resolve).catch(err => {
        var _err$response, _err$response$data, _err$response$data$er, _err$response2;

        reject({
          code: ERROR_PROJECT_UNKNOWN,
          message: (err === null || err === void 0 ? void 0 : (_err$response = err.response) === null || _err$response === void 0 ? void 0 : (_err$response$data = _err$response.data) === null || _err$response$data === void 0 ? void 0 : (_err$response$data$er = _err$response$data.error) === null || _err$response$data$er === void 0 ? void 0 : _err$response$data$er.message) || 'Failed to update the project',
          innerError: (err === null || err === void 0 ? void 0 : (_err$response2 = err.response) === null || _err$response2 === void 0 ? void 0 : _err$response2.data) || err
        });
      });
    });
  },

  archiveProject(_ref) {
    var {
      slug,
      archived
    } = _ref;
    return new Promise((resolve, reject) => {
      _apiService.default.client.patch("/projects/".concat(slug), {
        archived: !archived
      }).then(resolve).catch(err => {
        var _err$response3, _err$response3$data, _err$response3$data$e, _err$response4;

        reject({
          code: ERROR_PROJECT_UNKNOWN,
          message: (err === null || err === void 0 ? void 0 : (_err$response3 = err.response) === null || _err$response3 === void 0 ? void 0 : (_err$response3$data = _err$response3.data) === null || _err$response3$data === void 0 ? void 0 : (_err$response3$data$e = _err$response3$data.error) === null || _err$response3$data$e === void 0 ? void 0 : _err$response3$data$e.message) || 'Failed to update the project',
          innerError: (err === null || err === void 0 ? void 0 : (_err$response4 = err.response) === null || _err$response4 === void 0 ? void 0 : _err$response4.data) || err
        });
      });
    });
  },

  deleteProject(_ref2) {
    var {
      slug
    } = _ref2;
    return _apiService.default.client.delete("/projects/".concat(slug));
  },

  updateProject(id, data) {
    return new Promise((resolve, reject) => {
      _apiService.default.client.get("/projects/".concat(id)).then(res => {
        _apiService.default.client.patch("/projects/".concat(res.data.slug), data).then(resolve).catch(err => {
          reject({
            code: ERROR_PROJECT_UNKNOWN,
            message: 'Failed to update project',
            innerError: err
          });
        });
      }).catch(err => {
        return reject({
          code: ERROR_PROJECT_UNKNOWN,
          message: 'Failed to update project',
          innerError: err
        });
      });
    });
  },

  validateProject(project) {
    return _validationService.default.validate(project, PROJECT_CONSTRAINTS);
  },

  getBillingRates(_ref3) {
    var {
      projectId
    } = _ref3;
    return _apiService.default.client.get("/billingrates?project=".concat(projectId)).then(res => res.data[0] || res.data);
  },

  $poster() {
    var hasLogo = this.$links && this.$links.logo && !this.$links.logo.match(/Files$/);
    return hasLogo ? this.$links.logo : '/img/placeholder/poster.gif';
  },

  getProjectById(_ref4) {
    var {
      id
    } = _ref4;
    return _apiService.default.client.get("/projects?id=".concat(id)).then(res => {
      return _objectSpread(_objectSpread({}, (res === null || res === void 0 ? void 0 : res.data[0]) || (res === null || res === void 0 ? void 0 : res.data) || {}), {}, {
        $poster: this.$poster
      });
    });
  },

  getAllProjects(params) {
    if (params) {
      return _apiService.default.graphql.request(PROJECTS_QUERY, params).then(data => data === null || data === void 0 ? void 0 : data.projects);
    }

    return _apiService.default.client.get((0, _apiService.buildURL)('/projects', params)).then(res => res === null || res === void 0 ? void 0 : res.data);
  },

  getAllProjectsShort(params) {
    if (params) {
      return _apiService.default.graphql.request(PROJECTS_QUERY_SHORT, params).then(data => data.projects);
    }

    return _apiService.default.client.get('/projects');
  },

  getProjectsBySlug(_ref5) {
    var {
      slug
    } = _ref5;
    return _apiService.default.client.get("/projects?slug=".concat(slug)).then(res => res.data);
  },

  searchProjects(_ref6) {
    var {
      name,
      studio
    } = _ref6;
    return _apiService.default.client.get((0, _apiService.buildURL)("/projects", {
      search: name,
      studio,
      'order[archived]': 'asc',
      'order[name]': 'asc'
    }), {
      headers: {
        'X-Range': 'resources=0-20'
      }
    }).then(res => res.data);
  }

};
exports.ProjectService = ProjectService;
var _default = ProjectService;
exports.default = _default;