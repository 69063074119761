"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var TimeFormatService = {
  stringRegex: /^(\d+):(\d+):(\d+)[,|.](\d{1,3})?$/,
  isValidTime: time => {
    return !!time.match(TimeFormatService.stringRegex);
  },
  timeToTimestamp: time => {
    var match = time.match(TimeFormatService.stringRegex);

    if (!match) {
      return 0;
    }

    var precision = match[4].length === 2 ? 100 : 1000;
    return parseInt(match[1], 10) * 60 * 60 + parseInt(match[2], 10) * 60 + parseInt(match[3], 10) + parseInt(match[4], 10) / precision;
  },
  normalize: time => {
    var match = time.match(TimeFormatService.stringRegex);
    var millis = match[4];

    while (millis.length < 3) {
      millis += '0';
    }

    return "".concat(match[1], ":").concat(match[2], ":").concat(match[3], ".").concat(millis);
  },
  timestampToTime: (second, decimalMark) => {
    var hours = ~~(second / 3600);
    var min = ~~(second / 60) % 60;
    var sec = second % 60;
    var fixedSec = sec.toFixed(3).replace('.', decimalMark ? decimalMark : ',');
    return (hours < 10 ? '0' + hours : hours) + ':' + (min < 10 ? '0' + min : min) + ':' + (sec < 10 ? '0' + fixedSec : fixedSec);
  },
  timeDiff: (time1, time2) => {
    if (time1 === undefined || time2 === undefined) {
      return 0;
    }

    return TimeFormatService.timestampDiff(TimeFormatService.timeToTimestamp(time1), TimeFormatService.timeToTimestamp(time2));
  },
  msDiff: (time1, time2) => {
    if (time1 === undefined || time2 === undefined) {
      return 0;
    }

    return TimeFormatService.timeToTimestamp(time2) - TimeFormatService.timeToTimestamp(time1);
  },
  timestampDiff: (time1, time2) => {
    if (time1 === undefined || time2 === undefined || time1 === time2) {
      return 0;
    }

    var diff = time2 - time1;
    return Math.round(Math.max(-1, Math.min(1, diff)) * 10) / 10;
  },
  startTime: line => {
    var timestamp = TimeFormatService.timeToTimestamp(line.startTime) + (line.inOffset || 0);
    return TimeFormatService.timestampToTime(timestamp);
  },
  stopTime: line => {
    var timestamp = TimeFormatService.timeToTimestamp(line.stopTime) + (line.outOffset || 0);
    return TimeFormatService.timestampToTime(timestamp);
  }
};
var _default = TimeFormatService;
exports.default = _default;