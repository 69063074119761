"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _config = require("../../../../config/config.3x");

var _posthogJs = _interopRequireDefault(require("posthog-js"));

var FlagService = {
  init: () => {
    if (window.Cypress) {
      return;
    }

    return _posthogJs.default.init(_config.POSTHOG_TOKEN, _config.POSTHOG_CONFIG);
  },
  identify: session => {
    if (window.Cypress) {
      return;
    }

    _posthogJs.default.identify(session._id, {
      name: session.name,
      email: session.emailNormal
    });
  },
  isFeatureEnabled: featureName => {
    if (window.Cypress) {
      return true;
    }

    return _posthogJs.default.isFeatureEnabled(featureName);
  },
  reload: () => {
    if (window.Cypress) {
      return;
    }

    return _posthogJs.default.reloadFeatureFlags();
  }
};
var _default = FlagService;
exports.default = _default;