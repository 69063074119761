"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actions = exports.STORE_NAME = exports.INITIAL_STATE = exports.FETCH_USER_BY_EMAIL_SUCCESS = exports.FETCH_USER_BY_EMAIL_REQUEST = exports.FETCH_USER_BY_EMAIL_FAILURE = exports.FETCH_USER_BY_EMAIL = void 0;
exports.fetchUserByEmail = fetchUserByEmail;
exports.reducer = reducer;
exports.selectors = void 0;
exports.watchUsers = watchUsers;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _reduxActions = require("redux-actions");

var _effects = require("redux-saga/effects");

var _common = require("./common");

var _userService = _interopRequireDefault(require("~/app/3x/modules/services/user-service"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var STORE_NAME = 'usersStore';
exports.STORE_NAME = STORE_NAME;

function* fetchUserByEmail(_ref) {
  var {
    payload: email
  } = _ref;

  try {
    yield (0, _effects.put)(internalActions.getUserByEmailRequest(email));
    var user = yield _userService.default.getUserByEmail(email);
    yield (0, _effects.put)(internalActions.getUserByEmailSuccess({
      user,
      email
    }));
    return user;
  } catch (e) {
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.getUserByEmailFailure({
      email,
      err
    }));
    return err;
  }
}

var FETCH_USER_BY_EMAIL = 'users.by-email.fetch';
exports.FETCH_USER_BY_EMAIL = FETCH_USER_BY_EMAIL;
var FETCH_USER_BY_EMAIL_REQUEST = 'users.by-email.fetch.request';
exports.FETCH_USER_BY_EMAIL_REQUEST = FETCH_USER_BY_EMAIL_REQUEST;
var FETCH_USER_BY_EMAIL_SUCCESS = 'users.by-email.fetch.success';
exports.FETCH_USER_BY_EMAIL_SUCCESS = FETCH_USER_BY_EMAIL_SUCCESS;
var FETCH_USER_BY_EMAIL_FAILURE = 'users.by-email.fetch.failure';
exports.FETCH_USER_BY_EMAIL_FAILURE = FETCH_USER_BY_EMAIL_FAILURE;
var INITIAL_STATE = {
  isLoading: false,
  lastLoadedAt: null,
  users: {},
  emailToId: {}
};
exports.INITIAL_STATE = INITIAL_STATE;

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case FETCH_USER_BY_EMAIL_REQUEST:
      {
        var email = action.payload;

        var emailToId = _objectSpread(_objectSpread({}, state.emailToId), {}, {
          [email]: _objectSpread(_objectSpread({
            userId: null,
            lastLoadedAt: null
          }, state.emailToId[email]), {}, {
            isLoading: true
          })
        });

        return _objectSpread(_objectSpread({}, state), {}, {
          emailToId
        });
      }

    case FETCH_USER_BY_EMAIL_SUCCESS:
      {
        var {
          user,
          email: _email
        } = action.payload;
        var now = new Date();

        var _emailToId = _objectSpread(_objectSpread({}, state.emailToId), {}, {
          [_email]: {
            isLoading: false,
            userId: user && user._id,
            lastLoadedAt: now,
            loadError: null
          }
        });

        var users = state.users;

        if (user) {
          users = _objectSpread(_objectSpread({}, state.users), {}, {
            [user._id]: {
              isLoading: false,
              lastLoadedAt: now,
              data: user,
              loadError: null
            }
          });
        }

        return _objectSpread(_objectSpread({}, state), {}, {
          emailToId: _emailToId,
          users
        });
      }

    case FETCH_USER_BY_EMAIL_FAILURE:
      {
        var {
          email: _email2,
          err
        } = action.payload;

        var _emailToId2 = _objectSpread(_objectSpread({}, state.emailToId), {}, {
          [_email2]: {
            isLoading: false,
            userId: null,
            loadError: err,
            lastLoadedAt: state.emailToId[_email2].lastLoadedAt
          }
        });

        return _objectSpread(_objectSpread({}, state), {}, {
          emailToId: _emailToId2
        });
      }

    default:
      return state;
  }
}

var actions = {
  getUserByEmail: (0, _reduxActions.createAction)(FETCH_USER_BY_EMAIL)
};
/**
 * Actions that should only be invoked internally
 */

exports.actions = actions;
var internalActions = {
  getUserByEmailRequest: (0, _reduxActions.createAction)(FETCH_USER_BY_EMAIL_REQUEST),
  getUserByEmailSuccess: (0, _reduxActions.createAction)(FETCH_USER_BY_EMAIL_SUCCESS),
  getUserByEmailFailure: (0, _reduxActions.createAction)(FETCH_USER_BY_EMAIL_FAILURE)
};
var selectors = {
  getUserById: (0, _common.createSelector)(STORE_NAME, getUserById),
  getUserByEmail: (0, _common.createSelector)(STORE_NAME, getUserByEmail),
  getUserLastLoadedByEmailAt: (0, _common.createSelector)(STORE_NAME, getUserLastLoadedByEmailAt),
  isLoadingUserByEmail: (0, _common.createSelector)(STORE_NAME, isLoadingUserByEmail),
  isFailedLoadUserByEmail: (0, _common.createSelector)(STORE_NAME, isFailedLoadUserByEmail)
};
exports.selectors = selectors;

function getUserById(state, id) {
  if (!(id in state.users)) {
    return null;
  }

  var userContainer = state.users[id];
  return userContainer.data;
}

function getUserByEmail(state, email) {
  if (!(email in state.emailToId)) {
    return null;
  }

  var emailRecord = state.emailToId[email];
  var userId = emailRecord.userId;

  if (!userId) {
    return null;
  }

  return state.users[userId].data;
}

function getUserLastLoadedByEmailAt(state, email) {
  if (!(email in state.emailToId)) {
    return null;
  }

  var emailRecord = state.emailToId[email];
  return emailRecord.lastLoadedAt;
}

function isLoadingUserByEmail(state, email) {
  if (!(email in state.emailToId)) {
    return false;
  }

  var emailRecord = state.emailToId[email];
  return emailRecord.isLoading;
}

function isFailedLoadUserByEmail(state, email) {
  if (!(email in state.emailToId)) {
    return false;
  }

  var emailRecord = state.emailToId[email];
  return !!emailRecord.loadError;
}

function* watchUsers() {
  yield (0, _effects.takeLatest)(FETCH_USER_BY_EMAIL, fetchUserByEmail);
}