"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SpinnerContainer = exports.RecentUser = exports.RecentTitle = exports.RecentList = exports.RecentContainer = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;

var RecentContainer = _styledComponents.default.div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n\tpadding-top: 2rem;\n\tmin-height: 12rem;\n"])));

exports.RecentContainer = RecentContainer;

var RecentTitle = _styledComponents.default.h5(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n\tfont-weight: 700 !important;\n\tmargin-bottom: 1.25rem !important;\n"])));

exports.RecentTitle = RecentTitle;

var RecentList = _styledComponents.default.ul(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["\n\tlist-style: none;\n\tmargin-left: 0;\n"])));

exports.RecentList = RecentList;

var RecentUser = _styledComponents.default.li(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)(["\n\tmargin-bottom: 1rem;\n\topacity: 0.5;\n\ttransition: opacity 100ms;\n\n\t&:hover {\n\t\topacity: 1;\n\t}\n\n\ta {\n\t\tstrong {\n\t\t\tfont-weight: 500;\n\t\t\tmargin-right: 0.5rem;\n\t\t}\n\t}\n"])));

exports.RecentUser = RecentUser;

var SpinnerContainer = _styledComponents.default.div(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2.default)(["\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\tpadding-bottom: 3rem;\n"])));

exports.SpinnerContainer = SpinnerContainer;