"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _apiService = _interopRequireDefault(require("./api-service"));

var _transfer = _interopRequireDefault(require("./transfer"));

var _lodash = _interopRequireDefault(require("lodash"));

var _config = require("../../../../config/config.3x");

var _aspera = _interopRequireDefault(require("./aspera"));

var TransferManager = {
  credentials: {},
  transfers: [],
  errors: [],
  setCredentials: (session, token) => {
    TransferManager.credentials = {
      userId: session._id,
      token
    };
  },
  logTransfer: function () {
    var _logTransfer = (0, _asyncToGenerator2.default)(function* (files) {
      try {
        var {
          data
        } = yield _apiService.default.client.post('/events', {
          from: TransferManager.credentials.userId,
          files: files.map(file => file._id),
          type: 'fd'
        });
        return data;
      } catch (e) {
        var errors = files.map(file => {
          return {
            path: file.path,
            code: 'px01',
            desc: 'failed to log event'
          };
        });
        throw errors;
      }
    });

    function logTransfer(_x) {
      return _logTransfer.apply(this, arguments);
    }

    return logTransfer;
  }(),
  httpDownload: transferSpec => {
    var _transferSpec$presign;

    var event = window.document.createEvent('MouseEvents');
    var link = window.document.createElement('a');
    link.href = transferSpec.presignedUrl;
    link.download = (_transferSpec$presign = transferSpec.presignedUrl) === null || _transferSpec$presign === void 0 ? void 0 : _transferSpec$presign.split('?')[0].split('/').pop();
    event.initEvent('click', true, true);
    link.dispatchEvent(event);
  },
  startDownload: file => {
    return new Promise((resolve, reject) => _apiService.default.client.post('/aspera/downloadSpec', {
      id: file._id
    }).then(res => {
      var transferSpec = res.data;
      var connectSpec = {
        allow_dialogs: false
      };

      if (_aspera.default.Connect.getStatus() !== 'RUNNING' || _aspera.default.isDisabled) {
        TransferManager.httpDownload(transferSpec);
        resolve({});
      }

      var result = _aspera.default.Connect.startTransfer(transferSpec, connectSpec, {
        success: result => {
          result = result.transfer_specs[0];
          var transfer = new _transfer.default({
            uuid: result.uuid,
            title: file.name,
            percentage: 0,
            rate: 0,
            status: _aspera.default.TRANSFER_STATUS.INITIATING,
            add_time: new Date(),
            // We just make up a number greater then 0, percentage complete will be zero
            bytes_expected: 1,
            received: 0
          });
          TransferManager.transfers.push(transfer);
          resolve(transfer);
        },
        failure: e => {
          result.error = e;
          result.error.path = file.path; // We resolve failures so that we can handle failures individually later

          resolve(transferSpec);
        }
      });
    }).catch(e => {
      // Wrap $http failure as an object with error field, since that what parseErrors expects.
      reject({
        error: {
          path: file.path,
          code: e.status,
          user_message: e.statusText
        }
      });
    }));
  },
  parseErrors: results => {
    return (0, _lodash.default)(results).pluck('error').compact().map(error => {
      return {
        path: error.path,
        code: error.code,
        desc: error.user_message
      };
    }).value();
  },
  add: function () {
    var _add = (0, _asyncToGenerator2.default)(function* (files) {
      TransferManager.errors = [];

      try {
        yield TransferManager.logTransfer(files);
        var results = files.map(TransferManager.startDownload);
        return Promise.all(results).then(res => {
          var errors = TransferManager.parseErrors(res);

          if (errors.length) {
            throw new Error(errors);
          }

          return files;
        });
      } catch (errors) {
        TransferManager.errors = errors;
      }
    });

    function add(_x2) {
      return _add.apply(this, arguments);
    }

    return add;
  }(),
  upsert: transfer => {
    if (Array.isArray(transfer)) {
      return transfer.forEach(TransferManager.upsert);
    }

    var index = _lodash.default.findIndex(TransferManager.transfers, _lodash.default.pick(transfer, 'id'));

    if (index < 0) {
      return TransferManager.transfers.push(transfer);
    }

    TransferManager.transfers[index] = transfer;
  },
  remove: transfer => {
    if (Array.isArray(transfer)) {
      return transfer.forEach(TransferManager.remove);
    }

    TransferManager.transfers = _lodash.default.reject(TransferManager.transfers, _lodash.default.pick(transfer, 'id'));
  },
  pluginStatus: () => _aspera.default.Connect.getStatus(),
  eventHandler: (eventType, data) => {
    if (!data.transfers.length) {
      return;
    }

    data.transfers.forEach(t => {
      if (t.transfer_spec && t.transfer_spec.remote_host === _config.ASPERA_DOWNLOAD_ROOT) {
        var transfer = new _transfer.default(t);

        switch (transfer.status) {
          case _aspera.default.TRANSFER_STATUS.REMOVED:
            TransferManager.remove(transfer);
            break;

          case _aspera.default.TRANSFER_STATUS.COMPLETED:
          case _aspera.default.TRANSFER_STATUS.RUNNING:
          case _aspera.default.TRANSFER_STATUS.CANCELLED:
            TransferManager.upsert(transfer);
            break;
        }
      }
    });
  }
};
var _default = TransferManager;
exports.default = _default;