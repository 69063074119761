"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Overlay = exports.MenuContainer = exports.Header = exports.FiltersContainer = exports.Container = exports.CheckboxLabel = void 0;

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _polished = require("polished");

var _excluded = ["theme"];

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;

var transitionDuration = '300ms';

var Container = _styledComponents.default.div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n\tposition: relative;\n\ttransition: background-color ", " ease;\n\tbackground-color: ", ";\n\toverflow: hidden;\n\tpointer-events: ", ";\n"])), transitionDuration, _ref => {
  var {
    theme
  } = _ref,
      props = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  var opacity = props.isOpen ? 0.75 : 0;
  return (0, _polished.rgba)(theme['black'], opacity);
}, props => props.isOpen ? 'all' : 'none');

exports.Container = Container;

var Overlay = _styledComponents.default.div(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n\tposition: absolute;\n\ttop: 0;\n\tleft: 0;\n\twidth: 100%;\n\theight: 100%;\n\tpointer-events: ", ";\n"])), props => props.isOpen ? 'all' : 'none');

exports.Overlay = Overlay;

var MenuContainer = _styledComponents.default.div(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["\n\tdisplay: grid;\n\tgrid-template-rows: auto 1fr;\n\ttransition: transform ", " cubic-bezier(0.86, 0.01, 0.77, 0.78);\n\ttransform: ", ";\n\tbackground-color: ", ";\n\tmax-width: 40rem;\n\theight: 100vh;\n\tbox-shadow: 3px 0px 6px 0px ", ";\n"])), transitionDuration, props => props.isOpen ? "translateX(0)" : 'translateX(calc(-100% - 9px))', _ref2 => {
  var {
    theme
  } = _ref2;
  return (0, _polished.darken)(0.02, theme['midnight-lighten-5']);
}, _ref3 => {
  var {
    theme
  } = _ref3;
  return (0, _polished.rgba)(theme['black'], 0.5);
});

exports.MenuContainer = MenuContainer;

var Header = _styledComponents.default.header(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)(["\n\tpadding: 1.5rem 1.5rem 0;\n\n\th3 {\n\t\tmargin-bottom: 0.75rem;\n\t\tfont-weight: 700;\n\t}\n"])));

exports.Header = Header;

var FiltersContainer = _styledComponents.default.div(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2.default)(["\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: space-between;\n\tmargin-bottom: 1.25rem;\n\tpadding-top: 1rem;\n\n\t& > div {\n\t\tdisplay: flex;\n\n\t\tlabel {\n\t\t\tmargin-right: 2rem;\n\t\t\tmargin-bottom: 0;\n\t\t\tfont-weight: 700;\n\t\t}\n\t}\n"])));

exports.FiltersContainer = FiltersContainer;

var CheckboxLabel = _styledComponents.default.span(_templateObject6 || (_templateObject6 = (0, _taggedTemplateLiteral2.default)(["\n\tmargin-left: 0.5rem;\n"])));

exports.CheckboxLabel = CheckboxLabel;