"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.USAGE_TYPE_OPTIONS = void 0;
var USAGE_TYPE_OPTIONS = [{
  label: 'Broadcast',
  value: 'broadcast'
}, {
  label: 'Online',
  value: 'online'
}, {
  label: 'DCP',
  value: 'dcp'
}, {
  label: '4K DCP',
  value: 'dcp4k'
}, {
  label: '3D DCP',
  value: 'dcp3d'
}, {
  label: '3D 4K DCP',
  value: 'dcp3d4k'
}, {
  label: '35mm',
  value: 'film35'
}, {
  label: '70mm',
  value: 'film70'
}, {
  label: 'IMAX',
  value: 'imax'
}, {
  label: 'ATMOS',
  value: 'atmos'
}];
exports.USAGE_TYPE_OPTIONS = USAGE_TYPE_OPTIONS;
var BroadcastOptions = [{
  label: 'HD',
  value: 'HD',
  aspectRatios: ['16:9', '1.85:1', '2.00:1', '2.35:1', '2.40:1']
}, {
  label: 'SD 4:3',
  value: 'SD 4:3',
  aspectRatios: ['16:9', '1.85:1', '2.00:1', '2.35:1', '2.40:1']
}, {
  label: 'SD 16:9',
  value: 'SD 16:9',
  aspectRatios: ['16:9', '1.85:1', '2.00:1', '2.35:1', '2.40:1']
}];
var OnlineOptions = [{
  label: '1080p',
  value: '1080p',
  aspectRatios: ['16:9', '1.85:1', '2.00:1', '2.35:1', '2.40:1', '1:1']
}, {
  label: '720p',
  value: '720p',
  aspectRatios: ['16:9', '1.85:1', '2.00:1', '2.35:1', '2.40:1']
}, {
  label: '480p',
  value: '480p',
  aspectRatios: ['16:9', '1.85:1', '2.00:1', '2.35:1', '2.40:1']
}, {
  label: '360p',
  value: '360p',
  aspectRatios: ['16:9', '1.85:1', '2.00:1', '2.35:1', '2.40:1']
}, {
  label: '1920p',
  value: '1920p',
  aspectRatios: ['9:16']
}, {
  label: 'Orban',
  value: 'Orban',
  aspectRatios: ['16:9', '1.85:1', '2.00:1', '2.35:1', '2.40:1']
}, {
  label: '1350p',
  value: '1350p',
  aspectRatios: ['4:5']
}];
var TheatricalOptions = [{
  label: '2D Flat',
  value: '2D Flat'
}, {
  label: '2D Scope',
  value: '2D Scope'
}, {
  label: '2D Combo',
  value: '2D Combo'
}];
var Theatrical3DOptions = [{
  label: '3D Flat',
  value: '3D Flat'
}, {
  label: '3D Scope',
  value: '3D Scope'
}, {
  label: '3D Combo',
  value: '3D Combo'
}];
var Theatrical4KOptions = [{
  label: '2D Flat (4K)',
  value: '2D Flat (4K)'
}, {
  label: '2D Scope (4K)',
  value: '2D Scope (4K)'
}, {
  label: '2D Combo (4K)',
  value: '2D Combo (4K)'
}];
var Theatrical3D4KOptions = [{
  label: '3D Flat (4K)',
  value: '3D Flat (4K)'
}, {
  label: '3D Scope (4K)',
  value: '3D Scope (4K)'
}, {
  label: '3D Combo (4K)',
  value: '3D Combo (4K)'
}];
var TheatricalIMAXOptions = [{
  label: 'IMAX Laser',
  value: 'IMAX Laser'
}, {
  label: 'IMAX Xenon',
  value: 'IMAX Xenon'
}];
var TheatricalATMOSOptions = [{
  label: 'ATMOS',
  value: 'ATMOS'
}];
var PrintOptions = [{
  label: 'CMYK',
  value: 'cmyk'
}];
var DigitalOptions = [{
  label: 'RGB',
  value: 'rgb'
}];
var RoadshowOptions = [{
  label: 'Physical',
  value: 'physical'
}, {
  label: 'Aspera',
  value: 'aspera'
}];
var StreamingOptions = [{
  label: 'Link',
  value: 'streamingfile'
}];
var UsageOptions = {
  broadcast: BroadcastOptions,
  online: OnlineOptions,
  theatrical: TheatricalOptions,
  theatrical4K: Theatrical4KOptions,
  theatrical3D: Theatrical3DOptions,
  theatrical3D4K: Theatrical3D4KOptions,
  theatricalIMAX: TheatricalIMAXOptions,
  theatricalATMOS: TheatricalATMOSOptions,
  print: PrintOptions,
  digital: DigitalOptions,
  roadshow: RoadshowOptions,
  streaming: StreamingOptions
};
var _default = UsageOptions;
exports.default = _default;