"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Legend = exports.Label = exports.FormText = exports.FormItem = exports.Fieldset = exports.Container = exports.ButtonGroup = exports.AbsoluteContainer = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _polished = require("polished");

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8;

var Container = _styledComponents.default.div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\theight: 100vh;\n"])));

exports.Container = Container;

var Fieldset = _styledComponents.default.fieldset(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n\tmargin-bottom: 2rem;\n"])));

exports.Fieldset = Fieldset;

var Legend = _styledComponents.default.legend(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)([""])));

exports.Legend = Legend;

var Label = _styledComponents.default.label(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)(["\n\tfont-weight: 700;\n"])));

exports.Label = Label;

var FormItem = _styledComponents.default.div(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2.default)(["\n\tmargin-bottom: 1.5rem;\n"])));

exports.FormItem = FormItem;

var FormText = _styledComponents.default.p(_templateObject6 || (_templateObject6 = (0, _taggedTemplateLiteral2.default)(["\n\tfont-weight: 700;\n\tcolor: ", ";\n"])), _ref => {
  var {
    theme
  } = _ref;
  return (0, _polished.rgba)(theme['white'], 0.5);
});

exports.FormText = FormText;

var ButtonGroup = _styledComponents.default.div(_templateObject7 || (_templateObject7 = (0, _taggedTemplateLiteral2.default)(["\n\tdisplay: inline-flex;\n\tmargin-bottom: 1rem;\n\n\tbutton {\n\t\tmargin: 0;\n\n\t\t&:not(:first-child) {\n\t\t\tborder-top-left-radius: 0;\n\t\t\tborder-bottom-left-radius: 0;\n\t\t}\n\n\t\t&:not(:last-child) {\n\t\t\tborder-top-right-radius: 0;\n\t\t\tborder-bottom-right-radius: 0;\n\t\t}\n\t}\n"])));

exports.ButtonGroup = ButtonGroup;

var AbsoluteContainer = _styledComponents.default.div(_templateObject8 || (_templateObject8 = (0, _taggedTemplateLiteral2.default)(["\n\tposition: absolute;\n\ttop: 0;\n\tleft: 0;\n\twidth: 100%;\n\theight: 100%;\n"])));

exports.AbsoluteContainer = AbsoluteContainer;