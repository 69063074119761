"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
exports.useToggle = useToggle;

var _react = require("react");

function useToggle(initialState) {
  var [state, setState] = (0, _react.useState)(initialState);

  var toggleState = () => setState(state => !state);

  return [state, toggleState, setState];
}

var _default = useToggle;
exports.default = _default;