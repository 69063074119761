"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _stripTagsService = _interopRequireDefault(require("./strip-tags-service"));

var _timeFormatService = _interopRequireDefault(require("./time-format-service"));

var _lodash = _interopRequireDefault(require("lodash"));

var ASSService = {
  trim: str => {
    return str.replace(/^\s+|\s+$/g, '');
  },
  searchIndex: (lines, pattern, startIndex) => {
    var len = lines.length;
    startIndex = startIndex || 0;

    for (var i = startIndex; i < len; i++) {
      var line = lines[i];

      if (line.match(pattern)) {
        return i;
      }
    }

    return false;
  },
  extractStyles: lines => {
    var line;
    var styles = {};
    var currentIndex = ASSService.searchIndex(lines, /^\[V4\+ Styles\]$/);

    if (currentIndex === false) {
      return styles;
    }

    currentIndex = ASSService.searchIndex(lines, /^Format:/, ++currentIndex);

    if (currentIndex === false) {
      return styles;
    }

    line = ASSService.trim(lines[currentIndex]);
    var fields = line.replace(/^Format:\s+/, '').split(/\s*,\s*/);
    var nameIndex = fields.indexOf('Name');
    var alignmentIndex = fields.indexOf('Alignment');

    if (nameIndex === -1) {
      return styles;
    } // eslint-disable-next-line no-useless-escape


    var pattern = new RegExp('^' + new Array(fields.length).join('\s*(.*?)\s*,') + '(.*?)$');
    currentIndex = ASSService.searchIndex(lines, /^Style:/, ++currentIndex);

    while (currentIndex !== false) {
      line = ASSService.trim(lines[currentIndex]);
      var parts = line.replace(/^Style:\s+/, '').match(pattern);

      if (!parts) {
        throw new Error('Error, missing fields in a `Style` entry');
      }

      if (parts.length) {
        var style = {};

        if (alignmentIndex !== -1) {
          var alignmentNumber = parts[alignmentIndex + 1];
          style.alignment = alignmentNumber >= 7 ? 'top' : 'bottom';
        }

        styles[parts[nameIndex + 1]] = style;
      }

      currentIndex = ASSService.searchIndex(lines, /^Style:/, ++currentIndex);
    }

    return styles;
  },
  parse: lines => {
    var line;
    var currentIndex = 0;

    function normalizeTime(time) {
      if (time.charAt(1) === ':') {
        time = '0' + time;
      }

      return time.replace('.', ',') + '0';
    }

    function normalizeText(text) {
      return text.replace(/\\n|\\N/, '\n').replace(/{\\(.*)1}(.*){\\\1[0]}/g, (match, tag, content) => {
        return '<' + tag + '>' + content + '</' + tag + '>';
      });
    }

    var styles = ASSService.extractStyles(lines);
    currentIndex = ASSService.searchIndex(lines, /^\[Events\]$/);

    if (currentIndex === false) {
      throw new Error('Error, missing `[Events]` section');
    }

    currentIndex = ASSService.searchIndex(lines, /^Format:/, ++currentIndex);

    if (currentIndex === false) {
      throw new Error('Error, missing `Format` entry');
    }

    line = ASSService.trim(lines[currentIndex]);
    var fields = line.replace(/^Format:\s+/, '').split(/\s*,\s*/);
    var startIndex = fields.indexOf('Start');
    var endIndex = fields.indexOf('End');
    var styleIndex = fields.indexOf('Style');
    var textIndex = fields.indexOf('Text');

    if (startIndex === -1 || endIndex === -1 || textIndex === -1) {
      throw new Error('Error, missing fields in a `Format` entry');
    }

    var result = []; // eslint-disable-next-line no-useless-escape

    var pattern = new RegExp('^' + new Array(fields.length).join('\s*(.*?)\s*,') + '(.*?)$');
    currentIndex = ASSService.searchIndex(lines, /^Dialogue:/, ++currentIndex);

    while (currentIndex !== false) {
      line = ASSService.trim(lines[currentIndex]);
      var parts = line.replace(/^Dialogue:\s+/, '').match(pattern);

      if (!parts) {
        throw new Error('Error, missing fields in a `Dialogue` entry');
      }

      if (parts.length) {
        var entry = {
          startTime: normalizeTime(parts[startIndex + 1]),
          stopTime: normalizeTime(parts[endIndex + 1]),
          text: normalizeText(parts[textIndex + 1]),
          alignment: 'bottom'
        };

        if (styleIndex !== -1) {
          var style = styles[parts[styleIndex + 1]];

          if (style && style.alignment) {
            entry.alignment = style.alignment;
          }
        }

        result.push(entry);
      }

      currentIndex = ASSService.searchIndex(lines, /^Dialogue:/, ++currentIndex);
    }

    return result;
  },
  export: (lines, options) => {
    var result = '';
    options = options || {};
    var font = options.font || {
      face: 'Arial',
      size: 70
    };
    var marginL = options.isTiktok ? 120 : 20;
    var marginR = options.isTiktok ? 240 : 20;
    var marginT = options.isTiktok ? 150 : 20;
    var marginB = options.isTiktok ? 780 : 20;
    var playResX = options.isTiktok ? 1080 : 1920;
    var playResY = options.isTiktok ? 1920 : 1080;
    var alignmentTop = options.isTiktok ? '5' : '8';
    var alignmentBottom = options.isTiktok ? '1' : '2';
    result += '[Script Info]\n';
    result += 'ScriptType: v4.00+\n';
    result += 'PlayResX: ' + playResX + '\n';
    result += 'PlayResY: ' + playResY + '\n\n';
    result += '[V4+ Styles]\n';
    result += 'Format: Name, Fontname, Fontsize, PrimaryColour,Shadow,MarginL,MarginR,MarginV,Alignment\n';
    result += 'Style: Bottom,' + font.face + ',' + font.size + ",&H00FFFFFF,1,".concat(marginL, ",").concat(marginR, ",").concat(marginB, ",").concat(alignmentBottom, "\n");
    result += 'Style: Top,' + font.face + ',' + font.size + ",&H00FFFFFF,1,".concat(marginL, ",").concat(marginR, ",").concat(marginT, ",").concat(alignmentTop, "\n\n");
    result += '[Events]\n';
    result += 'Format: Layer, Start, End, Style, Text\n';

    function normalizeTime(time) {
      if (time.charAt(0) === '0') {
        time = time.substr(1);
      }

      return time.replace(',', '.').slice(0, -1);
    }

    function normalizeText(text) {
      text = text.replace(/&nbsp;/g, ' ');
      text = _lodash.default.unescape(text.replace(/<div>/g, '').replace(/<\/div>/g, '\\N').replace(/\n/g, '\\N').replace(/<br>/g, '\\N').replace(/<i>/g, '{\\i1}').replace(/<\/i>/g, '{\\i0}').replace(/<b>/g, '{\\b1}').replace(/<\/b>/g, '{\\b0}').replace(/<u>/g, '{\\u1}').replace(/<\/u>/g, '{\\u0}'));
      return (0, _stripTagsService.default)(text);
    }

    lines.forEach(line => {
      if (line.text === undefined) {
        return;
      }

      result += 'Dialogue: 0';
      result += ',' + normalizeTime(_timeFormatService.default.startTime(line));
      result += ',' + normalizeTime(_timeFormatService.default.stopTime(line));
      result += line.alignment === 'top' ? ',Top' : ',Bottom';
      result += ',' + normalizeText(line.text) + '\n';
    });
    return result;
  }
};
var _default = ASSService;
exports.default = _default;