"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = usePreferences;

var _reactQuery = require("@tanstack/react-query");

var _userService = _interopRequireDefault(require("../services/user-service"));

function usePreferences() {
  var {
    isInitialLoading: isLoading,
    isError: isErrorLoading,
    data
  } = (0, _reactQuery.useQuery)(['getPreferences'], () => _userService.default.getPreferences());
  var session = data === null || data === void 0 ? void 0 : data.session;
  var sessionId = session === null || session === void 0 ? void 0 : session.id;
  var preferences = session === null || session === void 0 ? void 0 : session.preferences;
  var queryClient = (0, _reactQuery.useQueryClient)();
  var {
    mutateAsync: updatePreferences,
    isLoading: isSaving
  } = (0, _reactQuery.useMutation)(prefs => _userService.default.updatePreferences(sessionId, prefs), {
    onSuccess: data => queryClient.setQueryData(['getPreferences'], data.updateSession)
  });
  return {
    session,
    isLoading,
    isErrorLoading,
    preferences,
    isSaving,
    updatePreferences
  };
}