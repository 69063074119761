"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _excluded = ["_id"],
    _excluded2 = ["id"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var IdService = {
  convertToId(object) {
    if (!object._id) {
      return object;
    }

    var {
      _id: id
    } = object,
        rest = (0, _objectWithoutProperties2.default)(object, _excluded);
    return _objectSpread(_objectSpread({}, rest), {}, {
      id
    });
  },

  convertFromId(object) {
    if (!object.id) {
      return object;
    }

    var {
      id: _id
    } = object,
        rest = (0, _objectWithoutProperties2.default)(object, _excluded2);
    return _objectSpread(_objectSpread({}, rest), {}, {
      _id
    });
  },

  convertToIds(array) {
    return array.map(item => {
      if (typeof item === 'object') {
        return IdService.convertToId(item);
      }

      return item;
    });
  },

  convertFromIds(array) {
    return array.map(item => {
      if (typeof item === 'object') {
        return IdService.convertFromId(item);
      }

      return item;
    });
  }

};
var _default = IdService;
exports.default = _default;