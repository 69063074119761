"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.rootReducer = exports.default = void 0;

var _redux = require("redux");

var _session = require("~/app/3x/store/session");

var _locale = require("~/app/3x/store/locale");

var _projects = require("~/app/3x/store/projects");

var _studios = require("~/app/3x/store/studios");

var _groups = require("~/app/3x/store/groups");

var _assets = require("~/app/3x/store/assets");

var _assetTypes = require("~/app/3x/store/asset-types");

var _previews = require("~/app/3x/store/previews");

var _files = require("~/app/3x/store/files");

var _languages = require("~/app/3x/store/languages");

var _territories = require("~/app/3x/store/territories");

var _users = require("~/app/3x/store/users");

var _workRequests = require("~/app/3x/store/work-requests");

var _translations = require("~/app/3x/store/translations");

var _ingests = require("~/app/3x/store/ingests");

var _ingestUpload = require("~/app/3x/store/ingest-upload");

var _comments = require("~/app/3x/store/comments");

var _notifications = require("~/app/3x/store/notifications");

var _projectsIndex = require("~/app/3x/store/projects-index");

var _projectSidebar = require("~/app/3x/store/project-sidebar");

var _projectPage = require("~/app/3x/store/project-page");

var _assetPage = require("~/app/3x/store/asset-page");

var _shareQueue = require("~/app/3x/store/share-queue");

var _shareEditPage = require("~/app/3x/store/share-edit-page");

var _shares = require("~/app/3x/store/shares");

var _sharesPage = require("~/app/3x/store/shares-page");

var _shareViewPage = require("~/app/3x/store/share-view-page");

var _shareFeedbackPage = require("~/app/3x/store/share-feedback-page");

var _downloadsPage = require("~/app/3x/store/downloads-page");

var _projectCrud = require("~/app/3x/store/project-crud");

var _workRequestQueue = require("~/app/3x/store/work-request-queue");

var _socialRequest = require("~/app/3x/store/social-request");

var _registerPage = require("~/app/3x/store/register-page");

var _subtitlerPage = require("~/app/3x/store/subtitler-page");

var _workRequestsPage = require("~/app/3x/store/work-requests-page");

var _workRequestPage = require("~/app/3x/store/work-request-page");

var rootReducer = (0, _redux.combineReducers)({
  [_session.STORE_NAME]: _session.reducer,
  [_locale.STORE_NAME]: _locale.reducer,
  [_projects.STORE_NAME]: _projects.reducer,
  [_studios.STORE_NAME]: _studios.reducer,
  [_groups.STORE_NAME]: _groups.reducer,
  [_assets.STORE_NAME]: _assets.reducer,
  [_assetTypes.STORE_NAME]: _assetTypes.reducer,
  [_previews.STORE_NAME]: _previews.reducer,
  [_files.STORE_NAME]: _files.reducer,
  [_languages.STORE_NAME]: _languages.reducer,
  [_territories.STORE_NAME]: _territories.reducer,
  [_users.STORE_NAME]: _users.reducer,
  [_workRequests.STORE_NAME]: _workRequests.reducer,
  [_translations.STORE_NAME]: _translations.reducer,
  [_ingests.STORE_NAME]: _ingests.reducer,
  [_ingestUpload.STORE_NAME]: _ingestUpload.reducer,
  [_comments.STORE_NAME]: _comments.reducer,
  [_notifications.STORE_NAME]: _notifications.reducer,
  [_projectsIndex.STORE_NAME]: _projectsIndex.reducer,
  projectSidebarStore: _projectSidebar.reducer,
  [_projectPage.STORE_NAME]: _projectPage.reducer,
  [_assetPage.STORE_NAME]: _assetPage.reducer,
  [_shareQueue.STORE_NAME]: _shareQueue.reducer,
  [_shareEditPage.STORE_NAME]: _shareEditPage.reducer,
  sharesStore: _shares.reducer,
  [_sharesPage.STORE_NAME]: _sharesPage.reducer,
  [_shareViewPage.STORE_NAME]: _shareViewPage.reducer,
  [_shareFeedbackPage.STORE_NAME]: _shareFeedbackPage.reducer,
  [_downloadsPage.STORE_NAME]: _downloadsPage.reducer,
  [_projectCrud.STORE_NAME]: _projectCrud.reducer,
  [_workRequestQueue.STORE_NAME]: _workRequestQueue.reducer,
  [_socialRequest.STORE_NAME]: _socialRequest.reducer,
  [_registerPage.STORE_NAME]: _registerPage.reducer,
  [_subtitlerPage.STORE_NAME]: _subtitlerPage.reducer,
  [_workRequestsPage.STORE_NAME]: _workRequestsPage.reducer,
  [_workRequestPage.STORE_NAME]: _workRequestPage.reducer
});
exports.rootReducer = rootReducer;
var _default = rootReducer;
exports.default = _default;