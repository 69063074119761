"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actions = exports.STORE_NAME = exports.INITIAL_STATE = exports.FETCH_ASSET_TYPES_SUCCESS = exports.FETCH_ASSET_TYPES_REQUEST = exports.FETCH_ASSET_TYPES_FAILURE = exports.FETCH_ASSET_TYPES = void 0;
exports.fetchAssetTypes = fetchAssetTypes;
exports.reducer = reducer;
exports.selectors = void 0;
exports.watchAssetTypes = watchAssetTypes;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _lodash = _interopRequireDefault(require("lodash"));

var _lodash2 = _interopRequireDefault(require("lodash.keyby"));

var _reduxActions = require("redux-actions");

var _effects = require("redux-saga/effects");

var _common = require("./common");

var _assetTypeService = _interopRequireDefault(require("~/app/3x/modules/services/asset-type-service"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var STORE_NAME = 'assetTypesStore';
exports.STORE_NAME = STORE_NAME;

function* fetchAssetTypes(_ref) {
  var {
    payload
  } = _ref;

  try {
    yield (0, _effects.put)(internalActions.getAssetTypesRequest(payload));
    var assetTypes = yield _assetTypeService.default.getAllAssetTypes();
    yield (0, _effects.put)(internalActions.getAssetTypesSuccess(assetTypes));
    return assetTypes;
  } catch (e) {
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.getAssetTypesFailure(err));
    return err;
  }
}

var FETCH_ASSET_TYPES = 'asset-types.fetch';
exports.FETCH_ASSET_TYPES = FETCH_ASSET_TYPES;
var FETCH_ASSET_TYPES_REQUEST = 'asset-types.fetch.request';
exports.FETCH_ASSET_TYPES_REQUEST = FETCH_ASSET_TYPES_REQUEST;
var FETCH_ASSET_TYPES_SUCCESS = 'asset-types.fetch.success';
exports.FETCH_ASSET_TYPES_SUCCESS = FETCH_ASSET_TYPES_SUCCESS;
var FETCH_ASSET_TYPES_FAILURE = 'asset-types.fetch.failure';
exports.FETCH_ASSET_TYPES_FAILURE = FETCH_ASSET_TYPES_FAILURE;
var INITIAL_STATE = {
  isLoading: false,
  lastLoadedAt: null,
  allAssetTypes: [],
  structuredAssetTypes: {}
};
exports.INITIAL_STATE = INITIAL_STATE;

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case FETCH_ASSET_TYPES_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });

    case FETCH_ASSET_TYPES_SUCCESS:
      {
        var now = new Date();
        var allAssetTypes = action.payload;
        var structuredAssetTypes = (0, _lodash2.default)(allAssetTypes, 'name');
        return _objectSpread(_objectSpread({}, state), {}, {
          allAssetTypes,
          structuredAssetTypes,
          isLoading: false,
          lastLoadedAt: now
        });
      }

    case FETCH_ASSET_TYPES_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });

    default:
      return state;
  }
}

var actions = {
  getAssetTypes: (0, _reduxActions.createAction)(FETCH_ASSET_TYPES)
};
/**
 * Actions that should only be invoked internally
 */

exports.actions = actions;
var internalActions = {
  getAssetTypesRequest: (0, _reduxActions.createAction)(FETCH_ASSET_TYPES_REQUEST),
  getAssetTypesSuccess: (0, _reduxActions.createAction)(FETCH_ASSET_TYPES_SUCCESS),
  getAssetTypesFailure: (0, _reduxActions.createAction)(FETCH_ASSET_TYPES_FAILURE)
};
var selectors = {
  getAssetTypes,
  getAssetType,
  isLoading,
  isLoaded,
  getLastLoadedAt
};
exports.selectors = selectors;

function getAssetTypes(state) {
  var assetTypesStore = state[STORE_NAME];
  return assetTypesStore.allAssetTypes;
}

function getAssetType(state, type) {
  var assetTypesStore = state[STORE_NAME];
  return _lodash.default.find(assetTypesStore.allAssetTypes, {
    name: type
  });
}

function isLoading(state) {
  var assetTypesStore = state[STORE_NAME];
  return assetTypesStore.isLoading;
}

function isLoaded(state) {
  var assetTypesStore = state[STORE_NAME];
  return !!assetTypesStore.lastLoadedAt;
}

function getLastLoadedAt(state) {
  var assetTypesStore = state[STORE_NAME];
  return assetTypesStore.lastLoadedAt;
}

function* watchAssetTypes() {
  yield (0, _effects.takeLatest)(FETCH_ASSET_TYPES, fetchAssetTypes);
}