"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var SUBService = {
  parse: lines => {
    var result = [];
    var current = {
      text: ''
    };
    lines.forEach(line => {
      line = line.replace(/^\s+|\s+$/g, ''); // eslint-disable-next-line no-useless-escape

      var subTime = line.match(/^([0-9:\.]+),([0-9:\.]+)$/);

      if (subTime) {
        if (current.text) {
          result.push(current);
        }

        current = {
          startTime: subTime[1],
          stopTime: subTime[2],
          text: ''
        };
        return;
      }

      if (line) {
        if (current.startTime === undefined || current.stopTime === 'undefined') {
          throw new Error('Malformed SUB file');
        }

        current.text += current.text ? '\n' + line : line;
      }
    });

    if (current.text) {
      result.push(current);
    }

    return result;
  }
};
var _default = SUBService;
exports.default = _default;