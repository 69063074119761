"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _lodash = _interopRequireDefault(require("lodash"));

var _userService = _interopRequireDefault(require("./user-service"));

var _apiService = _interopRequireWildcard(require("./api-service"));

var _common = require("../../store/common");

var _lodash2 = _interopRequireDefault(require("lodash.get"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var RESOURCES_HEADER = /^resources \d+-\d+\/(\d+|\*)$/;
var WorkRequestService = {
  canEditTranslation() {
    return this.status === 'incomplete' || this.status === 'submitted';
  },

  create(data) {
    return _apiService.default.client.post('/workrequests', data).then(res => res.data);
  },

  getById(id) {
    return _apiService.default.client.get("/workrequests/".concat(id)).then(res => {
      return _objectSpread(_objectSpread({}, res.data), {}, {
        canEditTranslation: this.canEditTranslation
      });
    }).catch(err => {
      throw (0, _lodash2.default)(err, 'response', false) || err;
    });
  },

  getReport() {
    var query = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var page = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    var limit = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 50;
    var start = page * limit;
    var end = (page + 1) * limit - 1;

    var promise = _apiService.default.client.get((0, _apiService.buildURL)('/reports/workRequests', query), {
      headers: {
        'X-Range': "resources=".concat(start, "-").concat(end)
      }
    });

    return promise.then(results => {
      var _results$headers$xCo;

      var totalItems = results.data.length;
      var totalPages = 1;
      var matches = ((_results$headers$xCo = results.headers['x-content-range']) === null || _results$headers$xCo === void 0 ? void 0 : _results$headers$xCo.match(RESOURCES_HEADER)) || null;

      if (matches !== null && !_lodash.default.isUndefined(matches[1]) && matches[1] !== '*') {
        totalItems = matches[1];
        totalPages = Math.ceil(totalItems / limit);
      }

      return {
        results: results.data,
        page,
        totalPages,
        totalItems
      };
    });
  },

  update(id, data) {
    return _apiService.default.client.patch("/workrequests/".concat(id), data).then(res => {
      return _objectSpread(_objectSpread({}, res.data), {}, {
        canEditTranslation: this.canEditTranslation
      });
    });
  },

  /**
   * Checks whether an order's deliverables must be edited
   * on 2.x. Theatrical orders must have the same details
   * and recipients for each DCP type.
   *
   * @param {object} workRequest - 2.x schema
   * @returns {boolean} - if true, must be edited on 2.x
   */
  shouldEditOn2x(workRequest) {
    if (!workRequest.dcp) {
      return false;
    }

    var {
      recipients,
      dcp: {
        items: dcpItems,
        details: dcpDetails
      }
    } = workRequest; // if no DCP items (not DCP) or a single DCP selected, OK to edit on 3.x

    if ((dcpItems === null || dcpItems === void 0 ? void 0 : dcpItems.length) < 2) {
      return false;
    }

    var allDcpRecipientIds = [];
    Object.keys(recipients).forEach(type => {
      if (dcpItems !== null && dcpItems !== void 0 && dcpItems.includes(type)) {
        allDcpRecipientIds = [...allDcpRecipientIds, recipients[type].map(r => r._id)];
      }
    }); // array of unique ids from all DCP recipients

    var uniqueIds = [...new Set(allDcpRecipientIds)];
    var detailsMatch = true;
    var recipientsMatch = true;
    dcpItems === null || dcpItems === void 0 ? void 0 : dcpItems.forEach(item => {
      if (!_lodash.default.isEqual(dcpDetails[dcpItems[0]], dcpDetails[item])) {
        detailsMatch = false;
      }

      if (!_lodash.default.difference(recipients[item].map(r => r._id), uniqueIds).length === 0) {
        recipientsMatch = false;
      }
    }); // if there's any mismatch, order must be edited on 2.x

    if (!detailsMatch || !recipientsMatch) {
      return true;
    }

    return false;
  },

  shouldApproveTranslation(workRequest, user) {
    var _workRequest$graphics, _workRequest$translat;

    var ownerId = workRequest.from._id || workRequest.from;
    var isOwner = ownerId === user._id;

    var isAdmin = _userService.default.isAdmin(user);

    var isAdminManager = _userService.default.isAdminManager(user);

    var isGfxOrder = (0, _common.isDedicatedGfxOrder)(workRequest);
    var needsApproval = isGfxOrder ? !!((_workRequest$graphics = workRequest.graphicsTranslation) !== null && _workRequest$graphics !== void 0 && _workRequest$graphics.approval) : !!((_workRequest$translat = workRequest.translation) !== null && _workRequest$translat !== void 0 && _workRequest$translat.approval);
    return needsApproval && (isAdmin || isAdminManager || isOwner);
  },

  shouldSubmitTranslationForApproval(workRequest, user) {
    var _workRequest$graphics2, _workRequest$translat2;

    var ownerId = workRequest.from._id || workRequest.from;
    var isNotOwner = ownerId !== user._id;
    var isGfxOrder = (0, _common.isDedicatedGfxOrder)(workRequest);

    var isAdmin = _userService.default.isAdmin(user);

    var isAdminManager = _userService.default.isAdminManager(user);

    var needsApproval = isGfxOrder ? !!((_workRequest$graphics2 = workRequest.graphicsTranslation) !== null && _workRequest$graphics2 !== void 0 && _workRequest$graphics2.approval) : !!((_workRequest$translat2 = workRequest.translation) !== null && _workRequest$translat2 !== void 0 && _workRequest$translat2.approval);
    return needsApproval && !isAdmin && !isAdminManager && isNotOwner;
  },

  rejectTag(workRequestId, tagName, description) {
    var url = "/workrequests/rejectTag/".concat(workRequestId);
    return _apiService.default.client.put(url, {
      tag: tagName || null,
      description
    });
  }

};
var _default = WorkRequestService;
exports.default = _default;