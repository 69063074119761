"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actions = exports.STORE_NAME = exports.INITIAL_STATE = exports.FETCH_LANGUAGES_SUCCESS = exports.FETCH_LANGUAGES_REQUEST = exports.FETCH_LANGUAGES_FAILURE = exports.FETCH_LANGUAGES = void 0;
exports.fetchLanguages = fetchLanguages;
exports.reducer = reducer;
exports.selectors = void 0;
exports.watchLanguages = watchLanguages;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _lodash = _interopRequireDefault(require("lodash"));

var _lodash2 = _interopRequireDefault(require("lodash.keyby"));

var _reduxActions = require("redux-actions");

var _effects = require("redux-saga/effects");

var _common = require("./common");

var _languageService = _interopRequireDefault(require("~/app/3x/modules/services/language-service"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var STORE_NAME = 'languagesStore';
exports.STORE_NAME = STORE_NAME;

function* fetchLanguages(_ref) {
  var {
    payload
  } = _ref;

  try {
    yield (0, _effects.put)(internalActions.getLanguagesRequest(payload));
    var languages = yield _languageService.default.getAllLanguages();
    yield (0, _effects.put)(internalActions.getLanguagesSuccess(languages));
    return languages;
  } catch (e) {
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.getLanguagesFailure(err));
    return err;
  }
}

var FETCH_LANGUAGES = 'languages.fetch';
exports.FETCH_LANGUAGES = FETCH_LANGUAGES;
var FETCH_LANGUAGES_REQUEST = 'languages.fetch.request';
exports.FETCH_LANGUAGES_REQUEST = FETCH_LANGUAGES_REQUEST;
var FETCH_LANGUAGES_SUCCESS = 'languages.fetch.success';
exports.FETCH_LANGUAGES_SUCCESS = FETCH_LANGUAGES_SUCCESS;
var FETCH_LANGUAGES_FAILURE = 'languages.fetch.failure';
exports.FETCH_LANGUAGES_FAILURE = FETCH_LANGUAGES_FAILURE;
var INITIAL_STATE = {
  isLoading: false,
  lastLoadedAt: null,
  languages: []
};
exports.INITIAL_STATE = INITIAL_STATE;

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case FETCH_LANGUAGES_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });

    case FETCH_LANGUAGES_SUCCESS:
      {
        var now = new Date();
        var languages = (0, _lodash2.default)(action.payload, 'name');
        return _objectSpread(_objectSpread({}, state), {}, {
          languages,
          isLoading: false,
          lastLoadedAt: now
        });
      }

    case FETCH_LANGUAGES_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });

    default:
      return state;
  }
}

var actions = {
  getLanguages: (0, _reduxActions.createAction)(FETCH_LANGUAGES)
};
/**
 * Actions that should only be invoked internally
 */

exports.actions = actions;
var internalActions = {
  getLanguagesRequest: (0, _reduxActions.createAction)(FETCH_LANGUAGES_REQUEST),
  getLanguagesSuccess: (0, _reduxActions.createAction)(FETCH_LANGUAGES_SUCCESS),
  getLanguagesFailure: (0, _reduxActions.createAction)(FETCH_LANGUAGES_FAILURE)
};
var selectors = {
  getLanguages,
  isLoading,
  isLoaded,
  getLastLoadedAt
};
exports.selectors = selectors;

function getLanguages(state) {
  var {
    languagesStore
  } = state;
  return _lodash.default.values(languagesStore.languages);
}

function isLoading(state) {
  var {
    languagesStore
  } = state;
  return languagesStore.isLoading;
}

function isLoaded(state) {
  var {
    languagesStore
  } = state;
  return !!languagesStore.lastLoadedAt;
}

function getLastLoadedAt(state) {
  var {
    languagesStore
  } = state;
  return languagesStore.lastLoadedAt;
}

function* watchLanguages() {
  yield (0, _effects.takeLatest)(FETCH_LANGUAGES, fetchLanguages);
}