"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _faye = _interopRequireDefault(require("faye"));

class Faye {
  constructor(url) {
    (0, _defineProperty2.default)(this, "publish", (channel, data) => {
      return this.client.publish(channel, data);
    });
    (0, _defineProperty2.default)(this, "subscribe", (channel, callback) => {
      return this.client.subscribe(channel, callback);
    });
    this.client = new _faye.default.Client(url);
  }

}

exports.default = Faye;