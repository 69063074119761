"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _lodash = _interopRequireDefault(require("lodash"));

var _moment = _interopRequireDefault(require("moment"));

var _validate2 = _interopRequireDefault(require("validate.js"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var DATE_TIME_EXTENSION = {
  format: function formatDateTime(value, options) {
    var format = options.dateOnly ? 'YYYY-MM-DD' : 'YYYY-MM-DD hh:mm';
    return _moment.default.utc(value).format(format);
  },
  parse: function parseDateTime(value) {
    return +_moment.default.utc(value);
  }
};
_validate2.default.formatters.structured = formatStructured;
_validate2.default.validators.arrayOf = validateArrayOf;
_validate2.default.validators.objectOf = validateObjectOf;
_validate2.default.validators.unique = validateUnique;
_validate2.default.validators.if = validateIf;
_validate2.default.validators.custom = validateCustom;

_validate2.default.extend(_validate2.default.validators.datetime, DATE_TIME_EXTENSION);

function defaultUniqueCompare(v1, v2) {
  return v1 === v2;
}
/**
 * Validates a group of values for uniqueness.
 * Requires that a "getAllValues" method be present on the validation option.
 * Attributes and Global Options will be passed to the "getAllValues" method
 * which is useful when comparing complex object's fields. In which case you
 * should place the "fullObject" in the "globalOptions".
 */


function validateUnique(value, options, attribute, attributes, globalOptions) {
  var {
    fullObject,
    parentObject,
    meta
  } = globalOptions;
  var compareFn = options.compareFn || defaultUniqueCompare;
  var allValues = options.getAllValues(value, {
    attributes,
    fullObject,
    parentObject,
    meta,
    globalOptions
  });

  var found = _lodash.default.filter(allValues, v => compareFn(value, v)); // The value is unique if it's only found once (itself)


  var duplicatedTags = false;

  if (_lodash.default.size(fullObject.tags.tags) > 1) {
    duplicatedTags = options.findDuplicates(fullObject.tags.tags);
  }

  if (found.length > 1 || duplicatedTags) {
    return options.message || this.message || 'must be unique';
  }
}
/**
 * Validates each element of an array against the same constraint.
 * Returns an array where each element is the validation of the corresponding input element,
 * or undefined if no errors were found for the given input element.
 */


function validateArrayOf(value, options, attribute, attributes, globalOptions) {
  var validations = _lodash.default.map(value, element => (0, _validate2.default)(element, options, _objectSpread(_objectSpread({}, globalOptions), {}, {
    parentObject: value
  })));

  var hasErrors = !!_lodash.default.filter(validations).length; // validate.js returns undefined for no errors

  if (!hasErrors) {
    return;
  } // validate.js expands error arrays into individual error records which makes tracking indexes
  // a pain. To avoid this we use an object (which is as good as an array in the right circumstances)


  return _lodash.default.reduce(validations, (result, v, i) => {
    if (v) {
      result[i] = v;
    }

    return result;
  }, {});
}
/**
 * Validates each key/value of an object against the same constraint.
 * Returns an object where each value is the validation of the corresponding key,
 * or undefined if no errors were found for the given input key.
 */


function validateObjectOf(value, options, attribute, attributes, globalOptions) {
  var validateFn = options.single ? _validate2.default.single : _validate2.default;
  options = _lodash.default.omit(options, 'single');

  var validations = _lodash.default.mapValues(value, val => validateFn(val, options, _objectSpread(_objectSpread({}, globalOptions), {}, {
    parentObject: value
  })));

  var hasErrors = !!_lodash.default.filter(validations).length; // validate.js returns undefined for no errors

  if (!hasErrors) {
    return;
  } // validate.js expands error arrays into individual error records which makes tracking indexes
  // a pain. To avoid this we use an object (which is as good as an array in the right circumstances)


  return _lodash.default.reduce(validations, (result, v, i) => {
    if (v) {
      result[i] = v;
    }

    return result;
  }, {});
}
/**
 * A conditional validator that applies validation rules based on
 * the provided condition method.
 */


function validateIf(value, options, attribute, attributes, globalOptions) {
  var {
    fullObject,
    parentObject,
    meta
  } = globalOptions; // If the condition is not met, do not run validations

  if (!options.condition(value, {
    attributes,
    fullObject,
    parentObject,
    meta,
    globalOptions
  })) {
    return;
  } // Wrap value so that we can validate a single value WITH structured formatting.
  // validate.single does not support structured formatting


  var result = (0, _validate2.default)({
    [attribute]: value
  }, {
    [attribute]: options.validations
  }, globalOptions);
  return result && result[attribute];
}
/**
 * A simple wrapper for creating a custom validator without
 * needing access to the underlying validate.js
 */


function validateCustom(value, options, attribute, attributes, globalOptions) {
  var {
    fullObject,
    parentObject,
    meta
  } = globalOptions;
  return options.fn(value, {
    attributes,
    fullObject,
    parentObject,
    meta,
    globalOptions
  });
}
/**
 * Formats validation orders in a structured object
 * {
 *     field1: {
 *         validator1: 'message',
 *         validator2: 'message'
 *     }
 * }
 */


formatStructured.ignoreKeys = ['if', 'objectOf'];

function formatStructured(errors) {
  return _lodash.default.reduce(errors, (result, _ref) => {
    var {
      attribute,
      validator,
      error
    } = _ref;

    if (!(attribute in result)) {
      result[attribute] = {};
    } // Some keys we don't want to include in the structured output, but we want their internal errors


    if (formatStructured.ignoreKeys.indexOf(validator) !== -1) {
      result[attribute] = _lodash.default.merge(result[attribute], error);
      return result;
    }

    result[attribute][validator] = error;
    return result;
  }, {});
}

var ValidationService = {
  validate: (value, constraints, options) => {
    // Set format as structured but allow users to override with options parameter
    options = _objectSpread({
      format: 'structured',
      fullObject: value,
      parentObject: value
    }, options);
    return (0, _validate2.default)(value, constraints, options);
  },
  // The same as validate except for the top layer of key/values are treated as a nested
  // object with its own set of constraints.
  validateNested: (value, constraints, options) => {
    return _lodash.default.reduce(value, (result, nested, section) => {
      var nestedOptions = _objectSpread(_objectSpread({}, options), {}, {
        fullObject: value
      });

      result[section] = ValidationService.validate(nested, constraints[section], nestedOptions);
      return result;
    }, {});
  },
  // The same as validate nested, except for every key is validated against the same constraints
  // instead of validating each nested key against its own nested constraint
  validateAllKeys: (value, constraints, options) => {
    return _lodash.default.reduce(value, (result, nested, section) => {
      var nestedOptions = _objectSpread(_objectSpread({}, options), {}, {
        fullObject: value
      });

      result[section] = ValidationService.validate(nested, constraints, nestedOptions);
      return result;
    }, {});
  }
};
var _default = ValidationService;
exports.default = _default;