"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.ERROR_STUDIO_UNKNOWN = void 0;

var _apiService = _interopRequireDefault(require("./api-service"));

var STUDIOS_QUERY = "query getStudios ($offset: Int, $limit: Int, $search: String) {\n\tstudios(offset: $offset, limit: $limit, search: $search){\n\t\ttotalCount\n\t\tnodes {\n\t\t\tid\n\t\t\tname\n\t\t\tslug\n\t\t\tlogo\n\t\t\tpreferences\n\t\t\tcoordinator{\n\t\t\t\tid: _id\n\t\t\t\tname\n\t\t\t}\n\t\t\tpreviewWatermarkFormat\n\t\t\tbilling\n\t\t}\n\t}\n}";
var STUDIOS_SHORT_QUERY = "query getStudios {\n\tstudios(limit: 100) {\n\t\ttotalCount\n\t  \tnodes {\n\t\t\tvalue: id\n\t\t\tlabel : name\n\t\t\tslug\n\t  }\n\t}\n}\n";
var STUDIOS_SHORT_QUERY_ORIGINAL = "query getStudios {\n\tstudios(limit: 100) {\n\t\ttotalCount\n\t  \tnodes {\n\t\t\tid\n\t\t\tname\n\t  }\n\t}\n }\n";
var ERROR_STUDIO_UNKNOWN = 'error.studio.unknown';
exports.ERROR_STUDIO_UNKNOWN = ERROR_STUDIO_UNKNOWN;
var StudioService = {
  getAllStudiosShort(original) {
    if (original) {
      return _apiService.default.graphql.request(STUDIOS_SHORT_QUERY_ORIGINAL).then(data => data.studios.nodes);
    }

    return _apiService.default.graphql.request(STUDIOS_SHORT_QUERY).then(data => data.studios.nodes);
  },

  getAllStudios(params) {
    if (params) {
      return _apiService.default.graphql.request(STUDIOS_QUERY, params).then(data => {
        return data.studios;
      });
    } // migrate later
    // return api.graphql.request(STUDIOS_QUERY).then((data) => data.studios.nodes);


    return _apiService.default.client.get('/studios', {
      headers: {
        'X-Range': 'resources=0-200'
      }
    }).then(res => res === null || res === void 0 ? void 0 : res.data);
  },

  searchStudios(_ref) {
    var {
      name
    } = _ref;
    return _apiService.default.client.get("/studios?search=".concat(name), {
      headers: {
        'X-Range': 'resources=0-20'
      }
    }).then(res => res.data);
  },

  postStudio(data) {
    return new Promise((resolve, reject) => {
      _apiService.default.client.post("/studios", data).then(resolve).catch(err => {
        var _err$response, _err$response$data, _err$response$data$er, _err$response2;

        reject({
          code: ERROR_STUDIO_UNKNOWN,
          message: (err === null || err === void 0 ? void 0 : (_err$response = err.response) === null || _err$response === void 0 ? void 0 : (_err$response$data = _err$response.data) === null || _err$response$data === void 0 ? void 0 : (_err$response$data$er = _err$response$data.error) === null || _err$response$data$er === void 0 ? void 0 : _err$response$data$er.message) || 'Failed to create a new studio',
          innerError: (err === null || err === void 0 ? void 0 : (_err$response2 = err.response) === null || _err$response2 === void 0 ? void 0 : _err$response2.data) || err
        });
      });
    });
  },

  patchStudio(data) {
    return new Promise((resolve, reject) => {
      _apiService.default.client.patch("/studios/".concat(data.slug), data).then(resolve).catch(err => {
        var _err$response3, _err$response3$data, _err$response3$data$e, _err$response4;

        reject({
          code: ERROR_STUDIO_UNKNOWN,
          message: (err === null || err === void 0 ? void 0 : (_err$response3 = err.response) === null || _err$response3 === void 0 ? void 0 : (_err$response3$data = _err$response3.data) === null || _err$response3$data === void 0 ? void 0 : (_err$response3$data$e = _err$response3$data.error) === null || _err$response3$data$e === void 0 ? void 0 : _err$response3$data$e.message) || 'Failed to update the studio',
          innerError: (err === null || err === void 0 ? void 0 : (_err$response4 = err.response) === null || _err$response4 === void 0 ? void 0 : _err$response4.data) || err
        });
      });
    });
  },

  deleteStudio(_ref2) {
    var {
      slug
    } = _ref2;
    return _apiService.default.client.delete("/studios/".concat(slug));
  }

};
var _default = StudioService;
exports.default = _default;