"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _redux = require("redux");

var _reduxSaga = _interopRequireDefault(require("redux-saga"));

var _reduxDevtoolsExtension = require("redux-devtools-extension");

var _rootSaga = _interopRequireDefault(require("~/app/3x/store/root-saga"));

var _rootReducer = _interopRequireDefault(require("~/app/3x/store/root-reducer"));

var _logrocket = _interopRequireDefault(require("logrocket"));

var _reduxLogger = require("redux-logger");

// redux-devtools-extension/developmentOnly
var sagaMiddleware = (0, _reduxSaga.default)();
var middlewares = []; // eslint-disable-next-line no-undef

if (process.env.NODE_ENV !== "production") {
  var logger = (0, _reduxLogger.createLogger)({
    collapsed: true
  });
  middlewares.push(logger);
}

var store = (0, _redux.createStore)(_rootReducer.default, (0, _reduxDevtoolsExtension.composeWithDevTools)((0, _redux.applyMiddleware)(sagaMiddleware, _logrocket.default.reduxMiddleware(), ...middlewares)));
sagaMiddleware.run(_rootSaga.default);
var _default = store;
exports.default = _default;