"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _lodash = _interopRequireDefault(require("lodash"));

var _apiService = _interopRequireWildcard(require("./api-service"));

var _eventService = _interopRequireDefault(require("./event-service"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var DEFAULT_PAGE_SIZE = 30;
var RESOURCES_HEADER = /^resources \d+-\d+\/(\d+|\*)$/;
var NotificationService = {
  getSummary: function () {
    var _getSummary = (0, _asyncToGenerator2.default)(function* () {
      return _apiService.default.client.get((0, _apiService.buildURL)('/notifications', {
        summary: true
      })).then(res => res.data);
    });

    function getSummary() {
      return _getSummary.apply(this, arguments);
    }

    return getSummary;
  }(),
  markRead: function () {
    var _markRead = (0, _asyncToGenerator2.default)(function* (_id) {
      return _apiService.default.client.patch("/notifications/".concat(_id), {
        read: true,
        viewed: true
      });
    });

    function markRead(_x) {
      return _markRead.apply(this, arguments);
    }

    return markRead;
  }(),
  markAllRead: function () {
    var _markAllRead = (0, _asyncToGenerator2.default)(function* (type) {
      return _apiService.default.client.patch("/notifications?type=".concat(type, "&read=0"), {
        read: true
      });
    });

    function markAllRead(_x2) {
      return _markAllRead.apply(this, arguments);
    }

    return markAllRead;
  }(),
  markUnread: function () {
    var _markUnread = (0, _asyncToGenerator2.default)(function* (_id) {
      return _apiService.default.client.patch("/notifications/".concat(_id), {
        read: false,
        viewed: true
      });
    });

    function markUnread(_x3) {
      return _markUnread.apply(this, arguments);
    }

    return markUnread;
  }(),
  getPagedNotifications: function () {
    var _getPagedNotifications = (0, _asyncToGenerator2.default)(function* (query, criteria) {
      var {
        page = 0,
        limit = DEFAULT_PAGE_SIZE
      } = criteria;
      var first = page * limit;
      var last = (page + 1) * limit - 1;

      var promise = _apiService.default.client.get((0, _apiService.buildURL)('/notifications', query), {
        headers: {
          'X-Range': "resources=".concat(first, "-").concat(last)
        }
      });

      return promise.then(results => {
        var _results$headers$xCo;

        var totalItems = results.length;
        var totalPages = 1;
        var matches = ((_results$headers$xCo = results.headers['x-content-range']) === null || _results$headers$xCo === void 0 ? void 0 : _results$headers$xCo.match(RESOURCES_HEADER)) || null;

        if (matches !== null && !_lodash.default.isUndefined(matches[1]) && matches[1] !== '*') {
          totalItems = matches[1];
          totalPages = Math.ceil(totalItems / limit);
        }

        var pagination = {
          page,
          limit,
          totalPages
        };
        return {
          results: results.data,
          pagination
        };
      });
    });

    function getPagedNotifications(_x4, _x5) {
      return _getPagedNotifications.apply(this, arguments);
    }

    return getPagedNotifications;
  }(),
  onRouteChange: fn => {
    _eventService.default.on('$stateChangeStart', () => fn());
  }
};
var _default = NotificationService;
exports.default = _default;