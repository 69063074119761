"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actions = exports.UPDATE_SENT_SHARE = exports.UPDATE_RECEIVED_SHARE = exports.STORE_NAME = exports.SEARCH_SENT_SHARES_SUCCESS = exports.SEARCH_SENT_SHARES_REQUEST = exports.SEARCH_SENT_SHARES_FAILURE = exports.SEARCH_SENT_SHARES = exports.SEARCH_RECEIVED_SHARES_SUCCESS = exports.SEARCH_RECEIVED_SHARES_REQUEST = exports.SEARCH_RECEIVED_SHARES_FAILURE = exports.SEARCH_RECEIVED_SHARES = exports.PAGE_SIZE = exports.INITIAL_STATE = exports.FETCH_NEXT_SENT_SHARES_PAGE_SUCCESS = exports.FETCH_NEXT_SENT_SHARES_PAGE_REQUEST = exports.FETCH_NEXT_SENT_SHARES_PAGE_FAILURE = exports.FETCH_NEXT_SENT_SHARES_PAGE = exports.FETCH_NEXT_SENT_SEARCH_SUCCESS = exports.FETCH_NEXT_SENT_SEARCH_REQUEST = exports.FETCH_NEXT_SENT_SEARCH_FAILURE = exports.FETCH_NEXT_SENT_SEARCH = exports.FETCH_NEXT_RECEIVED_SHARES_PAGE_SUCCESS = exports.FETCH_NEXT_RECEIVED_SHARES_PAGE_REQUEST = exports.FETCH_NEXT_RECEIVED_SHARES_PAGE_FAILURE = exports.FETCH_NEXT_RECEIVED_SHARES_PAGE = exports.FETCH_NEXT_RECEIVED_SEARCH_SUCCESS = exports.FETCH_NEXT_RECEIVED_SEARCH_REQUEST = exports.FETCH_NEXT_RECEIVED_SEARCH_FAILURE = exports.FETCH_NEXT_RECEIVED_SEARCH = exports.FETCH_NEW_SENT_SHARES_SUCCESS = exports.FETCH_NEW_SENT_SHARES_REQUEST = exports.FETCH_NEW_SENT_SHARES_FAILURE = exports.FETCH_NEW_SENT_SHARES = exports.FETCH_NEW_RECEIVED_SHARES_SUCCESS = exports.FETCH_NEW_RECEIVED_SHARES_REQUEST = exports.FETCH_NEW_RECEIVED_SHARES_FAILURE = exports.FETCH_NEW_RECEIVED_SHARES = void 0;
exports.reducer = reducer;
exports.selectors = void 0;
exports.watchSharesPage = watchSharesPage;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _lodash = _interopRequireDefault(require("lodash"));

var _lodash2 = _interopRequireDefault(require("lodash.iserror"));

var _reduxActions = require("redux-actions");

var _effects = require("redux-saga/effects");

var _common = require("./common");

var _shareService = _interopRequireDefault(require("~/app/3x/modules/services/share-service"));

var _languageService = _interopRequireDefault(require("~/app/3x/modules/services/language-service"));

var _languages = require("./languages");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var STORE_NAME = 'sharesPageStore';
exports.STORE_NAME = STORE_NAME;
var PAGE_SIZE = 10; // Labellize the languages in the shares

exports.PAGE_SIZE = PAGE_SIZE;

function* labelLanguages(shares) {
  var allLanguages = yield (0, _effects.select)(_languages.selectors.getLanguages);

  if (!allLanguages.length) {
    yield (0, _effects.put)(_languages.actions.getLanguages());
    allLanguages = yield _languageService.default.getAllLanguages();
  }

  return _lodash.default.map(shares, share => {
    return _objectSpread(_objectSpread({}, share), {}, {
      languages: _lodash.default.map(share.languages, language => {
        return _lodash.default.find(allLanguages, {
          code: language
        });
      })
    });
  });
} // When the user wants to retrieve the next page of sent shares (use this for page 0 as well)


function* fetchNextSentSharesPage(_ref) {
  var {
    payload
  } = _ref;

  try {
    var {
      userId
    } = payload;
    var query = setRequestQuery(userId, 'sent');
    var page = yield (0, _effects.select)(state => state[STORE_NAME].allShares.sent.currentPage);
    var start = page * PAGE_SIZE;
    var end = (page + 1) * PAGE_SIZE - 1;
    yield (0, _effects.put)(internalActions.fetchNextSentSharesPageRequest(userId));

    var sharesPromise = _shareService.default.getSharesByQueryAndPage({
      query,
      start,
      end
    });

    var shares = yield sharesPromise;
    var languagesLabelledShares = yield labelLanguages(shares.data);

    if ((0, _lodash2.default)(shares)) {
      throw new Error('Failed to load shares page');
    }

    var responseHeaders = shares.headers;
    var total = getPaginationTotal(responseHeaders['x-content-range']);
    var nextPage = page + 1;
    yield (0, _effects.put)(internalActions.fetchNextSentSharesPageSuccess({
      shares: languagesLabelledShares,
      total,
      nextPage
    }));
    return shares;
  } catch (e) {
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.fetchNextSentSharesPageFailure(err));
    return err;
  }
} // When the user wants to retrieve the next page of received shares (use this for page 0 as well)


function* fetchNextReceivedSharesPage(_ref2) {
  var {
    payload
  } = _ref2;

  try {
    var {
      userId
    } = payload;
    var query = setRequestQuery(userId, 'received');
    var page = yield (0, _effects.select)(state => state[STORE_NAME].allShares.received.currentPage);
    var start = page * PAGE_SIZE;
    var end = (page + 1) * PAGE_SIZE - 1;
    yield (0, _effects.put)(internalActions.fetchNextReceivedSharesPageRequest(userId));

    var sharesPromise = _shareService.default.getSharesByQueryAndPage({
      query,
      start,
      end
    });

    var shares = yield sharesPromise;

    if ((0, _lodash2.default)(shares)) {
      throw new Error('Failed to load shares page');
    }

    var languagesLabelledShares = yield labelLanguages(shares.data);
    var responseHeaders = shares.headers;
    var total = getPaginationTotal(responseHeaders['x-content-range']);
    var nextPage = page + 1;
    yield (0, _effects.put)(internalActions.fetchNextReceivedSharesPageSuccess({
      shares: languagesLabelledShares,
      total,
      nextPage
    }));
    return shares;
  } catch (e) {
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.fetchNextReceivedSharesPageFailure(err));
    return err;
  }
} // When the user needs to update their sent shares store with only new records (uses last updated at)


function* fetchNewSentShares(_ref3) {
  var {
    payload
  } = _ref3;

  try {
    var {
      userId
    } = payload;
    var query = setRequestQuery(userId, 'sent');
    var mostRecentLoadAt = yield (0, _effects.select)(state => state[STORE_NAME].allShares.sent.mostRecentLoadAt);

    if (!mostRecentLoadAt) {
      return;
    }

    yield (0, _effects.put)(internalActions.fetchNewSentSharesRequest(userId)); // Convert back to date/time as the API uses it (drop the milliseconds)

    mostRecentLoadAt = ~~(mostRecentLoadAt / 1000);
    query.created = {
      from: mostRecentLoadAt
    };
    var shares = yield _shareService.default.getSharesByQuery(query);

    if ((0, _lodash2.default)(shares)) {
      throw new Error('Failed to load new shares');
    }

    var languagesLabelledShares = yield labelLanguages(shares);
    yield (0, _effects.put)(internalActions.fetchNewSentSharesSuccess({
      shares: languagesLabelledShares
    }));
    return shares;
  } catch (e) {
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.fetchNewSentSharesFailure(err));
    return err;
  }
} // When the user needs to update their received shares store with only new records (uses last updated at)


function* fetchNewReceivedShares(_ref4) {
  var {
    payload
  } = _ref4;

  try {
    var {
      userId
    } = payload;
    var query = setRequestQuery(userId, 'received');
    var mostRecentLoadAt = yield (0, _effects.select)(state => state[STORE_NAME].allShares.received.mostRecentLoadAt);

    if (!mostRecentLoadAt) {
      return;
    }

    yield (0, _effects.put)(internalActions.fetchNewReceivedSharesRequest(userId)); // Convert back to date/time as the API uses it (drop the milliseconds)

    mostRecentLoadAt = ~~(mostRecentLoadAt / 1000);
    query.created = {
      from: mostRecentLoadAt
    };
    var shares = yield _shareService.default.getSharesByQuery(query);

    if ((0, _lodash2.default)(shares)) {
      throw new Error('Failed to load new shares');
    }

    var languagesLabelledShares = yield labelLanguages(shares);
    yield (0, _effects.put)(internalActions.fetchNewReceivedSharesSuccess({
      shares: languagesLabelledShares
    }));
    return shares;
  } catch (e) {
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.fetchNewReceivedSharesFailure(err));
    return err;
  }
} // When the user performs a server-side search


function* searchSentShares(_ref5) {
  var {
    payload
  } = _ref5;

  try {
    var {
      userId,
      searchText
    } = payload;
    var query = setRequestQuery(userId, 'sent', searchText);
    var start = 0;
    var end = PAGE_SIZE - 1;
    yield (0, _effects.put)(internalActions.searchSentSharesRequest({
      userId,
      searchText
    }));

    var sharesPromise = _shareService.default.getSharesByQueryAndPage({
      query,
      start,
      end
    });

    var shares = yield sharesPromise;

    if ((0, _lodash2.default)(shares)) {
      throw new Error('Failed to load shares');
    }

    var languagesLabelledShares = yield labelLanguages(shares);
    var responseHeaders = sharesPromise.$response.headers();
    var total = getPaginationTotal(responseHeaders['x-content-range']);
    var nextPage = 1;
    yield (0, _effects.put)(internalActions.searchSentSharesSuccess({
      shares: languagesLabelledShares,
      total,
      nextPage
    }));
    return shares;
  } catch (e) {
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.searchSentSharesFailure(err));
    return err;
  }
} // When the user performs a server-side search


function* searchReceivedShares(_ref6) {
  var {
    payload
  } = _ref6;

  try {
    var {
      userId,
      searchText
    } = payload;
    var query = setRequestQuery(userId, 'received', searchText);
    var start = 0;
    var end = PAGE_SIZE - 1;
    yield (0, _effects.put)(internalActions.searchReceivedSharesRequest({
      userId,
      searchText
    }));

    var sharesPromise = _shareService.default.getSharesByQueryAndPage({
      query,
      start,
      end
    });

    var shares = yield sharesPromise;

    if ((0, _lodash2.default)(shares)) {
      throw new Error('Failed to load shares');
    }

    var languagesLabelledShares = yield labelLanguages(shares);
    var responseHeaders = sharesPromise.$response.headers();
    var total = getPaginationTotal(responseHeaders['x-content-range']);
    var nextPage = 1;
    yield (0, _effects.put)(internalActions.searchReceivedSharesSuccess({
      shares: languagesLabelledShares,
      total,
      nextPage
    }));
    return shares;
  } catch (e) {
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.searchReceivedSharesFailure(err));
    return err;
  }
}

function* fetchNextSentSearchPage(_ref7) {
  var {
    payload
  } = _ref7;

  try {
    var {
      userId,
      searchText
    } = payload;
    var query = setRequestQuery(userId, 'sent', searchText);
    var page = yield (0, _effects.select)(state => state[STORE_NAME].searchShares.sent.currentPage);
    var start = page * PAGE_SIZE;
    var end = (page + 1) * PAGE_SIZE - 1;
    yield (0, _effects.put)(internalActions.fetchNextSentSearchRequest({
      userId,
      searchText
    }));

    var sharesPromise = _shareService.default.getSharesByQueryAndPage({
      query,
      start,
      end
    });

    var shares = yield sharesPromise;

    if ((0, _lodash2.default)(shares)) {
      throw new Error('Failed to load shares page');
    }

    var languagesLabelledShares = yield labelLanguages(shares);
    var responseHeaders = sharesPromise.$response.headers();
    var total = getPaginationTotal(responseHeaders['x-content-range']);
    var nextPage = 1;
    yield (0, _effects.put)(internalActions.fetchNextSentSearchSuccess({
      shares: languagesLabelledShares,
      total,
      nextPage
    }));
    return shares;
  } catch (e) {
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.fetchNextSentSearchFailure(err));
    return err;
  }
}

function* fetchNextReceivedSearchPage(_ref8) {
  var {
    payload
  } = _ref8;

  try {
    var {
      userId,
      searchText
    } = payload;
    var query = setRequestQuery(userId, 'received', searchText);
    var page = yield (0, _effects.select)(state => state[STORE_NAME].searchShares.received.currentPage);
    var start = page * PAGE_SIZE;
    var end = (page + 1) * PAGE_SIZE - 1;
    yield (0, _effects.put)(internalActions.fetchNextReceivedSearchRequest({
      userId,
      searchText
    }));

    var sharesPromise = _shareService.default.getSharesByQueryAndPage({
      query,
      start,
      end
    });

    var shares = yield sharesPromise;

    if ((0, _lodash2.default)(shares)) {
      throw new Error('Failed to load shares page');
    }

    var languagesLabelledShares = yield labelLanguages(shares);
    var responseHeaders = sharesPromise.$response.headers();
    var total = getPaginationTotal(responseHeaders['x-content-range']);
    var nextPage = 1;
    yield (0, _effects.put)(internalActions.fetchNextReceivedSearchSuccess({
      shares: languagesLabelledShares,
      total,
      nextPage
    }));
    return shares;
  } catch (e) {
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.fetchNextReceivedSearchFailure(err));
    return err;
  }
}

function getPaginationTotal() {
  var headerValue = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var rangePattern = /^resources \d+-\d+\/(\d+|\*)/;
  var matches = headerValue.match(rangePattern);

  if (matches === null || _lodash.default.isUndefined(matches[1]) || matches[1] === '*') {
    return 0;
  }

  return +matches[1];
}

function setRequestQuery(userId, direction, searchText) {
  var query = {
    user: userId,
    order: {
      _id: 'desc'
    }
  };

  if (direction === 'sent') {
    query.from = true;
  }

  if (direction === 'received') {
    query.to = true;
  }

  if (searchText) {
    query.q = searchText;
  }

  return query;
}

var FETCH_NEXT_SENT_SHARES_PAGE = 'shares.sent.fetch-next-page';
exports.FETCH_NEXT_SENT_SHARES_PAGE = FETCH_NEXT_SENT_SHARES_PAGE;
var FETCH_NEXT_SENT_SHARES_PAGE_REQUEST = 'shares.sent.fetch-next-page.request';
exports.FETCH_NEXT_SENT_SHARES_PAGE_REQUEST = FETCH_NEXT_SENT_SHARES_PAGE_REQUEST;
var FETCH_NEXT_SENT_SHARES_PAGE_SUCCESS = 'shares.sent.fetch-next-page.success';
exports.FETCH_NEXT_SENT_SHARES_PAGE_SUCCESS = FETCH_NEXT_SENT_SHARES_PAGE_SUCCESS;
var FETCH_NEXT_SENT_SHARES_PAGE_FAILURE = 'shares.sent.fetch-next-page.failure';
exports.FETCH_NEXT_SENT_SHARES_PAGE_FAILURE = FETCH_NEXT_SENT_SHARES_PAGE_FAILURE;
var FETCH_NEW_SENT_SHARES = 'shares.sent.fetch-new';
exports.FETCH_NEW_SENT_SHARES = FETCH_NEW_SENT_SHARES;
var FETCH_NEW_SENT_SHARES_REQUEST = 'shares.sent.fetch-new.request';
exports.FETCH_NEW_SENT_SHARES_REQUEST = FETCH_NEW_SENT_SHARES_REQUEST;
var FETCH_NEW_SENT_SHARES_SUCCESS = 'shares.sent.fetch-new.success';
exports.FETCH_NEW_SENT_SHARES_SUCCESS = FETCH_NEW_SENT_SHARES_SUCCESS;
var FETCH_NEW_SENT_SHARES_FAILURE = 'shares.sent.fetch-new.failure';
exports.FETCH_NEW_SENT_SHARES_FAILURE = FETCH_NEW_SENT_SHARES_FAILURE;
var SEARCH_SENT_SHARES = 'shares.sent.search';
exports.SEARCH_SENT_SHARES = SEARCH_SENT_SHARES;
var SEARCH_SENT_SHARES_REQUEST = 'shares.sent.search.request';
exports.SEARCH_SENT_SHARES_REQUEST = SEARCH_SENT_SHARES_REQUEST;
var SEARCH_SENT_SHARES_SUCCESS = 'shares.sent.search.success';
exports.SEARCH_SENT_SHARES_SUCCESS = SEARCH_SENT_SHARES_SUCCESS;
var SEARCH_SENT_SHARES_FAILURE = 'shares.sent.search.failure';
exports.SEARCH_SENT_SHARES_FAILURE = SEARCH_SENT_SHARES_FAILURE;
var FETCH_NEXT_SENT_SEARCH = 'shares.sent.fetch-next-search';
exports.FETCH_NEXT_SENT_SEARCH = FETCH_NEXT_SENT_SEARCH;
var FETCH_NEXT_SENT_SEARCH_REQUEST = 'shares.sent.fetch-next-search.request';
exports.FETCH_NEXT_SENT_SEARCH_REQUEST = FETCH_NEXT_SENT_SEARCH_REQUEST;
var FETCH_NEXT_SENT_SEARCH_SUCCESS = 'shares.sent.fetch-next-search.successs';
exports.FETCH_NEXT_SENT_SEARCH_SUCCESS = FETCH_NEXT_SENT_SEARCH_SUCCESS;
var FETCH_NEXT_SENT_SEARCH_FAILURE = 'shares.sent.fetch-next-search.failure';
exports.FETCH_NEXT_SENT_SEARCH_FAILURE = FETCH_NEXT_SENT_SEARCH_FAILURE;
var FETCH_NEXT_RECEIVED_SHARES_PAGE = 'shares.received.fetch-next-page';
exports.FETCH_NEXT_RECEIVED_SHARES_PAGE = FETCH_NEXT_RECEIVED_SHARES_PAGE;
var FETCH_NEXT_RECEIVED_SHARES_PAGE_REQUEST = 'shares.received.fetch-next-page.request';
exports.FETCH_NEXT_RECEIVED_SHARES_PAGE_REQUEST = FETCH_NEXT_RECEIVED_SHARES_PAGE_REQUEST;
var FETCH_NEXT_RECEIVED_SHARES_PAGE_SUCCESS = 'shares.received.fetch-next-page.success';
exports.FETCH_NEXT_RECEIVED_SHARES_PAGE_SUCCESS = FETCH_NEXT_RECEIVED_SHARES_PAGE_SUCCESS;
var FETCH_NEXT_RECEIVED_SHARES_PAGE_FAILURE = 'shares.received.fetch-next-page.failure';
exports.FETCH_NEXT_RECEIVED_SHARES_PAGE_FAILURE = FETCH_NEXT_RECEIVED_SHARES_PAGE_FAILURE;
var FETCH_NEW_RECEIVED_SHARES = 'shares.received.fetch-new';
exports.FETCH_NEW_RECEIVED_SHARES = FETCH_NEW_RECEIVED_SHARES;
var FETCH_NEW_RECEIVED_SHARES_REQUEST = 'shares.received.fetch-new.request';
exports.FETCH_NEW_RECEIVED_SHARES_REQUEST = FETCH_NEW_RECEIVED_SHARES_REQUEST;
var FETCH_NEW_RECEIVED_SHARES_SUCCESS = 'shares.received.fetch-new.success';
exports.FETCH_NEW_RECEIVED_SHARES_SUCCESS = FETCH_NEW_RECEIVED_SHARES_SUCCESS;
var FETCH_NEW_RECEIVED_SHARES_FAILURE = 'shares.received.fetch-new.failure';
exports.FETCH_NEW_RECEIVED_SHARES_FAILURE = FETCH_NEW_RECEIVED_SHARES_FAILURE;
var SEARCH_RECEIVED_SHARES = 'shares.received.search';
exports.SEARCH_RECEIVED_SHARES = SEARCH_RECEIVED_SHARES;
var SEARCH_RECEIVED_SHARES_REQUEST = 'shares.received.search.request';
exports.SEARCH_RECEIVED_SHARES_REQUEST = SEARCH_RECEIVED_SHARES_REQUEST;
var SEARCH_RECEIVED_SHARES_SUCCESS = 'shares.received.search.success';
exports.SEARCH_RECEIVED_SHARES_SUCCESS = SEARCH_RECEIVED_SHARES_SUCCESS;
var SEARCH_RECEIVED_SHARES_FAILURE = 'shares.received.search.failure';
exports.SEARCH_RECEIVED_SHARES_FAILURE = SEARCH_RECEIVED_SHARES_FAILURE;
var FETCH_NEXT_RECEIVED_SEARCH = 'shares.received.fetch-next-search';
exports.FETCH_NEXT_RECEIVED_SEARCH = FETCH_NEXT_RECEIVED_SEARCH;
var FETCH_NEXT_RECEIVED_SEARCH_REQUEST = 'shares.received.fetch-next-search.request';
exports.FETCH_NEXT_RECEIVED_SEARCH_REQUEST = FETCH_NEXT_RECEIVED_SEARCH_REQUEST;
var FETCH_NEXT_RECEIVED_SEARCH_SUCCESS = 'shares.received.fetch-next-search.successs';
exports.FETCH_NEXT_RECEIVED_SEARCH_SUCCESS = FETCH_NEXT_RECEIVED_SEARCH_SUCCESS;
var FETCH_NEXT_RECEIVED_SEARCH_FAILURE = 'shares.received.fetch-next-search.failure';
exports.FETCH_NEXT_RECEIVED_SEARCH_FAILURE = FETCH_NEXT_RECEIVED_SEARCH_FAILURE;
var UPDATE_SENT_SHARE = 'shares.sent.update.manual';
exports.UPDATE_SENT_SHARE = UPDATE_SENT_SHARE;
var UPDATE_RECEIVED_SHARE = 'shares.received.update.manual';
exports.UPDATE_RECEIVED_SHARE = UPDATE_RECEIVED_SHARE;
var INITIAL_STATE = {
  allShares: {
    sent: {
      shares: [],
      currentPage: 0,
      isLoadingNextPage: false,
      loadFailed: false,
      mostRecentLoadAt: null,
      hasMoreShares: true
    },
    received: {
      shares: [],
      currentPage: 0,
      isLoadingNextPage: false,
      loadFailed: false,
      mostRecentLoadAt: null,
      hasMoreShares: true
    }
  },
  searchShares: {
    sent: {
      shares: [],
      currentPage: 0,
      isLoading: false,
      isLoadingNext: false,
      loadFailed: false,
      hasMoreShares: true
    },
    received: {
      shares: [],
      currentPage: 0,
      isLoading: false,
      isLoadingNext: false,
      loadFailed: false,
      hasMoreShares: true
    }
  },
  newShares: {
    sent: {
      isLoading: false,
      loadFailed: false
    },
    received: {
      isLoading: false,
      loadFailed: false
    }
  }
};
exports.INITIAL_STATE = INITIAL_STATE;

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case FETCH_NEXT_SENT_SHARES_PAGE_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          allShares: _objectSpread(_objectSpread({}, state.allShares), {}, {
            sent: _objectSpread(_objectSpread({}, state.allShares.sent), {}, {
              isLoadingNextPage: true
            })
          })
        });
      }

    case FETCH_NEXT_RECEIVED_SHARES_PAGE_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          allShares: _objectSpread(_objectSpread({}, state.allShares), {}, {
            received: _objectSpread(_objectSpread({}, state.allShares.received), {}, {
              isLoadingNextPage: true
            })
          })
        });
      }

    case FETCH_NEXT_SENT_SHARES_PAGE_SUCCESS:
      {
        var now = new Date();
        var {
          shares,
          total,
          nextPage
        } = action.payload;
        var mostRecentLoadAt = state.allShares.sent.mostRecentLoadAt;

        if (!mostRecentLoadAt) {
          mostRecentLoadAt = now;
        }

        shares = [].concat(state.allShares.sent.shares, shares);
        shares = _lodash.default.uniq(shares, '_id');
        shares = _lodash.default.sortBy(shares, ['-created', 'name']);

        var _hasMoreShares = shares.length < total;

        return _objectSpread(_objectSpread({}, state), {}, {
          allShares: _objectSpread(_objectSpread({}, state.allShares), {}, {
            sent: {
              isLoadingNextPage: false,
              currentPage: nextPage,
              mostRecentLoadAt,
              hasMoreShares: _hasMoreShares,
              shares
            }
          })
        });
      }

    case FETCH_NEXT_RECEIVED_SHARES_PAGE_SUCCESS:
      {
        var _now = new Date();

        var {
          shares: _shares,
          total: _total,
          nextPage: _nextPage
        } = action.payload;
        var _mostRecentLoadAt = state.allShares.received.mostRecentLoadAt;

        if (!_mostRecentLoadAt) {
          _mostRecentLoadAt = _now;
        }

        _shares = [].concat(state.allShares.received.shares, _shares);
        _shares = _lodash.default.uniq(_shares, '_id');
        _shares = _lodash.default.sortBy(_shares, ['-created', 'name']);

        var _hasMoreShares2 = _shares.length < _total;

        return _objectSpread(_objectSpread({}, state), {}, {
          allShares: _objectSpread(_objectSpread({}, state.allShares), {}, {
            received: {
              isLoadingNextPage: false,
              currentPage: _nextPage,
              mostRecentLoadAt: _mostRecentLoadAt,
              hasMoreShares: _hasMoreShares2,
              shares: _shares
            }
          })
        });
      }

    case FETCH_NEXT_SENT_SHARES_PAGE_FAILURE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          allShares: _objectSpread(_objectSpread({}, state.allShares), {}, {
            sent: _objectSpread(_objectSpread({}, state.allShares.sent), {}, {
              isLoadingNextPage: false,
              loadFailed: true
            })
          })
        });
      }

    case FETCH_NEXT_RECEIVED_SHARES_PAGE_FAILURE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          allShares: _objectSpread(_objectSpread({}, state.allShares), {}, {
            received: _objectSpread(_objectSpread({}, state.allShares.received), {}, {
              isLoadingNextPage: false,
              loadFailed: true
            })
          })
        });
      }

    case FETCH_NEW_SENT_SHARES_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          newShares: _objectSpread(_objectSpread({}, state.newShares), {}, {
            sent: {
              isLoading: true
            }
          })
        });
      }

    case FETCH_NEW_RECEIVED_SHARES_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          newShares: _objectSpread(_objectSpread({}, state.newShares), {}, {
            received: {
              isLoading: true
            }
          })
        });
      }

    case FETCH_NEW_SENT_SHARES_SUCCESS:
      {
        var _now2 = new Date();

        var {
          shares: _shares2
        } = action.payload;
        _shares2 = [].concat(_shares2, state.allShares.sent.shares);
        _shares2 = _lodash.default.uniq(_shares2, '_id');
        _shares2 = _lodash.default.sortBy(_shares2, ['-created', 'name']);
        return _objectSpread(_objectSpread({}, state), {}, {
          allShares: _objectSpread(_objectSpread({}, state.allShares), {}, {
            sent: {
              mostRecentLoadAt: _now2,
              shares: _shares2
            }
          }),
          newShares: _objectSpread(_objectSpread({}, state.newShares), {}, {
            sent: {
              isLoading: false
            }
          })
        });
      }

    case FETCH_NEW_RECEIVED_SHARES_SUCCESS:
      {
        var _now3 = new Date();

        var {
          shares: _shares3
        } = action.payload;
        _shares3 = [].concat(_shares3, state.allShares.received.shares);
        _shares3 = _lodash.default.uniq(_shares3, '_id');
        _shares3 = _lodash.default.sortBy(_shares3, ['-created', 'name']);
        return _objectSpread(_objectSpread({}, state), {}, {
          allShares: _objectSpread(_objectSpread({}, state.allShares), {}, {
            received: {
              mostRecentLoadAt: _now3,
              shares: _shares3
            }
          }),
          newShares: _objectSpread(_objectSpread({}, state.newShares), {}, {
            received: {
              isLoading: false
            }
          })
        });
      }

    case FETCH_NEW_SENT_SHARES_FAILURE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          newShares: _objectSpread(_objectSpread({}, state.newShares), {}, {
            sent: _objectSpread(_objectSpread({}, state.newShares.sent), {}, {
              isLoading: false,
              loadFailed: true
            })
          })
        });
      }

    case FETCH_NEW_RECEIVED_SHARES_FAILURE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          newShares: _objectSpread(_objectSpread({}, state.newShares), {}, {
            received: _objectSpread(_objectSpread({}, state.newShares.received), {}, {
              isLoading: false,
              loadFailed: true
            })
          })
        });
      }

    case SEARCH_SENT_SHARES_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          searchShares: _objectSpread(_objectSpread({}, state.searchShares), {}, {
            sent: {
              isLoading: true,
              isLoadingNext: true,
              shares: [],
              currentPage: 0,
              hasMoreShares: true
            }
          })
        });
      }

    case SEARCH_RECEIVED_SHARES_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          searchShares: _objectSpread(_objectSpread({}, state.searchShares), {}, {
            received: {
              isLoading: true,
              isLoadingNext: true,
              shares: [],
              currentPage: 0,
              hasMoreShares: true
            }
          })
        });
      }

    case SEARCH_SENT_SHARES_SUCCESS:
      {
        var {
          shares: _shares4,
          total: _total2,
          nextPage: _nextPage2
        } = action.payload;

        var _hasMoreShares3 = _shares4.length < _total2;

        return _objectSpread(_objectSpread({}, state), {}, {
          searchShares: _objectSpread(_objectSpread({}, state.searchShares), {}, {
            sent: _objectSpread(_objectSpread({}, state.searchShares.sent), {}, {
              isLoading: false,
              isLoadingNext: false,
              shares: _shares4,
              currentPage: _nextPage2,
              hasMoreShares: _hasMoreShares3,
              total: _total2
            })
          })
        });
      }

    case SEARCH_RECEIVED_SHARES_SUCCESS:
      {
        var {
          shares: _shares5,
          total: _total3,
          nextPage: _nextPage3
        } = action.payload;

        var _hasMoreShares4 = _shares5.length < _total3;

        return _objectSpread(_objectSpread({}, state), {}, {
          searchShares: _objectSpread(_objectSpread({}, state.searchShares), {}, {
            received: _objectSpread(_objectSpread({}, state.searchShares.received), {}, {
              isLoading: false,
              isLoadingNext: false,
              shares: _shares5,
              currentPage: _nextPage3,
              hasMoreShares: _hasMoreShares4,
              total: _total3
            })
          })
        });
      }

    case SEARCH_SENT_SHARES_FAILURE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          searchShares: _objectSpread(_objectSpread({}, state.searchShares), {}, {
            sent: _objectSpread(_objectSpread({}, state.searchShares.sent), {}, {
              isLoading: false,
              isLoadingNext: false,
              loadFailed: true
            })
          })
        });
      }

    case SEARCH_RECEIVED_SHARES_FAILURE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          searchShares: _objectSpread(_objectSpread({}, state.searchShares), {}, {
            received: _objectSpread(_objectSpread({}, state.searchShares.received), {}, {
              isLoading: false,
              isLoadingNext: false,
              loadFailed: true
            })
          })
        });
      }

    case FETCH_NEXT_SENT_SEARCH_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          searchShares: _objectSpread(_objectSpread({}, state.searchShares), {}, {
            sent: _objectSpread(_objectSpread({}, state.searchShares.sent), {}, {
              isLoadingNext: true
            })
          })
        });
      }

    case FETCH_NEXT_RECEIVED_SEARCH_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          searchShares: _objectSpread(_objectSpread({}, state.searchShares), {}, {
            received: _objectSpread(_objectSpread({}, state.searchShares.received), {}, {
              isLoadingNext: true
            })
          })
        });
      }

    case FETCH_NEXT_SENT_SEARCH_SUCCESS:
      {
        var {
          shares: _shares6,
          total: _total4,
          nextPage: _nextPage4
        } = action.payload;
        _shares6 = [].concat(state.searchShares.sent.shares, _shares6);
        _shares6 = _lodash.default.uniq(_shares6, '_id');
        _shares6 = _lodash.default.sortBy(_shares6, ['-created', 'name']);

        var _hasMoreShares5 = _shares6.length < _total4;

        return _objectSpread(_objectSpread({}, state), {}, {
          searchShares: _objectSpread(_objectSpread({}, state.searchShares), {}, {
            sent: _objectSpread(_objectSpread({}, state.searchShares.sent), {}, {
              isLoadingNext: false,
              shares: _shares6,
              currentPage: _nextPage4,
              hasMoreShares: _hasMoreShares5,
              total: _total4
            })
          })
        });
      }

    case FETCH_NEXT_RECEIVED_SEARCH_SUCCESS:
      {
        var {
          shares: _shares7,
          total: _total5,
          nextPage: _nextPage5
        } = action.payload;
        _shares7 = [].concat(state.searchShares.received.shares, _shares7);
        _shares7 = _lodash.default.uniq(_shares7, '_id');
        _shares7 = _lodash.default.sortBy(_shares7, ['-created', 'name']);

        var _hasMoreShares6 = _shares7.length < _total5;

        return _objectSpread(_objectSpread({}, state), {}, {
          searchShares: _objectSpread(_objectSpread({}, state.searchShares), {}, {
            received: _objectSpread(_objectSpread({}, state.searchShares.received), {}, {
              isLoadingNext: false,
              shares: _shares7,
              currentPage: _nextPage5,
              hasMoreShares: _hasMoreShares6,
              total: _total5
            })
          })
        });
      }

    case FETCH_NEXT_SENT_SEARCH_FAILURE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          searchShares: _objectSpread(_objectSpread({}, state.searchShares), {}, {
            sent: _objectSpread(_objectSpread({}, state.searchShares.sent), {}, {
              isLoadingNext: false,
              loadFailed: true
            })
          })
        });
      }

    case FETCH_NEXT_RECEIVED_SEARCH_FAILURE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          searchShares: _objectSpread(_objectSpread({}, state.searchShares), {}, {
            received: _objectSpread(_objectSpread({}, state.searchShares.received), {}, {
              isLoadingNext: false,
              loadFailed: true
            })
          })
        });
      }

    case UPDATE_SENT_SHARE:
      {
        var share = action.payload;
        var sentShares = [...state.allShares.sent.shares];
        var index = sentShares.findIndex(s => s._id === share._id);
        sentShares[index] = share;
        return _objectSpread(_objectSpread({}, state), {}, {
          allShares: _objectSpread(_objectSpread({}, state.allShares), {}, {
            sent: _objectSpread(_objectSpread({}, state.allShares.sent), {}, {
              shares: sentShares
            })
          })
        });
      }

    case UPDATE_RECEIVED_SHARE:
      {
        var _share = action.payload;
        var receivedShares = [...state.allShares.received.shares];

        var _index = receivedShares.findIndex(s => s._id === _share._id);

        receivedShares[_index] = _share;
        return _objectSpread(_objectSpread({}, state), {}, {
          allShares: _objectSpread(_objectSpread({}, state.allShares), {}, {
            sent: _objectSpread(_objectSpread({}, state.allShares.sent), {}, {
              shares: receivedShares
            })
          })
        });
      }

    default:
      return state;
  }
}

var actions = {
  fetchNextSentSharesPage: (0, _reduxActions.createAction)(FETCH_NEXT_SENT_SHARES_PAGE, userId => ({
    userId
  })),
  fetchNextReceivedSharesPage: (0, _reduxActions.createAction)(FETCH_NEXT_RECEIVED_SHARES_PAGE, userId => ({
    userId
  })),
  fetchNewSentShares: (0, _reduxActions.createAction)(FETCH_NEW_SENT_SHARES, userId => ({
    userId
  })),
  fetchNewReceivedShares: (0, _reduxActions.createAction)(FETCH_NEW_RECEIVED_SHARES, userId => ({
    userId
  })),
  searchSentShares: (0, _reduxActions.createAction)(SEARCH_SENT_SHARES, (userId, query) => ({
    userId,
    query
  })),
  searchReceivedShares: (0, _reduxActions.createAction)(SEARCH_RECEIVED_SHARES, (userId, query) => ({
    userId,
    query
  })),
  fetchNextSentSearchShares: (0, _reduxActions.createAction)(FETCH_NEXT_SENT_SEARCH, (userId, query) => ({
    userId,
    query
  })),
  fetchNextReceivedSearchShares: (0, _reduxActions.createAction)(FETCH_NEXT_RECEIVED_SEARCH, (userId, query) => ({
    userId,
    query
  })),
  updateSentShare: (0, _reduxActions.createAction)(UPDATE_SENT_SHARE),
  updateReceivedShare: (0, _reduxActions.createAction)(UPDATE_RECEIVED_SHARE)
};
/**
 * Actions that should only be invoked internally
 */

exports.actions = actions;
var internalActions = {
  fetchNextSentSharesPageRequest: (0, _reduxActions.createAction)(FETCH_NEXT_SENT_SHARES_PAGE_REQUEST),
  fetchNextSentSharesPageSuccess: (0, _reduxActions.createAction)(FETCH_NEXT_SENT_SHARES_PAGE_SUCCESS),
  fetchNextSentSharesPageFailure: (0, _reduxActions.createAction)(FETCH_NEXT_SENT_SHARES_PAGE_FAILURE),
  fetchNextReceivedSharesPageRequest: (0, _reduxActions.createAction)(FETCH_NEXT_RECEIVED_SHARES_PAGE_REQUEST),
  fetchNextReceivedSharesPageSuccess: (0, _reduxActions.createAction)(FETCH_NEXT_RECEIVED_SHARES_PAGE_SUCCESS),
  fetchNextReceivedSharesPageFailure: (0, _reduxActions.createAction)(FETCH_NEXT_RECEIVED_SHARES_PAGE_FAILURE),
  fetchNewSentSharesRequest: (0, _reduxActions.createAction)(FETCH_NEW_SENT_SHARES_REQUEST),
  fetchNewSentSharesSuccess: (0, _reduxActions.createAction)(FETCH_NEW_SENT_SHARES_SUCCESS),
  fetchNewSentSharesFailure: (0, _reduxActions.createAction)(FETCH_NEW_SENT_SHARES_FAILURE),
  fetchNewReceivedSharesRequest: (0, _reduxActions.createAction)(FETCH_NEW_RECEIVED_SHARES_REQUEST),
  fetchNewReceivedSharesSuccess: (0, _reduxActions.createAction)(FETCH_NEW_RECEIVED_SHARES_SUCCESS),
  fetchNewReceivedSharesFailure: (0, _reduxActions.createAction)(FETCH_NEW_RECEIVED_SHARES_FAILURE),
  searchSentSharesRequest: (0, _reduxActions.createAction)(SEARCH_SENT_SHARES_REQUEST),
  searchSentSharesSuccess: (0, _reduxActions.createAction)(SEARCH_SENT_SHARES_SUCCESS),
  searchSentSharesFailure: (0, _reduxActions.createAction)(SEARCH_SENT_SHARES_FAILURE),
  searchReceivedSharesRequest: (0, _reduxActions.createAction)(SEARCH_RECEIVED_SHARES_REQUEST),
  searchReceivedSharesSuccess: (0, _reduxActions.createAction)(SEARCH_RECEIVED_SHARES_SUCCESS),
  searchReceivedSharesFailure: (0, _reduxActions.createAction)(SEARCH_RECEIVED_SHARES_FAILURE),
  fetchNextSentSearchRequest: (0, _reduxActions.createAction)(FETCH_NEXT_SENT_SEARCH_REQUEST),
  fetchNextSentSearchSuccess: (0, _reduxActions.createAction)(FETCH_NEXT_SENT_SEARCH_SUCCESS),
  fetchNextSentSearchFailure: (0, _reduxActions.createAction)(FETCH_NEXT_SENT_SEARCH_FAILURE),
  fetchNextReceivedSearchRequest: (0, _reduxActions.createAction)(FETCH_NEXT_RECEIVED_SEARCH_REQUEST),
  fetchNextReceivedSearchSuccess: (0, _reduxActions.createAction)(FETCH_NEXT_RECEIVED_SEARCH_SUCCESS),
  fetchNextReceivedSearchFailure: (0, _reduxActions.createAction)(FETCH_NEXT_RECEIVED_SEARCH_FAILURE)
};
var selectors = {
  getShares: createSelector(getShares),
  isLoadingNextPage: createSelector(isLoadingNextPage),
  isPageLoadError: createSelector(isPageLoadError),
  hasMoreShares: createSelector(hasMoreShares),
  getMostRecentLoadAt: createSelector(getMostRecentLoadAt),
  isLoadingNewShares: createSelector(isLoadingNewShares),
  isNewSharesLoadError: createSelector(isNewSharesLoadError),
  getSearchedShares: createSelector(getSearchedShares),
  isLoadingSearchResults: createSelector(isLoadingSearchResults),
  isSearchError: createSelector(isSearchError),
  isLoadingNextSearchResults: createSelector(isLoadingNextSearchResults),
  hasMoreSearchResults: createSelector(hasMoreSearchResults)
};
exports.selectors = selectors;

function getShares(state, direction) {
  return state.allShares[direction].shares;
}

function isLoadingNextPage(state, direction) {
  return state.allShares[direction].isLoadingNextPage;
}

function isPageLoadError(state, direction) {
  return state.allShares[direction].loadFailed;
}

function hasMoreShares(state, direction) {
  return state.allShares[direction].hasMoreShares;
}

function getMostRecentLoadAt(state, direction) {
  return state.allShares[direction].mostRecentLoadAt;
}

function isLoadingNewShares(state, direction) {
  return state.newShares[direction].isLoading;
}

function isNewSharesLoadError(state, direction) {
  return state.newShares[direction].loadFailed;
}

function getSearchedShares(state, direction) {
  return state.searchShares[direction].shares;
}

function isLoadingSearchResults(state, direction) {
  return state.searchShares[direction].isLoading;
}

function isSearchError(state, direction) {
  return state.searchShares[direction].loadFailed;
}

function isLoadingNextSearchResults(state, direction) {
  return state.searchShares[direction].isLoadingNext;
}

function hasMoreSearchResults(state, direction) {
  return state.searchShares[direction].hasMoreShares;
}
/**
 * Helper method that grabs our specific part of the global store, then passes it into the given selector.
 * This makes it so that each selector doesn't have to grab the sub-state itself, reducing code duplication.
 */


function createSelector(selector) {
  return function selectorWrapper(state) {
    var sharesStore = state[STORE_NAME];

    for (var _len = arguments.length, rest = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      rest[_key - 1] = arguments[_key];
    }

    return selector(sharesStore, ...rest);
  };
}

function* watchSharesPage() {
  yield (0, _effects.takeLatest)(FETCH_NEXT_SENT_SHARES_PAGE, fetchNextSentSharesPage);
  yield (0, _effects.takeLatest)(FETCH_NEW_SENT_SHARES, fetchNewSentShares);
  yield (0, _effects.takeLatest)(SEARCH_SENT_SHARES, searchSentShares);
  yield (0, _effects.takeLatest)(FETCH_NEXT_SENT_SEARCH, fetchNextSentSearchPage);
  yield (0, _effects.takeLatest)(FETCH_NEXT_RECEIVED_SHARES_PAGE, fetchNextReceivedSharesPage);
  yield (0, _effects.takeLatest)(FETCH_NEW_RECEIVED_SHARES, fetchNewReceivedShares);
  yield (0, _effects.takeLatest)(SEARCH_RECEIVED_SHARES, searchReceivedShares);
  yield (0, _effects.takeLatest)(FETCH_NEXT_RECEIVED_SEARCH, fetchNextReceivedSearchPage);
}