"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.ReduxService = void 0;

var _store = _interopRequireDefault(require("../../store"));

var ReduxService = {
  dispatch: payload => {
    return _store.default.dispatch(payload);
  },
  getStore: () => _store.default
};
exports.ReduxService = ReduxService;
var _default = ReduxService;
exports.default = _default;