"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actions = exports.STORE_NAME = exports.QUEUE_UPDATE = exports.INITIAL_STATE = void 0;
exports.reducer = reducer;
exports.selectors = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _reduxActions = require("redux-actions");

var _session = require("./session");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var STORE_NAME = 'shareQueueStore';
exports.STORE_NAME = STORE_NAME;
var QUEUE_UPDATE = 'share-queue.update';
exports.QUEUE_UPDATE = QUEUE_UPDATE;
var INITIAL_STATE = {
  isLoading: false,
  loadFailed: false,
  shareQueue: []
};
exports.INITIAL_STATE = INITIAL_STATE;

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _session.RESET_STORES:
      return INITIAL_STATE;

    case QUEUE_UPDATE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          shareQueue: action.payload || []
        });
      }

    default:
      return state;
  }
}

var actions = {
  updateQueue: (0, _reduxActions.createAction)(QUEUE_UPDATE)
};
exports.actions = actions;
var selectors = {
  getShareQueue
};
exports.selectors = selectors;

function getShareQueue(state) {
  var {
    shareQueue
  } = state.shareQueueStore;
  return shareQueue;
}