"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.SAVE_INGEST_QUERY = exports.GET_INGEST_QUERY = exports.GET_BATCH_QUERY = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _lodash = _interopRequireDefault(require("lodash"));

var _apiService = _interopRequireWildcard(require("./api-service"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var FIELDS = "\nid\nsource\nsize\nstatus\ndownload\narchived\ncontext\nasset {\n\tid\n\tname\n\tslug\n\ttype\n\tmediaType\n\tcreative\n\tproject {\n\t\tid\n\t\tfilePrefix\n\t\tstudio {\n\t\t\tpreviewWatermarkFormat\n\t\t}\n\t}\n\tfile\n}\nselected\ncreated\nfilename\nffprobe\nscratchPreview\nowner {\n\tname\n\temailNormal\n}\nencodes {\n\tname\n\tstatus\n}\n";
var GET_INGEST_QUERY = "\nquery getIngest($id: ID!) {\n\tingest(id: $id) {\n\t\t".concat(FIELDS, "\n\t}\n}\n");
exports.GET_INGEST_QUERY = GET_INGEST_QUERY;
var GET_BATCH_QUERY = "\nquery getIngestBatch($limit: Int, $batch: Float, $status: [String], $archived: Boolean) {\n\tingests(limit: $limit, batch: $batch, status: $status, archived: $archived) {\n\t\tnodes {\n\t\t\tid\n\t\t\tasset {\n\t\t\t\tmediaType\n\t\t\t\tcreative\n\t\t\t}\n\t\t\tsource\n\t\t\tselected\n\t\t\tffprobe\n\t\t}\n\t}\n}\n";
exports.GET_BATCH_QUERY = GET_BATCH_QUERY;
var SAVE_INGEST_QUERY = "\nmutation saveIngest ($input: IngestInput) {\n\tupdateIngest(input: $input) {\n\t\tingest {\n\t\t\t".concat(FIELDS, "\n\t\t}\n\t}\n}\n");
exports.SAVE_INGEST_QUERY = SAVE_INGEST_QUERY;
var IngestService = {
  create: data => {
    return _apiService.default.client.post('/ingests', data).then(res => res.data);
  },
  update: (id, data) => {
    return _apiService.default.client.patch("/ingests/".concat(id), data).then(res => res.data);
  },
  getIngestsForWorkRequest: workRequestId => {
    return _apiService.default.client.get((0, _apiService.buildURL)('/ingests', {
      workRequest: workRequestId
    })).then(res => res === null || res === void 0 ? void 0 : res.data);
  },
  getCountriesFromTerritories: territories => {
    return (0, _lodash.default)(territories).pluck('countries').flatten().filter(country => country === null || country === void 0 ? void 0 : country.code).uniq('code').sortBy('name').unshift({
      name: 'Worldwide',
      code: 'WW'
    }).value();
  },
  getTagLayout: (tags, mediaType, assetType, showAvOptions) => {
    var POSITION_HINTS = {
      image: {
        right: ['Text']
      },
      video: {
        right: ['Standard', 'Aspect', 'Dimension', 'Definition'],
        span: ['Text', 'Resolution', 'Codec']
      },
      zip: {
        right: ['Standard', 'Aspect', 'Dimension', 'Definition'],
        span: ['Text', 'Resolution', 'Codec']
      },
      document: {
        right: ['Standard', 'Aspect', 'Dimension', 'Definition'],
        span: ['Text', 'Resolution', 'Codec']
      }
    };
    var IMAGE_PICK_TAGS = ['cmyk', 'rgb', 'flattened', 'layered'];
    var AV_OMIT_TAGS = [...IMAGE_PICK_TAGS, 'previewonly']; // See https://pixwel.atlassian.net/browse/PLATFORM-1998

    var AV_OMIT_TYPES = ['Framerate', 'Frame Mode', 'Standard', 'Definition', 'Codec', 'Resolution', 'Aspect'];
    var RENDERED_GROUPS = ['SubDub', 'Text', 'Codec', 'Resolution', 'Framerate', 'Frame Mode', 'Extra'];
    var MULTIPLE_GROUPS = ['SubDub'];
    var filteredTags = [];

    if (mediaType === 'image') {
      filteredTags = tags.filter(tag => tag.type && IMAGE_PICK_TAGS.includes(tag.name));
    }

    if (mediaType === 'audio' || mediaType === 'video') {
      filteredTags = tags.filter(tag => tag.type && !AV_OMIT_TAGS.includes(tag.name) && (showAvOptions ? true : !AV_OMIT_TYPES.includes(tag.type)));
    }

    if (mediaType === 'zip' || mediaType === 'document') {
      if (assetType === 'image') {
        filteredTags = tags.filter(tag => tag.type && IMAGE_PICK_TAGS.includes(tag.name));
      }

      if (assetType === 'video') {
        filteredTags = tags.filter(tag => tag.type && !AV_OMIT_TAGS.includes(tag.name) && (showAvOptions ? true : !AV_OMIT_TYPES.includes(tag.type)));
      }
    }

    return (0, _lodash.default)(filteredTags).groupBy('type').transform((result, tags, group) => {
      var _hints$span;

      var hints = POSITION_HINTS[mediaType];
      var key = 'left';

      if (hints !== null && hints !== void 0 && hints.right.includes(group)) {
        key = 'right';
      }

      if (hints !== null && hints !== void 0 && (_hints$span = hints.span) !== null && _hints$span !== void 0 && _hints$span.includes(group)) {
        key = 'span';
      }

      return result[key].push({
        name: group,
        tags: (0, _lodash.default)(tags).sortBy('name').map(tag => {
          return _lodash.default.pick(tag, ['name', 'display']);
        }).value(),
        rendered: RENDERED_GROUPS.includes(group),
        multiple: MULTIPLE_GROUPS.includes(group)
      });
    }, {
      left: [],
      right: [],
      span: []
    }).value();
  },
  deepDiff: (o1, o2, carry) => {
    return _lodash.default.transform(o1, (result, value, key) => {
      if (_lodash.default.isEqual(value, o2[key])) {
        return;
      }

      if (_lodash.default.isArray(value)) {
        result[key] = value;
        return;
      }

      if (_lodash.default.isObject(value) && !_lodash.default.isDate(value)) {
        var _o2$key;

        result[key] = {};
        return IngestService.deepDiff(value, (_o2$key = o2[key]) !== null && _o2$key !== void 0 ? _o2$key : {}, result[key]);
      }

      result[key] = value;
    }, carry || {});
  },
  flattenObj: (object, prefix, carry) => {
    return _lodash.default.transform(object, (result, value, key) => {
      key = prefix ? "".concat(prefix, ".").concat(key) : key;

      if (_lodash.default.isObject(value) && !_lodash.default.isDate(value)) {
        return IngestService.flattenObj(value, key, result);
      }

      result[key] = value;
    }, carry || {});
  },
  getIngest: id => {
    return _apiService.default.graphql.request(GET_INGEST_QUERY, {
      id
    });
  },
  saveIngest: input => {
    return _apiService.default.graphql.request(SAVE_INGEST_QUERY, {
      input
    });
  },
  processIngest: input => {
    var query = "\n\t\t\tmutation processIngest ($input: IngestInput) {\n\t\t\t\tupdateIngest(input: $input) {\n\t\t\t\t\tingest {\n\t\t\t\t\t\t".concat(FIELDS, "\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t");
    return _apiService.default.graphql.request(query, {
      input
    });
  },
  reprocessIngest: input => {
    var query = "\n\t\t\tmutation reprocessIngest ($input: IngestInput) {\n\t\t\t\tupdateIngest(input: $input) {\n\t\t\t\t\tingest {\n\t\t\t\t\t\t".concat(FIELDS, "\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t");
    return _apiService.default.graphql.request(query, {
      input
    });
  },
  archiveIngest: input => {
    var query = "\n\t\t\tmutation archiveIngest ($input: IngestInput) {\n\t\t\t\tupdateIngest(input: $input) {\n\t\t\t\t\tingest {\n\t\t\t\t\t\t".concat(FIELDS, "\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t");
    return _apiService.default.graphql.request(query, {
      input
    });
  },
  getBatch: function () {
    var _getBatch = (0, _asyncToGenerator2.default)(function* (ingest, query) {
      var params = _objectSpread({
        batch: ingest.context.group,
        limit: 150
      }, query || {
        status: ['waiting']
      });

      var response = yield _apiService.default.graphql.request(GET_BATCH_QUERY, params);
      return response.ingests.nodes.filter(i => i.id !== ingest.id);
    });

    function getBatch(_x, _x2) {
      return _getBatch.apply(this, arguments);
    }

    return getBatch;
  }()
};
var _default = IngestService;
exports.default = _default;