"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.EventService = void 0;

var _events = require("events");

var _apiService = _interopRequireDefault(require("./api-service"));

var em = window.eventEmitter = window.eventEmitter || new _events.EventEmitter();
var EventService = {
  on: (event, fn) => {
    return em.on(event, fn);
  },
  broadcast: function broadcast(event) {
    for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      args[_key - 1] = arguments[_key];
    }

    return em.emit(event, ...args);
  },
  emit: function emit(event) {
    for (var _len2 = arguments.length, args = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
      args[_key2 - 1] = arguments[_key2];
    }

    return em.emit(event, ...args);
  },
  createEvent: (from, files, type) => {
    _apiService.default.client.post('/events', {
      from,
      files,
      type
    });
  }
};
exports.EventService = EventService;
var _default = EventService;
exports.default = _default;