"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _react = require("@uirouter/react");

var BOOLEAN_TYPE = {
  name: 'boolean',
  encode: val => val ? 'true' : 'false',
  decode: val => val === 'true',
  is: val => _lodash.default.isBoolean(val)
};
var LIST_TYPE = {
  name: 'list',
  raw: true,
  encode: items => {
    if (!_lodash.default.isArray(items)) {
      return '';
    }

    return items.join(',');
  },
  decode: item => {
    if (!_lodash.default.isString(item)) {
      return [];
    }

    if (item.includes(',')) {
      return item.split(',');
    } else if (item.includes('%2C')) {
      return item.split('%2C');
    }

    return [item];
  },
  is: val => {
    return _lodash.default.isString(val) || _lodash.default.isArray(val);
  },
  equals: (a, b) => {
    return _lodash.default.isEqual(a, b);
  }
};
var uiRouter = new _react.UIRouterReact();
uiRouter.plugin(_react.servicesPlugin); // services plugins is necessary for the router to function

uiRouter.plugin(_react.pushStateLocationPlugin);
uiRouter.urlService.config.type('boolean', BOOLEAN_TYPE);
uiRouter.urlService.config.type('list', LIST_TYPE); // to track transitions
// uiRouter.trace.enable('TRANSITION');
// uiRouter.urlService.rules.initial({ state: 'projects' });

uiRouter.urlService.rules.otherwise('/');
var RouteService = {
  _lastState: null,
  _isStarted: false,

  get lastState() {
    return RouteService._lastState;
  },

  get currentState() {
    return uiRouter.stateService.current;
  },

  registerStates(states) {
    states.forEach(state => {
      try {
        uiRouter.stateRegistry.register(state);
      } catch (e) {
        console.error(e);
      }
    });
    return uiRouter;
  },

  getRouteInfo() {
    return {
      name: uiRouter.stateService.current.name,
      params: uiRouter.stateService.params
    };
  },

  getRouteParam(param) {
    return uiRouter.stateService.params[param];
  },

  getRouteParams() {
    return uiRouter.stateService.params;
  },

  setRouteParams(params) {
    setTimeout(() => uiRouter.stateService.go(uiRouter.globals.$current.name, params, {
      location: 'replace',
      inherit: false
    }));
  },

  updateRouteParams(params) {
    setTimeout(() => uiRouter.stateService.go(uiRouter.globals.$current.name, params, {
      location: 'replace',
      inherit: true
    }));
  },

  goBack() {
    window.history.back();
  },

  reload() {
    return uiRouter.stateService.reload();
  },

  navigateTo(state, params) {
    var reload = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    var useTimeout = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
    return new Promise((resolve, reject) => {
      var useTimeoutWrapper = fn => useTimeout ? setTimeout(fn) : fn();

      return useTimeoutWrapper(() => {
        uiRouter.stateService.go(state, params, {
          reload,
          reloadState: true
        }).then(resolve).catch(reject);
      });
    });
  },

  hardNavigateTo(state) {
    for (var _len = arguments.length, params = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      params[_key - 1] = arguments[_key];
    }

    window.location = uiRouter.stateService.href(state, ...params);
  },

  hardNavigateToBlank(state, params) {
    var url = uiRouter.stateService.href(state, params);
    window.open(url, '_blank');
  },

  navigateTo2x(fragment) {
    var baseUrl = window.env === 'production' ? 'https://platform.pixwel.com' : 'https://staging.pixwel.com';
    window.open("".concat(baseUrl, "/").concat(fragment));
  },

  hardNavigateTo2x(url) {
    window.open(url);
  },

  registerRouteInterceptor(interceptor) {
    return uiRouter.transitionService.onStart({}, interceptor);
  },

  stateHref(state) {
    for (var _len2 = arguments.length, params = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
      params[_key2 - 1] = arguments[_key2];
    }

    return uiRouter.stateService.href(state, ...params);
  }

};
var _default = RouteService;
exports.default = _default;