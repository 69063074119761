"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Text = exports.Graphic = exports.Container = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _svgImage = _interopRequireDefault(require("../svg-image"));

var _templateObject, _templateObject2, _templateObject3;

var Graphic = (0, _styledComponents.default)(_svgImage.default)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n\twidth: 100%;\n\theight: 18rem;\n"])));
exports.Graphic = Graphic;

var Text = _styledComponents.default.p(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n\tmargin: 2.5rem 0;\n\tfont-size: 1.5rem;\n\tline-height: 1.3;\n\ttext-align: center;\n\tmax-width: 40rem;\n"])));

exports.Text = Text;

var Container = _styledComponents.default.div(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["\n\tdisplay: flex;\n\tflex-direction: column;\n\talign-items: center;\n\tpadding: 1.75rem;\n\n\t&.small {\n\t\t", " {\n\t\t\tmargin-top: 4rem;\n\t\t\theight: 8rem;\n\t\t}\n\n\t\t", " {\n\t\t\tmargin: 1rem 0;\n\t\t\tfont-size: 1rem;\n\t\t}\n\t}\n"])), Graphic, Text);

exports.Container = Container;