"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TabsContent = exports.ProfilePreferencesSmallLegend = exports.ProfileContainer = exports.NotificationsContainer = exports.IngestContainer = exports.EmailPreference = exports.ContactsContainer = exports.ContactListsContainer = exports.ContactListContainer = exports.AddContactListForm = exports.AddContactListContainer = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11;

var TabsContent = _styledComponents.default.div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n\tmin-height: 50vh;\n\theight: fit-content;\n\tpadding-right: 1.5rem;\n"])));

exports.TabsContent = TabsContent;

var NotificationsContainer = _styledComponents.default.div(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n\tmin-height: fit-content;\n\t.h5 {\n\t\tpadding: 1rem 0;\n\t\t[type=checkbox] {\n\t\t\tmargin-left: 1rem;\n\t\t}\n\t}\n\n\tfieldset {\n\t\tmargin-bottom: 2rem;\n\t}\n\n\t.preferences-notifications-projects{\n\t\tmargin-bottom: 4rem;\n\t\t.btn-menu-react-select__menu {\n\t\t\tmargin-bottom: 0;\n\t\t\t&-list {\n\t\t\t\tmax-height: 10rem;\n\t\t\t}\n\t\t}\n\t}\n"])));

exports.NotificationsContainer = NotificationsContainer;

var ProfileContainer = _styledComponents.default.div(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["\n\t.h5 {\n\t\tpadding: 1rem 0;\n\n\t\t[type=checkbox] {\n\t\t\tmargin-left: 1rem;\n\t\t}\n\t}\n\n\tfieldset {\n\t\tmargin-bottom: 2rem;\n\t}\n"])));

exports.ProfileContainer = ProfileContainer;

var IngestContainer = _styledComponents.default.div(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)(["\n\tfieldset {\n\t\twidth:100%;\n\t\tpadding-top:15px;\n\t}\n\n\tfieldset > div {\n\t\tdisplay: flex;\n\t\tflex-direction: row;\n\t\talign-items: center;\n\t\tmargin-bottom: 0.5rem;\n\t\theight: 30px;\n\t}\n\n\tlabel {\n\t\twidth: 40rem;\n\t}\n\n\tinput{\n\t\tdisplay: inline-block;\n\t\twidth: 4rem;\n\t\ttext-align:center;\n\t}\n"])));

exports.IngestContainer = IngestContainer;

var EmailPreference = _styledComponents.default.div(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2.default)(["\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: space-between;\n\twidth: 24rem;\n\tmargin-bottom: 1rem;\n\n\tlabel {\n\t\twidth: 12rem;\n\t\tmargin-right: 1.5rem;\n\t}\n\n\t.email-preference {\n\t\tflex: 1 1 0;\n\t}\n"])));

exports.EmailPreference = EmailPreference;

var ContactListContainer = _styledComponents.default.div(_templateObject6 || (_templateObject6 = (0, _taggedTemplateLiteral2.default)(["\n\tfieldset {\n\t\tmargin-bottom: 2rem;\n\t}\n"])));

exports.ContactListContainer = ContactListContainer;

var AddContactListContainer = _styledComponents.default.div(_templateObject7 || (_templateObject7 = (0, _taggedTemplateLiteral2.default)(["\n\t.add-contact-btn {\n\t\tdisplay: block;\n\t\tmargin: 0 0 1rem auto;\n\t}\n"])));

exports.AddContactListContainer = AddContactListContainer;

var AddContactListForm = _styledComponents.default.div(_templateObject8 || (_templateObject8 = (0, _taggedTemplateLiteral2.default)(["\n\tlabel {\n\t\tmargin-top: 1rem;\n\t}\n\n\tfieldset {\n\t\tmargin-bottom: 2rem;\n\t}\n"])));

exports.AddContactListForm = AddContactListForm;

var ContactListsContainer = _styledComponents.default.div(_templateObject9 || (_templateObject9 = (0, _taggedTemplateLiteral2.default)(["\n\tpadding-left: 0.5rem;\n\twidth: 5rem;\n"])));

exports.ContactListsContainer = ContactListsContainer;

var ContactsContainer = _styledComponents.default.div(_templateObject10 || (_templateObject10 = (0, _taggedTemplateLiteral2.default)(["\n\tdisplay: flex;\n\talign-items: center;\n\tmargin-bottom: 1.5rem;\n\t\n\t& > div {\n\t\t&:first-child {\n\t\t\tflex: 1 1 0;\n\t\t\tmax-width: 50em;\n\t\t}\n\t}\n"])));

exports.ContactsContainer = ContactsContainer;

var ProfilePreferencesSmallLegend = _styledComponents.default.span(_templateObject11 || (_templateObject11 = (0, _taggedTemplateLiteral2.default)(["\n\tfont-size: 0.8rem;\n\tcolor: ", ";\n\tfont-weight: 500;\n\tpadding: 0 1rem;\n"])), _ref => {
  var {
    theme
  } = _ref;
  return theme['silver-base'];
});

exports.ProfilePreferencesSmallLegend = ProfilePreferencesSmallLegend;