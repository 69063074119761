"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _transferManager = _interopRequireDefault(require("./transfer-manager"));

var _eventService = _interopRequireDefault(require("./event-service"));

var AsperaService = {
  _currentStatus: '',
  _runningListeners: [],
  _isListening: false,

  _onStatusChanged(event, eventType, data) {
    if (AsperaService._currentStatus === data) {
      return;
    }

    var wasAsperaRunning = AsperaService._currentStatus === 'RUNNING';
    var isAsperaRunning = data === 'RUNNING';

    if (wasAsperaRunning || isAsperaRunning) {
      AsperaService._notifyRunningListeners(isAsperaRunning);
    }

    AsperaService._currentStatus = data;
  },

  _notifyRunningListeners(isRunning) {
    for (var listener of AsperaService._runningListeners) {
      listener(isRunning);
    }
  },

  get isRunning() {
    return _transferManager.default.pluginStatus() === 'RUNNING';
  },

  addRunningListener(listener) {
    if (!AsperaService._isListening) {
      _eventService.default.on('Connect:Plugin:Status', AsperaService._onStatusChanged);

      AsperaService._isListening = true;
    }

    AsperaService._runningListeners.push(listener);
  },

  removeRunningListener(listener) {
    AsperaService._runningListeners = _lodash.default.without(AsperaService._runningListeners, listener);
  },

  setCredentials(session, token) {
    _transferManager.default.setCredentials(session, token);
  }

};
var _default = AsperaService;
exports.default = _default;