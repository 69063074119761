"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Nub = exports.Content = exports.Container = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _templateObject, _templateObject2, _templateObject3;

var Nub = _styledComponents.default.span(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n\tposition: absolute;\n\tdisplay: block;\n\ttop: ", ";\n\tleft: ", ";\n\ttransform: ", ";\n\ttransition: opacity 0s;\n\ttransition-delay: ", ";\n\tz-index: 39;\n\tborder-width: 0.75rem 0.75rem 0;\n\tborder-style: solid;\n\tborder-color: ", " transparent transparent;\n\topacity: 0;\n\tpointer-events: none;\n"])), _ref => {
  var {
    position
  } = _ref;

  switch (position) {
    case 'top':
      return '0';

    case 'right':
      return '50%';

    case 'bottom':
      return '100%';

    case 'left':
      return '50%';

    default:
      return '0';
  }
}, _ref2 => {
  var {
    position
  } = _ref2;

  switch (position) {
    case 'top':
      return '50%';

    case 'right':
      return '100%';

    case 'bottom':
      return '50%';

    case 'left':
      return '0';

    default:
      return '50%';
  }
}, _ref3 => {
  var {
    position
  } = _ref3;

  switch (position) {
    case 'top':
      return 'translateX(-50%) translateY(-100%)';

    case 'right':
      return 'translateX(-0.25rem) translateY(-50%) rotate(90deg)';

    case 'bottom':
      return 'translateX(-50%) translateY(-100%) translateY(0.75rem) rotate(-180deg)';

    case 'left':
      return 'translateX(-1.25rem) translateY(-50%) rotate(-90deg)';

    default:
      return 'translateX(-50%) translateY(-100%)';
  }
}, props => props.delay ? '500ms' : '0', _ref4 => {
  var {
    theme,
    background
  } = _ref4;
  return background ? theme[background] : theme['blue-base'];
});

exports.Nub = Nub;

var Content = _styledComponents.default.span(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n\tposition: absolute;\n\tdisplay: block;\n\ttop: ", ";\n\tleft: ", ";\n\ttransform: ", ";\n\ttransition: opacity 0s;\n\ttransition-delay: ", ";\n\tz-index: 40;\n\tborder-radius: 0.25rem;\n\tbackground: ", ";\n\tpadding: 0.25rem 0.5rem;\n\tmin-width: ", ";\n\twidth: ", ";\n\topacity: 0;\n\ttext-align: center;\n\tline-height: 1.4;\n\tcolor: ", ";\n\tfont-size: ", ";\n\tfont-weight: 700;\n\tpointer-events: none;\n"])), _ref5 => {
  var {
    position
  } = _ref5;

  switch (position) {
    case 'top':
      return '0';

    case 'right':
      return '50%';

    case 'bottom':
      return '100%';

    case 'left':
      return '50%';

    default:
      return '0';
  }
}, _ref6 => {
  var {
    position
  } = _ref6;

  switch (position) {
    case 'top':
      return '50%';

    case 'right':
      return '100%';

    case 'bottom':
      return '50%';

    case 'left':
      return '0';

    default:
      return '50%';
  }
}, _ref7 => {
  var {
    position
  } = _ref7;

  switch (position) {
    case 'top':
      return 'translateX(-50%) translateY(-100%) translateY(-0.325rem)';

    case 'right':
      return 'translateX(0%) translateY(-50%) translateX(0.5rem)';

    case 'bottom':
      return 'translateX(-50%) translateY(0%) translateY(0.325rem)';

    case 'left':
      return 'translateX(-100%) translateY(-50%) translateX(-0.5rem)';

    default:
      return 'translateX(-50%) translateY(-100%) translateY(-0.325rem)';
  }
}, props => props.delay ? '500ms' : '0', _ref8 => {
  var {
    theme,
    background
  } = _ref8;
  return background ? theme[background] : theme['blue-base'];
}, _ref9 => {
  var {
    size
  } = _ref9;

  if (Number(size) > 0) {
    return '0';
  }

  switch (size) {
    case 'sm':
      return '4rem';

    case 'lg':
      return '9rem';

    case 'xl':
      return '12rem';

    default:
      return '6rem';
  }
}, _ref10 => {
  var {
    size
  } = _ref10;
  return Number(size) > 0 ? "".concat(size, "rem") : 'auto';
}, _ref11 => {
  var {
    theme
  } = _ref11;
  return theme['white'];
}, _ref12 => {
  var {
    fontSize
  } = _ref12;

  if (Number(fontSize) > 0) {
    return fontSize + 'rem';
  }

  switch (fontSize) {
    case 'sm':
      return '0.6rem';

    case 'lg':
      return '1rem';

    case 'xl':
      return '1.2rem';

    default:
      return '0.8rem';
  }
});

exports.Content = Content;

var Container = _styledComponents.default.span(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["\n\tposition: relative;\n\tdisplay: inline-block;\n\n\t&:hover {\n\t\t", ",\n\t\t", " {\n\t\t\topacity: 1;\n\t\t}\n\t}\n"])), Nub, Content);

exports.Container = Container;