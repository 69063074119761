"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = useSession;

var _reactQuery = require("@tanstack/react-query");

var _reactRedux = require("react-redux");

var _session = require("~/app/3x/store/session");

var _sessionService = _interopRequireDefault(require("~/app/3x/modules/services/session-service"));

var _userService = _interopRequireDefault(require("~/app/3x/modules/services/user-service"));

function useSession() {
  var session = (0, _reactRedux.useSelector)(state => _session.selectors.getSession(state));

  if (!session) {
    var {
      data
    } = (0, _reactQuery.useQuery)(['session'], () => _sessionService.default.getSession());
    session = data;
  }

  var {
    mutateAsync: updateUser,
    isLoading: isUserSaving
  } = (0, _reactQuery.useMutation)(data => _userService.default.updateUser(session.id, data));
  return {
    session,
    isUserSaving,
    updateUser
  };
}