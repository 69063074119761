"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = AuthorizedUploadHandler;

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _moment = _interopRequireDefault(require("moment"));

var _jsCookie = _interopRequireDefault(require("js-cookie"));

var _lodash = _interopRequireDefault(require("lodash.get"));

var _lodash2 = _interopRequireDefault(require("lodash"));

var _apiService = _interopRequireDefault(require("./api-service"));

var _eventService = _interopRequireDefault(require("./event-service"));

var _transferManager = _interopRequireDefault(require("./transfer-manager"));

var _aspera = _interopRequireDefault(require("./aspera"));

var _upload = _interopRequireDefault(require("./upload"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function AuthorizedUploadHandler(options) {
  var signal = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  var uploadHandler = {
    error: null,
    state: null,
    progress: 0
  };
  var {
    updateCb,
    request: uploadRequest
  } = options;

  function handleError(string, object) {
    uploadHandler = _objectSpread(_objectSpread({}, uploadHandler), {}, {
      state: null,
      error: {
        string: 'userFileUpload.error.' + string,
        object: _lodash2.default.pick(object, ['status', 'data'])
      }
    });
    return new Error('error.' + string);
  }

  function createRequest(_x) {
    return _createRequest.apply(this, arguments);
  }

  function _createRequest() {
    _createRequest = (0, _asyncToGenerator2.default)(function* (options) {
      var signal = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      uploadHandler = _objectSpread(_objectSpread({}, uploadHandler), {}, {
        error: null,
        state: 'request',
        progress: 0,
        start: 0,
        current: 0,
        duration: 0,
        estimated: 'Calculating...',
        size: 0
      });
      return yield _apiService.default.client.post('/upload-request', options, {
        signal: signal
      }).then(res => res.data).catch(e => {
        handleError(e);
      });
    });
    return _createRequest.apply(this, arguments);
  }

  function transferFile(file, request) {
    uploadHandler.state = 'transfer';
    var WHITELIST = ['key', 'AWSAccessKeyId', 'acl', 'policy', 'signature', 'Content-Type'];
    var options = {
      fields: _lodash2.default.pick(_objectSpread(_objectSpread({}, request), uploadRequest), (value, key) => {
        return WHITELIST.indexOf(key) > -1 || key.indexOf('x-amz-meta-') > -1;
      }),
      url: request.bucket,
      method: 'POST',
      file: file,
      onUploadProgress: setProgress,
      signal: request.signal
    };

    if (request.language) {
      options.language = request.language;
    }

    var now = new Date().getTime();

    function setProgress(event) {
      var percent = parseInt(100.0 * event.loaded / event.total, 10);
      uploadHandler.title = file === null || file === void 0 ? void 0 : file.name;
      uploadHandler.status = 'running';
      uploadHandler.active = true;
      uploadHandler.progress = percent;
      uploadHandler.bytes_written = event.loaded;
      uploadHandler.bytes_expected = file.size;
      uploadHandler.elapsed = _moment.default.duration((new Date().getTime() - now) / 1000).humanize();
      uploadHandler.remaining = '-';
      updateCb && updateCb(uploadHandler);
    }

    function getFileUrl() {
      // eslint-disable-next-line no-template-curly-in-string
      return request.bucket + '/' + request.key.replace('${filename}', file.name);
    }

    function destinationName(file) {
      var path = file.name.replace(/\\/g, '\\\\');
      return path.charAt(0) === '/' ? path.split('/').pop() : path.split('\\\\').pop();
    }

    uploadHandler.size = file.size;
    uploadHandler.start = new Date().getTime();

    if (request.usage === 'slurpee2' && request.transferSpec) {
      // Failover when Aspera doesn't work
      if (_transferManager.default.pluginStatus() !== 'RUNNING') {
        return _upload.default.upload(options).then(() => {
          uploadHandler.status = 'completed';
          return request.node_url + '/' + request.ingest + '/' + destinationName(file);
        }).catch(_lodash2.default.partial(handleError, 'transfer'));
      }

      var credentials = JSON.parse(_jsCookie.default.get('pixwel.api.credentials'));
      var specs = {
        paths: [{
          source: file.name,
          destination: request.ingest + '/' + destinationName(file)
        }],
        authentication: 'token',
        remote_user: request.transferSpec.remote_user,
        remote_host: request.transferSpec.remote_host,
        token: request.transferSpec.token,
        direction: 'send',
        create_dir: true,
        target_rate_kbps: 500000,
        resume: 'sparse_checksum',
        fasp_port: 33001,
        ssh_port: 33001,
        cookie: credentials.username
      };
      var url = request.node_url + '/' + request.ingest + '/' + destinationName(file);
      return new Promise((resolve, reject) => {
        _eventService.default.emit('Connect:TransferStarted', _aspera.default.Connect.startTransfer(specs, {
          allow_dialogs: false
        }));

        uploadHandler.active = false;
        uploadHandler.remaining = 'Calculating...';

        uploadHandler.listener = (event, obj) => {
          obj.transfers.forEach(transfer => {
            if (!(0, _lodash.default)(transfer, 'transfer_spec.paths[0].destination', false)) {
              return;
            }

            var ingestId = transfer.transfer_spec.paths[0].destination.split('/').shift();

            if (ingestId === request.ingest) {
              uploadHandler.active = true;
              uploadHandler.status = transfer.status;
              uploadHandler.title = transfer.title;
              uploadHandler.bytes_expected = transfer.bytes_expected;
              uploadHandler.bytes_written = transfer.bytes_written;
              uploadHandler.elapsed = _moment.default.duration(transfer.elapsed_usec / 1000).humanize();
              uploadHandler.remaining = _moment.default.duration(transfer.remaining_usec / 1000).humanize();
              uploadHandler.progress = transfer.percentage * 100;

              uploadHandler.abort = () => {
                uploadHandler.cancelling = true;

                _aspera.default.Connect.removeTransfer(transfer.uuid);
              };

              updateCb && updateCb(uploadHandler);

              if (transfer.status === 'completed') {
                uploadHandler.active = false;
                resolve(url);
              }

              if (transfer.status === 'removed') {
                uploadHandler.active = false;
                uploadHandler.cancelling = false;
                reject();
              }
            }
          });
        };

        _aspera.default.Connect.addEventListener('transfer', uploadHandler.listener);
      });
    }

    return _upload.default.upload(options).then(getFileUrl).catch(error => {
      if (error.code === 'ERR_CANCELED') {
        return Promise.reject(error);
      }

      return handleError('transfer', error);
    });
  }

  function finalize(result) {
    uploadHandler.state = 'finished';
    return result;
  }

  var promise = createRequest(options.request, signal).then(req => transferFile(options.file, _objectSpread(_objectSpread(_objectSpread({}, req), options.request), {}, {
    signal
  }))).then(result => {
    return Promise.resolve(finalize(result));
  }).catch(error => {
    if (error.code === 'ERR_CANCELED') {
      return error;
    }

    return handleError('Upload', error);
  });
  return Object.assign(promise, {
    uploadHandler
  });
}