"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _logrocket = _interopRequireDefault(require("logrocket"));

var _config = require("../../../../config/config.3x");

if (_config.LOG_ROCKET_ENABLED) {
  _logrocket.default.init(_config.LOG_ROCKET_APP_ID);
}