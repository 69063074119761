"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.headerFontFamily = exports.baseFontFamily = exports.Title = exports.Legend = exports.Label = exports.HeaderXSm = exports.HeaderSm = exports.HeaderMd = exports.HeaderLg = exports.BodyText = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8;

var headerFontFamily = "'SF Display', -apple-system, BlinkMacSystemFont, 'Roboto', 'Segoe UI', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif";
exports.headerFontFamily = headerFontFamily;
var baseFontFamily = "'SF Text', -apple-system, BlinkMacSystemFont, 'Roboto', 'Segoe UI', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif";
exports.baseFontFamily = baseFontFamily;

var Title = _styledComponents.default.h1(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n\tfont-family: ", ";\n\tfont-size: 2rem;\n\tfont-weight: 700;\n\tline-height: 1.5;\n\tletter-spacing: 0;\n\tmargin-bottom: 1rem;\n"])), headerFontFamily);

exports.Title = Title;

var HeaderLg = _styledComponents.default.h2(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n\tfont-family: ", ";\n\tfont-size: 1.625rem;\n\tfont-weight: 700;\n\tline-height: 1.3;\n\tletter-spacing: 0;\n\tmargin-bottom: 0.5rem;\n"])), headerFontFamily);

exports.HeaderLg = HeaderLg;

var HeaderMd = _styledComponents.default.h3(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["\n\tfont-family: ", ";\n\tfont-size: 1.5rem;\n\tfont-weight: 700;\n\tline-height: 1.2;\n\tmargin-bottom: 0.5rem;\n"])), headerFontFamily);

exports.HeaderMd = HeaderMd;

var HeaderSm = _styledComponents.default.h4(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)(["\n\tfont-family: ", ";\n\tfont-size: 1.25rem;\n\tfont-weight: 700;\n\tline-height: 1.2;\n\tmargin-bottom: 0.5rem;\n"])), headerFontFamily);

exports.HeaderSm = HeaderSm;

var HeaderXSm = _styledComponents.default.h5(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2.default)(["\n\tfont-family: ", ";\n\tfont-size: 1rem;\n\tfont-weight: 700;\n\tline-height: 1.4;\n\tmargin-bottom: 0.5rem;\n"])), headerFontFamily);

exports.HeaderXSm = HeaderXSm;

var Legend = _styledComponents.default.legend(_templateObject6 || (_templateObject6 = (0, _taggedTemplateLiteral2.default)(["\n\tfont-family: ", ";\n\tfont-size: 1.25rem;\n\tfont-weight: 700;\n\tline-height: 1.4;\n\tmargin-bottom: 0.5rem;\n"])), headerFontFamily);

exports.Legend = Legend;

var Label = _styledComponents.default.label(_templateObject7 || (_templateObject7 = (0, _taggedTemplateLiteral2.default)(["\n\tdisplay: block;\n\tmargin: 0 0 0.5rem;\n\tline-height: 1.5;\n\tcolor: ", ";\n\tfont-size: 0.9rem;\n\tfont-weight: 700;\n\n\tinput + span {\n\t\tmargin-left: 0.5rem;\n\t}\n"])), _ref => {
  var {
    theme
  } = _ref;
  return theme['silver-lighten-20'];
});

exports.Label = Label;

var BodyText = _styledComponents.default.p(_templateObject8 || (_templateObject8 = (0, _taggedTemplateLiteral2.default)(["\n\tfont-family: ", ";\n\tfont-size: 1rem;\n\tline-height: 1.6;\n\tmargin-bottom: 1rem;\n\ttext-rendering: optimizeLegibility;\n"])), baseFontFamily);

exports.BodyText = BodyText;