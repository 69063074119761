"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actions = exports.STORE_NAME = exports.LOAD_SUCCESS = exports.LOAD_REQUEST = exports.LOAD_FAILURE = exports.LOAD = exports.INITIAL_STATE = void 0;
exports.reducer = reducer;
exports.selectors = void 0;
exports.watchRegisterPage = watchRegisterPage;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _lodash = _interopRequireDefault(require("lodash.iserror"));

var _reduxActions = require("redux-actions");

var _effects = require("redux-saga/effects");

var _common = require("./common");

var _users = require("./users");

var _territories = require("./territories");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var STORE_NAME = 'registerPageStore';
exports.STORE_NAME = STORE_NAME;

function* loadPageData(_ref) {
  var {
    payload: email
  } = _ref;

  try {
    yield (0, _effects.put)(internalActions.loadPageRequest()); // Fork to run separately (non-blocking)

    var userTask = yield (0, _effects.fork)(_users.fetchUserByEmail, _users.actions.getUserByEmail(email));
    var territoriesTask = yield (0, _effects.fork)(_territories.fetchTerritories, _territories.actions.getTerritories()); // Join forked task back in

    var user = yield (0, _effects.join)(userTask);
    var territories = yield (0, _effects.join)(territoriesTask);

    if ((0, _lodash.default)(user) || (0, _lodash.default)(territories)) {
      throw new Error('Failed to load page resources');
    }

    yield (0, _effects.put)(internalActions.loadPageSuccess());
  } catch (e) {
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.loadPageFailure(err));
    return err;
  }
}

var LOAD = 'register-page.load';
exports.LOAD = LOAD;
var LOAD_REQUEST = 'register-page.load.request';
exports.LOAD_REQUEST = LOAD_REQUEST;
var LOAD_SUCCESS = 'register-page.load.success';
exports.LOAD_SUCCESS = LOAD_SUCCESS;
var LOAD_FAILURE = 'register-page.load.failure';
exports.LOAD_FAILURE = LOAD_FAILURE;
var INITIAL_STATE = {
  isLoading: false,
  loadFailed: false
};
exports.INITIAL_STATE = INITIAL_STATE;

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case LOAD_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });

    case LOAD_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false,
        loadFailed: false
      });

    case LOAD_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false,
        loadFailed: true
      });

    default:
      return state;
  }
}

var actions = {
  loadPageData: (0, _reduxActions.createAction)(LOAD)
};
/**
 * Actions that should only be invoked internally
 */

exports.actions = actions;
var internalActions = {
  loadPageRequest: (0, _reduxActions.createAction)(LOAD_REQUEST),
  loadPageSuccess: (0, _reduxActions.createAction)(LOAD_SUCCESS),
  loadPageFailure: (0, _reduxActions.createAction)(LOAD_FAILURE)
};
var selectors = {
  isLoading: (0, _common.createSelector)(STORE_NAME, isLoading),
  isFailedLoad: (0, _common.createSelector)(STORE_NAME, isFailedLoad),
  isReady
};
exports.selectors = selectors;

function isLoading(state) {
  return state.isLoading;
}

function isFailedLoad(state) {
  return state.loadFailed;
}

function isReady(state, email) {
  // A user record may not exist. We just want to verify that we tried to obtain a user record.
  var isUserLoaded = !!_users.selectors.getUserLastLoadedByEmailAt(state, email);

  var areTerritoriesLoaded = _territories.selectors.isLoaded(state);

  return isUserLoaded && areTerritoriesLoaded;
}

function* watchRegisterPage() {
  yield (0, _effects.takeLatest)(LOAD, loadPageData);
}