"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _Faye = _interopRequireDefault(require("./Faye"));

var _config = require("../../../../config/config.3x");

var faye = window.faye = window.faye || new _Faye.default(_config.MESSAGE_ENDPOINT);
var FayeService = {
  subscribe: (channel, cb) => {
    var subscription = faye.subscribe(channel, cb);
    return () => subscription.cancel();
  }
};
var _default = FayeService;
exports.default = _default;