"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actions = exports.STORE_NAME = exports.INITIAL_STATE = exports.CHANGE_LANGUAGE_SUCCESS = exports.CHANGE_LANGUAGE_REQUEST = exports.CHANGE_LANGUAGE_FAILURE = exports.CHANGE_LANGUAGE = void 0;
exports.reducer = reducer;
exports.selectors = void 0;
exports.watchLocale = watchLocale;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _reduxActions = require("redux-actions");

var _effects = require("redux-saga/effects");

var _routeService = _interopRequireDefault(require("../modules/services/route-service"));

var _common = require("./common");

var _localeService = _interopRequireDefault(require("~/app/3x/modules/services/locale-service"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var STORE_NAME = 'localeStore';
exports.STORE_NAME = STORE_NAME;

function* changeLanguage(_ref) {
  var {
    payload: language
  } = _ref;

  try {
    yield (0, _effects.put)(internalActions.changeLanguageRequest(language));
    var lang = yield _localeService.default.loadLocale(language);
    yield (0, _effects.put)(internalActions.changeLanguageSuccess(lang)); // We reload the state to make sure all our Locale components get re-rendered
    // Once we can hook up nested React components directly to Redux, this can be removed

    _routeService.default.reload();
  } catch (e) {
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.changeLanguageFailure(err));
    return err;
  }
}

var CHANGE_LANGUAGE = 'locale.change';
exports.CHANGE_LANGUAGE = CHANGE_LANGUAGE;
var CHANGE_LANGUAGE_REQUEST = 'locale.change.request';
exports.CHANGE_LANGUAGE_REQUEST = CHANGE_LANGUAGE_REQUEST;
var CHANGE_LANGUAGE_SUCCESS = 'locale.change.success';
exports.CHANGE_LANGUAGE_SUCCESS = CHANGE_LANGUAGE_SUCCESS;
var CHANGE_LANGUAGE_FAILURE = 'locale.change.failure';
exports.CHANGE_LANGUAGE_FAILURE = CHANGE_LANGUAGE_FAILURE;
var INITIAL_STATE = {
  isLoadingLanguage: false,
  failedLanguageLoad: false
};
exports.INITIAL_STATE = INITIAL_STATE;

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case CHANGE_LANGUAGE_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoadingLanguage: true,
        failedLanguageLoad: false
      });

    case CHANGE_LANGUAGE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoadingLanguage: false
      });

    case CHANGE_LANGUAGE_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoadingLanguage: false,
        failedLanguageLoad: true
      });

    default:
      return state;
  }
}

var actions = {
  changeLanguage: (0, _reduxActions.createAction)(CHANGE_LANGUAGE)
};
/**
 * Actions that should only be invoked internally
 */

exports.actions = actions;
var internalActions = {
  changeLanguageRequest: (0, _reduxActions.createAction)(CHANGE_LANGUAGE_REQUEST),
  changeLanguageSuccess: (0, _reduxActions.createAction)(CHANGE_LANGUAGE_SUCCESS),
  changeLanguageFailure: (0, _reduxActions.createAction)(CHANGE_LANGUAGE_FAILURE)
};
var selectors = {
  isLanguageLoading: (0, _common.createSelector)(STORE_NAME, isLanguageLoading),
  isLanguageLoadFailed: (0, _common.createSelector)(STORE_NAME, isLanguageLoadFailed)
};
exports.selectors = selectors;

function isLanguageLoading(state) {
  return state.isLoadingLanguage;
}

function isLanguageLoadFailed(state) {
  return state.failedLanguageLoad;
}

function* watchLocale() {
  yield (0, _effects.takeLatest)(CHANGE_LANGUAGE, changeLanguage);
}