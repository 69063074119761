"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.captureError = captureError;
exports.handleGlobalError = handleGlobalError;

var Sentry = _interopRequireWildcard(require("@sentry/react"));

var _toastService = _interopRequireDefault(require("../services/toast-service"));

var _lodash = _interopRequireDefault(require("lodash"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var GLOBAL_ERROR_MSG = 'An unexpected error has occurred. ' + 'If you notice your application acting oddly, please refresh the page.';

function handleGlobalError(exception, cause) {
  if (window.env !== 'development') {
    Sentry.captureException(exception);
  }

  _toastService.default.create('error', GLOBAL_ERROR_MSG, {
    duration: 10000
  }); // Cause is not always provided. Dynamically attach it if available


  var errorProps = [exception];

  if (cause) {
    errorProps.unshift(cause);
  }

  console.error(...errorProps);
}

function captureError(e, message) {
  var _e$response;

  if (window.env !== 'development' && e.status !== 403) {
    Sentry.captureException(e);
  }

  console.error(message, e);

  if (message) {
    _toastService.default.create('error', message, {
      toastId: 'capture-error'
    });
  } else if (typeof e === 'string') {
    _toastService.default.create('error', e, {
      toastId: 'capture-error'
    });
  } else if (e !== null && e !== void 0 && (_e$response = e.response) !== null && _e$response !== void 0 && _e$response.data) {
    var errorMessage = '';

    if (typeof e.response.data === 'object') {
      Object.keys(e.response.data).forEach(key => {
        if (Array.isArray(e.response.data[key])) {
          errorMessage += _lodash.default.flatten(e.response.data[key]).join('\n');
        }
      });
    }

    if (e.response.data.errors) {
      errorMessage = Object.values(e.response.data.errors).join('\n');
    }

    _toastService.default.create('error', errorMessage, {
      toastId: 'capture-error'
    });
  } else if (typeof e.message === 'string') {
    _toastService.default.create('error', e.message, {
      toastId: 'capture-error'
    });
  }
}