"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MenuLinkBtn = exports.MenuLabel = exports.MenuItem = exports.Menu = exports.Container = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _polished = require("polished");

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;

var Menu = _styledComponents.default.ul(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n\tdisplay: flex;\n\tflex-direction: column;\n\tflex-wrap: nowrap;\n\tmargin: 0;\n\tpadding: 0;\n\tlist-style: none;\n\n\tli {\n\t\tposition: relative;\n\t\toutline: 0;\n\n\t\t&.first {\n\t\t\tmargin-top: 2rem;\n\t\t}\n\t}\n"])));

exports.Menu = Menu;

var MenuLabel = _styledComponents.default.span(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n\ttransform: translateX(-0.25rem);\n\twhite-space: nowrap;\n"])));

exports.MenuLabel = MenuLabel;

var MenuItem = _styledComponents.default.li(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["\n\ta {\n\t\tdisplay: flex;\n\t\talign-items: center;\n\t\tflex-flow: row nowrap;\n\t\ttransition: color 100ms,\n\t\t\tbackground-color 100ms,\n\t\t\tborder-left-color 100ms;\n\t\tborder: 0;\n\t\tborder-left-width: 0.125rem;\n\t\tborder-left-style: solid;\n\t\tborder-left-color: ", ";\n\t\tbackground-color: transparent;\n\t\tpadding: 0;\n\t\tcolor: ", ";\n\t\tfont-size: 0.9rem;\n\t\tfont-weight: 700;\n\n\t\tsvg {\n\t\t\tmargin: 1rem 1.375rem;\n\t\t\ttransition: opacity 100ms;\n\t\t\topacity: 0.3;\n\n\t\t\tpath,\n\t\t\trect {\n\t\t\t\tfill: ", ";\n\t\t\t}\n\t\t}\n\n\t\t&:hover {\n\t\t\tbackground: ", ";\n\t\t\tcolor: ", ";\n\n\t\t\tsvg {\n\t\t\t\topacity: 1;\n\t\t\t}\n\t\t}\n\n\t\t&:focus {\n\t\t\toutline: 0;\n\t\t}\n\t}\n"])), _ref => {
  var {
    theme,
    isActive
  } = _ref;
  return isActive ? theme['blue-base'] : 'transparent';
}, _ref2 => {
  var {
    theme,
    isActive
  } = _ref2;
  return isActive ? theme['white'] : (0, _polished.rgba)(theme['white'], 0.3);
}, _ref3 => {
  var {
    theme
  } = _ref3;
  return theme['white'];
}, _ref4 => {
  var {
    theme
  } = _ref4;
  return theme['midnight-lighten-10'];
}, _ref5 => {
  var {
    theme
  } = _ref5;
  return theme['white'];
});

exports.MenuItem = MenuItem;

var MenuLinkBtn = _styledComponents.default.button(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)(["\n\tdisplay: flex;\n\talign-items: center;\n\tflex-flow: row nowrap;\n\ttransition: color 100ms,\n\t\tbackground-color 100ms,\n\t\tborder-left-color 100ms;\n\tborder: 0;\n\tborder-left-width: 0.125rem;\n\tborder-left-style: solid;\n\tborder-left-color: ", ";\n\tbackground-color: transparent;\n\tpadding: 0;\n\tcolor: ", ";\n\tfont-size: 0.9rem;\n\tfont-weight: 700;\n\n\tsvg {\n\t\tmargin: 1rem 1.375rem;\n\t\ttransition: opacity 100ms;\n\t\topacity: 0.3;\n\n\t\tpath,\n\t\trect {\n\t\t\tfill: ", ";\n\t\t}\n\t}\n\n\t&:hover {\n\t\tbackground: ", ";\n\t\tcolor: ", ";\n\n\t\tsvg {\n\t\t\topacity: 1;\n\t\t}\n\t}\n\n\t&:focus {\n\t\toutline: 0;\n\t}\n"])), _ref6 => {
  var {
    theme,
    isActive
  } = _ref6;
  return isActive ? theme['blue-base'] : 'transparent';
}, _ref7 => {
  var {
    theme,
    isActive
  } = _ref7;
  return isActive ? theme['white'] : (0, _polished.rgba)(theme['white'], 0.3);
}, _ref8 => {
  var {
    theme
  } = _ref8;
  return theme['white'];
}, _ref9 => {
  var {
    theme
  } = _ref9;
  return theme['midnight-lighten-10'];
}, _ref10 => {
  var {
    theme
  } = _ref10;
  return theme['white'];
});

exports.MenuLinkBtn = MenuLinkBtn;

var Container = _styledComponents.default.div(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2.default)(["\n\tdisplay: flex;\n\tflex-flow: column;\n\tjustify-content: space-between;\n\theight: 100%;\n\twidth: ", ";\n\n\t.badge {\n\t\tposition: absolute;\n\t\ttop: 0.325rem;\n\t\tright: auto;\n\t\tleft: 2.325rem;\n\t}\n\n\t[tooltip] {\n\t\t&::before,\n\t\t&::after {\n\t\t\tvisibility: ", ";\n\t\t\ttransition-delay: 0s;\n\t\t}\n\n\t\t&::after {\n\t\t\twidth: 7rem;\n\t\t}\n\n\t\t&:hover {\n\t\t\t&::before,\n\t\t\t&::after {\n\t\t\t\ttransition-delay: 0.5s;\n\t\t\t}\n\t\t}\n\t}\n\n\t", " {\n\t\twidth: ", ";\n\t}\n\n\t", " {\n\t\tdisplay: ", ";\n\t}\n"])), _ref11 => {
  var {
    isMaximized
  } = _ref11;
  return isMaximized ? '12rem' : 'auto';
}, _ref12 => {
  var {
    isMaximized
  } = _ref12;
  return isMaximized ? 'hidden' : 'visible';
}, MenuLinkBtn, _ref13 => {
  var {
    isMaximized
  } = _ref13;
  return isMaximized ? '100%' : 'auto';
}, MenuLabel, _ref14 => {
  var {
    isMaximized
  } = _ref14;
  return isMaximized ? 'block' : 'none';
});

exports.Container = Container;