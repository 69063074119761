"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SearchLabel = exports.SearchInput = exports.Container = exports.ClearButton = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _templateObject, _templateObject2, _templateObject3, _templateObject4;

var Container = _styledComponents.default.div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n\tdisplay: flex;\n\talign-items: center;\n\tposition: relative;\n"])));

exports.Container = Container;

var SearchLabel = _styledComponents.default.label(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n\tposition: absolute;\n\ttop: calc(50% - 0.5rem);\n\tleft: 1rem;\n\tcursor: text;\n\tline-height: 0.75rem;\n"])));

exports.SearchLabel = SearchLabel;

var SearchInput = _styledComponents.default.input.attrs({
  type: 'text'
})(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["\n\tmargin-bottom: 0;\n\tpadding-left: 2.75rem !important;\n\n\t&:disabled,\n\t&[read-only] {\n\t\t& ~ ", " {\n\t\t\topacity: 0.33;\n\t\t}\n\t}\n"])), SearchLabel);

exports.SearchInput = SearchInput;

var ClearButton = _styledComponents.default.button(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)(["\n\tposition: absolute;\n\ttop: calc(50% - 0.5625rem);\n\tright: 0.5rem;\n\toutline: none;\n\tborder: 0;\n\tbackground: transparent;\n\tcursor: pointer;\n\tline-height: 0.875rem;\n\n\t&:focus {\n\t\toutline: none;\n\t}\n"])));

exports.ClearButton = ClearButton;