"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _apiService = _interopRequireDefault(require("./api-service"));

var _logrocket = _interopRequireDefault(require("logrocket"));

var Sentry = _interopRequireWildcard(require("@sentry/react"));

var _config = require("~/config/config.3x");

var _jsCookie = _interopRequireDefault(require("js-cookie"));

var _flagService = _interopRequireDefault(require("./flag-service"));

var _userService = _interopRequireDefault(require("~/app/3x/modules/services/user-service"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var STORE_KEY = 'pixwel.api.credentials';
var CLERK_STORE_KEY = '__session';
var AuthService = {
  login: credentials => {
    var isToken = !!credentials.token;
    var isTwoFactor = credentials.isTwoFactor;

    if (isToken && isTwoFactor) {
      AuthService.setToken({
        username: credentials.username,
        token: credentials.token
      });
    }

    credentials = !isToken ? credentials : {
      username: credentials.username,
      password: 'token-' + credentials.token
    };
    var url = "".concat(window.location.origin, "/login/:token");
    var params = {};

    if (!isTwoFactor) {
      params.url = url;
    }

    return _apiService.default.client.get('/session', {
      params,
      auth: credentials
    }).then(_ref => {
      var {
        data: session
      } = _ref;

      if (!session) {
        throw new Error(isToken ? 'token-expired' : 'access-denied');
      }

      if (session.token) {
        AuthService.setToken({
          username: credentials.username,
          token: session.token
        });
      } // Do not proceed with Logger configuration if session is valid yet (e.g. 2FA session response)


      if (!session._id) {
        return session;
      }

      if (_config.POSTHOG_ENABLED) {
        _flagService.default.identify(session);
      }

      _logrocket.default === null || _logrocket.default === void 0 ? void 0 : _logrocket.default.identify(session._id, {
        name: session.name,
        email: session.emailNormal,
        isImpersonating: AuthService.getToken().isImpersonating || false
      });

      var isAdmin = _userService.default.isAdmin(session);

      var isImpersonating = AuthService.isImpersonating();
      Sentry.getCurrentHub().getClient().getOptions().enabled = window.env !== 'development' && (isAdmin || isImpersonating);

      _logrocket.default.getSessionURL(sessionURL => {
        document.cookie = "LogRocket=".concat(sessionURL);
        Sentry.getCurrentScope().setExtra('sessionURL', sessionURL);
      });

      Sentry.getCurrentScope().setUser({
        email: session.emailNormal,
        username: session.name
      });
      return session;
    });
  },
  logout: () => {
    return _apiService.default.client.delete('/session').then(response => {
      AuthService.removeToken();
      window.location.href = '/';
      return (response === null || response === void 0 ? void 0 : response.data) || response || {};
    });
  },
  become: function () {
    var _become = (0, _asyncToGenerator2.default)(function* (userId) {
      try {
        var _result$data, _result$data2;

        var actual = AuthService.getToken();
        var result = yield _apiService.default.client.patch('/session', {
          _id: userId
        });

        if (!(result !== null && result !== void 0 && (_result$data = result.data) !== null && _result$data !== void 0 && _result$data.username) || !(result !== null && result !== void 0 && (_result$data2 = result.data) !== null && _result$data2 !== void 0 && _result$data2.token)) {
          throw new Error('User not found');
        }

        var session = (result === null || result === void 0 ? void 0 : result.data) || {};
        yield AuthService.removeToken();
        yield AuthService.setToken({
          isImpersonating: true,
          actual,
          username: session.username,
          token: session.token
        });
        window.location.reload();
      } catch (err) {
        window.location.reload();
      }
    });

    function become(_x) {
      return _become.apply(this, arguments);
    }

    return become;
  }(),
  isImpersonating: () => {
    return AuthService.getToken().isImpersonating || false;
  },
  switchBack: function () {
    var _switchBack = (0, _asyncToGenerator2.default)(function* () {
      var _AuthService$getToken, _AuthService$getToken2, _AuthService$getToken3, _AuthService$getToken4;

      var username = ((_AuthService$getToken = AuthService.getToken()) === null || _AuthService$getToken === void 0 ? void 0 : (_AuthService$getToken2 = _AuthService$getToken.actual) === null || _AuthService$getToken2 === void 0 ? void 0 : _AuthService$getToken2.username) || null;
      var token = ((_AuthService$getToken3 = AuthService.getToken()) === null || _AuthService$getToken3 === void 0 ? void 0 : (_AuthService$getToken4 = _AuthService$getToken3.actual) === null || _AuthService$getToken4 === void 0 ? void 0 : _AuthService$getToken4.token) || null;

      if (username && token) {
        yield AuthService.setToken({
          isImpersonating: false,
          username,
          token
        });
      } else {
        yield AuthService.removeToken();
      }

      window.location.reload();
    });

    function switchBack() {
      return _switchBack.apply(this, arguments);
    }

    return switchBack;
  }(),
  resetPassword: function () {
    var _resetPassword = (0, _asyncToGenerator2.default)(function* (token, password) {
      yield _apiService.default.client.post('/password-reset', {
        token,
        password
      });
    });

    function resetPassword(_x2, _x3) {
      return _resetPassword.apply(this, arguments);
    }

    return resetPassword;
  }(),
  twoFactorLogin: function () {
    var _twoFactorLogin = (0, _asyncToGenerator2.default)(function* (token) {
      return yield _apiService.default.client.post('/session', {
        token
      });
    });

    function twoFactorLogin(_x4) {
      return _twoFactorLogin.apply(this, arguments);
    }

    return twoFactorLogin;
  }(),
  removeToken: () => {
    _jsCookie.default.remove(STORE_KEY);
  },
  resumeSession: function () {
    var _resumeSession = (0, _asyncToGenerator2.default)(function* () {
      var token = AuthService.getToken();

      if (typeof token === 'string' && token.startsWith('ey')) {
        var _session = yield _apiService.default.client.get('/session', {
          headers: {
            Authorization: token
          }
        }).then(res => res.data);

        return _session;
      }

      if (!token) {
        throw new Error('no-token');
      }

      var session = yield AuthService.login({
        username: token.username,
        token: token.token
      });
      return session;
    });

    function resumeSession() {
      return _resumeSession.apply(this, arguments);
    }

    return resumeSession;
  }(),

  isJWT(tok) {
    var token = tok || this.getToken();
    return typeof token === 'string' && token.startsWith('ey');
  },

  getToken: () => {
    var clerkToken = _jsCookie.default.getJSON(CLERK_STORE_KEY);

    if (clerkToken) {
      return clerkToken;
    }

    var token = _jsCookie.default.getJSON(STORE_KEY);

    if (!token || !token.username || !token.token) {
      return false;
    }

    return token;
  },
  setToken: credentials => {
    return _jsCookie.default.set(STORE_KEY, credentials);
  }
};
var _default = AuthService;
exports.default = _default;