"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actions = exports.INITIAL_STATE = exports.FETCH_SHARE_BY_ID_SUCCESS = exports.FETCH_SHARE_BY_ID_REQUEST = exports.FETCH_SHARE_BY_ID_FAILURE = exports.FETCH_SHARE_BY_ID = void 0;
exports.fetchShareById = fetchShareById;
exports.reducer = reducer;
exports.selectors = void 0;
exports.watchShares = watchShares;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _reduxActions = require("redux-actions");

var _effects = require("redux-saga/effects");

var _session = require("./session");

var _common = require("./common");

var _shareService = _interopRequireDefault(require("~/app/3x/modules/services/share-service"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function* fetchShareById(_ref) {
  var {
    payload: shareId
  } = _ref;

  try {
    yield (0, _effects.put)(internalActions.getShareByIdRequest(shareId));
    var share = yield _shareService.default.getShareById(shareId);

    if (share === null) {
      throw new Error('Failed to load share');
    }

    yield (0, _effects.put)(internalActions.getShareByIdSuccess(share));
    return share;
  } catch (e) {
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.getShareByIdFailure({
      shareId,
      err
    }));
    return err;
  }
}

var FETCH_SHARE_BY_ID = 'share-by-id.fetch';
exports.FETCH_SHARE_BY_ID = FETCH_SHARE_BY_ID;
var FETCH_SHARE_BY_ID_REQUEST = 'share-by-id.fetch.request';
exports.FETCH_SHARE_BY_ID_REQUEST = FETCH_SHARE_BY_ID_REQUEST;
var FETCH_SHARE_BY_ID_SUCCESS = 'share-by-id.fetch.success';
exports.FETCH_SHARE_BY_ID_SUCCESS = FETCH_SHARE_BY_ID_SUCCESS;
var FETCH_SHARE_BY_ID_FAILURE = 'share-by-id.fetch.failure';
exports.FETCH_SHARE_BY_ID_FAILURE = FETCH_SHARE_BY_ID_FAILURE;
var INITIAL_STATE = {
  isLoading: false,
  lastLoadedAt: null,
  shares: {}
};
exports.INITIAL_STATE = INITIAL_STATE;

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _session.RESET_STORES:
      return INITIAL_STATE;

    case FETCH_SHARE_BY_ID_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });

    case FETCH_SHARE_BY_ID_SUCCESS:
      {
        var now = new Date();
        var share = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          shares: _objectSpread(_objectSpread({}, state.shares), {}, {
            [share._id]: {
              isLoading: false,
              lastLoadedAt: now,
              data: share
            }
          }),
          isLoading: false,
          lastLoadedAt: now
        });
      }

    case FETCH_SHARE_BY_ID_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });

    default:
      return state;
  }
}

var actions = {
  getShareById: (0, _reduxActions.createAction)(FETCH_SHARE_BY_ID)
};
/**
 * Actions that should only be invoked internally
 */

exports.actions = actions;
var internalActions = {
  getShareByIdRequest: (0, _reduxActions.createAction)(FETCH_SHARE_BY_ID_REQUEST),
  getShareByIdSuccess: (0, _reduxActions.createAction)(FETCH_SHARE_BY_ID_SUCCESS),
  getShareByIdFailure: (0, _reduxActions.createAction)(FETCH_SHARE_BY_ID_FAILURE)
};
var selectors = {
  getShareById,
  isLoaded
};
exports.selectors = selectors;

function getShareById(state, id) {
  var shareContainer = state.sharesStore.shares[id];

  if (!shareContainer) {
    return null;
  }

  return shareContainer.data;
}

function isLoaded(state, id) {
  var shareContainer = state.sharesStore.shares[id];

  if (!shareContainer) {
    return false;
  }

  return !!shareContainer.lastLoadedAt;
}

function* watchShares() {
  yield (0, _effects.takeLatest)(FETCH_SHARE_BY_ID, fetchShareById);
}