"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _apiService = _interopRequireDefault(require("./api-service"));

var OfflineService = {
  createOffline(workRequestId, tag, path) {
    var payload = {
      workRequest: workRequestId,
      tag,
      path
    };
    return _apiService.default.client.post('/offlines', payload);
  },

  deleteOffline(data) {
    return _apiService.default.client.delete("/offlines/".concat(data._id));
  },

  update(id, data) {
    return _apiService.default.client.patch("/offlines/".concat(id), data);
  }

};
var _default = OfflineService;
exports.default = _default;