"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _apiService = _interopRequireDefault(require("./api-service"));

var GET_GROUPS_QUERY = "query getGroups($q: String, $groupsId: [ID]) {\n  groups(q: $q, id: $groupsId, limit: 600) {\n  \ttotalCount\n    nodes {\n      value: id\n      label : name\n    }\n  }\n}\n";
var ALL_GROUPS_QUERY = "query getAllGroups($offset: Int, $limit: Int, $search: String) {\n  groups (offset: $offset, limit: $limit, q: $search) {\n  \ttotalCount\n    nodes {\n      id\n      name\n      studios {\n        id\n        name\n        logo\n      }\n      type\n      users {\n        id\n        emailNormal\n        name\n      }\n      addresses\n      domains\n    }\n  }\n}";
var GroupService = {
  getGroups(q, groupsId) {
    return _apiService.default.graphql.request(GET_GROUPS_QUERY, q ? {
      q
    } : {
      groupsId
    });
  },

  /**
   * Given a collection of groups, return the unique addresses within all of those groups
   * @param {Array} groups, e.g. groups collection on a User
   * @returns {Array} Unique addresses from those groups
   */
  getUniqueAddresses(groups) {
    function hashAddress(address) {
      return address.name + address.address + address.city + address.state + address.postalCode + address.country;
    }

    var allAddresses = _lodash.default.flatten(groups, 'addresses');

    _lodash.default.remove(allAddresses, _lodash.default.isUndefined);

    var uniqueAddresses = _lodash.default.uniq(allAddresses, hashAddress);

    return uniqueAddresses;
  },

  getAllGroups(params) {
    if (!params.search) {
      delete params.search;
    }

    return _apiService.default.graphql.request(ALL_GROUPS_QUERY, params).then(res => {
      var _res$groups;

      return (_res$groups = res === null || res === void 0 ? void 0 : res.groups) !== null && _res$groups !== void 0 ? _res$groups : {};
    });
  },

  createGroup(group) {
    return _apiService.default.client.post('/groups', group);
  },

  updateGroup(id, group) {
    return _apiService.default.client.patch("/groups/".concat(id), group);
  },

  patchGroup(group) {
    return _apiService.default.client.patch("/groups/".concat(group.id), group);
  },

  deleteGroup(group) {
    var _group$id;

    return _apiService.default.client.delete("/groups/".concat((_group$id = group === null || group === void 0 ? void 0 : group.id) !== null && _group$id !== void 0 ? _group$id : group));
  }

};
var _default = GroupService;
exports.default = _default;