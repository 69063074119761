"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SpinnerContainer = exports.NotificationList = exports.NoNotificationsMsg = exports.LoadMoreContainer = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _templateObject, _templateObject2, _templateObject3, _templateObject4;

var NotificationList = _styledComponents.default.div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n\tpadding-bottom: 1.5rem;\n\toverflow-y: auto;\n"])));

exports.NotificationList = NotificationList;

var NoNotificationsMsg = _styledComponents.default.div(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n\tpadding: 1rem;\n"])));

exports.NoNotificationsMsg = NoNotificationsMsg;

var SpinnerContainer = _styledComponents.default.div(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["\n\tmargin-bottom: 1.5rem;\n\theight: 4.5rem;\n"])));

exports.SpinnerContainer = SpinnerContainer;

var LoadMoreContainer = _styledComponents.default.div(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)(["\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\tmargin-bottom: 1.5rem;\n\tpadding: 1rem;\n"])));

exports.LoadMoreContainer = LoadMoreContainer;