"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _lodash2 = _interopRequireDefault(require("lodash.get"));

var ErrorService = {
  getErrorMessage: errors => {
    var _error$extensions, _error$extensions2;

    var error = _lodash.default.isArray(errors) ? errors[0] : errors;

    if ((0, _lodash2.default)(error, 'innerError.response.data.message', false)) {
      return error.innerError.response.data.message;
    }

    if (((_error$extensions = error.extensions) === null || _error$extensions === void 0 ? void 0 : _error$extensions.code) === 'BAD_USER_INPUT') {
      var {
        invalidFields
      } = error.extensions;
      var messages = Object.keys(invalidFields).map(field => invalidFields[field]);

      if (messages.length) {
        return _lodash.default.isArray(messages[0]) ? messages[0][0] : messages[0];
      }
    }

    if (error.message !== '') {
      return error.message;
    }

    return (_error$extensions2 = error.extensions) === null || _error$extensions2 === void 0 ? void 0 : _error$extensions2.code;
  }
};
var _default = ErrorService;
exports.default = _default;