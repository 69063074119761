"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UserName = exports.UserEmail = exports.PreferencesButton = exports.MenuContainer = exports.Container = exports.AvatarOverlay = exports.AvatarMenuLink = exports.AvatarMenuLabel = exports.AvatarButtons = exports.AvatarActions = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _polished = require("polished");

var _icon = _interopRequireDefault(require("../icon"));

var _mainMenu = require("../main-menu/main-menu.css");

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10;

var Container = _styledComponents.default.div(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n\tposition: relative;\n\tz-index: 90;\n"])));

exports.Container = Container;
var AvatarMenuLink = (0, _styledComponents.default)(_mainMenu.MenuLinkBtn)(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n\timg {\n\t\tmargin: 0.625rem 1rem;\n\t\tborder-radius: 50%;\n\t\twidth: 2rem;\n\t\theight: 2rem;\n\t}\n"])));
exports.AvatarMenuLink = AvatarMenuLink;
var AvatarMenuLabel = (0, _styledComponents.default)(_mainMenu.MenuLabel)(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["\n\ttransform: translateX(-0.375rem);\n"])));
exports.AvatarMenuLabel = AvatarMenuLabel;

var MenuContainer = _styledComponents.default.div(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)(["\n\tposition: absolute;\n\tbottom: 0;\n\tleft: 100%;\n\ttransform: ", ";\n\ttransform-origin: bottom left;\n\ttransition: transform 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955),\n\t\topacity 100ms ease-in-out,\n\t\tmargin 300ms ease-in-out;\n\topacity: ", ";\n\tz-index: 92;\n\tmargin: ", ";\n\tborder-radius: 0.25rem;\n\tbox-shadow: 0 0.25rem 0.5rem ", ";\n\tbackground: ", ";\n\tpadding: 1rem;\n\tmin-width: 21rem;\n"])), _ref => {
  var {
    isActive
  } = _ref;
  return isActive ? 'scale(1)' : 'scale(0)';
}, _ref2 => {
  var {
    isActive
  } = _ref2;
  return isActive ? 1 : 0;
}, _ref3 => {
  var {
    isActive
  } = _ref3;
  return isActive ? '0.5rem' : '0';
}, _ref4 => {
  var {
    theme
  } = _ref4;
  return (0, _polished.rgba)(theme['black'], 0.25);
}, _ref5 => {
  var {
    theme
  } = _ref5;
  return theme['midnight-lighten-5'];
});

exports.MenuContainer = MenuContainer;

var UserName = _styledComponents.default.h6(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2.default)(["\n\tmargin: 0;\n\tfont-weight: 700;\n"])));

exports.UserName = UserName;

var UserEmail = _styledComponents.default.p(_templateObject6 || (_templateObject6 = (0, _taggedTemplateLiteral2.default)(["\n\tmargin: 0;\n\tfont-size: 0.9rem;\n"])));

exports.UserEmail = UserEmail;

var AvatarActions = _styledComponents.default.div(_templateObject7 || (_templateObject7 = (0, _taggedTemplateLiteral2.default)(["\n\tdisplay: flex;\n\talign-items: flex-end;\n\tjustify-content: space-between;\n\tmargin-top: 1rem;\n"])));

exports.AvatarActions = AvatarActions;

var AvatarButtons = _styledComponents.default.div(_templateObject8 || (_templateObject8 = (0, _taggedTemplateLiteral2.default)(["\n\tbutton {\n\t\t&:not(:last-child) {\n\t\t\tmargin-right: 0.5rem;\n\t\t}\n\t}\n"])));

exports.AvatarButtons = AvatarButtons;
var PreferencesButton = (0, _styledComponents.default)(_icon.default)(_templateObject9 || (_templateObject9 = (0, _taggedTemplateLiteral2.default)(["\n\topacity: 0.5;\n\ttransition: opacity 150ms ease-out;\n\tcursor: pointer;\n\n\t&:hover {\n\t\topacity: 1;\n\t}\n"])));
exports.PreferencesButton = PreferencesButton;

var AvatarOverlay = _styledComponents.default.div(_templateObject10 || (_templateObject10 = (0, _taggedTemplateLiteral2.default)(["\n\tposition: fixed;\n\ttop: 0;\n\tright: 0;\n\tbottom: 0;\n\tleft: ", ";\n\ttransition: opacity 300ms ease-in-out;\n\topacity: ", ";\n\tz-index: 91;\n\tbackground-color: ", ";\n\tpointer-events: ", ";\n"])), _ref6 => {
  var {
    isMaximized
  } = _ref6;
  return isMaximized ? '12rem' : '4rem';
}, _ref7 => {
  var {
    isActive
  } = _ref7;
  return isActive ? 1 : 0;
}, _ref8 => {
  var {
    theme
  } = _ref8;
  return (0, _polished.rgba)(theme['black'], 0.5);
}, _ref9 => {
  var {
    isActive
  } = _ref9;
  return isActive ? 'all' : 'none';
});

exports.AvatarOverlay = AvatarOverlay;