"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actions = exports.STORE_NAME = exports.QUERY_SUCCESS = exports.QUERY_REQUEST = exports.QUERY_FAILURE = exports.QUERY = exports.LOAD_PAGE_SUCCESS = exports.LOAD_PAGE_REQUEST = exports.LOAD_PAGE_FAILURE = exports.LOAD_PAGE = exports.INITIAL_STATE = void 0;
exports.reducer = reducer;
exports.selectors = void 0;
exports.watchWorkRequestsPage = watchWorkRequestsPage;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _lodash = _interopRequireDefault(require("lodash.iserror"));

var _effects = require("redux-saga/effects");

var _reduxActions = require("redux-actions");

var _common = require("./common");

var _workRequestService = _interopRequireDefault(require("../modules/services/work-request-service"));

var _studios = require("./studios");

var _assetTypes = require("./asset-types");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var STORE_NAME = 'workRequestsPageStore';
exports.STORE_NAME = STORE_NAME;

function* loadPageData() {
  try {
    yield (0, _effects.put)(internalActions.loadPageRequest());
    var studiosTask = yield (0, _effects.fork)(_studios.fetchStudios, _studios.actions.getStudios());
    var assetTypesTask = yield (0, _effects.fork)(_assetTypes.fetchAssetTypes, _assetTypes.actions.getAssetTypes());
    var studios = yield (0, _effects.join)(studiosTask);
    var assetTypes = yield (0, _effects.join)(assetTypesTask);

    if ((0, _lodash.default)(studios) || (0, _lodash.default)(assetTypes)) {
      throw new Error('Failed to load page resources');
    }

    yield (0, _effects.put)(internalActions.loadPageSuccess());
  } catch (e) {
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.loadPageFailure({
      error: err
    }));
    return err;
  }
}

function* queryWorkRequests(_ref) {
  var {
    payload
  } = _ref;

  try {
    var {
      query,
      page,
      limit
    } = payload;
    yield (0, _effects.put)(internalActions.queryRequest({
      query,
      page
    }));
    var pagedResult = yield _workRequestService.default.getReport(query, page - 1, limit);
    yield (0, _effects.put)(internalActions.querySuccess({
      pagedResult
    }));
  } catch (e) {
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.queryFailure({
      error: err
    }));
    return err;
  }
}

var LOAD_PAGE = 'work-requests-page.load-page';
exports.LOAD_PAGE = LOAD_PAGE;
var LOAD_PAGE_REQUEST = 'work-requests-page.load-page.request';
exports.LOAD_PAGE_REQUEST = LOAD_PAGE_REQUEST;
var LOAD_PAGE_SUCCESS = 'work-requests-page.load-page.success';
exports.LOAD_PAGE_SUCCESS = LOAD_PAGE_SUCCESS;
var LOAD_PAGE_FAILURE = 'work-requests-page.load-page.failure';
exports.LOAD_PAGE_FAILURE = LOAD_PAGE_FAILURE;
var QUERY = 'work-requests-page.query';
exports.QUERY = QUERY;
var QUERY_REQUEST = 'work-requests-page.query.request';
exports.QUERY_REQUEST = QUERY_REQUEST;
var QUERY_SUCCESS = 'work-requests-page.query.success';
exports.QUERY_SUCCESS = QUERY_SUCCESS;
var QUERY_FAILURE = 'work-requests-page.query.failure';
exports.QUERY_FAILURE = QUERY_FAILURE;
var INITIAL_STATE = {
  currentPage: 1,
  totalPages: 1,
  isLoading: false,
  loadError: null,
  isQuerying: false,
  query: {},
  queryResults: [],
  queryError: null
};
exports.INITIAL_STATE = INITIAL_STATE;

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case LOAD_PAGE_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          isLoading: true,
          loadError: null,
          queryResults: []
        });
      }

    case LOAD_PAGE_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          isLoading: false,
          loadError: null
        });
      }

    case LOAD_PAGE_FAILURE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          isLoading: false,
          loadError: action.payload
        });
      }

    case QUERY_REQUEST:
      {
        var {
          query,
          page
        } = action.payload; // Leave old query results so that the visuals aren't flashing

        return _objectSpread(_objectSpread({}, state), {}, {
          currentPage: page,
          isQuerying: true,
          query: query,
          queryError: null
        });
      }

    case QUERY_SUCCESS:
      {
        var {
          pagedResult
        } = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          isQuerying: false,
          queryResults: pagedResult.results,
          totalPages: pagedResult.totalPages
        });
      }

    case QUERY_FAILURE:
      {
        var {
          error
        } = action.payload; // If an error occurs, clear old results that we left around
        // during the load. View should be cleared anyways with an error.

        return _objectSpread(_objectSpread({}, state), {}, {
          isQuerying: false,
          queryResults: [],
          queryError: error
        });
      }

    default:
      return state;
  }
}

var actions = {
  loadPageData: (0, _reduxActions.createAction)(LOAD_PAGE),
  queryWorkRequests: (0, _reduxActions.createAction)(QUERY)
};
/**
 * Actions that should only be invoked internally
 */

exports.actions = actions;
var internalActions = {
  loadPageRequest: (0, _reduxActions.createAction)(LOAD_PAGE_REQUEST),
  loadPageSuccess: (0, _reduxActions.createAction)(LOAD_PAGE_SUCCESS),
  loadPageFailure: (0, _reduxActions.createAction)(LOAD_PAGE_FAILURE),
  queryRequest: (0, _reduxActions.createAction)(QUERY_REQUEST),
  querySuccess: (0, _reduxActions.createAction)(QUERY_SUCCESS),
  queryFailure: (0, _reduxActions.createAction)(QUERY_FAILURE)
};
var selectors = {
  isReady,
  isLoading: (0, _common.createSelector)(STORE_NAME, isLoading),
  didLoadFail: (0, _common.createSelector)(STORE_NAME, didLoadFail),
  isQuerying: (0, _common.createSelector)(STORE_NAME, isQuerying),
  didQueryFail: (0, _common.createSelector)(STORE_NAME, didQueryFail),
  getQueryResults: (0, _common.createSelector)(STORE_NAME, getQueryResults),
  getPagination: (0, _common.createSelector)(STORE_NAME, getPagination)
};
exports.selectors = selectors;

function isReady(state) {
  return _studios.selectors.isLoaded(state) && _assetTypes.selectors.isLoaded(state);
}

function isLoading(state) {
  return state.isLoading;
}

function didLoadFail(state) {
  return !!state.loadError;
}

function isQuerying(state) {
  return state.isQuerying;
}

function didQueryFail(state) {
  return !!state.queryError;
}

function getQueryResults(state) {
  return state.queryResults;
}

function getPagination(state) {
  var {
    currentPage,
    totalPages
  } = state;
  return {
    currentPage,
    totalPages
  };
}

function* watchWorkRequestsPage() {
  yield (0, _effects.takeLeading)(LOAD_PAGE, loadPageData);
  yield (0, _effects.takeLatest)(QUERY, queryWorkRequests);
}