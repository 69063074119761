"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = startForman;

var _watchers = require("./watchers");

var _effects = require("redux-saga/effects");

function* startForman() {
  yield (0, _effects.fork)(_watchers.rootSaga);
}