"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actions = exports.STORE_NAME = exports.MARK_UNREAD_NOTIFICATIONS_SUCCESS = exports.MARK_UNREAD_NOTIFICATIONS_REQUEST = exports.MARK_UNREAD_NOTIFICATIONS_FAILURE = exports.MARK_UNREAD_NOTIFICATIONS = exports.MARK_READ_NOTIFICATIONS_SUCCESS = exports.MARK_READ_NOTIFICATIONS_REQUEST = exports.MARK_READ_NOTIFICATIONS_FAILURE = exports.MARK_READ_NOTIFICATIONS = exports.MARK_ALL_READ_NOTIFICATIONS_SUCCESS = exports.MARK_ALL_READ_NOTIFICATIONS_REQUEST = exports.MARK_ALL_READ_NOTIFICATIONS_FAILURE = exports.MARK_ALL_READ_NOTIFICATIONS = exports.MANUAL_UPDATE_NOTIFICATIONS = exports.INITIAL_STATE = exports.FETCH_NEW_NOTIFICATIONS_SUCCESS = exports.FETCH_NEW_NOTIFICATIONS_REQUEST = exports.FETCH_NEW_NOTIFICATIONS_FAILURE = exports.FETCH_NEW_NOTIFICATIONS = exports.FETCH_MORE_NOTIFICATIONS_SUCCESS = exports.FETCH_MORE_NOTIFICATIONS_REQUEST = exports.FETCH_MORE_NOTIFICATIONS_FAILURE = exports.FETCH_MORE_NOTIFICATIONS = void 0;
exports.reducer = reducer;
exports.selectors = void 0;
exports.watchNotifications = watchNotifications;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _lodash = _interopRequireDefault(require("lodash"));

var _lodash2 = _interopRequireDefault(require("lodash.get"));

var _reduxActions = require("redux-actions");

var _effects = require("redux-saga/effects");

var _common = require("./common");

var _notificationService = _interopRequireDefault(require("../modules/services/notification-service"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var STORE_NAME = 'notificationsStore';
exports.STORE_NAME = STORE_NAME;
var DEFAULT_CONTAINER = {
  isError: false,
  isUpdating: false,
  summary: {},
  notificationsByType: {
    workRequest: {
      data: [],
      isLoadingMore: false,
      isLoadingNew: false,
      pagination: {}
    },
    embargo: {
      data: [],
      isLoadingMore: false,
      isLoadingNew: false,
      pagination: {}
    },
    other: {
      data: [],
      isLoadingMore: false,
      isLoadingNew: false,
      pagination: {}
    }
  },
  filters: {
    type: 'workRequest',
    q: '',
    read: 0
  }
};
var PAGE_SIZE = 70;

function* markRead(_ref) {
  var {
    payload
  } = _ref;
  var {
    type,
    notification
  } = payload;
  var summary = yield (0, _effects.select)(state => (0, _lodash2.default)(state[STORE_NAME], ['summary', type], {}));
  notification.read = !notification.read;
  notification.viewed = !notification.viewed;
  summary.unread = --summary.unread;

  try {
    yield (0, _effects.put)(internalActions.markReadNotificationsRequest({
      type,
      notification
    }));
    yield _notificationService.default.markRead(notification._id);
    yield (0, _effects.put)(internalActions.markReadNotificationsSuccess({
      type,
      notification
    }));
  } catch (e) {
    notification.read = !notification.read;
    notification.viewed = !notification.viewed;
    summary.unread = ++summary.unread;
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.markReadNotificationsFailure({
      notification,
      err
    }));
    return err;
  }
}

function* markUnread(_ref2) {
  var {
    payload
  } = _ref2;
  var {
    type,
    notification
  } = payload;
  var summary = yield (0, _effects.select)(state => (0, _lodash2.default)(state[STORE_NAME], ['summary', type], {}));
  notification.read = !notification.read;
  notification.viewed = !notification.viewed;
  summary.unread = ++summary.unread;

  try {
    yield (0, _effects.put)(internalActions.markUnreadNotificationsRequest({
      type,
      notification
    }));
    yield _notificationService.default.markUnread(notification._id);
    yield (0, _effects.put)(internalActions.markUnreadNotificationsSuccess({
      type,
      notification
    }));
  } catch (e) {
    notification.read = !notification.read;
    notification.viewed = !notification.viewed;
    summary.unread = --summary.unread;
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.markUnreadNotificationsFailure({
      notification,
      err
    }));
    return err;
  }
}

function* markAllRead(_ref3) {
  var {
    payload
  } = _ref3;
  var {
    type
  } = payload;
  var summary = yield (0, _effects.select)(state => (0, _lodash2.default)(state[STORE_NAME], ['summary', type], {}));
  var oldUnread = summary.unread;
  summary.unread = 0;

  try {
    yield (0, _effects.put)(internalActions.markAllReadNotificationsRequest({
      type
    }));
    yield _notificationService.default.markAllRead(type);
    yield (0, _effects.put)(internalActions.markAllReadNotificationsSuccess({
      type
    }));
  } catch (e) {
    summary.unread = oldUnread;
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.markAllReadNotificationsFailure({
      err
    }));
    return err;
  }
}

function* fetchNewNotifications(_ref4) {
  var {
    payload
  } = _ref4;
  var {
    filters
  } = payload; // Check if we're already fetching new notifications for this type

  var isLoadingNew = yield (0, _effects.select)(selectors.isLoadingNew);

  if (isLoadingNew) {
    return;
  } // Get container, get range params


  var pagination = yield (0, _effects.select)(state => (0, _lodash2.default)(state[STORE_NAME], ['notificationsByType', filters.type, 'pagination'], null));
  var start = 0;
  var end = PAGE_SIZE;

  if (pagination) {
    end = pagination.newCount;
  }

  if (end - start <= 0) {
    return;
  }

  try {
    yield (0, _effects.put)(internalActions.loadNewNotificationsRequest({
      filters
    }));
    var summary = yield _notificationService.default.getSummary();
    var pagedResults = yield _notificationService.default.getPagedNotifications(filters, {
      limit: end
    });
    var {
      results: notifications,
      pagination: _pagination
    } = pagedResults;
    yield (0, _effects.put)(internalActions.loadNewNotificationsSuccess({
      summary,
      filters,
      notifications,
      pagination: _pagination
    }));
  } catch (e) {
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.loadNewNotificationsFailure({
      filters,
      err
    }));
    return err;
  }
}

function* fetchMoreNotifications(_ref5) {
  var {
    payload
  } = _ref5;
  var {
    filters
  } = payload; // Check if we're already fetching new notifications for this type

  var isLoadingNew = yield (0, _effects.select)(selectors.isLoadingNew, filters.type);

  if (isLoadingNew) {
    return;
  } // Get container, get range params


  var currentPage = yield (0, _effects.select)(state => (0, _lodash2.default)(state[STORE_NAME], ['notificationsByType', filters.type, 'pagination', 'currentPage'], -1));

  try {
    yield (0, _effects.put)(internalActions.loadMoreNotificationsRequest({
      filters,
      currentPage
    }));
    var summary = yield _notificationService.default.getSummary();

    if (!summary[filters.type]) {
      return;
    }

    var pagedResults = yield _notificationService.default.getPagedNotifications(filters, {
      page: currentPage + 1,
      limit: PAGE_SIZE
    });
    var {
      results: notifications,
      pagination
    } = pagedResults;
    yield (0, _effects.put)(internalActions.loadMoreNotificationsSuccess({
      summary,
      filters,
      notifications,
      pagination
    }));
  } catch (e) {
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.loadMoreNotificationsFailure({
      filters,
      err
    }));
    return err;
  }
}

var MANUAL_UPDATE_NOTIFICATIONS = 'notifications.manual-update';
exports.MANUAL_UPDATE_NOTIFICATIONS = MANUAL_UPDATE_NOTIFICATIONS;
var FETCH_NEW_NOTIFICATIONS = 'notifications.fetch-new';
exports.FETCH_NEW_NOTIFICATIONS = FETCH_NEW_NOTIFICATIONS;
var FETCH_NEW_NOTIFICATIONS_REQUEST = 'notifications.fetch-new.request';
exports.FETCH_NEW_NOTIFICATIONS_REQUEST = FETCH_NEW_NOTIFICATIONS_REQUEST;
var FETCH_NEW_NOTIFICATIONS_SUCCESS = 'notifications.fetch-new.success';
exports.FETCH_NEW_NOTIFICATIONS_SUCCESS = FETCH_NEW_NOTIFICATIONS_SUCCESS;
var FETCH_NEW_NOTIFICATIONS_FAILURE = 'notifications.fetch-new.failure';
exports.FETCH_NEW_NOTIFICATIONS_FAILURE = FETCH_NEW_NOTIFICATIONS_FAILURE;
var FETCH_MORE_NOTIFICATIONS = 'notifications.fetch-more';
exports.FETCH_MORE_NOTIFICATIONS = FETCH_MORE_NOTIFICATIONS;
var FETCH_MORE_NOTIFICATIONS_REQUEST = 'notifications.fetch-more.request';
exports.FETCH_MORE_NOTIFICATIONS_REQUEST = FETCH_MORE_NOTIFICATIONS_REQUEST;
var FETCH_MORE_NOTIFICATIONS_SUCCESS = 'notifications.fetch-more.success';
exports.FETCH_MORE_NOTIFICATIONS_SUCCESS = FETCH_MORE_NOTIFICATIONS_SUCCESS;
var FETCH_MORE_NOTIFICATIONS_FAILURE = 'notifications.fetch-more.failure';
exports.FETCH_MORE_NOTIFICATIONS_FAILURE = FETCH_MORE_NOTIFICATIONS_FAILURE;
var MARK_READ_NOTIFICATIONS = 'notifications.mark-read';
exports.MARK_READ_NOTIFICATIONS = MARK_READ_NOTIFICATIONS;
var MARK_READ_NOTIFICATIONS_REQUEST = 'notifications.mark-read.request';
exports.MARK_READ_NOTIFICATIONS_REQUEST = MARK_READ_NOTIFICATIONS_REQUEST;
var MARK_READ_NOTIFICATIONS_SUCCESS = 'notifications.mark-read.success';
exports.MARK_READ_NOTIFICATIONS_SUCCESS = MARK_READ_NOTIFICATIONS_SUCCESS;
var MARK_READ_NOTIFICATIONS_FAILURE = 'notifications.mark-read.failure';
exports.MARK_READ_NOTIFICATIONS_FAILURE = MARK_READ_NOTIFICATIONS_FAILURE;
var MARK_UNREAD_NOTIFICATIONS = 'notifications.mark-unread';
exports.MARK_UNREAD_NOTIFICATIONS = MARK_UNREAD_NOTIFICATIONS;
var MARK_UNREAD_NOTIFICATIONS_REQUEST = 'notifications.mark-unread.request';
exports.MARK_UNREAD_NOTIFICATIONS_REQUEST = MARK_UNREAD_NOTIFICATIONS_REQUEST;
var MARK_UNREAD_NOTIFICATIONS_SUCCESS = 'notifications.mark-unread.success';
exports.MARK_UNREAD_NOTIFICATIONS_SUCCESS = MARK_UNREAD_NOTIFICATIONS_SUCCESS;
var MARK_UNREAD_NOTIFICATIONS_FAILURE = 'notifications.mark-unread.failure';
exports.MARK_UNREAD_NOTIFICATIONS_FAILURE = MARK_UNREAD_NOTIFICATIONS_FAILURE;
var MARK_ALL_READ_NOTIFICATIONS = 'notifications.mark-all-read';
exports.MARK_ALL_READ_NOTIFICATIONS = MARK_ALL_READ_NOTIFICATIONS;
var MARK_ALL_READ_NOTIFICATIONS_REQUEST = 'notifications.mark-all-read.request';
exports.MARK_ALL_READ_NOTIFICATIONS_REQUEST = MARK_ALL_READ_NOTIFICATIONS_REQUEST;
var MARK_ALL_READ_NOTIFICATIONS_SUCCESS = 'notifications.mark-all-read.success';
exports.MARK_ALL_READ_NOTIFICATIONS_SUCCESS = MARK_ALL_READ_NOTIFICATIONS_SUCCESS;
var MARK_ALL_READ_NOTIFICATIONS_FAILURE = 'notifications.mark-all-read.failure';
exports.MARK_ALL_READ_NOTIFICATIONS_FAILURE = MARK_ALL_READ_NOTIFICATIONS_FAILURE;
var INITIAL_STATE = {
  notificationsByType: {}
};
exports.INITIAL_STATE = INITIAL_STATE;

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case MARK_ALL_READ_NOTIFICATIONS_REQUEST:
      {
        var {
          type
        } = action.payload;
        var oldNotifications = (0, _lodash2.default)(state, ['notificationsByType', type, 'data'], []);
        var notifications = oldNotifications.map(n => {
          n.read = true;
          return n;
        });
        return _objectSpread(_objectSpread({}, state), {}, {
          isUpdating: true,
          notificationsByType: _objectSpread(_objectSpread({}, state.notificationsByType), {}, {
            [type]: {
              data: notifications
            }
          })
        });
      }

    case MARK_ALL_READ_NOTIFICATIONS_SUCCESS:
      {
        var {
          type: _type
        } = action.payload;

        var _oldNotifications = (0, _lodash2.default)(state, ['notificationsByType', _type, 'data'], []);

        var _notifications = _oldNotifications.map(n => {
          n.read = true;
          return n;
        });

        return _objectSpread(_objectSpread({}, state), {}, {
          isUpdating: false,
          notificationsByType: _objectSpread(_objectSpread({}, state.notificationsByType), {}, {
            [_type]: {
              data: _notifications
            }
          })
        });
      }

    case MARK_ALL_READ_NOTIFICATIONS_FAILURE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          isError: true,
          isUpdating: false,
          notificationsByType: {
            [state.filters.type]: {
              data: []
            }
          }
        });
      }

    case MARK_READ_NOTIFICATIONS_REQUEST:
      {
        return _objectSpread({}, state);
      }

    case MARK_READ_NOTIFICATIONS_SUCCESS:
      {
        return _objectSpread({}, state);
      }

    case MARK_READ_NOTIFICATIONS_FAILURE:
      {
        return _objectSpread({}, state);
      }

    case MARK_UNREAD_NOTIFICATIONS_REQUEST:
      {
        return _objectSpread({}, state);
      }

    case MARK_UNREAD_NOTIFICATIONS_SUCCESS:
      {
        return _objectSpread({}, state);
      }

    case MARK_UNREAD_NOTIFICATIONS_FAILURE:
      {
        return _objectSpread({}, state);
      }

    case MANUAL_UPDATE_NOTIFICATIONS:
      {
        var {
          type: _type2,
          notification
        } = action.payload;
        var data = (0, _lodash2.default)(state, ['notificationsByType', _type2, 'data'], []);
        data.unshift(notification);
        var oldSummary = (0, _lodash2.default)(state, ['summary'], {});

        var summary = _lodash.default.clone(oldSummary);

        if (oldSummary[_type2]) {
          summary[_type2].unread = ++oldSummary[_type2].unread;
        }

        return _objectSpread(_objectSpread({}, state), {}, {
          summary,
          notificationsByType: _objectSpread(_objectSpread({}, state.notificationsByType), {}, {
            [_type2]: _objectSpread(_objectSpread({}, state.notificationsByType[_type2]), {}, {
              data
            })
          })
        });
      }

    case FETCH_NEW_NOTIFICATIONS_REQUEST:
      {
        var {
          filters
        } = action.payload;
        var container = DEFAULT_CONTAINER;
        return _objectSpread(_objectSpread(_objectSpread({}, container), state), {}, {
          filters,
          isError: false,
          notificationsByType: _objectSpread(_objectSpread({}, state.notificationsByType), {}, {
            [filters.type]: _objectSpread(_objectSpread({}, state.notificationsByType[filters.type]), {}, {
              isLoadingNew: true,
              pagination: {
                isLoadingMore: false
              }
            })
          })
        });
      }

    case FETCH_NEW_NOTIFICATIONS_SUCCESS:
      {
        var {
          summary: _summary,
          filters: _filters,
          notifications: _notifications2,
          pagination
        } = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          filters: _filters,
          summary: _summary,
          notificationsByType: _objectSpread(_objectSpread({}, state.notificationsByType), {}, {
            [_filters.type]: {
              data: [..._notifications2],
              isLoadingNew: false,
              pagination: {
                isLoadingMore: false,
                hasMore: (pagination.page + 1) * PAGE_SIZE < pagination.total,
                currentPage: pagination.page,
                total: pagination.total
              }
            }
          })
        });
      }

    case FETCH_NEW_NOTIFICATIONS_FAILURE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          isError: true,
          notificationsByType: _objectSpread(_objectSpread({}, state.notificationsByType), {}, {
            [state.filters.type]: _objectSpread(_objectSpread({}, state.notificationsByType[state.filters.type]), {}, {
              isLoadingNew: false,
              data: []
            })
          })
        });
      }

    case FETCH_MORE_NOTIFICATIONS_REQUEST:
      {
        var {
          filters: _filters2,
          currentPage
        } = action.payload;
        var _container = DEFAULT_CONTAINER;
        return _objectSpread(_objectSpread(_objectSpread({}, _container), state), {}, {
          filters: _filters2,
          notificationsByType: _objectSpread(_objectSpread({}, state.notificationsByType), {}, {
            [_filters2.type]: _objectSpread(_objectSpread({}, state.notificationsByType[_filters2.type]), {}, {
              isLoadingNew: !currentPage,
              pagination: {
                isLoadingMore: true
              }
            })
          })
        });
      }

    case FETCH_MORE_NOTIFICATIONS_SUCCESS:
      {
        var {
          summary: _summary2,
          filters: _filters3,
          notifications: _notifications3,
          pagination: _pagination2
        } = action.payload;

        var _oldNotifications2 = (0, _lodash2.default)(state, ['notificationsByType', _filters3.type, 'data'], []);

        return _objectSpread(_objectSpread({}, state), {}, {
          filters: _filters3,
          summary: _summary2,
          notificationsByType: _objectSpread(_objectSpread({}, state.notificationsByType), {}, {
            [_filters3.type]: {
              data: [..._oldNotifications2, ..._notifications3],
              isLoadingNew: false,
              pagination: {
                isLoadingMore: false,
                hasMore: (_pagination2.page + 1) * PAGE_SIZE < _pagination2.total,
                currentPage: _pagination2.page,
                total: _pagination2.total
              }
            }
          })
        });
      }

    case FETCH_MORE_NOTIFICATIONS_FAILURE:
      {
        var {
          filters: _filters4
        } = action.payload;

        var _container2 = (0, _lodash2.default)(state, ['notificationsByType', _filters4.type], DEFAULT_CONTAINER);

        return _objectSpread(_objectSpread({}, state), {}, {
          isError: true,
          notificationsByType: _objectSpread(_objectSpread({}, state.notificationsByType), {}, {
            [_filters4.type]: _objectSpread(_objectSpread({}, _container2), {}, {
              isLoadingNew: false,
              isLoadingMore: false,
              data: []
            })
          })
        });
      }

    default:
      return state;
  }
}

var actions = {
  manualUpdateNotifications: (0, _reduxActions.createAction)(MANUAL_UPDATE_NOTIFICATIONS),
  loadNewNotifications: (0, _reduxActions.createAction)(FETCH_NEW_NOTIFICATIONS),
  loadMoreNotifications: (0, _reduxActions.createAction)(FETCH_MORE_NOTIFICATIONS),
  markReadNotifications: (0, _reduxActions.createAction)(MARK_READ_NOTIFICATIONS),
  markUnreadNotifications: (0, _reduxActions.createAction)(MARK_UNREAD_NOTIFICATIONS),
  markAllReadNotifications: (0, _reduxActions.createAction)(MARK_ALL_READ_NOTIFICATIONS)
};
/**
 * Actions that should only be invoked internally
 */

exports.actions = actions;
var internalActions = {
  markAllReadNotificationsRequest: (0, _reduxActions.createAction)(MARK_ALL_READ_NOTIFICATIONS_REQUEST),
  markAllReadNotificationsSuccess: (0, _reduxActions.createAction)(MARK_ALL_READ_NOTIFICATIONS_SUCCESS),
  markAllReadNotificationsFailure: (0, _reduxActions.createAction)(MARK_ALL_READ_NOTIFICATIONS_FAILURE),
  markUnreadNotificationsRequest: (0, _reduxActions.createAction)(MARK_UNREAD_NOTIFICATIONS_REQUEST),
  markUnreadNotificationsSuccess: (0, _reduxActions.createAction)(MARK_UNREAD_NOTIFICATIONS_SUCCESS),
  markUnreadNotificationsFailure: (0, _reduxActions.createAction)(MARK_UNREAD_NOTIFICATIONS_FAILURE),
  markReadNotificationsRequest: (0, _reduxActions.createAction)(MARK_READ_NOTIFICATIONS_REQUEST),
  markReadNotificationsSuccess: (0, _reduxActions.createAction)(MARK_READ_NOTIFICATIONS_SUCCESS),
  markReadNotificationsFailure: (0, _reduxActions.createAction)(MARK_READ_NOTIFICATIONS_FAILURE),
  loadNewNotificationsRequest: (0, _reduxActions.createAction)(FETCH_NEW_NOTIFICATIONS_REQUEST),
  loadNewNotificationsSuccess: (0, _reduxActions.createAction)(FETCH_NEW_NOTIFICATIONS_SUCCESS),
  loadNewNotificationsFailure: (0, _reduxActions.createAction)(FETCH_NEW_NOTIFICATIONS_FAILURE),
  loadMoreNotificationsRequest: (0, _reduxActions.createAction)(FETCH_MORE_NOTIFICATIONS_REQUEST),
  loadMoreNotificationsSuccess: (0, _reduxActions.createAction)(FETCH_MORE_NOTIFICATIONS_SUCCESS),
  loadMoreNotificationsFailure: (0, _reduxActions.createAction)(FETCH_MORE_NOTIFICATIONS_FAILURE)
};
var selectors = {
  getNotifications: (0, _common.createSelector)(STORE_NAME, getNotifications),
  getNotificationsCount: (0, _common.createSelector)(STORE_NAME, getNotificationsCount),
  isLoadingNew: (0, _common.createSelector)(STORE_NAME, isLoadingNew),
  isLoadingMore: (0, _common.createSelector)(STORE_NAME, isLoadingMore),
  hasMore: (0, _common.createSelector)(STORE_NAME, hasMore),
  hasUnread: (0, _common.createSelector)(STORE_NAME, hasUnread),
  isUpdating: (0, _common.createSelector)(STORE_NAME, isUpdating),
  isError: (0, _common.createSelector)(STORE_NAME, isLoadingError),
  getSummary: (0, _common.createSelector)(STORE_NAME, getSummary)
};
exports.selectors = selectors;

function getNotifications(state) {
  var container = _objectSpread(_objectSpread({}, DEFAULT_CONTAINER), state);

  var type = container.filters.type || 'workRequest';
  var filters = container.filters.read === 0 ? {
    read: false
  } : null;
  return _lodash.default.filter((0, _lodash2.default)(state, ['notificationsByType', type, 'data'], []), filters);
}

function getSummary(state) {
  var container = _objectSpread(_objectSpread({}, DEFAULT_CONTAINER), state);

  return (0, _lodash2.default)(state, ['summary'], container.summary);
}

function getNotificationsCount(state, type) {
  return (0, _lodash2.default)(state, ['notificationsByType', type, 'total'], 0);
}

function isLoadingNew(state) {
  var container = _objectSpread(_objectSpread({}, DEFAULT_CONTAINER), state);

  return (0, _lodash2.default)(state, ['notificationsByType', container.filters.type, 'isLoadingNew'], false);
}

function isLoadingMore(state) {
  var container = _objectSpread(_objectSpread({}, DEFAULT_CONTAINER), state);

  return (0, _lodash2.default)(state, ['notificationsByType', container.filters.type, 'pagination', 'isLoadingMore'], false);
}

function hasMore(state) {
  var container = _objectSpread(_objectSpread({}, DEFAULT_CONTAINER), state);

  return (0, _lodash2.default)(state, ['notificationsByType', container.filters.type, 'pagination', 'hasMore'], true);
}

function hasUnread(state) {
  return ['workRequest', 'embargo', 'other'].map(type => {
    return (0, _lodash2.default)(state, ['summary', type, 'unread'], 0);
  }).some(num => num > 0);
}

function isUpdating(state) {
  return (0, _lodash2.default)(state, ['isUpdating'], false);
}

function isLoadingError(state) {
  return (0, _lodash2.default)(state, ['isError'], false);
}

function* watchNotifications() {
  yield (0, _effects.takeEvery)(FETCH_NEW_NOTIFICATIONS, fetchNewNotifications);
  yield (0, _effects.takeEvery)(FETCH_MORE_NOTIFICATIONS, fetchMoreNotifications);
  yield (0, _effects.takeEvery)(MARK_READ_NOTIFICATIONS, markRead);
  yield (0, _effects.takeEvery)(MARK_UNREAD_NOTIFICATIONS, markUnread);
  yield (0, _effects.takeEvery)(MARK_ALL_READ_NOTIFICATIONS, markAllRead);
}