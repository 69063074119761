"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _apiService = _interopRequireDefault(require("./api-service"));

var _lodash = _interopRequireDefault(require("lodash"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var SessionService = {
  getSession() {
    return _apiService.default.client.get('/session').then(res => {
      return _objectSpread(_objectSpread({}, res.data), {}, {
        studioLogo: () => {
          var _res$data$studios;

          if (((_res$data$studios = res.data.studios) === null || _res$data$studios === void 0 ? void 0 : _res$data$studios.length) === 1) {
            return res.data.studios[0].$links.logo;
          }

          return '/img/pixwel.logo.png';
        },
        isAdmin: () => {
          return _lodash.default.chain(res.data.groups).pluck('type').contains('a').value();
        }
      });
    });
  },

  resetPassword(_ref) {
    var {
      email,
      url
    } = _ref;
    return _apiService.default.client.post('/password-reset', {
      email: email,
      url: decodeURIComponent(url)
    });
  },

  twoFactorLogin(_ref2) {
    var {
      token
    } = _ref2;
    return _apiService.default.client.post('/session', {
      token: token
    });
  }

};
var _default = SessionService;
exports.default = _default;