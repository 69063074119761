"use strict";

require("~/app/3x/scss/pixwel.scss");

require("~/node_modules/video.js/dist/video-js.css");

require("~/node_modules/react-dates/lib/css/_datepicker.css");

require("~/node_modules/rc-tooltip/assets/bootstrap.css");

require("~/node_modules/react-circular-progressbar/dist/styles.css");

require("~/node_modules/react-toastify/dist/ReactToastify.css");