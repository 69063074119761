"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _lodash2 = _interopRequireDefault(require("lodash.get"));

var _moment = _interopRequireDefault(require("moment"));

var EmbargoService = {
  getEmbargoStatus(item) {
    var when = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : (0, _moment.default)();
    var embargo = (0, _lodash2.default)(item, 'embargo', {
      end: 0,
      start: 0
    }); // Some old assets user a single number for embargo, transform it to new schema

    if (_lodash.default.isNumber(embargo)) {
      embargo = {
        end: embargo,
        start: 0
      };
    }

    var embargoEnd = (0, _lodash2.default)(embargo, 'end', 0); // If not embargo is set, the file is not locked

    if (!embargoEnd) {
      return {
        isLocked: false,
        willLock: false
      };
    }

    var embargoStart = (0, _lodash2.default)(embargo, 'start', 0);
    var isAfterEmbargoEnd = when.isAfter((0, _moment.default)(embargoEnd * 1000));
    var willRelock = !!embargoStart; // If an embargo is set and we are not passed the date of embargo end, the file is locked

    if (!isAfterEmbargoEnd) {
      return {
        isLocked: true,
        willUnlock: true,
        willUnlockOn: embargoEnd
      };
    } // If the embargo period is over and the file is not set to relock, the file is not locked


    if (isAfterEmbargoEnd && !willRelock) {
      return {
        isLocked: false,
        willLock: false
      };
    }

    var isBeforeEmbargoStart = when.isBefore((0, _moment.default)(embargoStart * 1000)); // If the date is before the file is set to relock, the file is not locked

    if (isBeforeEmbargoStart) {
      return {
        isLocked: false,
        willLock: true,
        willLockOn: embargoStart
      };
    } // The file is locked indefinitely


    return {
      isLocked: true,
      willUnlock: false
    };
  }

};
var _default = EmbargoService;
exports.default = _default;