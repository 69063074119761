"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actions = exports.STORE_NAME = exports.INITIAL_STATE = exports.FETCH_TRANSLATION_MEMORIES_SUCCESS = exports.FETCH_TRANSLATION_MEMORIES_REQUEST = exports.FETCH_TRANSLATION_MEMORIES_FAILURE = exports.FETCH_TRANSLATION_MEMORIES = exports.FETCH_TRANSLATION_LANGUAGES_SUCCESS = exports.FETCH_TRANSLATION_LANGUAGES_REQUEST = exports.FETCH_TRANSLATION_LANGUAGES_FAILURE = exports.FETCH_TRANSLATION_LANGUAGES = exports.FETCH_TRANSLATION_BY_ID_SUCCESS = exports.FETCH_TRANSLATION_BY_ID_REQUEST = exports.FETCH_TRANSLATION_BY_ID_FAILURE = exports.FETCH_TRANSLATION_BY_ID = exports.FETCH_MACHINE_TRANSLATIONS_SUCCESS = exports.FETCH_MACHINE_TRANSLATIONS_REQUEST = exports.FETCH_MACHINE_TRANSLATIONS_FAILURE = exports.FETCH_MACHINE_TRANSLATIONS = void 0;
exports.fetchMachineTranslations = fetchMachineTranslations;
exports.fetchTranslationById = fetchTranslationById;
exports.fetchTranslationLanguages = fetchTranslationLanguages;
exports.fetchTranslationMemories = fetchTranslationMemories;
exports.internalActions = void 0;
exports.reducer = reducer;
exports.selectors = void 0;
exports.watchTranslations = watchTranslations;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _lodash = _interopRequireDefault(require("lodash.get"));

var _reduxActions = require("redux-actions");

var _effects = require("redux-saga/effects");

var _common = require("./common");

var _translationService = _interopRequireDefault(require("../modules/services/translation-service"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var STORE_NAME = 'translationStore';
exports.STORE_NAME = STORE_NAME;

function* fetchTranslationById(_ref) {
  var {
    payload: id
  } = _ref;

  try {
    yield (0, _effects.put)(internalActions.getTranslationByIdRequest(id));
    var translation = yield _translationService.default.getById(id);

    if (translation === null) {
      throw new Error('Failed to load translation');
    }

    yield (0, _effects.put)(internalActions.getTranslationByIdSuccess(translation));
    return translation;
  } catch (e) {
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.getTranslationByIdFailure({
      id,
      err
    }));
    return err;
  }
}

function* fetchTranslationMemories(_ref2) {
  var {
    payload
  } = _ref2;
  var {
    id,
    language
  } = payload;

  try {
    yield (0, _effects.put)(internalActions.getTranslationMemoriesRequest({
      id,
      language
    }));
    var translationMemories = yield _translationService.default.getTranslationMemories(id, language);

    if (translationMemories === null) {
      throw new Error('Failed to load translation memories');
    }

    yield (0, _effects.put)(internalActions.getTranslationMemoriesSuccess({
      id,
      language,
      translationMemories
    }));
    return translationMemories;
  } catch (e) {
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.getTranslationMemoriesFailure({
      id,
      language,
      err
    }));
    return err;
  }
}

function* fetchMachineTranslations(_ref3) {
  var {
    payload
  } = _ref3;
  var {
    id,
    language
  } = payload;

  try {
    yield (0, _effects.put)(internalActions.getMachineTranslationsRequest({
      id,
      language
    }));
    var machineTranslations = yield _translationService.default.getMachineTranslations(id, language);

    if (machineTranslations === null) {
      throw new Error('Failed to load machine translations');
    }

    yield (0, _effects.put)(internalActions.getMachineTranslationsSuccess({
      id,
      language,
      machineTranslations
    }));
    return machineTranslations;
  } catch (e) {
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.getMachineTranslationsFailure({
      id,
      language,
      err
    }));
    return err;
  }
}

function* fetchTranslationLanguages() {
  try {
    yield (0, _effects.put)(internalActions.getTranslationLanguagesRequest());
    var translationLanguages = yield _translationService.default.getTranslationLanguages();

    if (translationLanguages === null) {
      throw new Error('Failed to load translation languages');
    }

    yield (0, _effects.put)(internalActions.getTranslationLanguagesSuccess({
      translationLanguages
    }));
    return translationLanguages;
  } catch (e) {
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.getTranslationLanguagesFailure({
      err
    }));
    return err;
  }
}

var FETCH_TRANSLATION_BY_ID = 'translations.fetch.by-id';
exports.FETCH_TRANSLATION_BY_ID = FETCH_TRANSLATION_BY_ID;
var FETCH_TRANSLATION_BY_ID_REQUEST = 'translations.fetch.by-id.request';
exports.FETCH_TRANSLATION_BY_ID_REQUEST = FETCH_TRANSLATION_BY_ID_REQUEST;
var FETCH_TRANSLATION_BY_ID_SUCCESS = 'translations.fetch.by-id.success';
exports.FETCH_TRANSLATION_BY_ID_SUCCESS = FETCH_TRANSLATION_BY_ID_SUCCESS;
var FETCH_TRANSLATION_BY_ID_FAILURE = 'translations.fetch.by-id.failure';
exports.FETCH_TRANSLATION_BY_ID_FAILURE = FETCH_TRANSLATION_BY_ID_FAILURE;
var FETCH_TRANSLATION_MEMORIES = 'translation-memories.fetch';
exports.FETCH_TRANSLATION_MEMORIES = FETCH_TRANSLATION_MEMORIES;
var FETCH_TRANSLATION_MEMORIES_REQUEST = 'translation-memories.fetch.request';
exports.FETCH_TRANSLATION_MEMORIES_REQUEST = FETCH_TRANSLATION_MEMORIES_REQUEST;
var FETCH_TRANSLATION_MEMORIES_SUCCESS = 'translation-memories.fetch.success';
exports.FETCH_TRANSLATION_MEMORIES_SUCCESS = FETCH_TRANSLATION_MEMORIES_SUCCESS;
var FETCH_TRANSLATION_MEMORIES_FAILURE = 'translation-memories.fetch.failure';
exports.FETCH_TRANSLATION_MEMORIES_FAILURE = FETCH_TRANSLATION_MEMORIES_FAILURE;
var FETCH_MACHINE_TRANSLATIONS = 'machine-translations.fetch';
exports.FETCH_MACHINE_TRANSLATIONS = FETCH_MACHINE_TRANSLATIONS;
var FETCH_MACHINE_TRANSLATIONS_REQUEST = 'machine-translations.fetch.request';
exports.FETCH_MACHINE_TRANSLATIONS_REQUEST = FETCH_MACHINE_TRANSLATIONS_REQUEST;
var FETCH_MACHINE_TRANSLATIONS_SUCCESS = 'machine-translations.fetch.success';
exports.FETCH_MACHINE_TRANSLATIONS_SUCCESS = FETCH_MACHINE_TRANSLATIONS_SUCCESS;
var FETCH_MACHINE_TRANSLATIONS_FAILURE = 'machine-translations.fetch.failure';
exports.FETCH_MACHINE_TRANSLATIONS_FAILURE = FETCH_MACHINE_TRANSLATIONS_FAILURE;
var FETCH_TRANSLATION_LANGUAGES = 'translation-languages.fetch';
exports.FETCH_TRANSLATION_LANGUAGES = FETCH_TRANSLATION_LANGUAGES;
var FETCH_TRANSLATION_LANGUAGES_REQUEST = 'translation-languages.fetch.request';
exports.FETCH_TRANSLATION_LANGUAGES_REQUEST = FETCH_TRANSLATION_LANGUAGES_REQUEST;
var FETCH_TRANSLATION_LANGUAGES_SUCCESS = 'translation-languages.fetch.success';
exports.FETCH_TRANSLATION_LANGUAGES_SUCCESS = FETCH_TRANSLATION_LANGUAGES_SUCCESS;
var FETCH_TRANSLATION_LANGUAGES_FAILURE = 'translation-languages.fetch.failure';
exports.FETCH_TRANSLATION_LANGUAGES_FAILURE = FETCH_TRANSLATION_LANGUAGES_FAILURE;
var INITIAL_STATE = {
  translations: {},
  translationMemories: {},
  machineTranslations: {}
};
exports.INITIAL_STATE = INITIAL_STATE;

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case FETCH_TRANSLATION_BY_ID:
      {
        var id = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          translations: _objectSpread(_objectSpread({}, state.translations), {}, {
            [id]: _objectSpread(_objectSpread({
              data: null,
              lastLoadedAt: null
            }, state.translations[id]), {}, {
              isLoading: true,
              loadError: null
            })
          })
        });
      }

    case FETCH_TRANSLATION_BY_ID_SUCCESS:
      {
        var translation = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          translations: _objectSpread(_objectSpread({}, state.translations), {}, {
            [translation._id]: {
              data: translation,
              lastLoadedAt: new Date(),
              isLoading: false,
              loadError: null
            }
          })
        });
      }

    case FETCH_TRANSLATION_BY_ID_FAILURE:
      {
        var {
          id: _id,
          error
        } = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          translations: _objectSpread(_objectSpread({}, state.translations), {}, {
            [_id]: _objectSpread(_objectSpread({}, state.translations[_id]), {}, {
              isLoading: false,
              loadError: error
            })
          })
        });
      }

    case FETCH_TRANSLATION_MEMORIES_REQUEST:
      {
        var {
          id: _id2,
          language
        } = action.payload;
        var existingMemories = (0, _lodash.default)(state.translationMemories, [_id2], {});
        var existingLanguageMemories = (0, _lodash.default)(existingMemories, [language], {});
        return _objectSpread(_objectSpread({}, state), {}, {
          translationMemories: _objectSpread(_objectSpread({}, state.translationMemories), {}, {
            [_id2]: _objectSpread(_objectSpread({}, existingMemories), {}, {
              [language]: _objectSpread(_objectSpread({
                data: null,
                lastLoadedAt: null
              }, existingLanguageMemories), {}, {
                isLoading: true
              })
            })
          })
        });
      }

    case FETCH_TRANSLATION_MEMORIES_SUCCESS:
      {
        var {
          id: _id3,
          language: _language,
          translationMemories
        } = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          translationMemories: _objectSpread(_objectSpread({}, state.translationMemories), {}, {
            [_id3]: _objectSpread(_objectSpread({}, state.translationMemories[_id3]), {}, {
              [_language]: {
                isLoading: false,
                lastLoadedAt: new Date(),
                data: translationMemories
              }
            })
          })
        });
      }

    case FETCH_TRANSLATION_MEMORIES_FAILURE:
      {
        var {
          id: _id4,
          language: _language2,
          err
        } = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          translationMemories: _objectSpread(_objectSpread({}, state.translationMemories), {}, {
            [_id4]: _objectSpread(_objectSpread({}, state.translationMemories[_id4]), {}, {
              [_language2]: _objectSpread(_objectSpread({}, state.translationMemories[_id4][_language2]), {}, {
                isLoading: false,
                loadError: err
              })
            })
          })
        });
      }

    case FETCH_MACHINE_TRANSLATIONS_REQUEST:
      {
        var {
          id: _id5,
          language: _language3
        } = action.payload;
        var existingMachineTranslations = (0, _lodash.default)(state.machineTranslations, [_id5], {});
        var existingMachineTranslationsLanguage = (0, _lodash.default)(existingMachineTranslations, [_language3], {});
        return _objectSpread(_objectSpread({}, state), {}, {
          machineTranslations: _objectSpread(_objectSpread({}, state.machineTranslations), {}, {
            [_id5]: _objectSpread(_objectSpread({}, existingMachineTranslations), {}, {
              [_language3]: _objectSpread(_objectSpread({
                data: null,
                lastLoadedAt: null
              }, existingMachineTranslationsLanguage), {}, {
                isLoading: true
              })
            })
          })
        });
      }

    case FETCH_MACHINE_TRANSLATIONS_SUCCESS:
      {
        var {
          id: _id6,
          language: _language4,
          machineTranslations
        } = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          machineTranslations: _objectSpread(_objectSpread({}, state.machineTranslations), {}, {
            [_id6]: _objectSpread(_objectSpread({}, state.machineTranslations[_id6]), {}, {
              [_language4]: {
                isLoading: false,
                lastLoadedAt: new Date(),
                data: machineTranslations
              }
            })
          })
        });
      }

    case FETCH_MACHINE_TRANSLATIONS_FAILURE:
      {
        var {
          id: _id7,
          language: _language5,
          err: _err
        } = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          machineTranslations: _objectSpread(_objectSpread({}, state.machineTranslations), {}, {
            [_id7]: _objectSpread(_objectSpread({}, state.machineTranslations[_id7]), {}, {
              [_language5]: _objectSpread(_objectSpread({}, state.machineTranslations[_id7][_language5]), {}, {
                isLoading: false,
                loadError: _err
              })
            })
          })
        });
      }

    case FETCH_TRANSLATION_LANGUAGES_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          translationLanguages: {
            isLoading: true
          }
        });
      }

    case FETCH_TRANSLATION_LANGUAGES_SUCCESS:
      {
        var {
          translationLanguages
        } = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          translationLanguages: {
            isLoading: false,
            lastLoadedAt: new Date(),
            data: translationLanguages
          }
        });
      }

    case FETCH_TRANSLATION_LANGUAGES_FAILURE:
      {
        var {
          err: _err2
        } = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          translationLanguages: {
            isLoading: false,
            loadError: _err2
          }
        });
      }

    default:
      return state;
  }
}

var actions = {
  getTranslationById: (0, _reduxActions.createAction)(FETCH_TRANSLATION_BY_ID),
  getTranslationMemories: (0, _reduxActions.createAction)(FETCH_TRANSLATION_MEMORIES),
  getMachineTranslations: (0, _reduxActions.createAction)(FETCH_MACHINE_TRANSLATIONS),
  getTranslationLanguages: (0, _reduxActions.createAction)(FETCH_TRANSLATION_LANGUAGES)
};
/**
 * Actions that should only be invoked internally
 */

exports.actions = actions;
var internalActions = {
  getTranslationByIdRequest: (0, _reduxActions.createAction)(FETCH_TRANSLATION_BY_ID_REQUEST),
  getTranslationByIdSuccess: (0, _reduxActions.createAction)(FETCH_TRANSLATION_BY_ID_SUCCESS),
  getTranslationByIdFailure: (0, _reduxActions.createAction)(FETCH_TRANSLATION_BY_ID_FAILURE),
  getTranslationMemoriesRequest: (0, _reduxActions.createAction)(FETCH_TRANSLATION_MEMORIES_REQUEST),
  getTranslationMemoriesSuccess: (0, _reduxActions.createAction)(FETCH_TRANSLATION_MEMORIES_SUCCESS),
  getTranslationMemoriesFailure: (0, _reduxActions.createAction)(FETCH_TRANSLATION_MEMORIES_FAILURE),
  getMachineTranslationsRequest: (0, _reduxActions.createAction)(FETCH_MACHINE_TRANSLATIONS_REQUEST),
  getMachineTranslationsSuccess: (0, _reduxActions.createAction)(FETCH_MACHINE_TRANSLATIONS_SUCCESS),
  getMachineTranslationsFailure: (0, _reduxActions.createAction)(FETCH_MACHINE_TRANSLATIONS_FAILURE),
  getTranslationLanguagesRequest: (0, _reduxActions.createAction)(FETCH_TRANSLATION_LANGUAGES_REQUEST),
  getTranslationLanguagesSuccess: (0, _reduxActions.createAction)(FETCH_TRANSLATION_LANGUAGES_SUCCESS),
  getTranslationLanguagesFailure: (0, _reduxActions.createAction)(FETCH_TRANSLATION_LANGUAGES_FAILURE)
};
exports.internalActions = internalActions;
var selectors = {
  isLoading: (0, _common.createSelector)(STORE_NAME, isLoading),
  isLoadingTranslationMemories: (0, _common.createSelector)(STORE_NAME, isLoadingTranslationMemories),
  isLoadingMachineTranslations: (0, _common.createSelector)(STORE_NAME, isLoadingMachineTranslations),
  isLoadingTranslationLanguages: (0, _common.createSelector)(STORE_NAME, isLoadingTranslationLanguages),
  isLoaded: (0, _common.createSelector)(STORE_NAME, isLoaded),
  isLoadedTranslationMemories: (0, _common.createSelector)(STORE_NAME, isLoadedTranslationMemories),
  isLoadedMachineTranslations: (0, _common.createSelector)(STORE_NAME, isLoadedMachineTranslations),
  isLoadedTranslationLanguages: (0, _common.createSelector)(STORE_NAME, isLoadedTranslationLanguages),
  didLoadFail: (0, _common.createSelector)(STORE_NAME, didLoadFail),
  didLoadTranslationMemoriesFail: (0, _common.createSelector)(STORE_NAME, didLoadTranslationMemoriesFail),
  didLoadMachineTranslationsFail: (0, _common.createSelector)(STORE_NAME, didLoadMachineTranslationsFail),
  didLoadTranslationLanguagesFail: (0, _common.createSelector)(STORE_NAME, didLoadTranslationLanguagesFail),
  getTranslationById: (0, _common.createSelector)(STORE_NAME, getTranslationById),
  getTranslationMemories: (0, _common.createSelector)(STORE_NAME, getTranslationMemories),
  getMachineTranslations: (0, _common.createSelector)(STORE_NAME, getMachineTranslations),
  getTranslationLanguages: (0, _common.createSelector)(STORE_NAME, getTranslationLanguages)
};
exports.selectors = selectors;

function getTranslationById(state, id) {
  var translationContainer = state.translations[id];

  if (!translationContainer) {
    return null;
  }

  return translationContainer.data;
}

function getTranslationMemories(state, id, language) {
  var languageContainer = getTranslationMemoryLanguageContainer(state, id, language);
  return languageContainer ? languageContainer.data : null;
}

function getMachineTranslations(state, id, language) {
  var languageContainer = getMachineTranslationsLanguageContainer(state, id, language);
  return languageContainer ? languageContainer.data : null;
}

function getTranslationLanguages(state) {
  var _state$translationLan;

  return (_state$translationLan = state.translationLanguages) === null || _state$translationLan === void 0 ? void 0 : _state$translationLan.data;
}

function isLoading(state, id) {
  var translationContainer = state.translations[id];

  if (!translationContainer) {
    return false;
  }

  return translationContainer.isLoading;
}

function isLoadingTranslationMemories(state, id, language) {
  var languageContainer = getTranslationMemoryLanguageContainer(state, id, language);
  return languageContainer ? languageContainer.isLoading : false;
}

function isLoadingMachineTranslations(state, id, language) {
  var languageContainer = getMachineTranslationsLanguageContainer(state, id, language);
  return languageContainer ? languageContainer.isLoading : false;
}

function isLoadingTranslationLanguages(state) {
  var _state$translationLan2;

  return (_state$translationLan2 = state.translationLanguages) === null || _state$translationLan2 === void 0 ? void 0 : _state$translationLan2.isLoading;
}

function isLoaded(state, id) {
  var translationContainer = state.translations[id];

  if (!translationContainer) {
    return false;
  }

  return !!translationContainer.lastLoadedAt;
}

function isLoadedTranslationMemories(state, id, language) {
  var languageContainer = getTranslationMemoryLanguageContainer(state, id, language);
  return languageContainer ? !!languageContainer.lastLoadedAt : false;
}

function isLoadedMachineTranslations(state, id, language) {
  var languageContainer = getMachineTranslationsLanguageContainer(state, id, language);
  return languageContainer ? !!languageContainer.lastLoadedAt : false;
}

function isLoadedTranslationLanguages(state) {
  var _state$translationLan3;

  return !!((_state$translationLan3 = state.translationLanguages) !== null && _state$translationLan3 !== void 0 && _state$translationLan3.lastLoadedAt);
}

function didLoadFail(state, id) {
  var translationContainer = state.translations[id];

  if (!translationContainer) {
    return false;
  }

  return !!translationContainer.loadError;
}

function didLoadTranslationMemoriesFail(state, id, language) {
  var languageContainer = getTranslationMemoryLanguageContainer(state, id, language);
  return languageContainer ? !!languageContainer.loadError : false;
}

function didLoadMachineTranslationsFail(state, id, language) {
  var languageContainer = getMachineTranslationsLanguageContainer(state, id, language);
  return languageContainer ? !!languageContainer.loadError : false;
}

function didLoadTranslationLanguagesFail(state) {
  var _state$translationLan4;

  return !!((_state$translationLan4 = state.translationLanguages) !== null && _state$translationLan4 !== void 0 && _state$translationLan4.loadError);
}

function getTranslationMemoryLanguageContainer(state, id, language) {
  var translationMemoriesContainer = state.translationMemories[id];

  if (!translationMemoriesContainer) {
    return null;
  }

  var languageContainer = translationMemoriesContainer[language];

  if (!languageContainer) {
    return null;
  }

  return languageContainer;
}

function getMachineTranslationsLanguageContainer(state, id, language) {
  var machineTranslationsContainer = state.machineTranslations[id];

  if (!machineTranslationsContainer) {
    return null;
  }

  var languageContainer = machineTranslationsContainer[language];

  if (!languageContainer) {
    return null;
  }

  return languageContainer;
}

function* watchTranslations() {
  yield (0, _effects.takeEvery)(FETCH_TRANSLATION_BY_ID, fetchTranslationById);
  yield (0, _effects.takeEvery)(FETCH_TRANSLATION_MEMORIES, fetchTranslationMemories);
  yield (0, _effects.takeEvery)(FETCH_MACHINE_TRANSLATIONS, fetchMachineTranslations);
  yield (0, _effects.takeEvery)(FETCH_TRANSLATION_LANGUAGES, fetchTranslationLanguages);
}