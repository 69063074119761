"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actions = exports.UPDATE_TRANSFERS = exports.STORE_NAME = exports.SEARCH_DOWNLOADS_SUCCESS = exports.SEARCH_DOWNLOADS_REQUEST = exports.SEARCH_DOWNLOADS_FAILURE = exports.SEARCH_DOWNLOADS = exports.RESET_DOWNLOADS = exports.PAGE_SIZE = exports.INITIAL_STATE = exports.FETCH_NEXT_SEARCH_SUCCESS = exports.FETCH_NEXT_SEARCH_REQUEST = exports.FETCH_NEXT_SEARCH_FAILURE = exports.FETCH_NEXT_SEARCH = exports.FETCH_NEXT_DOWNLOADS_PAGE_SUCCESS = exports.FETCH_NEXT_DOWNLOADS_PAGE_REQUEST = exports.FETCH_NEXT_DOWNLOADS_PAGE_FAILURE = exports.FETCH_NEXT_DOWNLOADS_PAGE = exports.FETCH_NEW_DOWNLOADS_SUCCESS = exports.FETCH_NEW_DOWNLOADS_REQUEST = exports.FETCH_NEW_DOWNLOADS_FAILURE = exports.FETCH_NEW_DOWNLOADS = void 0;
exports.reducer = reducer;
exports.selectors = void 0;
exports.watchDownloadsPage = watchDownloadsPage;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _lodash = _interopRequireDefault(require("lodash"));

var _reduxActions = require("redux-actions");

var _effects = require("redux-saga/effects");

var _common = require("./common");

var _downloadsPageService = _interopRequireDefault(require("~/app/3x/modules/services/downloads-page-service"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var STORE_NAME = 'downloadsPageStore';
exports.STORE_NAME = STORE_NAME;
var PAGE_SIZE = 100; // When the user wants to retrieve the next page of downloads (use this for page 0 as well);

exports.PAGE_SIZE = PAGE_SIZE;

function* fetchNextDownloadsPage(_ref) {
  var {
    payload
  } = _ref;

  try {
    var {
      userId
    } = payload;
    var page = yield (0, _effects.select)(state => state[STORE_NAME].allDownloads.currentPage);
    var start = page * PAGE_SIZE;
    var end = (page + 1) * PAGE_SIZE - 1;
    var requestedAt = new Date().getTime();
    yield (0, _effects.put)(internalActions.fetchNextDownloadsPageRequest(userId));

    var downloadsPromise = _downloadsPageService.default.getAllDownloads({
      userId,
      start,
      end
    });

    var downloads = yield downloadsPromise;
    var responseHeaders = downloads.headers;
    var total = getPaginationTotal(responseHeaders['x-content-range']);
    var nextPage = page + 1;
    yield (0, _effects.put)(internalActions.fetchNextDownloadsPageSuccess({
      downloads,
      total,
      nextPage,
      requestedAt
    }));
    return downloads;
  } catch (e) {
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.fetchNextDownloadsPageFailure(err));
    return err;
  }
} // When the user needs to update their downloads store with only new records (uses last updated at)


function* fetchNewDownloads(_ref2) {
  var {
    payload
  } = _ref2;

  try {
    var {
      userId
    } = payload;
    var mostRecentLoadAt = yield (0, _effects.select)(state => state[STORE_NAME].allDownloads.mostRecentLoadAt);

    if (!mostRecentLoadAt) {
      return;
    }

    var requestedAt = new Date().getTime();
    yield (0, _effects.put)(internalActions.fetchNewDownloadsRequest(userId)); // Convert back to date/time as the API uses it (drop the milliseconds)

    mostRecentLoadAt = ~~(mostRecentLoadAt / 1000);
    var downloads = yield _downloadsPageService.default.fetchNewDownloads({
      userId,
      mostRecentLoadAt
    });
    yield (0, _effects.put)(internalActions.fetchNewDownloadsSuccess({
      downloads,
      requestedAt
    }));
    return downloads;
  } catch (e) {
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.fetchNewDownloadsFailure(err));
    return err;
  }
} // When the user performs a server-side search


function* searchDownloads(_ref3) {
  var {
    payload
  } = _ref3;

  try {
    var {
      userId,
      query
    } = payload;
    var start = 0;
    var end = PAGE_SIZE - 1;
    yield (0, _effects.put)(internalActions.searchDownloadsRequest({
      userId,
      query
    }));

    var downloadsPromise = _downloadsPageService.default.getAllDownloads({
      userId,
      query,
      start,
      end
    });

    var downloads = yield downloadsPromise;
    var responseHeaders = downloads.headers;
    var total = getPaginationTotal(responseHeaders['x-content-range']);
    var nextPage = 1;
    yield (0, _effects.put)(internalActions.searchDownloadsSuccess({
      downloads,
      total,
      nextPage
    }));
    return downloads;
  } catch (e) {
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.searchDownloadsFailure(err));
    return err;
  }
}

function* fetchNextSearchPage(_ref4) {
  var {
    payload
  } = _ref4;

  try {
    var {
      userId,
      query
    } = payload;
    var page = yield (0, _effects.select)(state => state[STORE_NAME].searchDownloads.currentPage);
    var start = page * PAGE_SIZE;
    var end = (page + 1) * PAGE_SIZE - 1;
    var requestedAt = new Date();
    yield (0, _effects.put)(internalActions.fetchNextSearchRequest({
      userId,
      query
    }));

    var downloadsPromise = _downloadsPageService.default.getAllDownloads({
      userId,
      query,
      start,
      end
    });

    var downloads = yield downloadsPromise;
    var responseHeaders = downloads.headers;
    var total = getPaginationTotal(responseHeaders['x-content-range']);
    var nextPage = 1;
    yield (0, _effects.put)(internalActions.fetchNextSearchSuccess({
      downloads,
      total,
      nextPage,
      requestedAt
    }));
    return downloads;
  } catch (e) {
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.fetchNextSearchFailure(err));
    return err;
  }
}

function getPaginationTotal() {
  var headerValue = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var rangePattern = /^resources \d+-\d+\/(\d+|\*)/;
  var matches = headerValue.match(rangePattern);

  if (matches === null || _lodash.default.isUndefined(matches[1]) || matches[1] === '*') {
    return 0;
  }

  return +matches[1];
}

var FETCH_NEXT_DOWNLOADS_PAGE = 'downloads.fetch-next-page';
exports.FETCH_NEXT_DOWNLOADS_PAGE = FETCH_NEXT_DOWNLOADS_PAGE;
var FETCH_NEXT_DOWNLOADS_PAGE_REQUEST = 'downloads.fetch-next-page.request';
exports.FETCH_NEXT_DOWNLOADS_PAGE_REQUEST = FETCH_NEXT_DOWNLOADS_PAGE_REQUEST;
var FETCH_NEXT_DOWNLOADS_PAGE_SUCCESS = 'downloads.fetch-next-page.success';
exports.FETCH_NEXT_DOWNLOADS_PAGE_SUCCESS = FETCH_NEXT_DOWNLOADS_PAGE_SUCCESS;
var FETCH_NEXT_DOWNLOADS_PAGE_FAILURE = 'downloads.fetch-next-page.failure';
exports.FETCH_NEXT_DOWNLOADS_PAGE_FAILURE = FETCH_NEXT_DOWNLOADS_PAGE_FAILURE;
var FETCH_NEW_DOWNLOADS = 'downloads.fetch-new';
exports.FETCH_NEW_DOWNLOADS = FETCH_NEW_DOWNLOADS;
var FETCH_NEW_DOWNLOADS_REQUEST = 'downloads.fetch-new.request';
exports.FETCH_NEW_DOWNLOADS_REQUEST = FETCH_NEW_DOWNLOADS_REQUEST;
var FETCH_NEW_DOWNLOADS_SUCCESS = 'downloads.fetch-new.success';
exports.FETCH_NEW_DOWNLOADS_SUCCESS = FETCH_NEW_DOWNLOADS_SUCCESS;
var FETCH_NEW_DOWNLOADS_FAILURE = 'downloads.fetch-new.failure';
exports.FETCH_NEW_DOWNLOADS_FAILURE = FETCH_NEW_DOWNLOADS_FAILURE;
var SEARCH_DOWNLOADS = 'downloads.search';
exports.SEARCH_DOWNLOADS = SEARCH_DOWNLOADS;
var SEARCH_DOWNLOADS_REQUEST = 'downloads.search.request';
exports.SEARCH_DOWNLOADS_REQUEST = SEARCH_DOWNLOADS_REQUEST;
var SEARCH_DOWNLOADS_SUCCESS = 'downloads.search.success';
exports.SEARCH_DOWNLOADS_SUCCESS = SEARCH_DOWNLOADS_SUCCESS;
var SEARCH_DOWNLOADS_FAILURE = 'downloads.search.failure';
exports.SEARCH_DOWNLOADS_FAILURE = SEARCH_DOWNLOADS_FAILURE;
var FETCH_NEXT_SEARCH = 'downloads.fetch-next-search';
exports.FETCH_NEXT_SEARCH = FETCH_NEXT_SEARCH;
var FETCH_NEXT_SEARCH_REQUEST = 'downloads.fetch-next-search.request';
exports.FETCH_NEXT_SEARCH_REQUEST = FETCH_NEXT_SEARCH_REQUEST;
var FETCH_NEXT_SEARCH_SUCCESS = 'downloads.fetch-next-search.successs';
exports.FETCH_NEXT_SEARCH_SUCCESS = FETCH_NEXT_SEARCH_SUCCESS;
var FETCH_NEXT_SEARCH_FAILURE = 'downloads.fetch-next-search.failure';
exports.FETCH_NEXT_SEARCH_FAILURE = FETCH_NEXT_SEARCH_FAILURE;
var RESET_DOWNLOADS = 'downloads.reset';
exports.RESET_DOWNLOADS = RESET_DOWNLOADS;
var UPDATE_TRANSFERS = 'transfers.update';
exports.UPDATE_TRANSFERS = UPDATE_TRANSFERS;
var INITIAL_STATE = {
  allDownloads: {
    downloads: [],
    currentPage: 0,
    isLoadingNextPage: false,
    mostRecentLoadAt: null,
    loadFailed: false,
    hasMoreDownloads: true
  },
  searchDownloads: {
    downloads: [],
    currentPage: 0,
    isLoading: false,
    isLoadingNext: false,
    loadFailed: false,
    hasMoreDownloads: true
  },
  newDownloads: {
    isLoading: false,
    loadFailed: false
  },
  transfers: []
};
exports.INITIAL_STATE = INITIAL_STATE;

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case FETCH_NEXT_DOWNLOADS_PAGE_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          allDownloads: _objectSpread(_objectSpread({}, state.allDownloads), {}, {
            isLoadingNextPage: true
          })
        });
      }

    case FETCH_NEXT_DOWNLOADS_PAGE_SUCCESS:
      {
        var {
          downloads,
          total,
          nextPage,
          requestedAt
        } = action.payload;
        var mostRecentLoadAt = state.allDownloads.mostRecentLoadAt;

        if (!mostRecentLoadAt) {
          mostRecentLoadAt = requestedAt;
        }

        downloads = [].concat(state.allDownloads.downloads, downloads.data);
        downloads = _lodash.default.uniq(downloads, '_id');
        downloads = _lodash.default.sortBy(downloads, ['-created', 'name']);

        var _hasMoreDownloads = downloads.length < total;

        return _objectSpread(_objectSpread({}, state), {}, {
          allDownloads: {
            isLoadingNextPage: false,
            currentPage: nextPage,
            mostRecentLoadAt,
            hasMoreDownloads: _hasMoreDownloads,
            downloads
          }
        });
      }

    case FETCH_NEXT_DOWNLOADS_PAGE_FAILURE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          allDownloads: _objectSpread(_objectSpread({}, state.allDownloads), {}, {
            isLoadingNextPage: false,
            loadFailed: true
          })
        });
      }

    case FETCH_NEW_DOWNLOADS_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          newDownloads: {
            isLoading: true
          }
        });
      }

    case FETCH_NEW_DOWNLOADS_SUCCESS:
      {
        var {
          downloads: _downloads,
          requestedAt: _requestedAt
        } = action.payload;
        _downloads = [].concat(_downloads.data, state.allDownloads.downloads);
        _downloads = _lodash.default.uniq(_downloads, '_id');
        _downloads = _lodash.default.sortBy(_downloads, ['-created', 'name']);
        return _objectSpread(_objectSpread({}, state), {}, {
          allDownloads: _objectSpread(_objectSpread({}, state.allDownloads), {}, {
            mostRecentLoadAt: _requestedAt,
            downloads: _downloads
          }),
          newDownloads: {
            isLoading: false
          }
        });
      }

    case FETCH_NEW_DOWNLOADS_FAILURE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          newDownloads: {
            isLoading: false,
            loadFailed: true
          }
        });
      }

    case SEARCH_DOWNLOADS_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          searchDownloads: {
            isLoading: true,
            isLoadingNext: true,
            downloads: [],
            currentPage: 0,
            hasMoreDownloads: true
          }
        });
      }

    case SEARCH_DOWNLOADS_SUCCESS:
      {
        var {
          downloads: _downloads2,
          total: _total,
          nextPage: _nextPage
        } = action.payload;

        var _hasMoreDownloads2 = _downloads2.length < _total;

        return _objectSpread(_objectSpread({}, state), {}, {
          searchDownloads: _objectSpread(_objectSpread({}, state.searchDownloads), {}, {
            isLoading: false,
            isLoadingNext: false,
            downloads: _downloads2.data,
            currentPage: _nextPage,
            hasMoreDownloads: _hasMoreDownloads2,
            total: _total
          })
        });
      }

    case SEARCH_DOWNLOADS_FAILURE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          searchDownloads: _objectSpread(_objectSpread({}, state.searchDownloads), {}, {
            isLoading: false,
            isLoadingNext: false,
            loadFailed: true
          })
        });
      }

    case FETCH_NEXT_SEARCH_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          searchDownloads: _objectSpread(_objectSpread({}, state.searchDownloads), {}, {
            isLoadingNext: true
          })
        });
      }

    case FETCH_NEXT_SEARCH_SUCCESS:
      {
        var {
          downloads: _downloads3,
          total: _total2,
          nextPage: _nextPage2
        } = action.payload;
        _downloads3 = [].concat(state.searchDownloads.downloads, _downloads3.data);
        _downloads3 = _lodash.default.uniq(_downloads3, '_id');
        _downloads3 = _lodash.default.sortBy(_downloads3, ['-created', 'name']);

        var _hasMoreDownloads3 = _downloads3.length < _total2;

        return _objectSpread(_objectSpread({}, state), {}, {
          searchDownloads: _objectSpread(_objectSpread({}, state.searchDownloads), {}, {
            isLoadingNext: false,
            downloads: _downloads3,
            currentPage: _nextPage2,
            hasMoreDownloads: _hasMoreDownloads3,
            total: _total2
          })
        });
      }

    case FETCH_NEXT_SEARCH_FAILURE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          searchDownloads: _objectSpread(_objectSpread({}, state.searchDownloads), {}, {
            isLoadingNext: false,
            loadFailed: true
          })
        });
      }

    case RESET_DOWNLOADS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          allDownloads: {
            downloads: [],
            currentPage: 0,
            mostRecentLoadAt: null,
            isLoadingNextPage: false,
            hasMoreDownloads: true
          }
        });
      }

    case UPDATE_TRANSFERS:
      {
        var transfers = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          transfers: [...transfers]
        });
      }

    default:
      return state;
  }
}

var actions = {
  fetchNextDownloadsPage: (0, _reduxActions.createAction)(FETCH_NEXT_DOWNLOADS_PAGE, userId => ({
    userId
  })),
  fetchNewDownloads: (0, _reduxActions.createAction)(FETCH_NEW_DOWNLOADS, userId => ({
    userId
  })),
  searchDownloads: (0, _reduxActions.createAction)(SEARCH_DOWNLOADS, (userId, query) => ({
    userId,
    query
  })),
  fetchNextSearchDownloads: (0, _reduxActions.createAction)(FETCH_NEXT_SEARCH, (userId, query) => ({
    userId,
    query
  }))
};
/**
 * Actions that should only be invoked internally
 */

exports.actions = actions;
var internalActions = {
  fetchNextDownloadsPageRequest: (0, _reduxActions.createAction)(FETCH_NEXT_DOWNLOADS_PAGE_REQUEST),
  fetchNextDownloadsPageSuccess: (0, _reduxActions.createAction)(FETCH_NEXT_DOWNLOADS_PAGE_SUCCESS),
  fetchNextDownloadsPageFailure: (0, _reduxActions.createAction)(FETCH_NEXT_DOWNLOADS_PAGE_FAILURE),
  fetchNewDownloadsRequest: (0, _reduxActions.createAction)(FETCH_NEW_DOWNLOADS_REQUEST),
  fetchNewDownloadsSuccess: (0, _reduxActions.createAction)(FETCH_NEW_DOWNLOADS_SUCCESS),
  fetchNewDownloadsFailure: (0, _reduxActions.createAction)(FETCH_NEW_DOWNLOADS_FAILURE),
  searchDownloadsRequest: (0, _reduxActions.createAction)(SEARCH_DOWNLOADS_REQUEST),
  searchDownloadsSuccess: (0, _reduxActions.createAction)(SEARCH_DOWNLOADS_SUCCESS),
  searchDownloadsFailure: (0, _reduxActions.createAction)(SEARCH_DOWNLOADS_FAILURE),
  fetchNextSearchRequest: (0, _reduxActions.createAction)(FETCH_NEXT_SEARCH_REQUEST),
  fetchNextSearchSuccess: (0, _reduxActions.createAction)(FETCH_NEXT_SEARCH_SUCCESS),
  fetchNextSearchFailure: (0, _reduxActions.createAction)(FETCH_NEXT_SEARCH_FAILURE),
  updateTransfers: (0, _reduxActions.createAction)(UPDATE_TRANSFERS)
};
var selectors = {
  getDownloads: createSelector(getDownloads),
  isLoadingNextPage: createSelector(isLoadingNextPage),
  isNextDownloadsPageError: createSelector(isNextDownloadsPageError),
  hasMoreDownloads: createSelector(hasMoreDownloads),
  getMostRecentLoadAt: createSelector(getMostRecentLoadAt),
  isLoadingNewDownloads: createSelector(isLoadingNewDownloads),
  isNewDownloadsError: createSelector(isNewDownloadsError),
  getSearchedDownloads: createSelector(getSearchedDownloads),
  isLoadingSearchResults: createSelector(isLoadingSearchResults),
  isLoadingNextSearchResults: createSelector(isLoadingNextSearchResults),
  isSearchError: createSelector(isSearchError),
  hasMoreSearchResults: createSelector(hasMoreSearchResults),
  getTransfers: createSelector(getTransfers)
};
exports.selectors = selectors;

function getDownloads(state) {
  return state.allDownloads.downloads;
}

function isLoadingNextPage(state) {
  return state.allDownloads.isLoadingNextPage;
}

function isNextDownloadsPageError(state) {
  return state.allDownloads.loadFailed;
}

function hasMoreDownloads(state) {
  return state.allDownloads.hasMoreDownloads;
}

function getMostRecentLoadAt(state) {
  return state.allDownloads.mostRecentLoadAt;
}

function isLoadingNewDownloads(state) {
  return state.newDownloads.isLoading;
}

function isNewDownloadsError(state) {
  return state.newDownloads.loadFailed;
}

function getSearchedDownloads(state) {
  return state.searchDownloads.downloads;
}

function isLoadingSearchResults(state) {
  return state.searchDownloads.isLoading;
}

function isLoadingNextSearchResults(state) {
  return state.searchDownloads.isLoadingNext;
}

function isSearchError(state) {
  return state.searchDownloads.loadFailed;
}

function hasMoreSearchResults(state) {
  return state.searchDownloads.hasMoreDownloads;
}

function getTransfers(state) {
  return state.transfers;
}
/**
 * Helper method that grabs our specific part of the global store, then passes it into the given selector.
 * This makes it so that each selector doesn't have to grab the sub-state itself, reducing code duplication.
 */


function createSelector(selector) {
  return function selectorWrapper(state) {
    var downloadsStore = state[STORE_NAME];

    for (var _len = arguments.length, rest = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      rest[_key - 1] = arguments[_key];
    }

    return selector(downloadsStore, ...rest);
  };
}

function* watchDownloadsPage() {
  yield (0, _effects.takeLatest)(FETCH_NEXT_DOWNLOADS_PAGE, fetchNextDownloadsPage);
  yield (0, _effects.takeLatest)(FETCH_NEW_DOWNLOADS, fetchNewDownloads);
  yield (0, _effects.takeLatest)(SEARCH_DOWNLOADS, searchDownloads);
  yield (0, _effects.takeLatest)(FETCH_NEXT_SEARCH, fetchNextSearchPage);
}