"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actions = exports.UPDATE_SIDEBAR = exports.UPDATE_MAIN = exports.UPDATE_BILLING_RATES = exports.STORE_NAME = exports.LOAD_SUCCESS = exports.LOAD_REQUEST = exports.LOAD_FAILURE = exports.LOAD = exports.INITIAL_STATE = void 0;
exports.reducer = reducer;
exports.selectors = void 0;
exports.watchProjectCrud = watchProjectCrud;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _lodash = _interopRequireDefault(require("lodash.iserror"));

var _reduxActions = require("redux-actions");

var _effects = require("redux-saga/effects");

var _common = require("./common");

var _studios = require("./studios");

var _projects = require("./projects");

var _projectService = _interopRequireDefault(require("~/app/3x/modules/services/project-service"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var STORE_NAME = 'projectCRUDStore';
exports.STORE_NAME = STORE_NAME;

function* loadPageData(_ref) {
  var {
    payload: projectId
  } = _ref;

  try {
    yield (0, _effects.put)(internalActions.loadPageRequest());
    var studiosTask = yield (0, _effects.fork)(_studios.fetchStudios, _studios.actions.getStudios());
    var projectTask;
    var billingRates;

    if (projectId) {
      projectTask = yield (0, _effects.fork)(_projects.fetchProjectById, _projects.actions.getProjectById(projectId));
      billingRates = yield _projectService.default.getBillingRates({
        projectId
      });
    }

    var studios = yield (0, _effects.join)(studiosTask);
    var project;

    if (projectTask) {
      project = yield (0, _effects.join)(projectTask);
    }

    if ((0, _lodash.default)(studios) || (0, _lodash.default)(project)) {
      throw new Error('Failed to load page resources');
    }

    yield (0, _effects.put)(internalActions.loadPageSuccess({
      project,
      billingRates
    }));
  } catch (e) {
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.loadPageFailure(err));
    return err;
  }
}

var LOAD = 'project-crud.load';
exports.LOAD = LOAD;
var LOAD_REQUEST = 'project-crud.load.request';
exports.LOAD_REQUEST = LOAD_REQUEST;
var LOAD_SUCCESS = 'project-crud.load.success';
exports.LOAD_SUCCESS = LOAD_SUCCESS;
var LOAD_FAILURE = 'project-crud.load.failure';
exports.LOAD_FAILURE = LOAD_FAILURE;
var UPDATE_MAIN = 'project-crud.update-main';
exports.UPDATE_MAIN = UPDATE_MAIN;
var UPDATE_SIDEBAR = 'project-crud.update-sidebar';
exports.UPDATE_SIDEBAR = UPDATE_SIDEBAR;
var UPDATE_BILLING_RATES = 'project-crud.update-billing-rates';
exports.UPDATE_BILLING_RATES = UPDATE_BILLING_RATES;
var INITIAL_STATE = {
  isLoading: false,
  loadFailed: false,
  main: {
    posterFile: ''
  },
  sidebar: {
    project: {},
    studio: {}
  }
};
exports.INITIAL_STATE = INITIAL_STATE;

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case LOAD_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true,
        billingRates: null
      });

    case LOAD_SUCCESS:
      {
        var {
          billingRates
        } = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          isLoading: false,
          loadFailed: false,
          billingRates
        });
      }

    case LOAD_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false,
        loadFailed: true
      });

    case UPDATE_MAIN:
      return _objectSpread(_objectSpread({}, state), {}, {
        main: _objectSpread({}, action.payload)
      });

    case UPDATE_SIDEBAR:
      return _objectSpread(_objectSpread({}, state), {}, {
        sidebar: _objectSpread({}, action.payload)
      });

    case UPDATE_BILLING_RATES:
      {
        var _billingRates = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          billingRates: _billingRates
        });
      }

    default:
      return state;
  }
}

var actions = {
  loadPageData: (0, _reduxActions.createAction)(LOAD),
  updateMain: (0, _reduxActions.createAction)(UPDATE_MAIN),
  updateSidebar: (0, _reduxActions.createAction)(UPDATE_SIDEBAR),
  updateBillingRates: (0, _reduxActions.createAction)(UPDATE_BILLING_RATES)
};
/**
 * Actions that should only be invoked internally
 */

exports.actions = actions;
var internalActions = {
  loadPageRequest: (0, _reduxActions.createAction)(LOAD_REQUEST),
  loadPageSuccess: (0, _reduxActions.createAction)(LOAD_SUCCESS),
  loadPageFailure: (0, _reduxActions.createAction)(LOAD_FAILURE)
};
var selectors = {
  isLoading,
  isReady,
  getPosterFile,
  getStudio,
  getProject,
  getBillingRates
};
exports.selectors = selectors;

function isLoading(state) {
  var projectCRUDStore = state[STORE_NAME];
  return projectCRUDStore.isLoading;
}

function isReady(state, projectId) {
  if (isLoading(state)) {
    return false;
  }

  var areStudiosLoaded = _studios.selectors.isLoaded(state);

  var isProjectLoaded = projectId ? _projects.selectors.getProjectById(state, projectId) : true;
  return areStudiosLoaded && isProjectLoaded;
}

function getPosterFile(state) {
  var projectCRUDStore = state[STORE_NAME];
  return projectCRUDStore.main.posterFile;
}

function getStudio(state) {
  var projectCRUDStore = state[STORE_NAME];
  return projectCRUDStore.sidebar.studio;
}

function getProject(state) {
  var projectCRUDStore = state[STORE_NAME];
  return projectCRUDStore.sidebar.project;
}

function getBillingRates(state) {
  var projectCRUDStore = state[STORE_NAME];
  return projectCRUDStore.billingRates;
}

function* watchProjectCrud() {
  yield (0, _effects.takeLatest)(LOAD, loadPageData);
}